import React, {useState} from 'react';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import {Title, Button, Label} from '../component';
import { Tabs, Table, Select } from 'antd';
import CampaignListTable from './CampaignListTable';
import ReportTable from './ReportTable';
import AdminRemainingTable from './AdminRemainingTable';

const Campaign = () => {
    const [defaultTabs, setDefaultTabs] = useState('1');
    const [isId, setIsId] = useState(null);
    const { TabPane } = Tabs;
    const navigate = useNavigate();

    const onClickRemaining = (id) => {
        if(id) {
            setDefaultTabs('2');
            setIsId(id);
        } else {
            setDefaultTabs('1');
            setIsId(null);
        }
    }
    const onChange = (key) => {
        setDefaultTabs(key);
    };

    return(
        <div className="p-campaign">
            <div className="p-campaign__head">
                <Title title='Campaign' subTitle='List of campaign that admin added' />
                <Button title='Add Campaign' type='primary' onClick={() => navigate("/campaign-add",{state:{name:'campaign'}})} iconLeft='BoxAdd'/>
            </div>
            <div className="p-campaign__tabs">
                <Tabs className="c-tabs" defaultActiveKey='1' onChange={onChange} activeKey={defaultTabs}>
                    <TabPane tab="Overview" key="1">
                        <CampaignListTable onClickRemaining={onClickRemaining} />
                    </TabPane>
                    {/* <TabPane tab="Report" key="2">
                        <ReportTable />
                    </TabPane> */}
                    <TabPane tab="Admin Remaining" key="2">
                        <AdminRemainingTable id={isId} />
                    </TabPane>
                </Tabs>
            </div>
        </div>
    );
}

export default Campaign;