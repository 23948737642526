import React, {useState, useEffect} from 'react';
import { Button, Search, NoData, Popup, Input, Loading } from '../component';
import { Table } from 'antd';
import { Excel } from 'antd-table-saveas-excel';
import allActions from '../../actions';
import {useSelector, useDispatch} from 'react-redux';
import { Store } from 'react-notifications-component';
import addNotify from '../common/Notify/addNotify';
import { formatData } from '../../utils/StringHelper';
import { networkTxnUrl } from '../../utils/campaignUltils';
import { whitelistType } from '../../constants';
import { whitelistActionType } from "../../actions/actionType";
import CopyToClipboard from 'react-copy-to-clipboard';
import IconCopy from '../common/asset/images/icon-copy.svg';
const WhitelistTable = ({roundId, title, type, data}) => {
   
    const [isOpen, setIsOpen] = useState(true);
    const [isEdit, setIsEdit] = useState(false);
    const [isDelete, setIsDelete] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [searchValue, setSearchValue] = useState("");
    const [searchPriority, setSearchPriority] = useState([]);
    const [searchStaking, setSearchStaking] = useState([]);
    const [checkSearch, setCheckSearch] = useState("")
    const allCampaignData = useSelector(state => state.campaigns);
    const { campaignOne } = allCampaignData;
   
    
    const dispatch = useDispatch();
    let totalCommit = 0;
    // data?.dataList && data?.dataList.map((item, index) => {
    //     totalCommit += item.totalCommit;
    // });

    const columns = [
        {
            title: 'Address',
            dataIndex: 'address',
            ellipsis: true,
            render: (_, record) => ( 
                <div className='flex-address'>
                    <CopyToClipboard
                        text={record.address}
                        onCopy={() => Store.addNotification(addNotify('Copied', 'success'))}
                    >
                        <img src={IconCopy} alt="Copy" />
                    </CopyToClipboard>
                {record.address.substring(0,2)}...{record.address.substr(record.address.length - 4)} 
                </div>
            ),
        }, {
            title: type === 1 ? 'Standard Commit (BUSD)' : 'Staked',
            dataIndex: 'standardCommit',
            ellipsis: true,
            sorter: {
                compare: (a, b) => a.standardCommit - b.standardCommit,
            },
            render: (_, record) => (
                <>{formatData(record?.standardCommit, '')}</>
            ),
        }, {
            title: 'commit range (BUSD)',
            dataIndex: 'commitRange',
            ellipsis: true,
            sorter: {
                compare: (a, b) => a.commitRange - b.commitRange,
            },
            render: (_, record) => {
                if(!record.commitRange) return <>--</>;
                let _commitRangeFields = record.commitRange && record.commitRange.split('-');
                return <>{formatData(_commitRangeFields[0], '')} - {formatData(_commitRangeFields[1], '')}</>
            },
        }, {
            title: 'commited',
            dataIndex: 'commited',
            ellipsis: true,
            sorter: {
                compare: (a, b) => a.commited - b.commited,
            },
            render: (_, record) => (
                <>{record.commited && record.commited > 0 ? (record?.joinedTxn ? <a href={networkTxnUrl(campaignOne?.network, record?.joinedTxn)} target="_blank" rel="noreferrer">{formatData(record.commited, '')}</a> : formatData(record.commited, '')) : '--'}</>
            ),
        }, {
            title: 'ACTUAL FUND NEEDED',
            dataIndex: 'actualFundNeeded',
            ellipsis: true,
            sorter: {
                compare: (a, b) => a.actualFundNeeded - b.actualFundNeeded,
            },
            render: (_, record) => (
                <>{record.actualFundNeeded > 0 ? formatData(record.actualFundNeeded, '') : '--'}</>
            ),
        }, {
            title: 'Claimed remaining',
            dataIndex: 'remainingFund',
            ellipsis: true,
            sorter: (a, b) => a.remainingFund - b.remainingFund,
            render: (_, record) => (
                <>{record.remainingFund && record.remainingFund > 0 ? (record.claimedRemainingTxn ? <a href={networkTxnUrl(campaignOne?.network, record.claimedRemainingTxn)} target="_blank" rel="noreferrer">{formatData(record.remainingFund, '')}</a>  : formatData(record.remainingFund, '')) : '--'}</>
            ),            
        }, {
            title: 'REFUND',
            dataIndex: 'refund',
            ellipsis: true,
            sorter: (a, b) => a.refund - b.refund,   
            render: (_, record) => (
                <>{record.refund && record.refund > 0 ? (record.refundedTxn ? <a href={networkTxnUrl(campaignOne?.network, record.refundedTxn)} target="_blank" rel="noreferrer">{formatData(record.refund, '')}</a> : formatData(record.refund, '')) : '--'}</>
            ),         
        }, {
            title: 'signature',
            dataIndex: 'signatureJoinPool',
            ellipsis: true,  
            render: (_, record) => (
                <>{record.signatureJoinPool ? record.signatureJoinPool.substring(0,4) + "..." + record.signatureJoinPool.substr(record.signatureJoinPool.length - 4) : "--"}</>
            ),       
        }, {
            title: 'action',
            dataIndex: 'action',
            render: (_, record) => (
                <div className="c-actionTable">
                    <div className="c-actionTable__box">
                        <img src="../images/icon-more.svg" alt="" />
                        <ul className="c-actionTable__content">
                            <li onClick={() => generateSignatureOne(record)}><span>Generate signature</span></li>
                        </ul>
                    </div>
                </div>
            ),
        }
    ];

    const handleExport = () => {
        if(data?.dataList && data?.dataList.length === 0) {
            Store.addNotification(addNotify('Empty data. Export failed!', 'danger'));
            return;
        }
        
        const excel = new Excel();

        let sheetName = type===1 ? "Priority List (Out WL)" : "Staking List";
        let fileName = type===1 ? "PriorityList" : "StakingList";
        const columnsExport = [
            {
                title: 'No.',
                dataIndex: 'key',
            }, {
                title: 'Address',
                dataIndex: 'address',
            }, {
                title: type === 1 ? 'Standard Commit (BUSD)' : 'Staked',
                dataIndex: 'standardCommit',
            }, {
                title: 'Commit range (BUSD)',
                dataIndex: 'commitRange',
            }, {
                title: 'Commited',
                dataIndex: 'commited'
            }, {
                title: 'Actual fund need',
                dataIndex: 'actualFundNeeded',
            }, {
                title: 'Claimed remaining',
                dataIndex: 'remainingFund'
            }, {
                title: 'REFUND',
                dataIndex: 'refund'
            }, {
                title: 'signature',
                dataIndex: 'signatureJoinPool'
            }
        ];

        excel
        .addSheet(sheetName)
        .addColumns(columnsExport)
        .addDataSource(data?.dataList, {
            str2Percent: true
        })
        .saveAs(fileName + ".xlsx");
    };

    const generateSignatureOne = (item) => {
        if(!item.id) {
            Store.addNotification(addNotify('Invalid whitelist. Please retry!', 'danger'));
            return;
        } 

        let payload = {
            "whiteListId": parseInt(item.id)
        };
        setIsLoading(true);
        dispatch(allActions.whitelistAction.generateSignatureCommitOne(payload, (response) => {
            setIsLoading(false);
            if(response.result === true) {
                Store.addNotification(addNotify('Signature generated successfully!', 'success'));
            }
            else {
                Store.addNotification(addNotify('Signature generated fail!', 'danger'));
            }
        }));
    }
    const onChangeSearch = (e) =>{ 
        e.preventDefault()
        setSearchValue(e.target.value)
    }  
    
    const submitSearch =(e)=>{
        if(e.key === "Enter"){
        
        if(title === "Priority List"){
            setCheckSearch("priority")
            if(data?.dataList.find(x =>x.address === searchValue.toLowerCase())){
                setSearchPriority(data?.dataList.filter(x => x.address === searchValue.toLowerCase()));
            }
            else{
                setSearchPriority([]);
            }
        }
        else{
            setCheckSearch("staking")
            if(data?.dataList.find(x =>x.address === searchValue.toLowerCase())){
                setSearchStaking(data?.dataList.filter(x => x.address === searchValue.toLowerCase()));
            }
            else{
                setSearchStaking([]);
            }
        }
    }
}

    return(
        <>
        {isLoading && <Loading />}
        <div className="c-listAddress">
            <div className="c-listAddress__head">
                <div className={`c-listAddress__toggle${isOpen ? ' is-open' : ''}`} onClick={() => setIsOpen(!isOpen)}>
                    <img src="../images/icon-arrow-down.svg" alt="Arrow" />
                </div>
                <h3 className="c-listAddress__title">{title}</h3>
                <div className="c-listAddress__btn">
                    <Search placeholder='Search address' onChange={onChangeSearch} onKeyPress={submitSearch}/>
                    <Button title='Export' type='blueBorder' iconRight='Export' onClick={() => handleExport()}/>
                </div>
            </div>
            <div className={`c-listAddress__body${isOpen ? ' is-open' : ''}`}>
                {checkSearch ==="priority"  &&
                <div className="c-table c-table--whitelist">
                    <Table
                        columns={columns}
                        dataSource={searchPriority}
                        title={() => <ul className="c-listAddress__info">
                            <li>{formatData(data?.dataList.length, '')}<br/>Address</li>
                            <li>{formatData(data?.totalStandardCommit, '')}<br/>{type===2 ? "KDG" : campaignOne?.acceptCurrency}</li>
                            <li>--<br/>{campaignOne?.acceptCurrency}</li>
                            <li>{formatData(data?.totalCommited, '')}<br/>{campaignOne?.acceptCurrency}</li>
                            <li>{formatData(data?.totalActualFundNeeded, '')}<br/>{campaignOne?.acceptCurrency}</li>
                            <li>{formatData(data?.totalRemainingFund, '')}<br/>{campaignOne?.acceptCurrency}</li>
                            <li>{formatData(data?.totalRefund, '')}<br/>{campaignOne?.acceptCurrency}</li>
                            <li>{formatData(data?.signatureJoinPoolCount, '')}<br/>Signature</li>
                            <li>--</li>
                        </ul>}
                    />
                </div>}

                {checkSearch === "staking" &&
                <div className="c-table c-table--whitelist">
                    <Table
                        columns={columns}
                        dataSource={searchStaking}
                        title={() => <ul className="c-listAddress__info">
                            <li>{formatData(data?.dataList.length, '')}<br/>Address</li>
                            <li>{formatData(data?.totalStandardCommit, '')}<br/>{type===2 ? "KDG" : campaignOne?.acceptCurrency}</li>
                            <li>--<br/>{campaignOne?.acceptCurrency}</li>
                            <li>{formatData(data?.totalCommited, '')}<br/>{campaignOne?.acceptCurrency}</li>
                            <li>{formatData(data?.totalActualFundNeeded, '')}<br/>{campaignOne?.acceptCurrency}</li>
                            <li>{formatData(data?.totalRemainingFund, '')}<br/>{campaignOne?.acceptCurrency}</li>
                            <li>{formatData(data?.totalRefund, '')}<br/>{campaignOne?.acceptCurrency}</li>
                            <li>{formatData(data?.signatureJoinPoolCount, '')}<br/>Signature</li>
                            <li>--</li>
                        </ul>}
                    />
                </div>}

                {checkSearch ? null : data?.dataList && data?.dataList.length > 0 ?
                 <div className="c-table c-table--whitelist">
                    <Table
                        columns={columns}
                        dataSource={data?.dataList}
                        title={() => <ul className="c-listAddress__info">
                            <li>{formatData(data?.dataList.length, '')}<br/>Address</li>
                            <li>{formatData(data?.totalStandardCommit, '')}<br/>{type===2 ? "KDG" : campaignOne?.acceptCurrency}</li>
                            <li>--<br/>{campaignOne?.acceptCurrency}</li>
                            <li>{formatData(data?.totalCommited, '')}<br/>{campaignOne?.acceptCurrency}</li>
                            <li>{formatData(data?.totalActualFundNeeded, '')}<br/>{campaignOne?.acceptCurrency}</li>
                            <li>{formatData(data?.totalRemainingFund, '')}<br/>{campaignOne?.acceptCurrency}</li>
                            <li>{formatData(data?.totalRefund, '')}<br/>{campaignOne?.acceptCurrency}</li>
                            <li>{formatData(data?.signatureJoinPoolCount, '')}<br/>Signature</li>
                            <li>--</li>
                        </ul>}
                    />
                </div> : null}
               
            </div>
        </div>
        {isEdit ? <Popup title='Edit' icon='Edit' btnTitle='Edit' onCancel={() => setIsEdit(false)}>
            <Input name='address' value='0xB1dFdc63208C398dF95Caa577A737c3feda72E4f' />
            <Input name='busd' value='10,000,000 BUSD' />
            <Input name='token' value='100,000,000 $FADO' readonly={true} />
        </Popup> : ''}
        {isDelete ? <Popup title='Do you want delete this address?' subTitle='You can’t restore this file' icon='Delete' btnTitle='Delete' onCancel={() => setIsDelete(false)} /> : ''}
        </>
    )
}
export default WhitelistTable;