export const GET_ALL_INO    = "GET_ALL_INO";
export const GET_INO_CAMPAIGN    = "GET_INO_CAMPAIGN";
export const GET_INO_BY_ID    = "GET_INO_BY_ID";
export const GET_INO_INFO    = "GET_INO_INFO";
export const CREATE_CAMPAIGN_INO    = "CREATE_CAMPAIGN_INO";
export const EDIT_CAMPAIGN_INO    = "EDIT_CAMPAIGN_INO";
export const DELETE_CAMPAIGN_INO    = "DELETE_CAMPAIGN_INO";
export const GET_BOXES_INO    = "GET_BOXES_INO";
export const GET_BOXES_INO_BY_ID    = "GET_BOXES_INO_BY_ID";
export const CREATE_BOXES_INO    = "CREATE_BOXES_INO";
export const EDIT_BOXES_INO    = "EDIT_BOXES_INO";
export const DELETE_BOXES_INO    = "DELETE_BOXES_INO";
export const CREATE_RULE_INO    = "CREATE_RULE_INO";
export const GET_RULE_INO_BY_ID    = "GET_RULE_INO_BY_ID";
export const EDIT_RULE_INO    = "EDIT_RULE_INO";
export const DELETE_RULE_INO   = "DELETE_RULE_INO";
export const CREATE_TIMELINE_INO    = "CREATE_TIMELINE_INO";
export const GET_TIMELINE_INO_BY_ID    = "GET_TIMELINE_INO_BY_ID";
export const EDIT_TIMELINE_INO    = "EDIT_TIMELINE_INO";
export const DELETE_TIMELINE_INO   = "DELETE_TIMELINE_INO";
export const CREATE_INFOMATION_INO    = "CREATE_INFOMATION_INO";
export const EDIT_INFOMATION_INO    = "EDIT_INFOMATION_INO";
export const GET_INFOMATION_INO_BY_ID    = "GET_INFOMATION_INO_BY_ID";
export const CREATE_SETTING_INO    = "CREATE_SETTING_INO";
export const GET_SETTING_INO_BY_ID    = "GET_SETTING_INO_BY_ID";
export const EDIT_SETTING_INO    = "EDIT_SETTING_INO";
export const DELETE_SETTING_INO    = "DELETE_SETTING_INO";
export const SEARCH_CAMPAIGN_INO    = "SEARCH_CAMPAIGN_INO";
export const SEARCH_BOX_INO    = "SEARCH_BOX_INO";
export const GET_PRIORITY_LIST_BY_CAMPAIGN_ID = "GET_PRIORITY_LIST_BY_CAMPAIGN_ID";
