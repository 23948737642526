import React, { useState, useRef, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { InputCustom, Title, Button, Loading } from '../component';
import { Select, DatePicker, Form, Input, Switch } from 'antd';
import { UploadIcon } from '../common/asset/icon';
import { Tokens, Networks } from '../../constants';
import axios from 'axios';
import allActions from '../../actions';
import {useSelector, useDispatch} from 'react-redux';
import addNotify from '../common/Notify/addNotify';
import { Store } from 'react-notifications-component';
import moment from 'moment';

const InoCampaignEdit = () => {
    const [imageData, setImageData] = useState({ url: null, name: null});
    const [isLoad, setIsLoad] = useState(true);
    const { Option } = Select;
    const { RangePicker } = DatePicker;
    const inputLogo = useRef();
    const [form] = Form.useForm();
    const dispatch = useDispatch();
    let navigate = useNavigate();
    let { handle } = useParams();

    const inoCampaign = useSelector(state => state.ino?.inoById);

    useEffect(() => {
        if(handle) {
            dispatch(allActions.inoAction.getInoById(handle));
        }
    },[handle]);

    useEffect(() => {
        if(inoCampaign) {
            form.setFieldsValue(inoCampaign);
            form.setFieldsValue({
                startTime: [inoCampaign?.startTime ? moment(inoCampaign?.startTime).utc() : null, inoCampaign?.sellingTime ? moment(inoCampaign?.sellingTime).utc() : null],
                sellingTimePhase2: [inoCampaign?.sellingTimePhase2 ? moment(inoCampaign?.sellingTimePhase2).utc() : null, inoCampaign?.endTime ? moment(inoCampaign?.endTime).utc() : null],
            });
            setIsLoad(false);
        }        
    },[inoCampaign]);

    const onChangeImage = (e) => {  
        let file = e.target.files[0];
        const formData = new FormData();
        formData.append("file", file); 
        axios({
            method: 'post',
            url: process.env.REACT_APP_API_URL + `/upload-to-s3`,
            headers: {
                'accept': '*/*',
                "Authorization": `Bearer ${localStorage.getItem(Tokens.ADMIN)}`,
                "Content-Type": "multipart/form-data"
            },
            data: formData,
        }).then(res => {
            setImageData({url: res.data, name: file.name});
        }).catch(err => {
            console.log(err);
        });   
    }

    const onFinish = (values) => {
        let imgUrl = imageData.url === null ? values.logoLink : imageData.url;

        let startTime = values.startTime && values.startTime[0];
        let sellingTime = values.startTime && values.startTime[1];
        let sellingTimePhase2 = values.sellingTimePhase2 && values.sellingTimePhase2[0];
        let endTime = values.sellingTimePhase2 && values.sellingTimePhase2[1];
        values.startTime = startTime;
        values.sellingTime = sellingTime;
        values.sellingTimePhase2 = sellingTimePhase2;
        values.endTime = endTime;

        values.handle = values.handle ? values.handle : null;
        values.id = parseInt(handle);
        values.logoLink = imgUrl;
        values.maxBuyPhase2 = values.maxBuyPhase2 ? values.maxBuyPhase2 : null;
        values.networkChain = values.networkChain ? values.networkChain : null;
        values.onlyFCFS = values.onlyFCFS ? values.onlyFCFS : false;
        values.paymentContract = values.paymentContract ? values.paymentContract : null;
        values.boxContract = values.boxContract ? values.boxContract : null;
        values.inoContract = values.inoContract ? values.inoContract : null;
        
        values.startTime = values.startTime ? values.startTime._d.toISOString() : null;
        values.sellingTime = values.sellingTime ? values.sellingTime._d.toISOString() : null;
        values.sellingTimePhase2 = values.sellingTimePhase2 ? values.sellingTimePhase2._d.toISOString() : null;
        values.endTime = values.endTime ? values.endTime._d.toISOString() : null;
        values.title = values.title ? values.title : null;
        values.phase = values.phase ? values.phase : 1;

        setIsLoad(true);
        dispatch(allActions.inoAction.editCampaignIno(values, (response) => {
            if(response.status === 200){  
                navigate(`/ino-campaigns/${inoCampaign.handle}`); 
                setIsLoad(false);
            }
            else{
                Store.addNotification(addNotify(response.title, 'danger')); 
                setIsLoad(false);
            }  
        })); 

        console.log('values',values);
    }
    return(
        <>
        {isLoad ? <Loading /> : ''}
        <Form name="basic" form={form} onFinish={onFinish}>
        <div className="p-ino is-add">
            <Title title='INO Campaign’s Information' subTitle='Enter the required information below to add project. You can change it before the project ends.' />
            <Form.Item name="id" className="l-displayNone">
                <InputCustom type="hidden" value={0} />
            </Form.Item>
            <div className="p-ino__row">
                <Form.Item name="title">
                    <InputCustom title="Title" placeholder="Enter title" />
                </Form.Item>
            </div>
            <div className="p-ino__row">
                <Form.Item name="logoLink">
                    <p className="c-ttl is-font16">Logo</p>
                    <input ref={inputLogo} type='file' onChange={(e) => onChangeImage(e)} />
                    {imageData.url ? <div onClick={()=>inputLogo.current.click()} className="c-img"><img src={imageData.url} alt="" />{imageData.name}</div> : 
                    <div className="c-uploadIMG"> 
                        <div onClick={()=>inputLogo.current.click()} className="c-btn c-btn--flat">
                            <UploadIcon />Upload Logo
                        </div>
                    </div>
                    }
                </Form.Item>
            </div>
            <div className="p-ino__row">
                <Form.Item name="handle">
                    <InputCustom title="Handle" />
                </Form.Item>
            </div>
            <div className="p-ino__row"> 
                <div className="c-select">
                    <p className="c-select__title">Network</p>
                    <Form.Item name="networkChain">
                        <Select className="c-selectChange" placeholder='Select network'>
                            <Option value={Networks.BSC}><img src="../images/icon-binance.svg" alt="Binance Smart Chain" />Binance Smart Chain</Option>
                            <Option value={Networks.POLY}><img src="../images/icon-polygon.svg" alt="Polygon" />Polygon</Option>
                            <Option value={Networks.ETH}><img src="../images/icon-ethereum.svg" alt="Ethereum" />Ethereum</Option>
                            {process.env.REACT_APP_MODE ==="development" && 
                            <>
                                <Option value={Networks.ARBTESTNET}><img src="../images/icon-arb.svg" alt="Arbitrum" />ArbitrumTest</Option>
                                <Option value={Networks.ARB}><img src="../images/icon-arb.svg" alt="Arbitrum" />Arbitrum</Option>
                            </> 
                            }
                            {process.env.REACT_APP_MODE ==="production" && <Option value={Networks.ARB}><img src="../images/icon-arb.svg" alt="Arbitrum" />Arbitrum</Option>}
                        </Select>
                    </Form.Item>
                </div>
            </div>
            <div className="p-ino__row"> 
                <div className="c-select">
                    <p className="c-select__title">Phase</p>
                    <Form.Item name="phase">
                        <Select className="c-selectChange" placeholder='Select Phase'>
                            <Option value={1}>Phase 1</Option>
                            <Option value={2}>Phase 2</Option>
                        </Select>
                    </Form.Item>
                </div>
            </div>
            <div className="p-ino__row">
                <Form.Item name="paymentContract">
                    <InputCustom title="Token Payment" />
                </Form.Item>
            </div>
            <div className="p-ino__row">
                <Form.Item name="inoContract">
                    <InputCustom title="Market Contract" />
                </Form.Item>
            </div>
            <div className="p-ino__row">
                <Form.Item name="boxContract">
                    <InputCustom title="Box Contract" />
                </Form.Item>
            </div>
            <div className="p-ino__row">
                <Form.Item name="maxBuyPhase2">
                    <InputCustom title="Max Buy Phase 2" placeholder="0" />
                </Form.Item>
            </div>
            <div className="p-ino__row is-widthFull">
                <div className="c-toggle">
                    <h3 className="c-ttl is-font16">FCFS</h3>
                    <Form.Item name="onlyFCFS" valuePropName="checked" className="c-toggle__cont">
                        <Switch className="c-toggle__custom"/>
                    </Form.Item> 
                    <p className="c-toggle__title">Only FCFS</p>
                </div>
            </div>
            <div className="p-ino__row is-width">
                <h3 className="c-ttl is-font18">Selling Time</h3>
                <div className="p-ino__box">                    
                    <div className="c-date">
                        <p className="c-date__title">Selling Time Phase 1 (VN)</p>
                        <Form.Item name ="startTime"> 
                            <RangePicker showTime format='HH:mm YYYY-MM-DD' />
                        </Form.Item>
                    </div>
                    <div className="c-date">
                        <p className="c-date__title">Selling Time Phase 2 (VN)</p>
                        <Form.Item name ="sellingTimePhase2"> 
                            <RangePicker showTime format='HH:mm YYYY-MM-DD' />
                        </Form.Item>
                    </div>
                </div>
            </div>
            <div className="p-ino__btn">
            <div className="c-btn c-btn--grey" onClick={() => navigate(`/ino-campaigns/${inoCampaign.handle}`)}>Cancel</div>
                <Button title='Submit' type='blue' />
            </div>
        </div>
        </Form>
        </>
    )
}

export default InoCampaignEdit;