import AirdropService from "../services/AirdropService";
import {
    GET_BANNER_LIST, 
    GET_BANNER_ID,
    GET_AIRDROP_LIST,
    GET_AIRDROP_BY_HANDLE,
    GET_WHITELIST_BY_ID
} from "./airdropType";

const getBannerList = (account) => async (dispatch) => {
  try {
    const res = await AirdropService.getBannerList(account);  
    dispatch({
      type: GET_BANNER_LIST,
      payload: res && res.data || []
    });
  } catch (err) {
    console.log(err);
  }
}


const postBanner = (payload) => async (dispatch) => {
  try {
    const res = await AirdropService.postBanner(payload); 
  } catch (err) {
    console.log(err);
  }
}

const getBannerId = (id) => async (dispatch) => {
  try {
    const res = await AirdropService.getBannerId(id); 
    dispatch({
      type: GET_BANNER_ID,
      payload: res && res.data || []
    });
  } catch (err) {
    console.log(err);
  }
}

const updateBanner = (id, payload, onCallBack) => async (dispatch) => {
  try {
    const res = await AirdropService.updateBanner(id, payload); 
    onCallBack(res.data)
  } catch (err) {
    console.log(err);
  }
}

const deleteBanner = (id, onCallBack) => async (dispatch) => {
  try {
    const res = await AirdropService.deleteBanner(id);  
    onCallBack(res)
  } catch (err) {
    onCallBack(err) 
  }
} 

const getAirdrop = () => async (dispatch) => {
  try {
    const res = await AirdropService.getAirdrop();  
    dispatch({
      type: GET_AIRDROP_LIST,
      payload: res && res.data || []
    });
  } catch (err) {
    console.log(err) 
  }
} 

const getAirdropByHandle = (payload) => async (dispatch) => {
  try {
    const res = await AirdropService.getAirdropByHandle(payload);  
    dispatch({
      type: GET_AIRDROP_BY_HANDLE,
      payload: res && res.data || []
    });
  } catch (err) {
    console.log(err) 
  }
}  
 
const createAirdrop = (payload, onCallBack) => async (dispatch) => {
  try {  
    const res = await AirdropService.createAirdrop(payload);  
    onCallBack(res)
  } catch (err) {
    onCallBack(err.response.data);
  }
}

const editAirdrop = (id, payload, onCallBack) => async (dispatch) => {
  try {
    const res = await AirdropService.editAirdrop(id, payload); 
    dispatch({
      type: GET_AIRDROP_BY_HANDLE,
      payload: res && res.data || []
    });
    onCallBack(res)
  } catch (err) {
    onCallBack(err.response.data) 
  }
}

const deleteAirdrop = (id, onCallBack) => async (dispatch) => {
  try {
    const res = await AirdropService.deleteAirdrop(id);  
    onCallBack(res)
  } catch (err) {
    onCallBack(err.response.data); 
  }
}  

const getAddressWhiteList = (id) => async (dispatch) => {
  try {
    const res = await AirdropService.getAddressWhiteList(id);
    dispatch({
      type: GET_WHITELIST_BY_ID,
      payload: res && res.data || []
    });
  } catch (err) {
    console.log(err.response.data);
  }
} 

const updateAddressWhiteList = (id, payload, onCallBack) => async (dispatch) => {
  try {
    const res = await AirdropService.updateAddressWhiteList(id, payload);
    onCallBack(res)
  } catch (err) {
    onCallBack(err.response.data);
  }
} 

const deleteAddressWhiteList = (id, onCallBack) => async (dispatch) => {
  try {
    const res = await AirdropService.deleteAddressWhiteList(id);
    onCallBack(res);
  } catch (err) {
    console.log(err.response.data);
  }
} 

const searchPriority = (id, payload) => async (dispatch) => {
  try {
    const res = await AirdropService.searchPriority(id, payload);
    dispatch({
      type: GET_WHITELIST_BY_ID,
      payload: res && res.data || []
    });
  } catch (err) {
    console.log(err)
  }
}  



const campaignAction = {
    getBannerList,
    postBanner,
    updateBanner,
    deleteBanner,
    getBannerId,
    getAirdrop,
    getAirdropByHandle,
    createAirdrop,
    editAirdrop,
    deleteAirdrop,
    getAddressWhiteList,
    updateAddressWhiteList,
    deleteAddressWhiteList,
    searchPriority
};

export default campaignAction;