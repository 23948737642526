import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, NoData, InputCustom, Loading, Popup } from '../component';
import { Form } from 'antd';
import allActions from '../../actions';
import {useDispatch} from 'react-redux';
import addNotify from '../common/Notify/addNotify';
import { Store } from 'react-notifications-component';

const InoSetting = ({data, inoCampaign}) => {
    const [addSetting, setAddSetting] = useState(false);
    const [isLoad, setIsLoad] = useState(false);
    const [deleteItem, setDeleteItem] = useState(null);
    const dispatch = useDispatch();
    let navigate = useNavigate();

    const onFinishAdd = (values) => {
        setIsLoad(true);        

        values.inoLaunchpad = inoCampaign;
        values.totalStaking = values.totalStaking ? parseInt(values.totalStaking) : null;
        values.totalBox = values.totalBox ? parseInt(values.totalBox) : null;
        dispatch(allActions.inoAction.createSettingIno(values, (response) => {
            if(response.status === 201){  
                setIsLoad(false);
                window.location.reload();
            }
            else{
                Store.addNotification(addNotify(response.title, 'danger')); 
                setIsLoad(false);
            }  
        }));
    }

    return(
        <>
        {isLoad ? <Loading /> : ''} 
        <div className="c-inoBoxInfo">
            <div className="c-inoBoxInfo__head">
                <h3 className="c-ttl is-font16">Staking Condition</h3>
                <div className="c-inoBoxInfo__btn">
                    <Button title='Add Staking' type='flat' iconLeft='BoxAdd' onClick={() => setAddSetting(true)}/>
                </div>
            </div>
            <div className="c-inoBoxInfo__box">
                {data && data.length > 0 ? <>
                    <div className="c-inoBoxInfo__ttl">
                        <p>Total Staking</p>
                        <p>Total Boxes</p>
                    </div>
                    {data && data.length > 0 && data.map((item, key) => (
                        <div className="c-inoBoxInfo__row" key={key}>
                            <p>{item.totalStaking}</p>
                            <p>{item.totalBox}</p>
                            <p className="is-btn">
                                <span onClick={() => navigate(`/ino-campaigns/${inoCampaign.handle}/setting/${item.id}`)}><img src="../images/edit.png" alt="" /></span>
                                <span onClick={() => setDeleteItem(item.id)}><img src="../images/delete.png" alt="" /></span>
                            </p>
                        </div>
                    ))}
                </> : <NoData />}                
            </div>
        </div>
        {addSetting ? <Form name="basic" onFinish={onFinishAdd}>
            <Popup title='Add Staking Condition' btnTitle='Add Staking Condition' onCancel={() => setAddSetting(false)}>
                <Form.Item name="totalStaking">
                    <InputCustom type='number' placeholder='Total staking' />
                </Form.Item>
                <Form.Item name="totalBox">
                    <InputCustom type='number' placeholder='Total box' />
                </Form.Item>
            </Popup>
        </Form> : ''}

        {deleteItem && <Popup 
            title='Do you want delete this rule?' 
            subTitle='You can’t restore this file' 
            btnTitle='Delete' icon='Delete' 
            onCancel={() => setDeleteItem(null)}
            onClick={() => {
                dispatch(allActions.inoAction.deleteSettingIno(deleteItem));
                setIsLoad(true);
                setTimeout(() => {
                    window.location.reload(true);
                }, 3000);}
            }
        />}
        </>
    )
}

export default InoSetting;