import React, { useState, useRef, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Title, Button, NoData, Search, Popup , InputCustom} from '../component';
import { Select, Table, Form } from 'antd';
import { UploadIcon } from '../common/asset/icon';
import allActions from '../../actions';
import {useSelector, useDispatch} from 'react-redux';
import {Tokens} from '../../constants';
import axios from 'axios';

const BoxesEdit = () => {
    const [imageData, setImageData] = useState({ url: null, name: null});
    let navigate = useNavigate();
    const { Option } = Select;
    const inputRef = useRef();
    const dispatch = useDispatch();
    const { handle } = useParams();
    const [form] = Form.useForm();

    const inoBoxesById = useSelector(state => state.ino?.inoBoxesById);
    const inoList = useSelector(state => state.ino?.inoListCampaign);

    useEffect(() => {
        if(handle) {
            dispatch(allActions.inoAction.getInoBoxesById(handle));
        } 
        dispatch(allActions.inoAction.getInoLuanchpad());
    },[handle]);

    useEffect(() => {
        form.setFieldsValue(inoBoxesById);
        form.setFieldsValue({
            inoLaunchpad: inoBoxesById?.inoLaunchpad.id,        
        });
    },[inoBoxesById]);

    const onChangeImage = (e) => { 
        let file = e.target.files[0];
        const formData = new FormData();
        formData.append("file", file); 
        axios({
            method: 'post',
            url: process.env.REACT_APP_API_URL + `/upload-to-s3`,
            headers: {
                'accept': '*/*',
                "Authorization": `Bearer ${localStorage.getItem(Tokens.ADMIN)}`,
                "Content-Type": "multipart/form-data"
            },
            data: formData,
        }).then(res => {
            setImageData({url: res.data, name: file.name}); 
        }).catch(err => {
            console.log(err);
        });   
    }

    console.log(inoBoxesById);

    return(
        <Form name="basic" form={form}>
            <div className="c-popup">
                <div className="c-popup__box">
                    <div className="c-popup__close" onClick={() => navigate('/boxes-list')}><img src="../images/icon-close.svg" alt="Close" /></div>
                    <div className="c-popup__icon">
                        <img src="../images/icon-popup-add.svg" alt="Icon" />
                    </div>
                    <h3 className="c-popup__title">Edit Box</h3>
                    <div className="c-popup__content">
                        <Form.Item name="id" className="l-displayNone">
                            <InputCustom type="hidden" />
                        </Form.Item>
                        <Form.Item name="imageLink">
                            <input ref={inputRef} type='file' onChange={(e) => onChangeImage(e)} />
                            {imageData.url ? <div onClick={()=>inputRef.current.click()} className="c-img">
                                <img  src={imageData.url} alt="" />{imageData.name}</div> : 
                            <div onClick={()=>inputRef.current.click()} className="c-uploadIMG"> 
                                <div  className="c-btn c-btn--flat">
                                    <UploadIcon />Upload Logo Box
                                </div>
                            </div>
                            }
                        </Form.Item>
                        <Form.Item name="name">
                            <InputCustom placeholder='Box name' />
                        </Form.Item>
                        <Form.Item name="total">
                            <InputCustom placeholder='Total sale' />
                        </Form.Item>
                        <Form.Item name="currency">
                            <InputCustom placeholder='Currency' />
                            {/* <Select className="c-selectChange" placeholder='Currency'>
                                <Option value='busd'>BUSD</Option>
                                <Option value='bcf'>BCF</Option>
                                <Option value='zwz'>ZWZ</Option>
                            </Select> */}
                        </Form.Item>
                        <Form.Item name="price">
                            <InputCustom placeholder='Price' />
                        </Form.Item>
                        <Form.Item name="description">
                            <InputCustom placeholder='Desciption' />
                        </Form.Item>
                        <Form.Item name="inoLaunchpad">
                            <Select className="c-selectChange" placeholder='INO Launchpad'>
                                {inoList && inoList.map((item,key) => (
                                    <Option key={key} value={item.id}>{item.title}</Option>
                                ))}
                                <Option value='bcf'>BCF</Option>
                                <Option value='zwz'>ZWZ</Option>
                            </Select>
                        </Form.Item>
                    </div>          
                    <div className="c-popup__btn">
                        <div className="c-btn c-btn--greyBorder" onClick={() => navigate('/boxes-list')}>Cancel</div>
                        <Button title='Edit' type='primary' htmlType='submit' />
                    </div>
                </div>
            </div>
        </Form>
    )
}

export default BoxesEdit;