import React from 'react';
import {useNavigate } from 'react-router-dom';
import {Title, Button} from '../component'; 
import SaveDraftListTable from './SaveDraftListTable';
// import ReportTable from './ReportTable'; 

const SaveDraft = () => { 
    const navigate = useNavigate();
    return(
        <div className="p-campaign">
            <div className="p-campaign__head">
                <Title title='Draft Saved' subTitle='List of campaign that saved draft before' />
                <Button title='Add Draft' type='primary' onClick={() => navigate("/campaign-add",{state:{name:'savedraft'}})} iconLeft='BoxAdd'/>
            </div>
            <div className="p-campaign__tabs"> 
                <SaveDraftListTable /> 
            </div>
        </div>
    );
}

export default SaveDraft;