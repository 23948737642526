import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useSearchParams, useNavigate } from 'react-router-dom';
import { Button, Title, NoData, Search } from '../../component';
import { Select, Table } from 'antd';
import { Store } from 'react-notifications-component';
import addNotify from '../../common/Notify/addNotify';
import {useSelector, useDispatch} from 'react-redux';
import allActions from '../../../actions';

const InoWhitelist = () => {
    const { Option } = Select;
    let [searchParams, setSearchParams] = useSearchParams();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    let campaignId = searchParams.get("campaign");
    const [campaignSelected, setCampaignSelected] = useState(campaignId);

    const inoList = useSelector(state => state.ino?.inoListCampaign);

    useEffect(() => {
        dispatch(allActions.inoAction.getInoLuanchpad());
    },[]);
    
    const columns = [
        {
            title: 'Address',
            dataIndex: 'address',
            ellipsis: true,
            render: (text) => <>{text ? text : '--'}</>,
        }, {
            title: 'Standard BoXES (Boxes)',
            dataIndex: 'boxes',
            ellipsis: true,
            render: (text) => <>{text ? text : '--'}</>,
        }, {
            title: 'action',
            dataIndex: 'action',
            render: (_, record) => (
                <div className="c-actionTable">
                    <div className="c-actionTable__box">
                        <img src="../images/icon-more.svg" alt="" />
                        <ul className="c-actionTable__content">
                            <li><Link to={`#`}>Edit</Link></li>
                            <li><Link to={`#`}>Delete</Link></li>
                        </ul>
                    </div>
                </div>
            )
        }
    ];

    const data = [
        {
            id: '1',
            address: '0x87...8Fb6',
            boxes: '500'
        },
    ];

    const handlePriorityList = () => {

        if(!campaignId || campaignId == 'null') {
            Store.addNotification(addNotify('Please select INO Campaign! ', 'danger'));
            return;
        }

        navigate(`/ino-priority-list?campaign=${campaignId}`);
    }

    const onChangCampaign = (id) => {
        if(id) {
            // dispatch(allActions.roundAction.getRoundsByCampaignsId(id));
            setSearchParams({'campaign': id});
            setCampaignSelected(id);
        }
    }

    return(
        <div className="p-inoWhitelist">
            <div className="p-inoWhitelist__head">
                <Title title='Whitelist' subTitle='The list of wallet addresses participating in the whitelist, you can also add out the whitelist and edit the information' />
                <div className="p-inoWhitelist__btn">
                    <Button title='Priority list' type='primary' onClick={() => handlePriorityList()}/>
                    <Button title='Update data' type='blueBorder' iconLeft='Refresh' disabled/>
                    <Button title='Generate Whitelist' type='blueBorder' iconLeft='Refresh' disabled/>
                </div>
                <div className="p-inoWhitelist__timeStaking">
                    STAKING DEADLINE:<span>00d</span>:<span>20h</span>:<span>00m</span>:<span>00s</span>
                </div>
                <div className="p-inoWhitelist__boxHead">
                    <div className="c-select">
                        <p className="c-select__title">Campaign</p>
                        <Select className="c-selectChange" style={{width: 260}} placeholder='Select Campaign' 
                            defaultValue={campaignSelected ? parseInt(campaignSelected) : null} 
                            onChange={(value) => onChangCampaign(value)}
                        >
                            {inoList && inoList.length > 0 && inoList.map((item, index) => (
                                <Option key={index} value={item.id}>{item.title}</Option>
                            ))}
                        </Select>
                    </div>
                    {/* <div className="c-select">
                        <p className="c-select__title">Phases</p>
                        <Select className="c-selectChange" style={{width: 260}} placeholder='Select Phases'>
                            <Option value="1">1</Option>
                            <Option value="2">2</Option>
                        </Select>
                    </div> */}
                    <ul>
                        <li>
                            <span>Total Address<img src="../images/icon-gps.svg" alt="Total Address" /></span>
                            0
                        </li>
                        <li className="is-active">
                            <span>Total Boxes<img src="../images/icon-coin.svg" alt="Total BUSD" /></span>
                            0
                        </li>
                    </ul>
                </div>
            </div>
            <div className="p-inoWhitelist__table">
                <div className="c-table">
                    <div className="c-table__head">
                        <h3 className="c-ttl">Priority List</h3>
                        <span>
                            <Search placeholder='Search address' />
                            <Button title='Export' type='blueBorder' iconRight='Export' />
                        </span>
                    </div>
                    {data.length > 0 ? <Table rowKey='id' columns={columns} dataSource={data} /> : <NoData /> }
                </div>
            </div>
            <div className="p-inoWhitelist__table">
                <div className="c-table">
                    <div className="c-table__head">
                        <h3 className="c-ttl">Staking List</h3>
                        <span>
                            <Search placeholder='Search address' />
                            <Button title='Delete All' type='blueBorder' iconRight='Delete' />
                            <Button title='Export' type='blueBorder' iconRight='Export' />
                        </span>
                    </div>
                    {data.length > 0 ? <Table rowKey='id' columns={columns} dataSource={data} /> : <NoData /> }
                </div>
            </div>
        </div>
    )
}

export default InoWhitelist;