import AuthService from "../services/AuthService";
import {LocalStorageKey, loggedConstant, Routes, Tokens} from '../constants'
import { authActionType } from "./actionType";
import { getLocalStorageDataByEncryptKey } from "../utils/sharedMethod";
import { setUserProfile } from "./localStorageAction";

const getUserAuthenticate = (payload) => async (dispatch) => {
    try {
      const res = await AuthService.authenticate(payload);
      if(res && res.status === 200) {
        localStorage.setItem(Tokens.ADMIN, res.data && res.data.token);
        dispatch({
          type: authActionType.LOGIN_SUCCESS,
          payload: res.data
        });
      }
      
    } catch (err) {
      console.log(err);
    }
}

const login = (user, navigate) => async (dispatch) => {
  const { username, password } = user;
  return AuthService.authenticate({ username, password })
  .then(
    (response) => {
      if (user.rememberMe) {
          localStorage.setItem('currentUser', btoa(JSON.stringify(user)));
      } else {
          if (getLocalStorageDataByEncryptKey('currentUser')) {
              localStorage.removeItem('currentUser');
          }
      }
      localStorage.setItem(Tokens.ADMIN, response.data.token);
      localStorage.removeItem(loggedConstant.IS_USER_LOGOUT);
      dispatch(setUserProfile(LocalStorageKey.USER, response.data.token));
      dispatch({ type: authActionType.LOGIN_SUCCESS, payload: response.data.token });
      // dispatch(addToast({ text: getFormattedMessage('login.success.logged.message') }));
      // dispatch(fetchConfig());
      if (sessionStorage.getItem('prevAdminPrevUrl')) {
          window.location.href = sessionStorage.getItem('prevAdminPrevUrl');
      } else {
          navigate(Routes.ADMIN_DEFAULT);
      }
      return Promise.resolve();
    },
    (error) => {
        const message = (error.response && error.response.data &&
          error.response.data.message) || error.message || error.toString();
        dispatch({type: authActionType.LOGIN_FAIL,});
        // dispatch({type: SET_MESSAGE,payload: message,});
        return Promise.reject();
    }
  );
}; 

const logout = () => (dispatch) => {
  AuthService.logout();
  dispatch({ type: authActionType.LOGOUT });
};

export default {
  getUserAuthenticate,
  login,
  logout
}