import React from 'react';
import { Title } from '../component';
import { Checkbox } from 'antd';

const AccountEdit = () => {
    return(
        <div className="p-account">
            <Title title="Edit Permission" subTitle="Edit role’s permissions" />
            <div className="p-account__table c-table">
                <table>
                    <thead>
                        <tr>
                            <th className="is-borderRight">Roles</th>
                            <th className="is-borderRight"></th>
                            <th>CEO</th>
                            <th>Assit CEO</th>
                            <th>Marketing</th>
                            <th>sub marketing</th>
                            <th>system admin</th>
                            <th>super admin</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td rowSpan="3" className="is-borderRight is-rowSpan">Campaign</td>
                            <td className="is-borderRight">View</td>
                            <td><Checkbox defaultChecked={true} /></td>
                            <td><Checkbox /></td>
                            <td><Checkbox /></td>
                            <td><Checkbox /></td>
                            <td><Checkbox /></td>
                            <td><Checkbox /></td>
                        </tr>
                        <tr>
                            <td className="is-borderRight">Add/Edit/Delete</td>
                            <td><Checkbox /></td>
                            <td><Checkbox /></td>
                            <td><Checkbox /></td>
                            <td><Checkbox /></td>
                            <td><Checkbox /></td>
                            <td><Checkbox /></td>
                        </tr>
                        <tr>
                            <td className="is-borderRight">Run/Stop/Kill Job</td>
                            <td><Checkbox /></td>
                            <td><Checkbox /></td>
                            <td><Checkbox /></td>
                            <td><Checkbox /></td>
                            <td><Checkbox /></td>
                            <td><Checkbox /></td>
                        </tr>
                        <tr>
                            <td rowSpan="3" className="is-borderRight is-rowSpan">Whitelist</td>
                            <td className="is-borderRight">View</td>
                            <td><Checkbox defaultChecked={true} /></td>
                            <td><Checkbox /></td>
                            <td><Checkbox /></td>
                            <td><Checkbox /></td>
                            <td><Checkbox /></td>
                            <td><Checkbox /></td>
                        </tr>
                        <tr>
                            <td className="is-borderRight">Add/Edit/Delete</td>
                            <td><Checkbox /></td>
                            <td><Checkbox /></td>
                            <td><Checkbox /></td>
                            <td><Checkbox /></td>
                            <td><Checkbox /></td>
                            <td><Checkbox /></td>
                        </tr>
                        <tr>
                            <td className="is-borderRight">Run/Stop/Kill Job</td>
                            <td><Checkbox /></td>
                            <td><Checkbox /></td>
                            <td><Checkbox /></td>
                            <td><Checkbox /></td>
                            <td><Checkbox /></td>
                            <td><Checkbox /></td>
                        </tr>
                        <tr>
                            <td rowSpan="3" className="is-borderRight is-rowSpan">Allocation</td>
                            <td className="is-borderRight">View</td>
                            <td><Checkbox defaultChecked={true} /></td>
                            <td><Checkbox /></td>
                            <td><Checkbox /></td>
                            <td><Checkbox /></td>
                            <td><Checkbox /></td>
                            <td><Checkbox /></td>
                        </tr>
                        <tr>
                            <td className="is-borderRight">Add/Edit/Delete</td>
                            <td><Checkbox /></td>
                            <td><Checkbox /></td>
                            <td><Checkbox /></td>
                            <td><Checkbox /></td>
                            <td><Checkbox /></td>
                            <td><Checkbox /></td>
                        </tr>
                        <tr>
                            <td className="is-borderRight">Run/Stop/Kill Job</td>
                            <td><Checkbox /></td>
                            <td><Checkbox /></td>
                            <td><Checkbox /></td>
                            <td><Checkbox /></td>
                            <td><Checkbox /></td>
                            <td><Checkbox /></td>
                        </tr>
                        <tr>
                            <td rowSpan="3" className="is-borderRight is-rowSpan">Token Inform</td>
                            <td className="is-borderRight">View</td>
                            <td><Checkbox defaultChecked={true} /></td>
                            <td><Checkbox /></td>
                            <td><Checkbox /></td>
                            <td><Checkbox /></td>
                            <td><Checkbox /></td>
                            <td><Checkbox /></td>
                        </tr>
                        <tr>
                            <td className="is-borderRight">Add/Edit/Delete</td>
                            <td><Checkbox /></td>
                            <td><Checkbox /></td>
                            <td><Checkbox /></td>
                            <td><Checkbox /></td>
                            <td><Checkbox /></td>
                            <td><Checkbox /></td>
                        </tr>
                        <tr>
                            <td className="is-borderRight">Run/Stop/Kill Job</td>
                            <td><Checkbox /></td>
                            <td><Checkbox /></td>
                            <td><Checkbox /></td>
                            <td><Checkbox /></td>
                            <td><Checkbox /></td>
                            <td><Checkbox /></td>
                        </tr>
                        <tr>
                            <td rowSpan="3" className="is-borderRight is-rowSpan">Setting</td>
                            <td className="is-borderRight">View</td>
                            <td><Checkbox defaultChecked={true} /></td>
                            <td><Checkbox /></td>
                            <td><Checkbox /></td>
                            <td><Checkbox /></td>
                            <td><Checkbox /></td>
                            <td><Checkbox /></td>
                        </tr>
                        <tr>
                            <td className="is-borderRight">Add/Edit/Delete</td>
                            <td><Checkbox /></td>
                            <td><Checkbox /></td>
                            <td><Checkbox /></td>
                            <td><Checkbox /></td>
                            <td><Checkbox /></td>
                            <td><Checkbox /></td>
                        </tr>
                        <tr>
                            <td className="is-borderRight">Run/Stop/Kill Job</td>
                            <td><Checkbox /></td>
                            <td><Checkbox /></td>
                            <td><Checkbox /></td>
                            <td><Checkbox /></td>
                            <td><Checkbox /></td>
                            <td><Checkbox /></td>
                        </tr>
                        <tr>
                            <td rowSpan="3" className="is-borderRight is-rowSpan">Commit<br/>Range</td>
                            <td className="is-borderRight">View</td>
                            <td><Checkbox defaultChecked={true} /></td>
                            <td><Checkbox /></td>
                            <td><Checkbox /></td>
                            <td><Checkbox /></td>
                            <td><Checkbox /></td>
                            <td><Checkbox /></td>
                        </tr>
                        <tr>
                            <td className="is-borderRight">Add/Edit/Delete</td>
                            <td><Checkbox /></td>
                            <td><Checkbox /></td>
                            <td><Checkbox /></td>
                            <td><Checkbox /></td>
                            <td><Checkbox /></td>
                            <td><Checkbox /></td>
                        </tr>
                        <tr>
                            <td className="is-borderRight">Run/Stop/Kill Job</td>
                            <td><Checkbox /></td>
                            <td><Checkbox /></td>
                            <td><Checkbox /></td>
                            <td><Checkbox /></td>
                            <td><Checkbox /></td>
                            <td><Checkbox /></td>
                        </tr>
                        <tr>
                            <td rowSpan="3" className="is-borderRight is-rowSpan">Time<br/>Schedule</td>
                            <td className="is-borderRight">View</td>
                            <td><Checkbox defaultChecked={true} /></td>
                            <td><Checkbox /></td>
                            <td><Checkbox /></td>
                            <td><Checkbox /></td>
                            <td><Checkbox /></td>
                            <td><Checkbox /></td>
                        </tr>
                        <tr>
                            <td className="is-borderRight">Add/Edit/Delete</td>
                            <td><Checkbox /></td>
                            <td><Checkbox /></td>
                            <td><Checkbox /></td>
                            <td><Checkbox /></td>
                            <td><Checkbox /></td>
                            <td><Checkbox /></td>
                        </tr>
                        <tr>
                            <td className="is-borderRight">Run/Stop/Kill Job</td>
                            <td><Checkbox /></td>
                            <td><Checkbox /></td>
                            <td><Checkbox /></td>
                            <td><Checkbox /></td>
                            <td><Checkbox /></td>
                            <td><Checkbox /></td>
                        </tr>
                        <tr>
                            <td rowSpan="3" className="is-borderRight is-rowSpan">System<br/>Config</td>
                            <td className="is-borderRight">View</td>
                            <td><Checkbox defaultChecked={true} /></td>
                            <td><Checkbox /></td>
                            <td><Checkbox /></td>
                            <td><Checkbox /></td>
                            <td><Checkbox /></td>
                            <td><Checkbox /></td>
                        </tr>
                        <tr>
                            <td className="is-borderRight">Add/Edit/Delete</td>
                            <td><Checkbox /></td>
                            <td><Checkbox /></td>
                            <td><Checkbox /></td>
                            <td><Checkbox /></td>
                            <td><Checkbox /></td>
                            <td><Checkbox /></td>
                        </tr>
                        <tr>
                            <td className="is-borderRight">Run/Stop/Kill Job</td>
                            <td><Checkbox /></td>
                            <td><Checkbox /></td>
                            <td><Checkbox /></td>
                            <td><Checkbox /></td>
                            <td><Checkbox /></td>
                            <td><Checkbox /></td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    )
}

export default AccountEdit;