import { userActionType } from "../actions/actionType";

const user = (state = {}, action) => {
    switch(action.type){
        case userActionType.GET_USER_LIST:
            return {
                ...state,
                userList: action.payload
            } 

        default:
            return state
    }
}

export default user;