import React, {useState} from 'react';
import { Button, Search, NoData, Popup, Input, Loading, FormDataPopup, InputCustom } from '../component';
import { Table, Form } from 'antd';
import { Excel } from 'antd-table-saveas-excel';
import allActions from '../../actions';
import {useSelector, useDispatch} from 'react-redux';
import { Store } from 'react-notifications-component';
import addNotify from '../common/Notify/addNotify';
import { formatData } from '../../utils/StringHelper';

const PriorityListTable = ({roundId, title, type, data}) => {
    const [isOpen, setIsOpen] = useState(true);
    const [isEdit, setIsEdit] = useState(false);
    const [itemSelected, setItemSelected] = useState(null);
    const [isDelete, setIsDelete] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [searchValue, setSearchValue] = useState("");
    const dispatch = useDispatch();

    const columns = [
        {
            title: 'Address',
            dataIndex: 'address',
            ellipsis: true,
            render: (_, record) => (
                <>{record.address.substring(0,4)}...{record.address.substr(record.address.length - 4)}</>
            ),
        }, {
            title: 'Standard Commit (BUSD)',
            dataIndex: 'standardCommit',
            ellipsis: true,
            sorter: {
                compare: (a, b) => a.standardCommit - b.standardCommit,
            },
            render: (_, record) => (
                <>{formatData(record.standardCommit, '')}</>
            ),
        }, {
            title: 'action',
            dataIndex: 'action',
            render: (_, record) => (
                <div className="c-actionTable">
                    <div className="c-actionTable__box">
                        <img src="../images/icon-more.svg" alt="" />
                        <ul className="c-actionTable__content">
                            <li onClick={() => handleEdit(record)}><span>Edit</span></li>
                            <li onClick={() => handleDeletePopup(record)}><span>Delete</span></li>
                        </ul>
                    </div>
                </div>
            ),
        }
    ];

    const handleExport = () => {
        setIsLoading(true);
        const excel = new Excel();

        let sheetName = 'Priority List (Out WL)';
        let fileName = 'PriorityList';
        const columnsExport = [
            {
                title: 'No.',
                dataIndex: 'key',
            }, {
                title: 'Address',
                dataIndex: 'address',
            }, {
                title: 'Standard Commit (BUSD)',
                dataIndex: 'standardCommit',
            }
        ];

        excel
        .addSheet(sheetName)
        .addColumns(columnsExport)
        .addDataSource(data, {
            str2Percent: true
        })
        .saveAs(fileName + ".xlsx");
        
        setTimeout(() => {
            setIsLoading(false);
        }, 1000);
    };

    const handleEdit = (item) => {
        setIsEdit(true);
        setItemSelected(item);
    }

    const handleDeletePopup = (item) => {
        setIsDelete(true);
        setItemSelected(item);
    }

    const handleDelete = () => {
        setIsLoading(true);
        dispatch(allActions.snapshotAction.deletePriority(itemSelected.id, (res)=> {
            setIsLoading(false);
            setIsDelete(false);
            if(res.callResult == true) {
                dispatch(allActions.snapshotAction.getPriorityListByRoundId(roundId));
            }
        }));
    }

    const handleDeleteAll = () => {
        setIsLoading(true);
        dispatch(allActions.snapshotAction.deleteAllPriority(roundId, "OUT_SNAPSHOT", (res)=> {
            setIsLoading(false);
            if(res.callResult == true) {
                dispatch(allActions.snapshotAction.getPriorityListByRoundId(roundId));
            }
        }));
    }

    const onFinish = (values) => {
        setIsLoading(true);
        dispatch(allActions.snapshotAction.updatePriority(values));

        setIsEdit(false);
        setTimeout(() => {
            setIsLoading(false);
            dispatch(allActions.snapshotAction.getPriorityListByRoundId(roundId));
        }, 3000);
    }; 
    
    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };

    const onChangeValue = (e)=>{
        e.preventDefault()
        setSearchValue(e.target.value)
    }

    const submitSearch =()=>{
        const payload = {
            roundId: roundId,
            address: searchValue,
        }
        dispatch(allActions.snapshotAction.searchPriority(payload, (response) => {
            if(response.callResult === true){

            }
        }))
    }

    return(
        <>
        {isLoading && <Loading />}
        <div className="c-priorityList">
            <div className="c-priorityList__head">
                <div className={`c-priorityList__toggle${isOpen ? ' is-open' : ''}`} onClick={() => setIsOpen(!isOpen)}>
                    <img src="../images/icon-arrow-down.svg" alt="Arrow" />
                </div>
                <h3 className="c-priorityList__title">{title}</h3>
                <div className="c-priorityList__btn">
                    <Search placeholder='Search address' onChange={onChangeValue} onKeyDown={submitSearch}/>
                    <Button title='Delete All' type='blueBorder' iconLeft='Trash' onClick={()=>handleDeleteAll()} />
                    <Button title='Export' type='blueBorder' iconRight='Export' onClick={() => handleExport()}/>
                </div>
            </div>
            <div className={`c-priorityList__body${isOpen ? ' is-open' : ''}`}>
                {data && data.length > 0 ? 
                <div className="c-table c-table--whitelist">
                    <Table columns={columns} dataSource={data} />
                </div> : <NoData />}
            </div>
        </div>
        {isEdit && (
            <div className="c-popup">
                <div className="c-popup__boxForm">
                <div className="c-popup__close" onClick={() => setIsEdit(false)}><img src="../images/icon-close.svg" alt="Close" /></div>
                <div className="c-popup__icon">
                    <img src='../images/icon-popup-edit.svg' alt="Icon" />
                </div>
                <Form
                    name="basic"
                    labelCol={{ span: 8 }}
                    wrapperCol={{ span: 16 }}
                    initialValues={itemSelected}
                    onFinish={onFinish}
                    onFinishFailed={onFinishFailed}
                    autoComplete="off"
                    >
                    <Form.Item name="id" >
                        <InputCustom type="hidden" />
                    </Form.Item>
                    <Form.Item
                        label="Address"
                        name="address"
                        rules={[{ required: true, message: 'Please input Address!' }]}
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item
                        label="Standard commit"
                        name="standardCommit"
                        rules={[{ required: true, message: 'Please input Standard commit!' }]}
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item wrapperCol={{ offset: 5, span: 16 }}>
                        <div className="c-popup__btn">
                            <Button title='Cancel' type='greyBorder' onClick={() => setIsEdit(false)} />
                            <Button title='Submit' type='primary' htmlType="submit" />
                        </div>
                    </Form.Item>
                </Form>
                </div>
            </div>
        )}
        {isDelete &&
        <Popup 
            title='Do you want delete this address?' 
            subTitle='You can’t restore this file' 
            icon='Delete' btnTitle='Delete' 
            onClick={() => handleDelete()}
            onCancel={() => setIsDelete(false)} 
            />}
        </>
    )
}
export default PriorityListTable;