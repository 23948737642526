import React, {useState} from 'react';
import { Button, Input, Popup } from '../component';

const Schedule = () => {
    const [isOpen, setIsOpen] = useState(false);
    const [isEdit, setIsEdit] = useState(false);
    return(
        <>
        <div className="c-schedule">
            <div className="c-schedule__btn" onClick={() => setIsOpen(!isOpen)}>
                <img src={`../images/icon-arrow-circle-${isOpen ? 'up' : 'down'}.svg`} alt="Arrow" />
            </div>
            <div className={`c-schedule__body${isOpen ? ' is-open' : ''}`}>
                <ul className="c-schedule__content">
                    <li>
                        <p className="c-schedule__txt">Private</p>
                        <p className="c-schedule__txt">9AM 19 Sep 2022</p>
                        <p className="c-schedule__txt"><span className="is-color">124,923.87</span></p>
                        <p className="c-schedule__txt">188,100</p>
                        <p className="c-schedule__link">--</p>
                        <p className="c-schedule__txt">
                            <Button title='Edit' type='flat' iconLeft='Edit' onClick={() => setIsEdit(!isEdit)}/>
                        </p>
                    </li>
                    <li>
                        <p className="c-schedule__txt">Private</p>
                        <p className="c-schedule__txt">9AM 19 Sep 2022</p>
                        <p className="c-schedule__txt"><span className="is-color">124,923.87</span></p>
                        <p className="c-schedule__txt">188,100</p>
                        <p className="c-schedule__link">
                            <a href="https://bscscan.com/address/0xa2Bc32D70fFD4bC3C20da4FD3FcDba0b563e0a1D" target="_blank" rel="noopener noreferrer">
                                https://bscscan.com/address/0xa2Bc32D70fFD4bC3C20da4FD3FcDba0b563e0a1D
                            </a>
                        </p>
                        <p className="c-schedule__txt">
                            <Button title='Edit' type='flat' iconLeft='Edit' onClick={() => setIsEdit(!isEdit)}/>
                        </p>
                    </li>
                </ul>
                <div className="c-schedule__box">
                    <div className="c-schedule__info">
                        <p className="c-schedule__txt">
                            <span className="is-ttl">Vesting</span>
                            25%TGE, cliff 1 month, 25% monthly for the next 3 months
                            <span className="is-txt">Last updated 9AM 18 Nov 2022</span>
                        </p>
                    </div>
                    <div className="c-schedule__info">
                        <p className="c-schedule__txt"><span className="is-ttl">Invest</span>1,875</p>
                    </div>
                    <div className="c-schedule__info">
                        <p className="c-schedule__txt"><span className="is-ttl">Initial price</span>0.0015</p>
                    </div>
                    <div className="c-schedule__info">
                        <p className="c-schedule__txt"><span className="is-ttl">Amount</span>1,249,238.67</p>
                    </div>
                </div>
            </div>            
        </div>
        {isEdit ? <Popup title='Edit HashID' subTitle='Enter hashTD' btnTitle='Edit' icon='Edit' onCancel={() => setIsEdit(false)}>
            <Input name='hashID' placeholder='HashID' />
        </Popup> : ''}
        </>
    )
}

export default Schedule;