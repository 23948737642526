import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, InputCustom, NoData, Popup, Loading } from '../component';
import { Form } from 'antd';
import allActions from '../../actions';
import {useDispatch} from 'react-redux';
import addNotify from '../common/Notify/addNotify';
import { Store } from 'react-notifications-component';

const InoRule = ({data, inoCampaign}) => {
    const [addRule, setAddRule] = useState(false);
    const [deleteItem, setDeleteItem] = useState(null);
    const [isLoad, setIsLoad] = useState(false);
    const dispatch = useDispatch();
    let navigate = useNavigate();

    const elmRule = data && data.length > 0 && data.map((item) => (
        <div className="c-inoBoxInfo__row" key={item.id}>
            <p>{item.title}</p>
            <p className="is-btn">
                <span onClick={() => navigate(`/ino-campaigns/${inoCampaign.handle}/rule/${item.id}`)}><img src="../images/edit.png" alt="" /></span>
                <span onClick={() => setDeleteItem(item.id)}><img src="../images/delete.png" alt="" /></span>
            </p>
        </div>
    ));

    const onFinishAdd = (values) => {
        setIsLoad(true);        

        values.inoLaunchpad = inoCampaign;
        values.title = values.title ? values.title : null;

        dispatch(allActions.inoAction.createRuleIno(values, (response) => {
            if(response.status === 201){  
                setIsLoad(false);
                window.location.reload();
            }
            else{
                Store.addNotification(addNotify(response.title, 'danger')); 
                setIsLoad(false);
            }  
        }));
    }

    return(
        <>
        {isLoad ? <Loading /> : ''} 
        <div className="c-inoBoxInfo">
            <div className="c-inoBoxInfo__head">
                <h3 className="c-ttl is-font16">Rule</h3>
                <div className="c-inoBoxInfo__btn">
                    <Button title='Add Rule' type='flat' iconLeft='BoxAdd' onClick={() => setAddRule(true)}/>
                </div>
            </div>
            <div className="c-inoBoxInfo__box is-rule">
                {data && data.length > 0 ? elmRule : <NoData />}
            </div>
        </div>
        {addRule ? <Form name="basic" onFinish={onFinishAdd}>
            <Popup title='Add Rule' btnTitle='Add Rule' onCancel={() => setAddRule(false)}>
                <Form.Item name="title">
                    <InputCustom placeholder='Enter rule' />
                </Form.Item>
            </Popup>
        </Form> : ''}
        {deleteItem && <Popup 
            title='Do you want delete this rule?' 
            subTitle='You can’t restore this file' 
            btnTitle='Delete' icon='Delete' 
            onCancel={() => setDeleteItem(null)}
            onClick={() => {
                dispatch(allActions.inoAction.deleteRuleIno(deleteItem));
                setIsLoad(true);
                setTimeout(() => {
                    window.location.reload(true);
                }, 3000);}
            }
        />}
        </>
    )
}

export default InoRule;