import http from "./http-common";

const getInoAll = (payload) => {
  return http.get(`/ino-launchpads`, { params: payload });
};

const getInoLuanchpad = (payload) => {
    return http.get(`/ino-launchpads`, { params: payload });
};

const getInoById = (id) => {
  return http.get(`/ino-launchpads/${id}`);
};

const getInoInfo = (handle) => {
  return http.get(`/ino-launchpads/${handle}/details`);
};

const createCampaignIno = (payload) => {
  return http.post(`/ino-launchpads`, payload);
};

const editCampaignIno = (payload) => {
  return http.put(`/ino-launchpads/${payload.id}`, payload);
};

const deleteCampaignIno = (id) => {
  return http.delete(`/ino-launchpads/${id}`);
};

const getInoBoxes = (payload) => {
  return http.get(`/ino-boxes`, { params: payload });
};

const getInoBoxesById = (id) => {
  return http.get(`/ino-boxes/${id}`);
};

const createBoxesIno = (payload) => {
  return http.post(`/ino-boxes`, payload);
};

const editBoxesIno = (payload) => {
  return http.put(`/ino-boxes/${payload.id}`, payload);
};

const deleteBoxesIno = (id) => {
  return http.delete(`/ino-boxes/${id}`);
};

const createRuleIno = (payload) => {
  return http.post(`/ino-rules`, payload);
};

const getInoRuleById = (id) => {
  return http.get(`/ino-rules/${id}`);
};

const editRuleIno = (payload) => {
  return http.put(`/ino-rules/${payload.id}`, payload);
};

const deleteRuleIno = (id) => {
  return http.delete(`/ino-rules/${id}`);
};

const createTimelineIno = (payload) => {
  return http.post(`/ino-timelines`, payload);
};

const getInoTimelineById = (id) => {
  return http.get(`/ino-timelines/${id}`);
};

const editTimelineIno = (payload) => {
  return http.put(`/ino-timelines/${payload.id}`, payload);
};

const deleteTimelineIno = (id) => {
  return http.delete(`/ino-timelines/${id}`);
};

const createInfomationIno = (payload) => {
  return http.post(`/ino-informations`, payload);
};

const editInfomationIno = (payload) => {
  return http.put(`/ino-informations/${payload.id}`, payload);
};

const getInoInfomationById = (id) => {
  return http.get(`/ino-informations/${id}`);
};

const createSettingIno = (payload) => {
  return http.post(`/ino-settings`, payload);
};

const getInoSettingById = (id) => {
  return http.get(`/ino-settings/${id}`);
};

const editSettingIno = (payload) => {
  return http.put(`/ino-settings/${payload.id}`, payload);
};

const deleteSettingIno = (id) => {
  return http.delete(`/ino-settings/${id}`);
};

const searchCampaignIno = (title) => {
  return http.get(`/ino-launchpads/search?keySearch=${title}`);
};

const searchBoxIno = (title) => {
  return http.get(`/ino-boxes/search?keySearch=${title}`);
}

const getPriorityListByCampaignId = (id) => {
  return http.get(`/ino-priority-lists/by-ino-launchpad/${id}`);
};

const deleteAllPriority = (id) => {
  return http.delete(`/ino-priority-lists/by-ino-launchpad/${id}`);
};

const deletePriority = (id) => {
  return http.delete(`/ino-priority-lists/${id}`);
};

const updatePriority = (id, payload) => {
  return http.put(`/ino-priority-lists/${id}`, payload);
};

const InoDataService = {
    getInoAll,
    getInoLuanchpad,
    getInoInfo,
    createCampaignIno,
    editCampaignIno,
    deleteCampaignIno,
    getInoBoxes,
    getInoBoxesById,
    createBoxesIno,
    editBoxesIno,
    deleteBoxesIno,
    createRuleIno,
    getInoById,
    getInoRuleById,
    editRuleIno,
    deleteRuleIno,
    createTimelineIno,
    getInoTimelineById,
    editTimelineIno,
    deleteTimelineIno,
    createInfomationIno,
    editInfomationIno,
    getInoInfomationById,
    createSettingIno,
    getInoSettingById,
    editSettingIno,
    deleteSettingIno,
    searchCampaignIno,
    searchBoxIno,
    getPriorityListByCampaignId,
    deleteAllPriority,
    deletePriority,
    updatePriority
};
export default InoDataService;