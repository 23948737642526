import React from 'react';
import Schedule from './schedule';

const TokenSchedule = () => {
    return(
        <div className="c-claimTokenSchedule">
            <h3 className="c-claimTokenSchedule__title">Campaign: Fado Go</h3>
            <Schedule />
            <Schedule />
        </div>
    )
}

export default TokenSchedule;