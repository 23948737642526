import React, {useState, useEffect, Suspense, useCallback} from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import './components/common/asset/scss/style.scss';
import 'antd/dist/antd.css';
import 'react-notifications-component/dist/theme.css';
import Login from './components/login'; 
import Campaign from './components/campaign';
import SaveDraft from './components/savedraft'; 
import CampaignInfo from './components/campaignDetail/campaignInfo';
import CampaignEdit from './components/campaignDetail/campaignEdit';
import RoundEdit from './components/campaignDetail/roundEdit';
import CampaignAdd from './components/campaignDetail/campaignAdd';
import RoundAdd from './components/campaignDetail/roundAdd';
import TokenInform from './components/tokenInform';
import TokenInformEdit from './components/tokenInform/edit';
import Whitelist from './components/whitelist';
import Allocation from './components/allocation';
import ClaimTokenSchedule from './components/claimTokenSchedule';
import SystemAdmin from './components/systemAdmin';
import Setting from './components/setting';
import AccountEdit from './components/setting/accountEdit';
import Component from './components/pageComponent';
import Banner from './components/airdrop/banner';
import Bounty from './components/airdrop/bounty';
import Winner from './components/airdrop/winner';
import BountyInfo from './components/airdropDetail/bountyInfo';
import BountyAdd from './components/airdropDetail/bountyAdd'; 
import BountyEdit from './components/airdropDetail/bountyEdit';  
import {useDispatch} from 'react-redux'; 
import PriorityList from './components/priorityList';
import Layout from './components/common/layout'; 
import allActions from './actions';
import EventBus from './components/common/EventBus';
import Ino from './components/ino';
import InoCampaignInfo from './components/ino/inoCampaign';
import InoAdd from './components/ino/add';
import InoCampaignEdit from './components/ino/edit';
import BoxesList from './components/ino/boxesList';
import InoWhitelist from './components/inoWhitelist/whitelist';
import InoPriorityList from './components/inoWhitelist/priority';
import BoxesEdit from './components/ino/boxesEdit';
import InoRuleEdit from './components/inoInfo/inoRuleEdit';
import InoTimelineEdit from './components/inoInfo/inoTimelineEdit';
import InoBoxEdit from './components/inoInfo/inoBoxEdit';
import InoInfomationEdit from './components/inoInfo/inoInfomationEdit';
import InoSettingEdit from './components/inoInfo/inoSettingEdit'; 
import GameMarket from './components/gamehub/marketing';
import MarketAdd from './components/gamehubDetail/marketAdd';
import MarketEdit from './components/gamehubDetail/marketEdit';
import MarketInfo from './components/gamehubDetail/marketInfo';
import Catalog from './components/catalog/catalog';
import CatalogAdd from './components/catalogDetail/catalogAdd';
import CatalogEdit from './components/catalogDetail/catalogEdit'; 
 
function App() {
    const routeProps = { };
    const permissions = [];
    const dispatch = useDispatch();
    useEffect(() => {
        
        // fetchAppSetting();
        // fetchSettings();
        // const splitURL = window.location.href.split('?')[0];
        // if (!(splitURL.includes('/login')
        //         // splitURL.includes('app/admin/forgot-password') ||
        //         // splitURL.includes('app/admin/reset-password')
        //     )) {
        //     sessionStorage.setItem('prevAdminPrevUrl', window.location.href);
        // }

        // token = localStorage.getItem(Tokens.ADMIN);
        // if (token) {
        //     let decodedJwt = jwt_decode(token);

        //     if(decodedJwt.exp * 1000 < Date.now()) {
        //         localStorage.removeItem(Tokens.ADMIN);

        //     }
        
        //     // fetchConfig();
        //     getUserProfile(LocalStorageKey.USER);
        // }
        // else {
        // }

        EventBus.on("logout", () => {
            logOut();
        });
        return () => {
            EventBus.remove("logout");
        };
    }, []);

    const logOut = useCallback(() => {
        dispatch(allActions.authAction.logout());
    }, [dispatch]);

    return (
        <Router>
            <Suspense fallback={<>...</>}>
                <Routes>
                    <Route path="/login" element={<Login />} />
                    <Route path="/" element={<Layout {...routeProps}/>}  >
                        <Route exact path="/" element={<Campaign />} />
                        <Route exact path="/component" element={<Component />} />
                        <Route exact path="/savedraft" element={<SaveDraft/>} /> 
                        <Route exact path="/campaign-edit/:handle" element={<CampaignEdit/>} />
                        <Route exact path="/campaign-add" element={<CampaignAdd/>} />
                        <Route path="/round-edit/:handle" element={<RoundEdit/>} />
                        <Route path="/round-add/:handle" element={<RoundAdd/>} />
                        <Route path="/campaign-info/:handle" element={<CampaignInfo/>} />
                        <Route exact path="/token-inform" element={<TokenInform />} />
                        <Route exact path="/token-inform/:handle" element={<TokenInformEdit />} />
                        <Route path="/whitelist" element={<Whitelist />} />
                        <Route exact path="/priority-list" element={<PriorityList />} />
                        <Route exact path="/allocation" element={<Allocation />} />
                        <Route exact path="/claim-token" element={<ClaimTokenSchedule />} />
                        <Route exact path="/system-admin" element={<SystemAdmin />} />
                        <Route exact path="/setting" element={<Setting />} />
                        <Route exact path="/setting/:handle" element={<AccountEdit />} />
                        <Route exact path="/airdrop-banner" element={<Banner />} />
                        <Route exact path="/airdrop-bounty" element={<Bounty />} />
                        <Route exact path="/bounty-info/:handle" element={<BountyInfo />} />
                        <Route exact path="/bounty-add" element={<BountyAdd />} />
                        <Route exact path="/bounty-edit/:handle" element={<BountyEdit />} />
                        <Route exact path="/airdrop-winner" element={<Winner />} />
                       
                        <Route exact path="/ino-campaigns" element={<Ino />} />
                        <Route path="/ino-campaigns/:handle" element={<InoCampaignInfo />} />
                        <Route path="/ino-campaigns/:handle/rule/:handle" element={<InoRuleEdit />} />
                        <Route path="/ino-campaigns/:handle/timeline/:handle" element={<InoTimelineEdit />} />
                        <Route path="/ino-campaigns/:handle/boxes/:handle" element={<InoBoxEdit />} />
                        <Route path="/ino-campaigns/:handle/info/:handle" element={<InoInfomationEdit />} />
                        <Route path="/ino-campaigns/:handle/setting/:handle" element={<InoSettingEdit />} />
                        <Route path="/ino-edit/:handle" element={<InoCampaignEdit />} />
                        <Route path="/ino-add" element={<InoAdd />} />
                        <Route path="/boxes-list" element={<BoxesList />} />
                        <Route path="/boxes-list/:handle" element={<BoxesEdit />} />

                        <Route path="/ino-whitelist" element={<InoWhitelist />} />
                        <Route path="/ino-priority-list" element={<InoPriorityList />} />
                       
                        <Route path="/marketGame" element={<GameMarket />} />
                        <Route path="/marketGame-add" element={<MarketAdd />} />
                        <Route path="/marketGame-edit/:handle" element={<MarketEdit />} />
                        <Route path="/marketGame-info/:handle" element={<MarketInfo />} /> 
                        <Route path="/catalogs" element={<Catalog />} /> 
                        <Route path="/catalogs-add" element={<CatalogAdd />} />
                        <Route path="/catalogs-edit/:handle" element={<CatalogEdit />} /> 
                        <Route path="*" element={<Navigate to="/" />} />
                    </Route>
                </Routes>
            </Suspense>
        </Router>
    );
}

export default App;
