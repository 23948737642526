export const GET_CAMPAIGN_LIST    = "GET_CAMPAIGN_LIST";

export const GET_CAMPAIGN_BY_ID   = "GET_CAMPAIGN_BY_ID";

export const POST_CREATE_CAMPAIGN = "POST_CREATE_CAMPAIGN";

export const UPDATE_CAMPAIGN = "UPDATE_CAMPAIGN";

export const DELETE_CAMPAIGN = "DELETE_CAMPAIGN";

export const GET_CAMPAIGN_SIMPLE    = "GET_CAMPAIGN_SIMPLE";

export const GET_REMAINING_BY_ID    = "GET_REMAINING_BY_ID";

export const GET_SAVE_DRAFT = "GET_SAVE_DRAFT";