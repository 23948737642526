import React, { useState, useRef, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Title, Button, NoData, Search, Popup , InputCustom} from '../component';
import { Select, Table, Form } from 'antd';
import { UploadIcon } from '../common/asset/icon';
import allActions from '../../actions';
import {useSelector, useDispatch} from 'react-redux';
import { Excel } from 'antd-table-saveas-excel';

const columnsExport = [
    {
        title: 'box',
        dataIndex: 'name',
    }, {
        title: 'total sale',
        dataIndex: 'total',
    }, {
        title: 'price',
        dataIndex: 'price',
    }, {
        title: 'DESCRIPTION',
        dataIndex: 'description',
    }, {
        title: 'ORDER BOXES',
        dataIndex: 'orderBoxs',
    }
];

const BoxesList = () => {
    const [addBoxes, setAddBoxes] = useState(false);
    const [valueSearch, setValueSearch] = useState('');
    const { Option } = Select;
    const inputRef = useRef();
    const dispatch = useDispatch();

    const inoBoxesList = useSelector(state => state.ino?.inoListBoxes);

    useEffect(() => {
        dispatch(allActions.inoAction.getInoBoxes());
    },[]);

    const columns = [
        {
            title: 'INO campaign',
            dataIndex: 'inoLaunchpad',
            render: (text) => <>{text ? text.title : '--'}</>,
        }, {
            title: 'box',
            dataIndex: 'box',
            render: (_, record) => (
                <div className="c-nameTable is-tokenInform">
                    <div className="c-nameTable__logo"><img src={record.imageLink} alt={record.name} /></div>
                    <p className="c-nameTable__title">{record.name}</p>
                </div>
            ),
            ellipsis: true,
        }, {
            title: 'total sale',
            dataIndex: 'total',
            ellipsis: true,
            render: (text) => <>{text ? text : '--'}</>,
        }, {
            title: 'price',
            dataIndex: 'price',
            render: (_, record) => (<>{record.price} {record.currency}</>),
            ellipsis: true,
        }, {
            title: 'DESCRIPTION',
            dataIndex: 'description',
            ellipsis: true,
            render: (text) => <>{text ? text : '--'}</>,
        }, {
            title: 'ORDER BOXES',
            dataIndex: 'orderBoxs',
            ellipsis: true,
            render: (text) => <>{text ? text : '--'}</>,
        }
    ];

    const onSearch = () => {
        if(valueSearch !== '') {
            dispatch(allActions.inoAction.searchBoxIno(valueSearch));
        } else {
            dispatch(allActions.inoAction.getInoBoxes());
        }
    }

    const handleExport = () => {
        const excel = new Excel();
        excel
        .addSheet("BoxesList")
        .addColumns(columnsExport)
        .addDataSource(inoBoxesList, {
            str2Percent: true
        })
        .saveAs("BoxesList.xlsx");
    };
    
    return(
        <>
        <div className="p-boxesList">
            <div className="p-boxesList__head">
                <Title title='Boxes List' subTitle='View list of added boxes, also you can edit or add new box' />
            </div>
            <div className="p-boxesList__table">
                <div className="c-table c-table--allocation">
                    <div className="c-table__head">
                        <Search 
                            placeholder='Search Campaign'
                            onChange={(e) => setValueSearch(e.target.value)}
                            onKeyDown={(e) => {
                                if (e.code === "Enter") {
                                    onSearch();                                    
                                }
                            }}
                        />
                        <Button title='Export' type='blueBorder' iconRight='Export' onClick={handleExport} />
                    </div>
                    {inoBoxesList && inoBoxesList.length > 0 ? <Table rowKey='id' columns={columns} dataSource={inoBoxesList} /> : <NoData /> }
                </div>
            </div>
        </div>

        {addBoxes ? <Form name="basic">
            <Popup title='Add Box' icon='Add' btnTitle='Add Box' onCancel={() => setAddBoxes(false)}>
                <Form.Item name="logoURL">
                    <input ref={inputRef} type='file' />
                    <div className="c-uploadIMG"> 
                        <div onClick={()=>inputRef.current.click()} className="c-btn c-btn--flat">
                            <UploadIcon />Upload Image / Gift box
                        </div>
                    </div>
                </Form.Item>
                <Form.Item name="box-name">
                    <InputCustom placeholder='Box name' />
                </Form.Item>
                <Form.Item name="total-sale">
                    <InputCustom placeholder='Total sale' />
                </Form.Item>
                <Form.Item name="currency">
                    <Select className="c-selectChange" placeholder='Currency'>
                        <Option value='busd'>BUSD</Option>
                        <Option value='bcf'>BCF</Option>
                        <Option value='zwz'>ZWZ</Option>
                    </Select>
                </Form.Item>
                <Form.Item name="price">
                    <InputCustom placeholder='Price' />
                </Form.Item>
                <Form.Item name="sesciption">
                    <InputCustom placeholder='Desciption' />
                </Form.Item>
                <Form.Item name="ino-launchpad">
                    <Select className="c-selectChange" placeholder='INO Launchpad'>
                        <Option value='bcf'>BCF</Option>
                        <Option value='zwz'>ZWZ</Option>
                    </Select>
                </Form.Item>
            </Popup>
        </Form> : ''}
        </>
    )
}

export default BoxesList;