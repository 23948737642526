import SystemAdminDataService from "../services/SystemAdmin";
import {
    GET_SYSTEM_ERROR_LIST,
    GET_SYSTEM_LOCK_LIST,
    SYSTEM_CHANGE_STATUS,
    SEARCH_ERROR_LIST,
    SEARCH_LOCK_LIST
} from "./systemAdminType";

const getSystemErrorList = (payload) => async (dispatch) => {
    try {
      const res = await SystemAdminDataService.getSystemErrorList(payload);
      dispatch({
        type: GET_SYSTEM_ERROR_LIST,
        payload: res && res.data || []
      });
    } catch (err) {
      console.log(err);
    }
}

const getSystemLockList = (payload) => async (dispatch) => {
  try {
    const res = await SystemAdminDataService.getSystemLockList(payload);
    dispatch({
      type: GET_SYSTEM_LOCK_LIST,
      payload: res && res.data || []
    });
  } catch (err) {
    console.log(err);
  }
}

const systemChangeStatus = (id, callback) => async (dispatch) => {
  try {
    const res = await SystemAdminDataService.systemChangeStatus(id);

    if(res && res.status === 200) {
      dispatch({
        type: SYSTEM_CHANGE_STATUS,
        payload: res && res.data || []
      });
      callback({changeStatusResult: true})
    } else {
      callback({changeStatusResult: false})
    }
  } catch (err) {
    console.log(err);
  }
}

const searchErrorList = (payload) => async (dispatch) => {
  try {
    const res = await SystemAdminDataService.searchErrorList(payload);
    dispatch({
      type: SEARCH_ERROR_LIST,
      payload: res && res.data || []
    });
  } catch (err) {
    console.log(err);
  }
}

const searchLockList = (payload) => async (dispatch) => {
  try {
    const res = await SystemAdminDataService.searchLockList(payload);
    dispatch({
      type: SEARCH_LOCK_LIST,
      payload: res && res.data || []
    });
  } catch (err) {
    console.log(err);
  }
}

const systemAdminAction = {
    getSystemErrorList,
    getSystemLockList,
    systemChangeStatus,
    searchErrorList,
    searchLockList
};

export default systemAdminAction;