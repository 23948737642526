import http from "./http-common";

const getCampaignList = (payload) => {
  return http.get("/campaigns", { params: payload });
};

const getCampaignById = (id) => {
  return http.get(`/campaigns/${id}`);
};

const createCampaign = (payload) => {
  return http.post("/campaigns", payload);
};

const updateCampaign = (payload) => { 
  return http.put(`/campaigns/${payload.id}`, payload);
}; 

const deleteCampaign = (payload) => { 
  return http.delete(`/campaigns/${payload}`);
};

const getCampaignSimple = (payload) => {
  return http.get("/campaigns-simple", { params: payload });
};

const getRemainingById = (id) => {
  return http.get(`/campaigns​/report-remaining?ids=${id}`);
};
const getSaveDraft = () => {
  return http.get("/campaigns-drafts?page=0");
};

const CampaignService = {
  getCampaignList,
  getCampaignById,
  createCampaign,
  updateCampaign,
  deleteCampaign,
  getCampaignSimple,
  getSaveDraft,
  getRemainingById
};
export default CampaignService;