import http from "./http-common";

const getBannerList = () => {
  return http.get("/banners");
}; 

const postBanner = (payload) => {
  return http.post("/banners", payload);
}; 

const getBannerId = (id) => {
  return http.get(`/banners/${id}`);
}; 

const updateBanner = (id, payload) => {
  return http.put(`/banners/${id}`, payload);
}; 

const deleteBanner = (id) => {
  return http.delete(`/banners/${id}`);
}; 

const getAirdrop = () => { 
  return http.get(`/airdrops`, { params: {page: 0, size: 10000}});
}; 

const getAirdropByHandle = (id) => {
  return http.get(`/airdrops/${id}`);
}; 

const createAirdrop = (payload) =>{
  return http.post(`/airdrops`, payload);
}

const editAirdrop = (id, payload) =>{
  return http.put(`/airdrops/${id}`, payload);
}

const deleteAirdrop = (id) =>{
  return http.delete(`/airdrops/${id}`);
}

const getAddressWhiteList = (id) => {
  return http.get(`airdrops/${id}/white-list`);
}

const updateAddressWhiteList = (id, payload) => {
  return http.put(`/white-list-airdrops/${id}`, payload);
}

const deleteAddressWhiteList = (id) => {
  return http.delete(`/white-list-airdrops/${id}`);
}

const searchPriority = (id, payload) => {
  return http.get(`/airdrops/${id}/white-list?address=${payload}`);
}

const AirdropService = {
    getBannerList,
    postBanner,
    deleteBanner,
    updateBanner,
    getBannerId,
    getAirdrop,
    getAirdropByHandle,
    createAirdrop,
    editAirdrop,
    deleteAirdrop,
    getAddressWhiteList,
    updateAddressWhiteList,
    deleteAddressWhiteList,
    searchPriority
};
export default AirdropService;