import { dataStatus, whitelistType } from "../constants";
import WhitelistDataService from "../services/WhitelistService";
import { whitelistActionType } from "../actions/actionType";

const getWhitelistByRoundId = (roundId, wlType, onCallback) => async (dispatch) => {
    try {
      const res = await WhitelistDataService.getWhitelistByRoundId(roundId, wlType);
      let totalCommited=0, totalActualFundNeeded=0, totalRefund=0, totalRemainingFund=0, totalStandardCommit=0, signatureJoinPoolCount=0;

      res && res.data.map((item, index) => {
        totalCommited += item.commited;
        totalActualFundNeeded += item.actualFundNeeded;
        totalRefund += item.refund;
        totalRemainingFund += item.remainingFund;
        totalStandardCommit += item.standardCommit;

        if(item.signatureJoinPool) {
          signatureJoinPoolCount += 1;
        }
      });

      if(wlType === whitelistType.STAKING) {
        dispatch({
          type: whitelistActionType.GET_WHITELIST_STAKING_BY_ROUNDID,
          payload: {
            dataList: res && res.data,
            totalCommited: totalCommited,
            totalActualFundNeeded: totalActualFundNeeded,
            totalRefund: totalRefund,
            totalRemainingFund: totalRemainingFund,
            totalStandardCommit: totalStandardCommit,
            signatureJoinPoolCount: signatureJoinPoolCount
          }
        });
      }
      else if(wlType === whitelistType.PRIORITY) {
        dispatch({
          type: whitelistActionType.GET_WHITELIST_PRIORITY_BY_ROUNDID,
          payload: {
            dataList: res && res.data,
            totalCommited: totalCommited,
            totalActualFundNeeded: totalActualFundNeeded,
            totalRefund: totalRefund,
            totalRemainingFund: totalRemainingFund,
            totalStandardCommit: totalStandardCommit,
            signatureJoinPoolCount: signatureJoinPoolCount
          }
        });
      }
      onCallback({callResult: true});
    } catch (err) {
      onCallback({callResult: true});
      console.log(err);
    }
}

const generateSignatureCommitAll = (payload, callback) => async (dispatch) => {
  try {
    const res = await WhitelistDataService.generateSignatureCommitAll(payload);
    if(res && res.status === 200) {
      callback({result: res.data === dataStatus.SUCCESS ? true : false});
    }
  } catch (err) {
    console.log(err);
  }
}

const generateSignatureCommitOne = (payload, callback) => async (dispatch) => {
  try {
    const res = await WhitelistDataService.generateSignatureCommitOne(payload);
    if(res && res.status === 200) {
      callback({result: res.data === dataStatus.SUCCESS ? true : false});
    }
  } catch (err) {
    console.log(err);
  }
}

const generateWhitelist = (payload, callback) => async (dispatch) => {
  try {
    const res = await WhitelistDataService.generateWhitelist(payload);
    if(res && res.status === 200) {
      callback({result: res.data === dataStatus.SUCCESS ? true : false, error: null});
    }
  } catch (err) {
    callback({result: false, error: err?.response?.data});
  }
}

const recalculate = (payload, callback) => async (dispatch) => {
  try {
    const res = await WhitelistDataService.recalculate(payload);
    if(res && res.status === 200) {
      callback({result: res.data === dataStatus.SUCCESS ? true : false, error: null});
    }
  } catch (err) {
    callback({result: false, error: err?.response?.data});
  }
}

const searchWhitelist = (payload, wlType, onCallback) => async (dispatch) => {
 try {
    const res = await WhitelistDataService.searchWhitelist(payload);
    if(wlType === whitelistType.STAKING) {
      dispatch({
        type: whitelistActionType.GET_WHITELIST_STAKING_BY_ROUNDID,
        payload: {
          dataList: res && [res.data],
          totalCommited: res.data?.commited,
          totalActualFundNeeded: res.data?.actualFundNeeded,
          totalRefund: res.data?.refund,
          totalRemainingFund:  res.data?.remainingFund,
          totalStandardCommit:  res.data?.standardCommit,
          signatureJoinPoolCount:  res.data?.signatureJoinPoolCount
        }
      });
    }
    else if(wlType === whitelistType.PRIORITY) {
      dispatch({
        type: whitelistActionType.GET_WHITELIST_PRIORITY_BY_ROUNDID,
        payload: {
          dataList: res && [res.data],
          totalCommited: res.data?.commited,
          totalActualFundNeeded: res.data?.actualFundNeeded,
          totalRefund: res.data?.refund,
          totalRemainingFund:  res.data?.remainingFund,
          totalStandardCommit:  res.data?.standardCommit,
          signatureJoinPoolCount:  res.data?.signatureJoinPoolCount
        }
      });
    }
    onCallback({callResult: true});
  } catch (err) {
    console.log(err)
    // callback({result: false, error: err?.response?.data});
  }
}

const generateSignatureEspecialRefund = (payload, callback) => async (dispatch) => {
  try {
    const res = await WhitelistDataService.generateSignatureEspecialRefund(payload); 
    if(res && res.status === 200) {
      callback({result: res.data === dataStatus.SUCCESS ? true : false, error: null});
    }
  } catch (err) {
    console.log(err)
    callback({result: true, error: err?.response?.data});
  }
}

export default {
  getWhitelistByRoundId,
  generateSignatureCommitAll,
  generateSignatureCommitOne,
  generateWhitelist,
  recalculate,
  searchWhitelist,
  generateSignatureEspecialRefund
}