import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {Title, Button, StatusLabel, Popup} from '../component';
import { Tabs, Table, Select } from 'antd';
import allActions from '../../actions';
import {useSelector, useDispatch} from 'react-redux'; 

const CatalogItem = () => {
    const { Option } = Select;
    const [deleteItem, setDeleteItem] = useState(null); 
    const dispatch = useDispatch(); 
    let navigate = useNavigate(); 
    const handleDelete = (id) => {
        setDeleteItem(id);
    }
 
    const columnsOverview = [ 
        {
            title: 'CATALOG NAME',
            dataIndex: 'name', 
            ellipsis: true, 
        }, {
            title: 'action',
            dataIndex: 'action',
            render: (_, record) => (
                <div className="c-actionTable">
                    <div className="c-actionTable__box">
                        <img src="../images/icon-more.svg" alt="" />
                        <ul className="c-actionTable__content">
                            <li onClick={() => navigate('/catalogs-edit/'+record.id)}><span>Edit</span></li>
                            <li onClick={() => handleDelete(record.id)}><span>Delete</span></li>
                        </ul>
                    </div>
                </div>
            ),
        }
    ];

    useEffect(() => { 
        dispatch(allActions.gamehubAction.getCatalogList()); 
    }, []); 
    
    const catalogsList = useSelector(state => state.gamehub.catalogsList);   
    
    catalogsList && catalogsList.length > 0 && catalogsList.sort((a, b) => {return b.id - a.id}); 

    const onChange = (pagination, filters, sorter, extra) => {
        console.log('params', pagination, filters, sorter, extra);
    };  
    return(
        <div className="c-table"> 
            {deleteItem && <Popup 
                title='Do you want delete this catalog?' 
                subTitle='You can’t restore this file' 
                btnTitle='Delete' icon='Delete' 
                onCancel={() => setDeleteItem(null)}
                onClick={() => dispatch(allActions.gamehubAction.deleteCatalogList(deleteItem))} 
            />}
            <Table rowKey='id' columns={columnsOverview} dataSource={catalogsList} onChange={onChange} />
        </div>
    );
}

export default CatalogItem;