import React, { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {Title, Button, NoData} from '../component';
// import RoundInfo from './roundInfo';
import allActions from '../../actions';
import {useSelector, useDispatch} from 'react-redux';
import { formatData, formatText } from '../../utils/StringHelper';
import { formatDateTime } from '../../utils/formatDatetime';

const MarketInfo = () => {
    let navigate = useNavigate();
    const dispatch = useDispatch();
    let { handle } = useParams(); 
 
    useEffect(() => {
        if(handle !== null) {
            dispatch(allActions.gamehubAction.getMarketGameById(handle)); 
        }
        window.scrollTo({top: 0, left: 0, behavior: 'smooth' });
    },[]);

    const marketGameById = useSelector(state => state.gamehub.marketGameById);
    console.log("marketGameById", marketGameById);
    return(
        <div className="p-campaignInfo">
            <Title title={`${marketGameById?.title}’s Information`} />
            <div className="p-campaignInfo__round">
                <div className="p-campaignInfo__title">
                    <p>Event’s Timetable</p>  
                </div>
                <div className="c-info__row"> 
                    <div className="c-info__box">
                        <div className='flex-display'>
                        <div className="c-info__row"> 
                            <h3 className="c-info__ttl">Display</h3> 
                                <div>
                                    {marketGameById?.hot &&  <p className="c-info__txt">HOT</p>}
                                    {marketGameById?.popular &&  <p className="c-info__txt">POPULAR</p>}
                                    {marketGameById?.recommended &&  <p className="c-info__txt">RECOMMENDED</p>} 
                                </div>  
                        </div>  
                        <div className="c-info__row right">
                                <h3 className="c-info__ttl">Categories</h3>
                                <p className="c-info__txt">{marketGameById?.catalog?.name}</p>
                        </div>   
                        </div>   
                        <div className='flex-display'>
                            <div className="c-info__row">
                                <h3 className="c-info__ttl">Token ID</h3>
                                <p className="c-info__txt">{marketGameById?.tokenId}</p>
                            </div> 
                            <div className="c-info__row right">
                                    <h3 className="c-info__ttl">Link Play Game</h3>
                                    <p className="c-info__txt">{marketGameById?.link}</p>
                            </div>   
                           
                        </div> 
                        <div className='flex-display'> 
                            <div className="c-info__row">
                                <h3 className="c-info__ttl">Developer</h3>
                                <p className="c-info__txt">{marketGameById?.developerBy}</p>
                            </div>  
                            <div className="c-info__row right">
                                <h3 className="c-info__ttl">Video Link</h3>
                                <p className="c-info__txt ">{marketGameById?.videoLink}</p>
                            </div> 
                        </div> 
                    </div>
                </div> 
            </div>
            <div className="p-campaignInfo__info">
                <div className="p-campaignInfo__btn">
                    <Button title='Edit Event' type='flat' iconLeft='Edit' onClick={() => navigate('/marketGame-edit/'+handle)}/> 
                </div>
                <div className="c-info">
                    <div className="c-info__img"><img src={marketGameById?.poster} alt={marketGameById?.title} /></div>
                    <div className="c-info__logo">
                        <p>{marketGameById?.title}</p> 
                    </div> 
                    <div className="c-info__dflex">  
                        <div className="c-info__row">
                            <h3 className="c-info__ttl">Description</h3>
                            <p className="c-info__txt">{marketGameById?.description}</p>
                        </div>
                    </div>
                    <div className="c-info__dflex">
                        <div className="c-info__row">
                            <h3 className="c-info__ttl">Communities</h3>
                              
                                {marketGameById?.socialNetwork?.facebookLink &&  <div className='flex-display'>
                                  <h3><img src="../images/icon-facebook.svg" alt="" />Facebook</h3>
                                  <p className="c-info__txt social">{marketGameById?.socialNetwork?.facebookLink}</p>
                                </div> }
                                {marketGameById?.socialNetwork?.mediumLink  && <div className='flex-display'>
                                    <h3><img src="../images/icon-medium.svg" alt="" />Medium</h3>
                                    <p className="c-info__txt social">{marketGameById?.socialNetwork?.mediumLink}</p>
                                </div> }
                                {marketGameById?.socialNetwork?.other  &&  <div className='flex-display'>
                                    <h3><img src="../images/icon-website.svg" alt="" />Website</h3>
                                    <p className="c-info__txt social">{marketGameById?.socialNetwork?.other}</p>
                                </div> }
                                {marketGameById?.socialNetwork?.telegramLink  && <div className='flex-display'> 
                                    <h3><img src="../images/icon-telegram.svg" alt="" />Telegram</h3>  
                                    <p className="c-info__txt social">{marketGameById?.socialNetwork?.telegramLink}</p> 
                                </div> }
                                {marketGameById?.socialNetwork?.twitterLink  && <div className='flex-display'>
                                    <h3><img src="../images/icon-twitter.svg" alt="" />Twitter</h3>
                                    <p className="c-info__txt social">{marketGameById?.socialNetwork?.twitterLink}</p>
                                </div> }
                                {marketGameById?.socialNetwork?.youtuberLink  && <div className='flex-display'>
                                    <h3><img src="../images/icon-youtube.svg" alt="" />Youtube</h3>
                                    <p className="c-info__txt social">{marketGameById?.socialNetwork?.youtuberLink}</p>
                                </div> }
                           
                        </div> 
                    </div> 
                </div>
            </div>
        </div>
    )
}

export default MarketInfo;