import http from "./http-common";

const getRoundsByCampaignsId = (id) => {
  return http.get(`/campaigns/all-round/${id}`);
};

const getRoundById = (id) => {
  return http.get(`/rounds/${id}`);
};

const updateRound = (payload) => {
  return http.put(`/rounds/${payload.id}`, payload);
};

const deleteRound = (id) => {
  return http.delete(`/rounds/${id}`);
};

const createRound = (payload) => {
  return http.post(`/rounds/`, payload);
};

const RoundService = {
  getRoundsByCampaignsId,
  getRoundById,
  updateRound,
  deleteRound,
  createRound
};
export default RoundService;