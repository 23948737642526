import React, {useState, useEffect, useRef} from 'react';
import { Title, InputCustom, Button, Loading } from '../component';
import { Select, DatePicker, Form, Checkbox } from 'antd';
import {useNavigate, useParams} from "react-router-dom";
import allActions from '../../actions';
import {useDispatch, useSelector} from 'react-redux'; 
import { Store } from 'react-notifications-component';
import addNotify from '../common/Notify/addNotify';
import {NetworkArray, Tokens} from '../../constants';
import axios from 'axios';  
import {Upload} from '../common/asset/icon'; 

const MarketAdd = () => {  
    const [imgBanner, setImgBanner] = useState(''); 
    const [imgBannerMobile, setImgBannerMoblie] = useState('');
    const [imgSlider1, setImgSlider1] = useState('');
    const [imgSlider2, setImgSlider2] = useState('');
    const [imgSlider3, setImgSlider3] = useState('');
    const inputImage = useRef(); 
    const inputImageMobile = useRef();  
    const inputImage1 = useRef();
    const inputImage2 = useRef();
    const inputImage3 = useRef();
    
    const [loadingPage, setLoadingPage] = useState(false); 
    const [campaignPayload, setCampaignPayload] = useState({});
    const [arrFilter, setArrFilter] = useState([]); 
    const [form] = Form.useForm();   
    const tokenInformData = useSelector(state => state.tokenInform);
    let navigate = useNavigate();
    const dispatch = useDispatch();  
    const { Option } = Select;  
   
    useEffect(() => { 
        dispatch(allActions.tokenInformAction.getTokenInformList());
    },[]); 

    const onChangeImage= (e) => { 
        let file = e.target.files[0];
        const formData = new FormData();
        formData.append("file", file); 
        axios({
            method: 'post',
            url: process.env.REACT_APP_API_URL + `/upload-to-s3`,
            headers: {
                'accept': '*/*',
                "Authorization": `Bearer ${localStorage.getItem(Tokens.ADMIN)}`,
                "Content-Type": "multipart/form-data"
            },
            data: formData,
        }).then(res => {
           setImgBanner(res.data); 
        }).catch(err => {
            console.log(err);
        });
    } 

    const onChangeImageMobi= (e) => { 
        let file = e.target.files[0];
        const formData = new FormData();
        formData.append("file", file); 
        axios({
            method: 'post',
            url: process.env.REACT_APP_API_URL + `/upload-to-s3`,
            headers: {
                'accept': '*/*',
                "Authorization": `Bearer ${localStorage.getItem(Tokens.ADMIN)}`,
                "Content-Type": "multipart/form-data"
            },
            data: formData,
        }).then(res => {
            setImgBannerMoblie(res.data); 
        }).catch(err => {
            console.log(err);
        });
    } 

    const onChangeImageSlider1= (e) => { 
        let file = e.target.files[0];
        const formData = new FormData();
        formData.append("file", file); 
        axios({
            method: 'post',
            url: process.env.REACT_APP_API_URL + `/upload-to-s3`,
            headers: {
                'accept': '*/*',
                "Authorization": `Bearer ${localStorage.getItem(Tokens.ADMIN)}`,
                "Content-Type": "multipart/form-data"
            },
            data: formData,
        }).then(res => {
            setImgSlider1(res.data); 
        }).catch(err => {
            console.log(err);
        });
    } 
    const onChangeImageSlider2= (e) => { 
        let file = e.target.files[0];
        const formData = new FormData();
        formData.append("file", file); 
        axios({
            method: 'post',
            url: process.env.REACT_APP_API_URL + `/upload-to-s3`,
            headers: {
                'accept': '*/*',
                "Authorization": `Bearer ${localStorage.getItem(Tokens.ADMIN)}`,
                "Content-Type": "multipart/form-data"
            },
            data: formData,
        }).then(res => {
            setImgSlider2(res.data); 
        }).catch(err => {
            console.log(err);
        });
    } 
    const onChangeImageSlider3 = (e) => { 
        let file = e.target.files[0];
        const formData = new FormData();
        formData.append("file", file); 
        axios({
            method: 'post',
            url: process.env.REACT_APP_API_URL + `/upload-to-s3`,
            headers: {
                'accept': '*/*',
                "Authorization": `Bearer ${localStorage.getItem(Tokens.ADMIN)}`,
                "Content-Type": "multipart/form-data"
            },
            data: formData,
        }).then(res => {
            setImgSlider3(res.data); 
        }).catch(err => {
            console.log(err);
        });
    } 

    const changeTokenInform = (value) => {
        setCampaignPayload({
            ...campaignPayload,  
            tokenInformId: parseInt(value)
        });
    }

    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };  

    const onFinish = (values) => {    
        if (!values.tokenInform) {
            Store.addNotification(addNotify('Please select Token Inform !', 'danger'));
            setLoadingPage(false);
            return;
        }   
        
        let _tokenInformValid = tokenInformData.find(x => x.id === values.tokenInform);
       
        var path1 = imgSlider1.slice(0, 56);
        var name1 = imgSlider1.slice(57);
    
        var path2 = imgSlider2.slice(0, 56);
        var name2 = imgSlider2.slice(57);
        
        var path3 = imgSlider3.slice(0, 56);
        var name3 = imgSlider3.slice(57); 

        const gameImagesArr= [
            {
                "gameMarket": null, 
                "name": name1,
                "path": path1
            },
            {
                "gameMarket": null, 
                "name": name2,
                "path": path2
            },
            {
                "gameMarket": null, 
                "name": name3,
                "path": path3
            }
        ];

        let payload = { 
            "catalog": {
                "id": 5,
                "name": "Metaverse",
                "logo": null
            },
            "changePercentage24h": null,
            "currentPrice": null,
            "description":  values?.description ? values?.description : null,
            "developerBy": values.developerBy ?? null,
            "gameImages": gameImagesArr,
            "homePage": true,
            "hot": arrFilter.find (x => x==="hot") ?true: null, 
            "idoPrice": values?.idoPrice ? values?.idoPrice : null,
            "idoRoi": null,
            "link": values?.link ? values?.link : null,
            "popular":  arrFilter.find (x => x==="popular") ?true: null,
            "position": "BOTTOM",
            "poster": imgBanner,
            "posterMobile": imgBannerMobile,
            "recommended":  arrFilter.find (x => x==="recommended") ?true: null,
            "socialNetwork": {
                "facebookLink": values.facebookLink ?? null,
                "mediumLink": values.mediumLink ?? null,
                "other": values.other ?? null,
                "telegramLink": values.telegramLink ?? null,
                "twitterLink": values.twitterLink ?? null,
                "youtuberLink": values.youtuberLink ?? null
            },
            "symbol": null,
            "title": values?.title ?? null,
            "tokenId": _tokenInformValid?.tokenId,
            "token_logo": null,
            "totalSupply": null,
            "totalVolume": null,
            "verified": true,
            "videoLink": values.videoLink ?? null,
            "year": null 
        };   
        
        dispatch(allActions.gamehubAction.postMarketList(payload, (response) => {  
            if(response.status === 201){  
                setLoadingPage(false);
                navigate('/marketGame'); 
            }
            else{ 
                Store.addNotification(addNotify(response.title, 'danger')); 
                setLoadingPage(false);
            }  
        }));  
    };
    const plainOptions = ['hot', 'recommended', 'popular'];
    const onChange = (checkedValues) => {
        setArrFilter(checkedValues);
      };
    
    return(
        <>
        {loadingPage ? <Loading /> : ''}  
        <Form 
            name="basic"
            form={form}
            labelCol={{ span: 8 }}
            wrapperCol={{ span: 16 }}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            autoComplete="off"
        >
        <div className="p-campaignAdd">
            <div className="p-campaignAdd__left">
                <Title title='Event’s Information' subTitle='Enter the required information below to add event. You can change it before the event ends.' />
                <div className="p-campaignAdd__row">
                <div className="c-select">
                    <p className="c-select__title">Categories</p>
                    <Form.Item name="categories">
                        <Select className="c-selectChange" placeholder='Select categories'>
                            <Option value="Metaverse">Metaverse</Option>
                            <Option value="Intellectual">Intellectual</Option>
                            <Option value="Puzzle">Puzzle</Option>
                            <Option value="Sport">Sport</Option>
                            <Option value="MMORPG">MMORPG</Option>
                            <Option value="Other">Other</Option>
                        </Select>
                    </Form.Item>  
                </div>
                <div className="c-select">
                    <Form.Item   
                        name="idoPrice" 
                        rules={[{ 
                            required: false, 
                            message: "Ido Price wrong format",
                            pattern: new RegExp(/^\d*(\.\d+)?$/)
                        }]} 
                        >
                        <InputCustom title='Ido Price'/>
                    </Form.Item> 
                </div>  
                </div>
                <div className="p-campaignAdd__row"> 
                    <div className="c-select">
                            <p className="c-select__title">Token Inform <span style={{color: 'red'}}>*</span></p>
                            <Form.Item 
                                name="tokenInform" 
                            > 
                            <Select className="c-selectChange" onChange={changeTokenInform}>
                                {tokenInformData && tokenInformData.map((token) => (
                                    <Option key={token.id} value={token.id}>
                                        <img src={token.logoURL} alt={token.symbol + " - " + token.name} />
                                        {token.symbol} - {token.name}
                                    </Option>)
                                )}
                            </Select> 
                            </Form.Item>
                    </div>
                    <div className="c-select">
                        <Form.Item 
                            name="link" 
                        >
                            <InputCustom title='Link Play Game' />
                        </Form.Item>
                    </div> 
                </div> 
                <div className="p-campaignAdd__row">  
                    <div className="c-select">
                        <Form.Item 
                            name="developerBy" 
                        >
                            <InputCustom title='Developer' />
                        </Form.Item>
                    </div> 
                    <div className="c-select">
                        <Form.Item 
                            name="videoLink" 
                        >
                            <InputCustom title='Video link' />
                        </Form.Item>
                    </div> 
                </div>  
                <p className="c-select__title">Filter</p>
                <div className="c-select">
                        <Form.Item   
                            name="list" 
                            >
                            <Checkbox.Group options={plainOptions} defaultValue={['hot']} onChange={onChange} />
                        </Form.Item> 
                    </div> 
            </div>
            <div className="p-campaignAdd__right"> 
                <div className="c-upload">
                    <h3 className="c-upload__title">Images</h3>
                    <div className={`c-upload__box${imgBanner === '' ? ' is-add' : ''}`}>
                        {imgBanner === '' ? <div className='btn-click' onClick={()=> inputImage.current.click()}> 
                            <Upload/>
                            <p>Upload</p>
                        </div> : <div onClick={() => inputImage.current.click()} className="c-upload__edit">
                            <img src={imgBanner} alt="" />
                        </div>}
                    </div>
                    <input ref={inputImage} onChange={onChangeImage} type="file" /> 
                </div>  
                    
                <div className="c-upload">
                    <h3 className="c-upload__title">Images Mobile</h3>
                    <div className={`c-upload__box${imgBannerMobile === '' ? ' is-add' : ''}`}>
                        {imgBannerMobile === '' ? <div className='btn-click' onClick={()=> inputImageMobile.current.click()}> 
                            <Upload/>
                            <p>Upload</p>
                        </div> : <div onClick={() => inputImageMobile.current.click()} className="c-upload__edit">
                            <img src={imgBannerMobile} alt="" />
                        </div>}
                    </div>
                    <input ref={inputImageMobile} onChange={onChangeImageMobi} type="file" /> 
                </div>
                <div className='slider-gamehub'>
                    <div className="c-upload">
                        <h3 className="c-upload__title">Slider 1</h3>
                        <div className={`c-upload__box${imgSlider1 === '' ? ' is-add' : ''}`}>
                            {imgSlider1 === '' ? <div className='btn-click' onClick={()=> inputImage1.current.click()}> 
                                <Upload/>
                                <p>Upload</p>
                            </div> : <div onClick={() => inputImage1.current.click()} className="c-upload__edit">
                                <img src={imgSlider1} alt="" />
                            </div>}
                        </div>
                        <input ref={inputImage1} onChange={onChangeImageSlider1} type="file" /> 
                    </div>
                    <div className="c-upload">
                        <h3 className="c-upload__title">Slider 2</h3>
                        <div className={`c-upload__box${imgSlider2 === '' ? ' is-add' : ''}`}>
                            {imgSlider2 === '' ? <div className='btn-click' onClick={()=> inputImage2.current.click()}> 
                                <Upload/>
                                <p>Upload</p>
                            </div> : <div onClick={() => inputImage2.current.click()} className="c-upload__edit">
                                <img src={imgSlider2} alt="" />
                            </div>}
                        </div>
                        <input ref={inputImage2} onChange={onChangeImageSlider2} type="file" /> 
                    </div>
                    <div className="c-upload">
                        <h3 className="c-upload__title">Slider 3</h3>
                        <div className={`c-upload__box${imgSlider3 === '' ? ' is-add' : ''}`}>
                            {imgSlider3 === '' ? <div className='btn-click' onClick={()=> inputImage3.current.click()}> 
                                <Upload/>
                                <p>Upload</p>
                            </div> : <div onClick={() => inputImage3.current.click()} className="c-upload__edit">
                                <img src={imgSlider3} alt="" />
                            </div>}
                        </div>
                        <input ref={inputImage3} onChange={onChangeImageSlider3} type="file" /> 
                    </div>
                </div>

                <div className="p-campaignAdd__row is-wFull">
                    <div className="c-select">
                        <Form.Item 
                            name="title"  
                        >
                            <InputCustom title='Game Name' placeholder='Enter Name' />
                        </Form.Item>
                    </div>
                </div> 

                <div className="p-campaignAdd__row is-wFull">
                    <div className="c-select">
                        <Form.Item 
                            name="description"  
                        >
                            <InputCustom title='Description' placeholder='Enter description' />
                        </Form.Item>
                    </div>
                </div> 
                <div className="p-campaignAdd__row is-wFull">
                    <h3 className="c-ttl is-font16">Channel Media</h3>
                    <ul className="c-media">
                        <li>
                            <p>Channel name</p>
                            <p>URL</p>
                        </li>
                        <li>
                            <h3><img src="../images/icon-telegram.svg" alt="" />Telegram</h3>
                            <div className='inputCustom'>
                                <Form.Item name="telegramLink">
                                    <InputCustom placeholder='Enter link' />
                                </Form.Item>
                            </div>
                        </li>
                        <li>
                            <h3><img src="../images/icon-twitter.svg" alt="" />Twitter</h3>
                            <div className='inputCustom'>
                                <Form.Item name="twitterLink">
                                    <InputCustom placeholder='Enter link' />
                                </Form.Item>
                            </div>
                        </li>
                        <li>
                            <h3><img src="../images/icon-medium.svg" alt="" />Medium</h3>
                            <div className='inputCustom'>
                                <Form.Item name="mediumLink">
                                    <InputCustom placeholder='Enter link' />
                                </Form.Item>
                            </div>
                        </li>
                        <li>
                            <h3><img src="../images/icon-website.svg" alt="" />Website</h3>
                            <div className='inputCustom'>
                                <Form.Item name="websiteLink">
                                    <InputCustom placeholder='Enter link' />
                                </Form.Item>
                            </div>
                        </li>
                        <li>
                            <h3><img src="../images/icon-facebook.svg" alt="" />Facebook</h3>
                            <div className='inputCustom'>
                                <Form.Item name="facebookLink">
                                    <InputCustom placeholder='Enter link' />
                                </Form.Item>
                            </div>
                        </li>
                        <li>
                            <h3><img src="../images/icon-discord.svg" alt="" />Discord</h3>
                            <div className='inputCustom'>
                                <Form.Item name="discordLink">
                                    <InputCustom placeholder='Enter link'/>
                                </Form.Item>
                            </div>
                        </li>
                        <li>
                            <h3><img src="../images/icon-youtube.svg" alt="" />Youtube</h3>
                            <div className='inputCustom'>
                                <Form.Item name="youtubeLink">
                                    <InputCustom placeholder='Enter link'/>
                                </Form.Item>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
            <div className="p-campaignAdd__btn">
                <div className="p-campaignAdd__left">
                    <Button title='Cancel' type='grey' onClick={() => navigate('/')} />
                </div>
                <div className="p-campaignAdd__right"> 
                    <Button title='Submit' type='blue' onKeyDown={(e)=> e.keyCode == 13 ? e.preventDefault(): ''} />
                </div>
            </div>
        </div>
        </Form>
        </>
    )
}

export default MarketAdd;