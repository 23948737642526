import React, { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { Excel } from 'antd-table-saveas-excel';
import { Title, Button, Search, NoData, Loading } from '../component';
import { Table, Select } from 'antd';
import allActions from '../../actions';
import { useSelector, useDispatch } from 'react-redux';
import { formatDateTime } from '../../utils/formatDatetime';
import { Store } from 'react-notifications-component';
import addNotify from '../common/Notify/addNotify';

const columnsExport = [
    {
        title: 'Round',
        dataIndex: 'round',
    }, {
        title: 'Address',
        dataIndex: 'ownerAddress',
    }, {
        title: 'Claim time (UTC)',
        dataIndex: 'claimTime',
    }, {
        title: 'Percentage (%)',
        dataIndex: 'percentage',
    }, {
        title: 'Token Amount',
        dataIndex: 'tokenAmount',
    }, {
        title: 'signature',
        dataIndex: 'signatureIDO',
    }, {
        title: 'claimed',
        dataIndex: 'claimedTxn',
    }
];

const columns = [
    {
        title: 'Round',
        dataIndex: 'round',
        ellipsis: true,
        render: (text) => <>{text ? text : '--'}</>,
    }, {
        title: 'Address',
        dataIndex: 'ownerAddress',
        ellipsis: true,
        render: (text) => <>{text ? text.length > 8 ? `${text.substring(0, 4)}...${text.substring(text.length - 4)}` : text : '--'}</>,
    }, {
        title: 'Claim time (UTC)',
        dataIndex: 'claimTime',
        ellipsis: true,
        render: (text) => <>{text ? formatDateTime(text) : '--'}</>,
    }, {
        title: 'Percentage (%)',
        dataIndex: 'percentage',
        ellipsis: true,
        render: (text) => <>{text ? text : '--'}</>,
    }, {
        title: 'Token Amount',
        dataIndex: 'tokenAmount',
        ellipsis: true,
        render: (text) => <>{text ? text : '--'}</>,
    }, {
        title: 'signature',
        dataIndex: 'signatureIDO',
        ellipsis: true,
        render: (text) => <>{text ? text.length > 8 ? `${text.substring(0, 4)}...${text.substring(text.length - 4)}` : text : '--'}</>,
    }, {
        title: 'claimed',
        dataIndex: 'claimedTxn',
        ellipsis: true,
        render: (text) => <>{text ? <a href={`https://bscscan.com/tx/${text}`}>{text}</a> : '--'}</>,
    }
];

const Allocation = () => {
    let [searchParams, setSearchParams] = useSearchParams();
    const [isLoader, setIsLoader] = useState(false);
    const [searchInput, setSearchInput] = useState('');
    const [allocationDataTable, setAllocationDataTable] = useState([]);
    const { Option } = Select;
    const dispatch = useDispatch();

    const allCampaignData = useSelector(state => state.campaigns.campaignSimpleList);
    const roundData = useSelector(state => state.rounds);
    const allocationData = useSelector(state => state.allocations);

    const { campaignRoundList } = roundData;

    let campaignId = searchParams.get("campaign");
    let roundId = searchParams.get("round");

    useEffect(() => {
        dispatch(allActions.campaignAction.getCampaignSimple());
        onChangCampaign(campaignId);
        onChangRound(roundId);
    }, []);

    useEffect(() => {
        setAllocationDataTable(allocationData);
    }, [allocationData]);

    const dateStart = new Date("2022-11-01T07:00:00");
    const dateEnd = new Date("2022-12-01T06:59:00");

    allocationData && allocationData.map((item) => {
        let claimTimeDate = new Date(item.claimTime);
        if (dateStart <= claimTimeDate && claimTimeDate <= dateEnd) {
            let _campaign = allCampaignData && allCampaignData.filter((c) => c.id == campaignId);
            console.log([_campaign && _campaign[0]?.title, item.round, item.claimTime]);
        }
    })
    const onChangCampaign = (id) => {
        if (id) {
            dispatch(allActions.roundAction.getRoundsByCampaignsId(id));
            setSearchParams({ 'campaign': id });
        }
    }

    const onChangRound = (id) => {
        if (id) {
            setSearchParams({ 'campaign': campaignId, 'round': id });
            dispatch(allActions.allocationAction.getAllocationList(id));
        }
    }

    const onSearch = () => {
        if (searchInput !== '') {
            const data = {
                "address": searchInput.toLowerCase(),
                "roundId": parseInt(roundId)
            };
            dispatch(allActions.allocationAction.searchAllocation(data));
            setAllocationDataTable(allocationData)
        } else {
            if (roundId) {
                dispatch(allActions.allocationAction.getAllocationList(roundId));
            }
            setAllocationDataTable(allocationData);
        }
    }

    const generateAllocation = () => {
        setIsLoader(true);
        if (roundId) {
            const data = {
                "roundId": parseInt(roundId)
            };
            dispatch(allActions.allocationAction.generateAllocation(data, (res) => {
                if (res.generateAllocation === true) {
                    setIsLoader(false);
                    dispatch(allActions.allocationAction.getAllocationList(roundId));
                    setTimeout(() => {
                        Store.addNotification(addNotify('Allocation generated successfully!', 'success'));
                    }, 3000);
                }

            }));
        }
    }

    const handleExport = () => {
        const excel = new Excel();
        excel
            .addSheet("Allocation")
            .addColumns(columnsExport)
            .addDataSource(allocationDataTable, {
                str2Percent: true
            })
            .saveAs("allocation.xlsx");
    };

    return (
        <>
            {isLoader ? <Loading /> : ''}
            <div className="p-allocation">
                <div className="p-allocation__head">
                    <Title title='Allocation' subTitle='Percentage, value token statistics of each project is divided by each claim time' />
                    <div className="p-allocation__btn">
                    <Button title='Generate allocations' type='blueBorder' iconLeft='Refresh' onClick={() => generateAllocation()}/>
                </div>
                </div>
                <div className="p-allocation__boxHead">
                    <div className="c-select">
                        <p className="c-select__title">Campaign</p>
                        <Select className="c-selectChange" defaultValue={campaignId ? parseInt(campaignId) : null} onChange={(value) => onChangCampaign(value)} style={{ width: 260 }} placeholder='Select Campaign'>
                            {allCampaignData && allCampaignData.length > 0 && allCampaignData.map((item, index) => (
                                <Option key={index} value={item.id}>{item.title}</Option>
                            ))}
                        </Select>
                    </div>
                    <div className="c-select">
                        <p className="c-select__title">Round</p>
                        <Select className="c-selectChange" defaultValue={roundId ? parseInt(roundId) : null} onChange={(value) => onChangRound(value)} style={{ width: 260 }} placeholder='Select Round'>
                            {campaignRoundList && campaignRoundList.map((item, index) => (
                                <Option key={index} value={item.id}>{item.round}</Option>
                            ))}
                        </Select>
                    </div>
                </div>
                <div className="p-allocation__box">
                    <div className="c-table c-table--allocation">
                        <div className="c-table__head">
                            <Search
                                placeholder='Search address'
                                onChange={(e) => setSearchInput(e.target.value)}
                                onKeyDown={(e) => {
                                    if (e.code === "Enter") {
                                        onSearch();
                                    }
                                }}
                            />
                            <Button title='Export' type='blueBorder' iconRight='Export' onClick={handleExport} />
                        </div>
                        {allocationDataTable && allocationDataTable.length > 0 ? <Table rowKey='id' columns={columns} dataSource={allocationDataTable} /> : <NoData />}
                    </div>
                </div>
            </div>
        </>
    )
}

export default Allocation;