import React from 'react';

export const getLocalStorageDataByEncryptKey = (keyName) => {
    return localStorage.getItem(keyName) ? JSON.parse(atob(localStorage.getItem(keyName))) : null;
};

export const getLocalStorageDataByKey = (keyName) => {
    return localStorage.getItem(keyName) ? localStorage.getItem(keyName) : null;
};

export const checkExistingRoute = (lastLocation, history) => {
    history.listen(location => {
        lastLocation = location;
    });
    const prevHistoryPush = history.push;
    history.push = (path) => {
        if (!lastLocation || path && path.pathname !== lastLocation.pathname + lastLocation.search + lastLocation.hash
        ) {
            prevHistoryPush(path)
        }
    };
};