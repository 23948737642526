import {
    GET_SYSTEM_ERROR_LIST,
    GET_SYSTEM_LOCK_LIST,
    SYSTEM_CHANGE_STATUS,
    SEARCH_ERROR_LIST,
    SEARCH_LOCK_LIST
} from "../actions/systemAdminType";

const initialState = [];

function systemAdminReducer(systemAdmin = initialState, action) {
    const { type, payload } = action;

    switch (type) {
        case GET_SYSTEM_ERROR_LIST:
            return {...systemAdmin, errorList: payload};

        case GET_SYSTEM_LOCK_LIST:
            return {...systemAdmin, lockList: payload};

        case SYSTEM_CHANGE_STATUS:
            return {...systemAdmin, lockList: payload};

        case SEARCH_ERROR_LIST:
            return {...systemAdmin, errorList: payload};

        case SEARCH_LOCK_LIST:
            return {...systemAdmin, lockList: payload};

        default:
            return systemAdmin;
    }
};

export default systemAdminReducer;