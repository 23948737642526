import http from "./http-common";

const getPriorityListByRoundId = (roundId) => {
  return http.get(`/priority-lists/${roundId}`, { params: {page: 0, size: 10000}});
};
const updatePriority = (payload) => {
  return http.put(`/priority-lists/${payload.id}`, payload);
};
const addMultiPriority = (roundId, formData) => {
  return http.post(`/round/${roundId}/white-list`, formData);
};
const deletePriority = (id) => {
  return http.delete(`/priority-lists/${id}`);
};
const deleteAllPriority = (roundId, type) => {
  return http.delete(`/priority-lists/${roundId}/type/${type}`);
};
const searchPriority = (payload) => {  
  return http.post(`/priority-lists/search-by-address`, payload);
};
const SnapshotService = {
  getPriorityListByRoundId,
  updatePriority,
  deletePriority,
  deleteAllPriority,
  searchPriority
};
export default SnapshotService;