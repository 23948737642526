import React from 'react'; 
import {Title, Button } from '../component'; 
import MarketItem  from './marketItem'; 
import { useNavigate } from 'react-router-dom';

const GameMarket = () => {  
    const navigate = useNavigate();
    return(
        <> 
        <div className="p-campaign">
            <div className="p-campaign__head">
                <Title title='Game Market' subTitle='' />
                <Button title='Add Game Market' type='primary' iconLeft='BoxAdd' onClick={() => navigate("/marketGame-add")}/>
            </div> 
            <div className="p-campaign__tabs"> 
                <MarketItem /> 
            </div>
        </div> 
        </> 
    );
}

export default GameMarket;