import {
    GET_ALL_INO,
    GET_INO_CAMPAIGN,
    GET_INO_BY_ID,
    GET_INO_INFO,
    CREATE_CAMPAIGN_INO,
    EDIT_CAMPAIGN_INO,
    DELETE_CAMPAIGN_INO,
    GET_BOXES_INO,
    GET_BOXES_INO_BY_ID,
    EDIT_BOXES_INO,
    DELETE_BOXES_INO,
    CREATE_BOXES_INO,
    CREATE_RULE_INO,
    GET_RULE_INO_BY_ID,
    EDIT_RULE_INO,
    DELETE_RULE_INO,
    CREATE_TIMELINE_INO,
    GET_TIMELINE_INO_BY_ID,
    EDIT_TIMELINE_INO,
    DELETE_TIMELINE_INO,
    CREATE_INFOMATION_INO,
    EDIT_INFOMATION_INO,
    GET_INFOMATION_INO_BY_ID,
    CREATE_SETTING_INO,
    GET_SETTING_INO_BY_ID,
    EDIT_SETTING_INO,
    DELETE_SETTING_INO,
    SEARCH_CAMPAIGN_INO,
    SEARCH_BOX_INO
} from "../actions/inoType";

const initialState = [];

function inoReducer(ino = initialState, action) {
    const { type, payload } = action;

    switch (type) {
        case GET_ALL_INO:
            return { ...ino, inoListAll: payload};

        case GET_INO_CAMPAIGN:
            return { ...ino, inoListCampaign: payload};

        case GET_INO_BY_ID:
            return { ...ino, inoById: payload};

        case GET_INO_INFO:
            return { ...ino, inoInfo: payload};
        
        case CREATE_CAMPAIGN_INO:
            return payload;

        case EDIT_CAMPAIGN_INO:
            return payload;

        case DELETE_CAMPAIGN_INO:
            return payload;

        case GET_BOXES_INO:
            return { ...ino, inoListBoxes: payload};

        case GET_BOXES_INO_BY_ID:
            return { ...ino, inoBoxesById: payload};

        case CREATE_BOXES_INO:
            return payload;

        case EDIT_BOXES_INO:
            return payload;

        case DELETE_BOXES_INO:
            return payload;

        case CREATE_RULE_INO:
            return payload;

        case GET_RULE_INO_BY_ID:
            return { ...ino, inoRuleById: payload};

        case EDIT_RULE_INO:
            return payload;

        case DELETE_RULE_INO:
            return payload;

        case CREATE_TIMELINE_INO:
            return payload;

        case GET_TIMELINE_INO_BY_ID:
            return { ...ino, inoTimelineById: payload};

        case EDIT_TIMELINE_INO:
            return payload;

        case DELETE_TIMELINE_INO:
            return payload;

        case CREATE_INFOMATION_INO:
            return payload;

        case EDIT_INFOMATION_INO:
            return payload;

        case GET_INFOMATION_INO_BY_ID:
            return { ...ino, inoInfomationById: payload};

        case CREATE_SETTING_INO:
            return payload;

        case GET_SETTING_INO_BY_ID:
            return { ...ino, inoSettingById: payload};

        case EDIT_SETTING_INO:
            return payload;

        case DELETE_SETTING_INO:
            return payload;

        case SEARCH_CAMPAIGN_INO:
            return { ...ino, inoListCampaign: payload};

        case SEARCH_BOX_INO:
            return { ...ino, inoListBoxes: payload};

        default:
            return ino;
    }
};

export default inoReducer;