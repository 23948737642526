import React, {useState, useEffect, useRef} from 'react';
import { Title, InputCustom, Button, Loading, Popup } from '../component';
import { Select, DatePicker, Form, Checkbox } from 'antd';
import {useNavigate, useParams} from "react-router-dom";
import allActions from '../../actions';
import {useDispatch, useSelector} from 'react-redux'; 
import { Store } from 'react-notifications-component';
import addNotify from '../common/Notify/addNotify';
import {NetworkArray, Tokens} from '../../constants';
import axios from 'axios';   

const MarketEdit = () => {  
    const [imgBanner, setImgBanner] = useState(''); 
    const [imgBannerMobile, setImgBannerMoblie] = useState('');
    const [imgSlider1, setImgSlider1] = useState('');
    const [imgSlider2, setImgSlider2] = useState('');
    const [imgSlider3, setImgSlider3] = useState('');
    const [loadingPage, setLoadingPage] = useState(false);   
    const [arrFilter, setArrFilter] = useState([]); 
    const [isDelete, setIsDelete] = useState(false); 
    const inputImage = useRef(); 
    const inputImageMobile = useRef();  
    const inputImage1 = useRef();
    const inputImage2 = useRef();
    const inputImage3 = useRef(); 

    const [form] = Form.useForm();   
    const tokenInformData = useSelector(state => state.tokenInform);
    const marketGameById = useSelector(state => state.gamehub.marketGameById);
    const catalogsList = useSelector(state => state.gamehub.catalogsList); 
 
    let navigate = useNavigate();
    const dispatch = useDispatch();  
    const { handle } = useParams();
    const { Option } = Select;   

    useEffect(() => { 
        if(handle !== null) {
            dispatch(allActions.gamehubAction.getMarketGameById(handle));
        }
        dispatch(allActions.tokenInformAction.getTokenInformList()); 
        dispatch(allActions.gamehubAction.getCatalogList()); 
    },[]); 
    
    useEffect(() => { 
        if(handle){
            marketGameById?.hot && arrFilter.push("hot");
            marketGameById?.recommended && arrFilter.push("recommended");
            marketGameById?.popular && arrFilter.push("popular");
            arrFilter.filter((item, index) => arrFilter.indexOf(item) === index)
        }  
    }, [handle, marketGameById]);  

    useEffect(() => { 
        form.setFieldsValue({
            "id": parseInt(handle),  
            "catalog": marketGameById?.catalog.name,
            "changePercentage24h": null,
            "currentPrice": null,
            "description":  marketGameById?.description,
            "developerBy": marketGameById?.developerBy,
            "gameImages": marketGameById?.gameImages,
            "homePage": true,
            "hot": marketGameById?.hot, 
            "idoPrice": marketGameById?.idoPrice ,
            "idoRoi": null,
            "link": marketGameById?.link,
            "popular":  marketGameById?.popular,
            "position": "BOTTOM",
            "poster": marketGameById?.poster,
            "posterMobile": marketGameById?.posterMobile,
            "recommended":  marketGameById?.recommended, 
            "facebookLink": marketGameById?.socialNetwork?.facebookLink ,
            "mediumLink": marketGameById?.socialNetwork?.mediumLink ,
            "other": marketGameById?.socialNetwork?.other ,
            "telegramLink": marketGameById?.socialNetwork?.telegramLink ,
            "twitterLink": marketGameById?.socialNetwork?.twitterLink ,
            "youtuberLink": marketGameById?.socialNetwork?.youtuberLink, 
            "symbol": null,
            "title": marketGameById?.title,
            "tokenId": marketGameById?.tokenId,
            "token_logo": null,
            "totalSupply": null,
            "totalVolume": null,
            "verified": true,
            "videoLink": marketGameById?.videoLink,
            "year": null, 
        })
    },[handle, marketGameById]);  

    const handleDelete = () => {   
        dispatch(allActions.gamehubAction.deleteMarketGame(handle)); 
        setLoadingPage(true);
        setTimeout(() => {
            navigate('/marketGame')
        }, 3000);
    } 
     
    const onChangeImage= (e) => { 
        let file = e.target.files[0];
        const formData = new FormData();
        formData.append("file", file); 
        axios({
            method: 'post',
            url: process.env.REACT_APP_API_URL + `/upload-to-s3`,
            headers: {
                'accept': '*/*',
                "Authorization": `Bearer ${localStorage.getItem(Tokens.ADMIN)}`,
                "Content-Type": "multipart/form-data"
            },
            data: formData,
        }).then(res => {
           setImgBanner(res.data); 
        }).catch(err => {
            console.log(err);
        });
    } 

    const onChangeImageMobi= (e) => { 
        let file = e.target.files[0];
        const formData = new FormData();
        formData.append("file", file); 
        axios({
            method: 'post',
            url: process.env.REACT_APP_API_URL + `/upload-to-s3`,
            headers: {
                'accept': '*/*',
                "Authorization": `Bearer ${localStorage.getItem(Tokens.ADMIN)}`,
                "Content-Type": "multipart/form-data"
            },
            data: formData,
        }).then(res => {
            setImgBannerMoblie(res.data); 
        }).catch(err => {
            console.log(err);
        });
    } 

    const onChangeImageSlider1= (e) => { 
        let file = e.target.files[0];
        const formData = new FormData();
        formData.append("file", file); 
        axios({
            method: 'post',
            url: process.env.REACT_APP_API_URL + `/upload-to-s3`,
            headers: {
                'accept': '*/*',
                "Authorization": `Bearer ${localStorage.getItem(Tokens.ADMIN)}`,
                "Content-Type": "multipart/form-data"
            },
            data: formData,
        }).then(res => {
            setImgSlider1(res.data); 
        }).catch(err => {
            console.log(err);
        });
    } 
    const onChangeImageSlider2= (e) => { 
        let file = e.target.files[0];
        const formData = new FormData();
        formData.append("file", file); 
        axios({
            method: 'post',
            url: process.env.REACT_APP_API_URL + `/upload-to-s3`,
            headers: {
                'accept': '*/*',
                "Authorization": `Bearer ${localStorage.getItem(Tokens.ADMIN)}`,
                "Content-Type": "multipart/form-data"
            },
            data: formData,
        }).then(res => {
            setImgSlider2(res.data); 
        }).catch(err => {
            console.log(err);
        });
    } 
    const onChangeImageSlider3 = (e) => { 
        let file = e.target.files[0];
        const formData = new FormData();
        formData.append("file", file); 
        axios({
            method: 'post',
            url: process.env.REACT_APP_API_URL + `/upload-to-s3`,
            headers: {
                'accept': '*/*',
                "Authorization": `Bearer ${localStorage.getItem(Tokens.ADMIN)}`,
                "Content-Type": "multipart/form-data"
            },
            data: formData,
        }).then(res => {
            setImgSlider3(res.data); 
        }).catch(err => {
            console.log(err);
        });
    } 

   
    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };  

    const onFinish = (values) => {  
        if (!values.tokenId) {
            Store.addNotification(addNotify('Please select Token Inform !', 'danger'));
            setLoadingPage(false);
            return;
        }    
        
        let _catalog = catalogsList.find(x=> x.name === values.catalog);
        
        let payload = { 
            "id": parseInt(handle),
            "catalog": _catalog,
            "changePercentage24h": null,
            "currentPrice": null,
            "description": values?.description ? values?.description : null,
            "developerBy": values.developerBy ?? null,
            "gameImages": [],
            "homePage": true,
            "hot": arrFilter.find (x => x==="hot") ?true: false, 
            "idoPrice": values?.idoPrice ? values?.idoPrice : null,
            "idoRoi": null,
            "link": values.link ?? null,
            "popular":  arrFilter.find (x => x==="popular") ?true: false,
            "position": "BOTTOM",
            "poster": imgBanner ? imgBanner : marketGameById?.poster,
            "posterMobile": imgBannerMobile ? imgBannerMobile : marketGameById?.posterMobile,
            "recommended":  arrFilter.find (x => x==="recommended") ?true: false,
            "socialNetwork": {
                "id": marketGameById?.socialNetwork?.id,
                "facebookLink": values.facebookLink ?? null,
                "mediumLink": values.mediumLink ?? null,
                "other": values.other ?? null,
                "telegramLink": values.telegramLink ?? null,
                "twitterLink": values.twitterLink ?? null,
                "youtuberLink": values.youtuberLink ?? null
            },
            "symbol": null,
            "title": values.title ?? null,
            "tokenId": values.tokenId,
            "token_logo": null,
            "totalSupply": null,
            "totalVolume": null,
            "verified": true,
            "videoLink": values.videoLink ?? null,
            "year": null 
        };   
       
        dispatch(allActions.gamehubAction.updateMarketGameById(handle ,payload, (response) => {   
            if(response.status === 200){  
                Store.addNotification(addNotify("Edit Success",'success')); 
                setLoadingPage(false); 
            }
            else{ 
                Store.addNotification(addNotify(response.title, 'danger')); 
                setLoadingPage(false);
            }  
        }));  
    };
    const Options = ['hot', 'recommended', 'popular'];
    const onChange = (checkedValues) => {
        setArrFilter(checkedValues);
    };
    
    return(
        <>
        {loadingPage ? <Loading /> : ''}  
        <Form 
            name="basic"
            form={form}
            labelCol={{ span: 8 }}
            wrapperCol={{ span: 16 }}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            autoComplete="off"
        >
        <div className="p-campaignAdd">
            <div className="p-campaignAdd__left">
                <Title title='Event’s Information' subTitle='Enter the required information below to add event. You can change it before the event ends.' />
                <div className="p-campaignAdd__row">
                <div className="c-select">
                    <p className="c-select__title">Categories</p>
                    <Form.Item name="catalog">
                        <Select className="c-selectChange" placeholder='Select categories'>
                            {catalogsList?.map((e,i)=> <Option key={i.toString()} value={e?.name}>{e?.name}</Option>)}  
                        </Select>
                    </Form.Item>  
                </div>
                <div className="c-select">
                    <Form.Item   
                        name="idoPrice" 
                        rules={[{ 
                            required: false, 
                            message: "Ido Price wrong format",
                            pattern: new RegExp(/^\d*(\.\d+)?$/)
                        }]} 
                        >
                        <InputCustom title='Ido Price' />
                    </Form.Item> 
                </div>  
                </div>
                <div className="p-campaignAdd__row"> 
                    <div className="c-select">  
                            <p className="c-select__title">Token Inform <span style={{color: 'red'}}>*</span></p>
                            <Form.Item 
                                name="tokenId" 
                            > 
                                <Select className="c-selectChange"> 
                                    {tokenInformData && tokenInformData.map((token) => ( 
                                        token?.tokenId ? <Option key={token.id} value={token?.tokenId}>
                                            <img src={token.logoURL} alt={token.symbol + " - " + token.name} />
                                        {token.symbol} - {token.name}
                                         </Option> : <Option key={token.id} value={null}>
                                            TokenId null 
                                         </Option>
                                       )
                                    )}
                                </Select> 
                            </Form.Item>  
                    </div>
                    <div className="c-select">
                        <Form.Item 
                            name="link" 
                        >
                            <InputCustom title='Link Play Game' />
                        </Form.Item>
                    </div> 
                </div> 
                <div className="p-campaignAdd__row">  
                    <div className="c-select">
                        <Form.Item 
                            name="developerBy" 
                        >
                            <InputCustom title='Developer' />
                        </Form.Item>
                    </div> 
                    <div className="c-select">
                        <Form.Item 
                            name="videoLink" 
                        >
                            <InputCustom title='Video link' />
                        </Form.Item>
                    </div> 
                </div>  
                <p className="c-select__title">Filter</p>
                <div className="c-select"> 
                    <Checkbox.Group options={Options} value={arrFilter} onChange={onChange}/> 
                </div> 
            </div>
            <div className="p-campaignAdd__right"> 
                <div className="c-upload">
                    <h3 className="c-upload__title">Images</h3>
                    <div className={`c-upload__box${imgBanner === '' ? ' is-add' : ''}`}>
                        {imgBanner? 
                            <div onClick={() => inputImage.current.click()} className="c-upload__edit">
                                <img src={imgBanner} alt="" />
                            </div>:
                            <div onClick={() => inputImage.current.click()} className="c-upload__edit">
                                <img src={marketGameById?.poster} alt="" />
                        </div> }  
                    </div>
                    <input ref={inputImage} onChange={onChangeImage} type="file" /> 
                </div>

                <div className="c-upload">
                    <h3 className="c-upload__title">Images Mobile</h3>
                    <div className={`c-upload__box${imgBannerMobile === '' ? ' is-add' : ''}`}> 
                        {imgBannerMobile ? 
                                <div onClick={() => inputImageMobile.current.click()} className="c-upload__edit">
                                    <img src={imgBannerMobile} alt="" />
                                </div>:
                                <div onClick={() => inputImageMobile.current.click()} className="c-upload__edit">
                                    <img src={marketGameById?.posterMobile} alt="" />
                                </div> }  
                            <input ref={inputImageMobile} onChange={onChangeImage} type="file" />
                    </div>
                    <input ref={inputImageMobile} onChange={onChangeImageMobi} type="file" /> 
                </div>

                <div className='slider-gamehub'>
                    <div className="c-upload">
                        <h3 className="c-upload__title">Slider 1</h3>
                        <div className={`c-upload__box${imgSlider1 === '' ? ' is-add' : ''}`}>
                            {imgSlider1 ? <div onClick={() => inputImage1.current.click()} className="c-upload__edit">
                                <img src={imgSlider1} alt="" />
                            </div> : <div className='btn-click' onClick={()=> inputImage1.current.click()}> 
                                <img src={marketGameById?.gameImages[0]?.path+"/"+marketGameById?.gameImages[0]?.name } alt="" />
                            </div>}
                        </div>
                        <input ref={inputImage1} onChange={onChangeImageSlider1} type="file" /> 
                    </div>
                    <div className="c-upload">
                        <h3 className="c-upload__title">Slider 2</h3>
                        <div className={`c-upload__box${imgSlider2 === '' ? ' is-add' : ''}`}>
                            {imgSlider2 ? <div onClick={() => inputImage2.current.click()} className="c-upload__edit">
                                <img src={imgSlider2} alt="" />
                            </div> : <div className='btn-click' onClick={()=> inputImage2.current.click()}> 
                                <img src={marketGameById?.gameImages[1]?.path+"/"+marketGameById?.gameImages[1]?.name } alt="" />
                            </div>}
                        </div>
                        <input ref={inputImage2} onChange={onChangeImageSlider2} type="file" /> 
                    </div>
                    <div className="c-upload">
                        <h3 className="c-upload__title">Slider 3</h3>
                        <div className={`c-upload__box${imgSlider3 === '' ? ' is-add' : ''}`}>
                            {imgSlider3 ? <div onClick={() => inputImage3.current.click()} className="c-upload__edit">
                                <img src={imgSlider3} alt="" />
                            </div> : <div className='btn-click' onClick={()=> inputImage3.current.click()}> 
                                <img src={marketGameById?.gameImages[2]?.path+"/"+marketGameById?.gameImages[2]?.name } alt="" />
                            </div>}
                        </div>
                        <input ref={inputImage3} onChange={onChangeImageSlider3} type="file" /> 
                    </div>
                </div>
                <div className="p-campaignAdd__row is-wFull">
                    <div className="c-select">
                        <Form.Item 
                            name="title"  
                        >
                            <InputCustom title='Game Name' placeholder='Enter Name' />
                        </Form.Item>
                    </div>
                </div> 

                <div className="p-campaignAdd__row is-wFull">
                    <div className="c-select">
                        <Form.Item 
                            name="description"  
                        >
                            <InputCustom title='Description' placeholder='Enter description' />
                        </Form.Item>
                    </div>
                </div> 
                <div className="p-campaignAdd__row is-wFull">
                    <h3 className="c-ttl is-font16">Channel Media</h3>
                    <ul className="c-media">
                        <li>
                            <p>Channel name</p>
                            <p>URL</p>
                        </li>
                        <li>
                            <h3><img src="../images/icon-telegram.svg" alt="" />Telegram</h3>
                            <div className='inputCustom'>
                                <Form.Item name="telegramLink">
                                    <InputCustom placeholder='Enter link' />
                                </Form.Item>
                            </div>
                        </li>
                        <li>
                            <h3><img src="../images/icon-twitter.svg" alt="" />Twitter</h3>
                            <div className='inputCustom'>
                                <Form.Item name="twitterLink">
                                    <InputCustom placeholder='Enter link' />
                                </Form.Item>
                            </div>
                        </li>
                        <li>
                            <h3><img src="../images/icon-medium.svg" alt="" />Medium</h3>
                            <div className='inputCustom'>
                                <Form.Item name="mediumLink">
                                    <InputCustom placeholder='Enter link' />
                                </Form.Item>
                            </div>
                        </li>
                        <li>
                            <h3><img src="../images/icon-website.svg" alt="" />Website</h3>
                            <div className='inputCustom'>
                                <Form.Item name="websiteLink">
                                    <InputCustom placeholder='Enter link' />
                                </Form.Item>
                            </div>
                        </li>
                        <li>
                            <h3><img src="../images/icon-facebook.svg" alt="" />Facebook</h3>
                            <div className='inputCustom'>
                                <Form.Item name="facebookLink">
                                    <InputCustom placeholder='Enter link' />
                                </Form.Item>
                            </div>
                        </li>
                        <li>
                            <h3><img src="../images/icon-discord.svg" alt="" />Discord</h3>
                            <div className='inputCustom'>
                                <Form.Item name="discordLink">
                                    <InputCustom placeholder='Enter link'/>
                                </Form.Item>
                            </div>
                        </li>
                        <li>
                            <h3><img src="../images/icon-youtube.svg" alt="" />Youtube</h3>
                            <div className='inputCustom'>
                                <Form.Item name="youtubeLink">
                                    <InputCustom placeholder='Enter link'/>
                                </Form.Item>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
            <div className="p-campaignAdd__btn">
                <div className="p-campaignAdd__left">
                    <Button title='Cancel' type='grey' onClick={() => navigate('/')} />
                </div>
                <div className="p-campaignAdd__right"> 
                    <div className="c-btn c-btn--flat" type='flat' onClick={() => setIsDelete(true)}>Delete</div>
                    <Button title='Submit' type='blue' onKeyDown={(e)=> e.keyCode == 13 ? e.preventDefault(): ''} />
                </div>
            </div>
            {isDelete ? <Popup onClick={() => handleDelete()} title='Do you want delete this campaign?' subTitle='You can’t restore this file' btnTitle='Delete' icon='Delete' onCancel={() => setIsDelete(false)} /> : ''}
        </div>
        </Form>
        </>
    )
}

export default MarketEdit;