export const UPDATE_CLAIM_IDO    = "UPDATE_CLAIM_IDO";

export const GET_ALLOCATION_LIST    = "GET_ALLOCATION_LIST";

export const SEARCH_ALLOCATION    = "SEARCH_ALLOCATION";

export const GEN_SIGN_BY_ROUNDID    = "GEN_SIGN_BY_ROUNDID";

export const ASYNC_VESTING_SCHEDULE    = "ASYNC_VESTING_SCHEDULE";

export const GENERATE_ALLOCATION = "GENERATE_ALLOCATION";
