import {
    GET_PRIORITY_LIST_BY_CAMPAIGN_ID
} from "../actions/inoType";

const initialState = [];

function inoWhitelistReducer(ino = initialState, action) {
    const { type, payload } = action;

    switch (type) {
        case GET_PRIORITY_LIST_BY_CAMPAIGN_ID:
            return { ...ino, inoPriorityList: payload};

        default:
            return ino;
    }
};

export default inoWhitelistReducer;