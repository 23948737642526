import React, {useEffect, useState} from 'react';
import allActions from '../../actions';
import {useSelector, useDispatch} from 'react-redux';
import {Button} from '../component';
import { Table, Select } from 'antd';
import { Excel } from 'antd-table-saveas-excel';

const columnsRemainingExport = [
    {
        title: 'Campaign Name',
        dataIndex: 'name',
        ellipsis: true,
    }, {
        title: 'UNIT',
        dataIndex: 'unit',
        ellipsis: true,
    }, {
        title: 'private',
        dataIndex: 'private',
        ellipsis: true,
    }, {
        title: 'guaranteed',
        dataIndex: 'guaranteed',
        ellipsis: true,
    }, {
        title: 'community',
        dataIndex: 'community',
        ellipsis: true,
    }, {
        title: 'wallet',
        dataIndex: 'wallet',
        ellipsis: true,
    }
];

const columnsRemaining = [
    {
        title: 'Campaign Name',
        dataIndex: 'name',
        render: (_, record) => (
            <div className="c-nameTable">
                <div className="c-nameTable__logo"><img src={record.img} alt={record.name} /></div>
                <p className="c-nameTable__title">{record.name}</p>
            </div>
        ),
        className: 'is-border',
        ellipsis: true,
        onCell: (_, index) => {
            if (index % 2 === 0) {
                return {
                    rowSpan: 2
                }
            }
            if (index % 2 === 1) {
                return {
                    rowSpan: 0
                }
            }
            if (index % 2 === 2) {
                return {
                    rowSpan: 2
                }
            }
            return {};
        }
    }, {
        title: 'UNIT',
        dataIndex: 'unit',
        ellipsis: true,
    }, {
        title: 'private',
        dataIndex: 'private',
        ellipsis: true,
    }, {
        title: 'guaranteed',
        dataIndex: 'guaranteed',
        ellipsis: true,
    }, {
        title: 'community',
        dataIndex: 'community',
        ellipsis: true,
    }, {
        title: 'wallet',
        dataIndex: 'wallet',
        ellipsis: true,
    }, {
        title: 'action',
        dataIndex: 'action',
        className: 'is-btnCenter',
        render: (_, record) => (
            <Button title='Claim' type='flat' disabled iconLeft='Claim'/>
        ),
    }
];

const AdminRemainingTable = ({id}) => {
    const { Option } = Select;
    const dispatch = useDispatch();

    const remainingData = useSelector(state => state.campaigns.remainingList);
    const campaignSimpleList = useSelector(state => state.campaigns.campaignSimpleList);

    useEffect(() => {
        dispatch(allActions.campaignAction.getCampaignSimple());
    },[]);

    useEffect(() => {
        if(id) {
            dispatch(allActions.campaignAction.getRemainingById(id));
        } else {
            dispatch(allActions.campaignAction.getRemainingById(''));
        }
    },[id]);

    const handleChange = (value) => {
        dispatch(allActions.campaignAction.getRemainingById(value));
    };

    let remainingnList = [];
    remainingData && remainingData.map((item, key) => {
        const _roundList = item.roundList;
        let _items;
        //console.log("key", key)
        let index = key+1;
        index = index*2;
        if(_roundList && _roundList.length > 0) {
            const communityRound =  _roundList.filter((i) => i.roundType === 1);
            const guaranteeRound =  _roundList.filter((i) => i.roundType === 2);
            const privateRound =  _roundList.filter((i) => i.roundType === 3);

            remainingnList.push({
                key: index,
                name: item.title,
                img: item.logoUrl,
                unit: item.tokenInformSymbol,
                private: privateRound && privateRound.length > 0 ? privateRound[0].totalTokenRemaining : 0,
                guaranteed: guaranteeRound && guaranteeRound.length > 0 ? guaranteeRound[0].totalTokenRemaining : 0,
                community: communityRound && communityRound.length > 0 ? communityRound[0].totalTokenRemaining : 0,
                wallet: '--',
            });

            remainingnList.push({
                key: index+1,
                name: item.title,
                img: item.logoUrl,
                unit: item.acceptCurrency,
                private: privateRound && privateRound.length > 0 ? privateRound[0].totalStableCoinRemaining : 0,
                guaranteed: guaranteeRound && guaranteeRound.length > 0 ? guaranteeRound[0].totalStableCoinRemaining : 0,
                community: communityRound && communityRound.length > 0 ? communityRound[0].totalStableCoinRemaining : 0,
                wallet: '--',
            });
        }
        else {
            remainingnList.push({
                key: index,
                name: item.title,
                img: item.logoUrl,
                unit: item.tokenInformSymbol,
                private: 0,
                guaranteed: 0,
                community: 0,
                wallet: '--',
            });

            remainingnList.push({
                key: index+1,
                name: item.title,
                img: item.logoUrl,
                unit: item.acceptCurrency,
                private: 0,
                guaranteed: 0,
                community: 0,
                wallet: '--',
            });
        }
    });

    const handleExport = () => {
        const excel = new Excel();
        excel
        .addSheet("Remaining")
        .addColumns(columnsRemainingExport)
        .addDataSource(remainingnList, {
            str2Percent: true
        })
        .saveAs("remainingList.xlsx");
    };

    console.log("remainingnList", remainingnList);
    
    return(
        <div className="c-table c-table--remaining">
            <div className="c-table__head">
                <Select className="c-selectChange" defaultValue={id !== null ? id : 'All Campaign'} style={{width: 260}} placeholder="Select a campaign" onChange={handleChange}>
                    {campaignSimpleList && campaignSimpleList.length > 0 && campaignSimpleList.map((item, index) => (
                        <Option key={index} value={item.id}>{item.title}</Option>
                    ))}
                </Select>
                <Button title='Export' type='blueBorder' iconRight='Export' onClick={handleExport}/>
            </div>
            <Table columns={columnsRemaining} dataSource={remainingnList} />
        </div>
    );
}

export default AdminRemainingTable;