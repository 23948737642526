import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import {Title, Button, StatusLabel} from '../component';
import { Tabs, Table, Select } from 'antd';
import allActions from '../../actions';
import {useSelector, useDispatch} from 'react-redux';
import { formatDateTime } from '../../utils/formatDatetime';
import { formatData } from '../../utils/StringHelper';
import { Excel } from 'antd-table-saveas-excel';

const MarketItem = () => {
    const { Option } = Select;
    const dispatch = useDispatch(); 
    
    const columnsOverview = [
        {
            title: 'GAME NAME',
            dataIndex: 'title', 
            ellipsis: true,
        }, 
        {
            title: 'DISPLAY',
            dataIndex: 'hot',  
            ellipsis: true, 
            render: (_, record) => ( 
                record?.hot ?  <p>Hot</p> :
                record?.popular ?  <p>Popular</p> :
                record?.recommended &&  <p>Recommended</p>   
            ), 
        }, {
            title: 'CATEGORIES',
            dataIndex: 'startAirdrop', 
            ellipsis: true,
            render: (_, record) => (  
                <p>{record?.catalog?.name}</p>  
            ), 
        }, {
            title: 'action',
            dataIndex: 'action',
            render: (_, record) => (
                <div className="c-actionTable">
                    <div className="c-actionTable__box">
                        <img src="../images/icon-more.svg" alt="" />
                        <ul className="c-actionTable__content">
                            <li><Link to={'/marketGame-edit/'+record.id}>Edit</Link></li>
                            <li><Link to={'/marketGame-info/'+record.id}>Information</Link></li> 
                        </ul>
                    </div>
                </div>
            ),
        }
    ];

    useEffect(() => { 
        dispatch(allActions.gamehubAction.getMarketList()); 
    }, []); 
    
    const marketList = useSelector(state => state.gamehub.marketList);  
    
    marketList && marketList.length > 0 && marketList.sort((a, b) => {return b.id - a.id});


    const onChange = (pagination, filters, sorter, extra) => {
        console.log('params', pagination, filters, sorter, extra);
    };  
    return(
        <div className="c-table"> 
            <Table rowKey='id' columns={columnsOverview} dataSource={marketList} onChange={onChange} />
        </div>
    );
}

export default MarketItem;