import React, {useState, useRef, useEffect} from 'react';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import {Title, Button, Label, Search, Popup, InputCustom, Loading } from '../component';
import { Tabs, Table, Select , Form} from 'antd'; 
import { UploadIcon } from '../common/asset/icon';
import BannerItem  from './bannerItem'; 
import axios from 'axios';  
import {Tokens} from '../../constants';
import {useDispatch} from 'react-redux';
import allActions from '../../actions';

const Banner = () => { 
    const dispatch = useDispatch();
    const [isAdd, setIsAdd] = useState(false)
    const [form] = Form.useForm();
    const navigate = useNavigate(); 
    const [imageData, setImageData] = useState("");
    const [imageDataMobile, setImageDataMobile] = useState(""); 
    const inputRef = useRef(null);
    const inputRefMobi = useRef(null);


    const onFinish = (values) => {
        const payload = {
            banner: imageData,
            bannerMobile: imageDataMobile, 
            position: "TOP",
            title: values.linkImage,
            type: "AIRDROP"
        }  
        dispatch(allActions.airdropAction.postBanner(payload ,(res)=>{
            if(res){
                setIsAdd(false);
                window.location.reload(true);
            }
        })); 
    } 

    const onChangeImage = (e, type) =>{
        let file = e.target.files[0]; 
        const formData = new FormData(); 
        formData.append("file", file); 
        axios({
            method: 'post',
            url: process.env.REACT_APP_API_URL + `/upload-to-s3`,
            headers: {
                'accept': '*/*',
                "Authorization": `Bearer ${localStorage.getItem(Tokens.ADMIN)}`,
                "Content-Type": "multipart/form-data"
            },
            data: formData,
        }).then(res => { 
            if(type === "web"){
                setImageData(res.data);
            }
            else{
                setImageDataMobile(res.data)
            }  
        }).catch(err => {
            console.log(err);
        });
    }  
    
    return(
        <> 
        <div className="p-campaign">
            <div className="p-campaign__head">
                <Title title='Banner Slider' subTitle='Banners that have been uploaded' />
                <Button title='Upload Banner' type='primary' onClick={() => setIsAdd(true)} iconLeft='BoxAdd'/>
            </div> 
            <div className="p-campaign__tabs"> 
                <BannerItem /> 
            </div>
        </div>
        {isAdd ? <Form form={form} name="basic" onFinish={onFinish}>
         <Popup  title='Upload Banner' btnTitle='Save' onCancel={() => setIsAdd(false)}>
             <Form.Item name="id" className="l-displayNone">
                 <InputCustom type="hidden" />
             </Form.Item>
             <Form.Item name="key" className="l-displayNone">
                 <InputCustom type="hidden" />
             </Form.Item> 
             <h3 className="add_img">Website</h3>
             <Form.Item name="banner">
                <input ref={inputRef} type='file' onChange={(e) => onChangeImage(e, "web")} />
                {imageData ?
                    <div onClick={()=>inputRef.current.click()} className="c-img airdrop-upload">
                        <img src={imageData} alt="" />
                    </div> : 
                    <div onClick={()=>inputRef.current.click()} className="c-uploadIMG"> 
                        <div className="c-btn c-btn--flat">
                            <UploadIcon />Upload Image Website
                        </div>
                    </div>
                } 
            </Form.Item> 

            <h3 className="add_img">Mobile</h3>
             <Form.Item name="bannerMobile">
                <input ref={inputRefMobi} type='file' onChange={(e) => onChangeImage(e, "mobi")} />
                {imageDataMobile ?
                    <div onClick={()=>inputRefMobi.current.click()} className="c-img airdrop-upload">
                        <img src={imageDataMobile} alt="" />
                    </div> : 
                    <div onClick={()=>inputRefMobi.current.click()} className="c-uploadIMG"> 
                        <div className="c-btn c-btn--flat">
                            <UploadIcon />Upload Image Mobile
                        </div>
                    </div>
                } 
            </Form.Item> 
            <Form.Item name="linkImage">
                <InputCustom title='Link Image' />
            </Form.Item> 
         </Popup>
         </Form> : null} 
        </> 
    );
}

export default Banner;