import React, {useState, useEffect} from 'react';
import {Button, Input, Select} from '../component';

//Image
import LogoKDG from '../common/asset/images/logo-kdg.svg';
import LoginImgTop from '../common/asset/images/login-img1.png';
import LoginImgBottom from '../common/asset/images/login-img2.png';
import {useSelector, useDispatch} from 'react-redux';
import allActions from '../../actions';
import { useNavigate } from "react-router-dom";
import { getLocalStorageDataByEncryptKey } from '../../utils/sharedMethod';
import { Routes, Tokens } from '../../constants';

const Login = () => {  
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [userData, setUserData] = useState({
        "username": "",
        "password": "",
        "rememberMe": true
    });

    useEffect(() => {
        if (localStorage.getItem(Tokens.ADMIN)) {
            navigate(Routes.ADMIN_DEFAULT);
        }
        if(getLocalStorageDataByEncryptKey('currentUser')) {
            setUserData(getLocalStorageDataByEncryptKey('currentUser'));
        }
        
        // const params = new URLSearchParams(props.location.search);
        // const msg = params.get('msg');
        // const success = params.get('success');
        // if (msg) {
        //     displayMessage(msg, +success);
        // }
    }, []);

    const handleChangeUsername = (e) => {

        console.log("handleChangeUsername", e.target.value);
        setUserData({
            ...userData,  
            username: e.target.value 
        });
    }

    const handleChangePassword = (e) => {
        setUserData({
            ...userData,  
            password: e.target.value 
        });
    }

    const handleLogin = () => {
        dispatch(allActions.authAction.login(userData, navigate));
    }
    return(
        <div className="p-login">
            <div className="p-login__left">
                <h1><img src={LogoKDG} alt="KDG" /></h1>
            </div>
            <div className="p-login__box">
                <div className="p-login__title">
                    <p>Welcome to <span>KingdomStarter</span></p>
                    <h3>Sign in</h3>
                </div>
                <div className="p-login__row">
                    <Input title='Username' 
                        placeholder='Enter your username' 
                        required={true}
                        onChange={handleChangeUsername}
                    />
                </div>
                <div className="p-login__row">
                    <Input type='password' 
                        title='Password' 
                        placeholder='Enter your password' 
                        required={true}
                        onChange={handleChangePassword}
                    />
                </div>
                <div className="p-login__forgot">
                    <a href="#">Forgot Password</a>
                </div>
                <Button title='Sign In' type='primary' onClick={() => handleLogin()} />
            </div>
            <div className="p-login__img is-top"><img src={LoginImgTop} alt="KDG 1$ soon" /></div>
            <div className="p-login__img is-bottom"><img src={LoginImgBottom} alt="KDG 1$ soon" /></div>
        </div>
    );
}

export default Login;