import userActions from './userActions';
import campaignAction from './campaignAction';
import roundAction from './roundAction';
import whitelistAction from './whitelistAction';
import authAction from './authAction';
import tokenInformAction from './tokenInformAction';
import allocationAction from './allocationAction';
import snapshotAction from './snapshotAction';
import systemAdminAction from './systemAdminAction';
import airdropAction from './airdropAction'; 
import inoAction from './inoAction';
import inoWhitelistAction from './inoWhitelistAction';
import gamehubAction from './gamehubAction';


const allActions = {
    userActions,
    campaignAction,
    roundAction,
    whitelistAction,
    authAction,
    tokenInformAction,
    allocationAction,
    snapshotAction,
    systemAdminAction,
    airdropAction,
    inoAction,
    inoWhitelistAction,
    gamehubAction
}

export default allActions