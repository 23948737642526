import SnapshotDataService from "../services/SnapshotService";
import { snapshotActionType } from "./actionType";

const getPriorityListByRoundId = (roundId) => async (dispatch) => {
    try {
      const res = await SnapshotDataService.getPriorityListByRoundId(roundId);
      dispatch({
        type: snapshotActionType.GET_SNAPSHOT_PRIORITY_LIST_BY_ROUNDID,
        payload: res && res.data
      });
    } catch (err) {
      console.log(err);
    }
}
const updatePriority = (payload) => async (dispatch) => {
  try {
    const res = await SnapshotDataService.updatePriority(payload);
    dispatch({
      type: snapshotActionType.UPDATE_PRIORITY,
      payload: {}
    });
  } catch (err) {
    console.log(err);
  }
}

const deletePriority = (id, onCallback) => async (dispatch) => {
  try {
    const res = await SnapshotDataService.deletePriority(id);
    if(res && res.status == 200) {
      onCallback({callResult: true, isSuccess: true});
    }
    else {
      onCallback({callResult: true, isSuccess: false});
    }
  } catch (err) {
    console.log(err);
  }
}

const deleteAllPriority = (roundId, type, onCallback) => async (dispatch) => {
  try {
    const res = await SnapshotDataService.deleteAllPriority(roundId, type);
    if(res && res.status == 200) {
      onCallback({callResult: true, isSuccess: true});
    }
    else {
      onCallback({callResult: true, isSuccess: false});
    }
  } catch (err) {
    console.log(err);
    onCallback({callResult: true, isSuccess: false});
  }
}

const searchPriority = (payload, onCallback) => async (dispatch) => {
  try {
    const res = await SnapshotDataService.searchPriority(payload);
    dispatch({
      type: snapshotActionType.SEARCH_PRIORITY,
      payload: res && res.data || []
    });
    
    onCallback({callResult: true});
  } catch (err) {
    dispatch({
      type: snapshotActionType.SEARCH_PRIORITY,
      payload: []
    });
    onCallback({callResult: true});
    console.log(err);
  }
}
export default {
  getPriorityListByRoundId,
  updatePriority,
  deletePriority,
  deleteAllPriority,
  searchPriority
}