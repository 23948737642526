import { whitelistActionType } from "../actions/actionType";

const initialState = [];

function whitelistReducer(whitelist = initialState, action) {
    const { type, payload } = action;

    switch (type) {
        case whitelistActionType.GET_WHITELIST_STAKING_BY_ROUNDID:
            return { ...whitelist, stakingList:  payload }

        case whitelistActionType.GET_WHITELIST_PRIORITY_BY_ROUNDID:
            return { ...whitelist, priorityList:  payload }

        case whitelistActionType.GENERATE_SIGNATURE_COMMIT_ALL:
            return { ...whitelist, generateAll:  payload }

        case whitelistActionType.GENERATE_SIGNATURE_COMMIT_ONE:
            return { ...whitelist, generateOne:  payload }

        default:
            return { ...whitelist };
    }
};

export default whitelistReducer;