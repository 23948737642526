import React from 'react';
import {Button, Input, Select, Title, Toggle, Radio, Label, Search} from './component';

const Component = () => {
    return(
        <div className="p-component">
            <div className="p-component__box">
                <div className="p-component__item">
                    <Button title='Add Campaign' type='primary'/>
                </div>
                <div className="p-component__item">
                    <Button title='Add Campaign' type='primary' disabled={true}/>
                </div>
                <div className="p-component__item">
                    <Button title='Add Campaign' type='primary' iconLeft='BoxAdd'/>
                </div>
                <div className="p-component__item">
                    <Button title='Add Campaign' type='primary' iconLeft='Upload'/>
                </div>
                <div className="p-component__item">
                    <Button title='Edit Campaign' type='primary' iconLeft='Edit'/>
                </div>
            </div>
            <div className="p-component__box">
                <div className="p-component__item">
                    <Button title='Campaign' type='flat'/>
                </div>
                <div className="p-component__item">
                    <Button title='Campaign' type='flat' disabled={true}/>
                </div>
                <div className="p-component__item">
                    <Button title='Campaign' type='flat' iconLeft='Plus'/>
                </div>
                <div className="p-component__item">
                    <Button title='Campaign' type='flat' iconLeft='Download'/>
                </div>
                <div className="p-component__item">
                    <Button title='Campaign' type='flat' iconLeft='Trash'/>
                </div>
                <div className="p-component__item">
                    <Button title='Campaign' type='flat' iconLeft='Refresh'/>
                </div>
                <div className="p-component__item">
                    <Button title='Claim' type='flat' iconLeft='Claim'/>
                </div>
            </div>
            <div className="p-component__box">
                <div className="p-component__item">
                    <Button title='Add Campaign' type='blue'/>
                </div>
                <div className="p-component__item">
                    <Button title='Add Campaign' type='blue' disabled={true}/>
                </div>
                <div className="p-component__item">
                    <Button title='Upload' type='blue' iconLeft='Upload'/>
                </div>
                <div className="p-component__item">
                    <Button title='Unlock' type='blue' iconLeft='Unlock'/>
                </div>
            </div>
            <div className="p-component__box">
                <div className="p-component__item">
                    <Button title='Campaign' type='blueBorder'/>
                </div>
                <div className="p-component__item">
                    <Button title='Campaign' type='blueBorder' disabled={true}/>
                </div>
                <div className="p-component__item">
                    <Button title='Campaign' type='blueBorder' iconLeft='ArrowLeft'/>
                </div>
                <div className="p-component__item">
                    <Button title='Campaign' type='blueBorder' iconRight='ArrowRight'/>
                </div>
                <div className="p-component__item">
                    <Button title='Export' type='blueBorder' iconRight='Export'/>
                </div>
            </div>
            <div className="p-component__box">
                <div className="p-component__item">
                    <Toggle title='Content' />
                </div>
            </div>
            <div className="p-component__box">
                <div className="p-component__item">
                    <Radio name='Claim' title='Claim Type' items={['Monthly', 'Daily']} />
                </div>
            </div>
            <div className="p-component__box">
                <div className="p-component__item">
                    <Input title='Content' placeholder='Enter your content' />
                </div>
                <div className="p-component__item">
                    <Input type='password' title='Password' placeholder='Enter your password' required={true}/>
                </div>
            </div>
            <div className="p-component__box">
                <div className="p-component__item">
                    <Search placeholder='Search token' />
                </div>
            </div>
            <div className="p-component__box">
                <div className="p-component__item">
                    <Select title='test' items={['Select a fruit', 'Banana', 'Apple', 'Orange']} defaultValue='Banana'/>
                </div>
            </div>
            <div className="p-component__box">
                <div className="p-component__item">
                    <Title title='Campaign' subTitle='List of campaign that admin added' />
                </div>
            </div>
            <div className="p-component__box">
                <div className="p-component__item">
                    <Label title='Upcoming' />
                </div>
                <div className="p-component__item">
                    <Label title='TBA' />
                </div>
                <div className="p-component__item">
                    <Label title='Opening' />
                </div>
                <div className="p-component__item">
                    <Label title='Completed' />
                </div>
            </div>
            <div className="p-component__box">
                <div className="p-component__item">
                    <div className="c-pager">
                        <p>Showing 1 to 10 of 100 entries</p>
                        <ul>
                            <li><button><img src="../images/icon-arrow-leftDup.svg" alt="" /></button></li>
                            <li><button><img src="../images/icon-arrow-left.svg" alt="" /></button></li>
                            <li className="is-active"><a href="/">1</a></li>
                            <li><a href="/">2</a></li>
                            <li><a href="/">3</a></li>
                            <li><a href="/">4</a></li>
                            <li><a href="/">5</a></li>
                            <li><button><img src="../images/icon-arrow-right.svg" alt="" /></button></li>
                            <li><button><img src="../images/icon-arrow-rightDup.svg" alt="" /></button></li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default Component;