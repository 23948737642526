import RoundDataService from "../services/RoundService";
import {
  GET_ROUNDS_BY_CAMPAIGN_ID,
  GET_ROUND_BY_ID,
  UPDATE_ROUND,
  DELETE_ROUND,
  CREATE_ROUND
} from "./roundType";

const getRoundsByCampaignsId = (id) => async (dispatch) => {
  try {
    const res = await RoundDataService.getRoundsByCampaignsId(id);
    dispatch({
      type: GET_ROUNDS_BY_CAMPAIGN_ID,
      payload: res && res.data || []
    });
  } catch (err) {
    console.log(err);
  }
}

const getRoundById = (id) => async (dispatch) => {
  try {
    const res = await RoundDataService.getRoundById(id);
    dispatch({
      type: GET_ROUND_BY_ID,
      payload: res && res.data || []
    });
  } catch (err) {
    console.log(err);
  }
}

const updateRound = (payload, callback) => async (dispatch) => { 
  try {  
    const res = await RoundDataService.updateRound(payload); 
      dispatch({
        type: UPDATE_ROUND,
        payload: {}
      });   
      callback(res)
  } catch (err) { 
    callback(err.response.data);
  }
}

const deleteRound = (id) => async (dispatch) => {
  try {
    const res = await RoundDataService.deleteRound(id); 
    dispatch({
      type: DELETE_ROUND,
      payload: {}
    });
    
  } catch (err) {
    console.log(err);
  }
}

const createRound = (payload, callback) => async (dispatch) => {
  try {
    const res = await RoundDataService.createRound(payload);   
      dispatch({
        type: CREATE_ROUND,
        payload: {}
      }); 
      callback(res);  
  } catch (err) { 
    callback(err.response.data);
  }
}

const roundAction = {
  getRoundsByCampaignsId,
  getRoundById,
  updateRound,
  deleteRound,
  createRound
};

export default roundAction;