import AllocationService from "../services/AllocationService";
import {
  UPDATE_CLAIM_IDO,
  GET_ALLOCATION_LIST,
  SEARCH_ALLOCATION,
  GEN_SIGN_BY_ROUNDID,
  ASYNC_VESTING_SCHEDULE,
  GENERATE_ALLOCATION
} from "./allocationType";

const updateClaimIdo = (payload) => async (dispatch) => {
    try {
      const res = await AllocationService.updateClaimIdo(payload);
      console.log(res);
      dispatch({
        type: UPDATE_CLAIM_IDO,
        payload: {}
      });
    } catch (err) {
      console.log(err);
    }
}

const getAllocationList = (payload) => async (dispatch) => {
  try {
    const res = await AllocationService.getAllocationList(payload);
    dispatch({
      type: GET_ALLOCATION_LIST,
      payload: res && res.data || []
    });
  } catch (err) {
    console.log(err);
  }
}

const searchAllocation = (payload) => async (dispatch) => {
  try {
    const res = await AllocationService.searchAllocation(payload);
    dispatch({
      type: SEARCH_ALLOCATION,
      payload: res && res.data || []
    });
  } catch (err) {
    console.log(err);
  }
}

const genSignByRoundId = (payload, callback) => async (dispatch) => {
  try {
    const res = await AllocationService.genSignByRoundId(payload);
    if(res && res.status === 200) {
      dispatch({
        type: GEN_SIGN_BY_ROUNDID,
        payload: res && res.data || []
      });
      callback({genSignResult: true})
    } else {
      callback({genSignResult: false})
    }
    
  } catch (err) {
    console.log(err);
  }
}

const asyncVestingSchedule = (payload, callback) => async (dispatch) => {
  try {
    const res = await AllocationService.asyncVestingSchedule(payload);
    if(res && res.status === 200) {
      dispatch({
        type: ASYNC_VESTING_SCHEDULE,
        payload: res && res.data || []
      });
      callback({asyncVestingResult: true})
    } else {
      callback({asyncVestingResult: false})
    }
    
  } catch (err) {
    console.log(err);
  }
}

const generateAllocation = (payload, callback) => async (dispatch) => {
  try {
    const res = await AllocationService.generateAllocation(payload);
    if(res && res.status === 200) {
      dispatch({
        type: GENERATE_ALLOCATION,
        payload: res && res.data || []
      });
      callback({generateAllocation: true})
    } else {
      callback({generateAllocation: false})
    }
  } catch (err) {
    console.log(err);
  }
}

export default {
  updateClaimIdo,
  getAllocationList,
  searchAllocation,
  genSignByRoundId,
  asyncVestingSchedule,
  generateAllocation
}