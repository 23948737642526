import React, {useState, useEffect} from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { InputCustom, Button, Toggle, Popup, Title, Loading } from '../component';
import { Select, DatePicker, Form, Switch, Radio, Input } from 'antd';
import moment from 'moment';
import allActions from '../../actions';
import {useSelector, useDispatch} from 'react-redux';
import { Plus } from '../common/asset/icon';
import { Store } from 'react-notifications-component';
import addNotify from '../common/Notify/addNotify';

const RoundEdit = () => {
    const [isLoad, setIsLoad] = useState(false);
    const [isDelete, setIsDelete] = useState(false);
    const [timeTable, setTimeTable] = useState(false);
    // const [modalRefund, setModalRefund] = useState(true);
    const [isChangeRound, setIsChangeRound] = useState();
    const dispatch = useDispatch();
    let navigate = useNavigate();
    let { handle } = useParams();
    const { Option } = Select;
    const { TextArea } = Input;
    const { RangePicker } = DatePicker;
    const [form] = Form.useForm();
    const roundData = useSelector(state => state.rounds?.roundOne);
    const campaignData = useSelector(state => state.campaigns?.campaignOne);
    const [allowRefund, setAllowRefund] = useState(roundData?.allowRefund);
    const [allowRefundEspecial, setAllowRefundEspecial] = useState(roundData?.refundEspecialAllow);
    
    const onFinishFailed = (errorInfo) => { 
        Store.addNotification(addNotify('Please Vesting Schedule (UTC) !', 'danger')); 
    }; 
    useEffect(() => {
        if(handle !== null) {
            dispatch(allActions.roundAction.getRoundById(handle));
        }
        window.scrollTo({top: 0, left: 0, behavior: 'smooth' });
    },[]);

    useEffect(() => {
        if(roundData?.campaignId) {
            dispatch(allActions.campaignAction.getCampaignById(roundData?.campaignId));
        }
    },[roundData?.campaignId]);
    console.log("🚀 ~ file: roundEdit.js ~ line 45 ~ RoundEdit ~ roundData", roundData)
    
    useEffect(() => {
        form.setFieldsValue(roundData);
        form.setFieldsValue({
            roundName: roundData?.round,
            phase :roundData?.phase,
            idoDate: roundData?.startIDO ? moment(roundData?.startIDO): null,
            startJoinPool: [roundData?.startJoinPool ? moment(roundData?.startJoinPool) : null, roundData?.endJoinPool ? moment(roundData?.endJoinPool) : null],
            claimDate: roundData?.startClaim ? moment(roundData?.startClaim): null,
            slots: roundData?.slots ? roundData?.slots : 0,
            refundEspecialAllow: roundData?.refundEspecialAllow,
            addressRefundEspecial: roundData?.addressRefundEspecial,
            refundEspecialDuration: [roundData?.refundEspecialStart ? moment(roundData?.refundEspecialStart) : null, roundData?.refundEspecialEnd ? moment(roundData?.refundEspecialEnd): null],
            timeTable: roundData?.startIDO !== null ? false: true
        });
        setAllowRefundEspecial(roundData?.refundEspecialAllow);
        setIsChangeRound(roundData?.roundType);
        setTimeTable(roundData?.startIDO !== null ? false : true);
        setAllowRefund(roundData?.allowRefund);
    },[roundData]);
     
    const onFinish = (values) => {  
        values.activated = true;
         
        if(campaignData?.modelType === 1){  
            values.vestingInfos = values.vestingInfos;
        }
        else{
             values.vestingSchedules = values.vestingSchedules ? JSON.stringify(values.vestingSchedules) : null;
        } 
       
        if(timeTable===true){
            values.idoDate = null;
            values.startJoinPool = null;
            values.endJoinPool = null;
            values.claimDate = null;
        }
        else{
            values.idoDate = values.idoDate?._d.toISOString() || null;
            let startJoinPool = values.startJoinPool[0];
            let endJoinPool = values.startJoinPool[1];
            values.startJoinPool = startJoinPool || null;
            values.startJoinPool = values.startJoinPool?._d.toISOString() || null;
            values.endJoinPool = endJoinPool || null;
            values.endJoinPool = values.endJoinPool?._d.toISOString() || null;
            values.claimDate = values.claimDate?._d.toISOString() || null;
        }
       
        if(!values.allowRefund && values.refundEspecialAllow) {
            Store.addNotification(addNotify('Please enable allowRefund!', 'danger'));
            setIsLoad(false);
            return;
        }

        if(values.refundEspecialAllow) {
            let refundEspecialStart = values.refundEspecialDuration && values.refundEspecialDuration[0];
            let refundEspecialEnd = values.refundEspecialDuration && values.refundEspecialDuration[1];
            values.refundEspecialStart = refundEspecialStart;
            values.refundEspecialEnd = refundEspecialEnd;
        }
        // Round = 1,3,4 OR phase = 2
        if(((values.phase === 1 && values.roundType !== 2) || values.phase === 2)) {
            values.slots = parseInt(values.slots);
        } else {
            values.slots = 0;
        }    
        setIsLoad(true); 
        
        if(values.startJoinPool < values.idoDate) {
            Store.addNotification(addNotify('Please select start Join Pool Date >= Ido Date', 'danger'));
            setIsLoad(false);
            return;
        }
        if(values.endJoinPool > values.claimDate) {
            Store.addNotification(addNotify('Please select Claim Date >= End Join Pool Date', 'danger'));
            setIsLoad(false);
            return;
        }

        console.log("values", values);
        dispatch(allActions.roundAction.updateRound(values, (response) => {  
            if(response.status === 200){ 
                if(handle !== null) {
                    dispatch(allActions.roundAction.getRoundById(handle));
                }
                Store.addNotification(addNotify("Round updated successfully!", 'success')); 
            }
            else{
                Store.addNotification(addNotify(response.title, 'danger')); 
            } 
            setIsLoad(false);
        })); 
         
    }; 

    const handleDelete = () => {
        dispatch(allActions.roundAction.deleteRound(roundData.id)); 
        setIsLoad(true);
        setTimeout(() => {
            navigate('/campaign-info/'+roundData?.campaignId)
        }, 3000);
    }

    const handleChangeRound = (value) => {
        setIsChangeRound(value);
    }

    return(
        <>
        {isLoad ? <Loading /> : ''}        
        <Form form={form} name="basic" initialValues={{ remember: true }} onFinish={onFinish} onFinishFailed ={onFinishFailed}>
        <Form.Item name="id" className="l-displayNone">
            <InputCustom type="hidden" />
        </Form.Item>
      
        <div className="p-roundEdit">
            <div className="p-roundEdit__head">
                <Title title='Round' subTitle='List of rounds added to the campaign' />
            </div>
            <div className="p-roundEdit__body">
                <h3 className="c-ttl"><span>Campaign:</span>{campaignData?.title}</h3>
                <div className="c-round">
                    <div className="c-round__head">
                        <div className="c-round__row is-col3">
                            <Form.Item name="roundName">
                                <InputCustom title='Round Name' />
                            </Form.Item>
                            <div className="c-select">
                                <p className="c-select__title">Round Type</p>
                                <Form.Item name="roundType">
                                    <Select className="c-selectChange" onChange={handleChangeRound}>
                                        <Option value={1}>Community</Option>
                                        <Option value={2}>Guaranteed</Option>
                                        <Option value={3}>Private / Most Value KDG Hold</Option>
                                        <Option value={4}>Most Value KDG Builder</Option>
                                    </Select>
                                </Form.Item>                                
                            </div>
                            <div className="c-select">
                                <p className="c-select__title">Phase</p>
                                <Form.Item name="phase">
                                    <Select className="c-selectChange">
                                        <Option value={1}>1</Option>
                                        <Option value={2}>2</Option>
                                    </Select>
                                </Form.Item>
                            </div>
                        </div>
                    </div>
                    <div className="c-round__body is-open">
                        <div className="c-round__content"> 
                            <div className="c-round__row">
                                <Form.Item name="addressCommited">
                                    <InputCustom title='Address Commited' placeholder='Address Commited' />
                                </Form.Item>
                                <Form.Item name="addressRemaningFund">
                                    <InputCustom title='Address Remaning Fund' placeholder='Address Remaning Fund' />
                                </Form.Item>
                                {campaignData?.modelType === 2  &&  <Form.Item name="addressClaimIdo"> 
                                    <InputCustom title='Address Claim IDO' placeholder='Address Claim IDO' />
                                </Form.Item>} 
                               
                                <Form.Item name="addressClaimDaily">
                                    <InputCustom title='Address Claim Daily' placeholder='Address Claim Daily' />
                                </Form.Item>
                                <Form.Item name="processBar">
                                    <InputCustom title='Progress Bar' placeholder='Progress Bar' />
                                </Form.Item>
                                <Form.Item name="totalRaise">
                                    <InputCustom title={`Total Raise (${campaignData?.acceptCurrency})`} placeholder={`Total Raise (${campaignData?.acceptCurrency})`} />
                                </Form.Item>
                                <Form.Item name="idoRate">
                                    <InputCustom title={`IDO Rate (${campaignData?.acceptCurrency})`} placeholder={`IDO Rate (${campaignData?.acceptCurrency})`} />
                                </Form.Item>
                                <Form.Item 
                                    name="commitRangeMinPercent"
                                    rules={[{ 
                                        required: false, 
                                        message: "Commit Min Percent wrong format",
                                        pattern: new RegExp(/^\d*(\.\d+)?$/)
                                    }]}  
                                >
                                    <InputCustom title='Commit Min Percent (%)'  placeholder='100' />
                                </Form.Item>
                                <Form.Item 
                                    name="commitRangeMaxPercent" 
                                    rules={[{ 
                                        required: false, 
                                        message: "Commit Max Percent wrong format",
                                        pattern: new RegExp(/^\d*(\.\d+)?$/)
                                    }]} 
                                >
                                    <InputCustom title='Commit Max Percent (%)' placeholder='100' />
                                </Form.Item>
                                <Form.Item name="slots">
                                    <InputCustom title='Slots' note="<p>Setup Slots for: <br/>1. Phase=1, Type=Community<br/>2. Phase=1, Type=Private/MVKH<br/>3. Phase=1, Type=MVKB<br/>4. Phase=2, Type=(Any) </p>"/>
                                </Form.Item>
                                <Form.Item name="priority">
                                    <InputCustom title='Priority' type='number' placeholder='Priority' />
                                </Form.Item>
                                <Form.Item name="announcement">
                                    <InputCustom title='Annoucement' placeholder='Enter annoucement' />
                                </Form.Item>
                            </div>
                            <div className="c-round__row">
                                <div className="c-round__vesting">
                                    <h3 className="c-ttl">Vesting</h3>
                                    <div className="c-round__box">
                                       
                                        <div className="c-toggle">
                                            <Form.Item name="allowRefund" valuePropName="checked" className="c-toggle__cont">
                                                <Switch className="c-toggle__custom" onChange={(e) => setAllowRefund(e)}/>
                                            </Form.Item>
                                            {campaignData?.modelType === 1 
                                            ?<p className="c-toggle__title">Allow refund all at TGE ?</p>
                                            :<p className="c-toggle__title">Allow refund?</p>}  
                                        </div> 
                                        {allowRefund===true ? <Form.Item name="refundDuration">
                                            <InputCustom title='Refund Duration (hours)' type='number' />
                                            </Form.Item> : ''} 
                                            <div className="c-radio">
                                                <p className="c-radio__title">Claim Type</p>
                                                <Form.Item name="claimType" className="c-radio__custom">
                                                    <Radio.Group>
                                                        <Radio value="MONTHLY">Monthly</Radio>
                                                        <Radio value="DAILY">Daily</Radio>
                                                    </Radio.Group>
                                                </Form.Item>
                                            </div> 
                                        
                                        <div className="c-textarea">
                                            <p className="c-textarea__title">Vesting Text</p>
                                            <Form.Item name="vesting" className="c-textarea__custom">
                                                <TextArea />
                                            </Form.Item>
                                        </div>
                                        <div className="c-repeater">
                                            <h3 className="c-repeater__title">Vesting Schedule (UTC)</h3>
                                            {campaignData?.modelType === 1 ?  <Form.List name="vestingInfos" >
                                                {(fields, { add, remove }) => (
                                                <>
                                                    {fields.map(({key, name, ...restField }) => ( 
                                                    <div className='bg_vestingteam'>
                                                        <div className="c-repeater__row" key={key}>
                                                            <span className="c-repeater__remove" onClick={() => remove(name)}>
                                                                <img src="../images/icon-remove.svg" alt="Remove" />
                                                            </span>
                                                            <div className="c-date is-edit">
                                                                <Form.Item rules={[{ required: true, message: 'time is required!' }]} {...restField} name={[name, 'vestingTime']}>
                                                                    <input className="c-dateCustom" type="datetime-local" />
                                                                </Form.Item>
                                                            </div>
                                                            <Form.Item rules={[{ required: true, message: 'percentage is required!' }]} {...restField} name={[name, 'vestingPercent']}>
                                                                <InputCustom placeholder='Add Vesting' />
                                                            </Form.Item>   
                                                        </div>
                                                            <Form.Item {...restField} name={[name, 'vestingSc']}>
                                                                <InputCustom  title='SC Claim' placeholder='SC claim' />
                                                            </Form.Item>
                                                            
                                                            <Form.Item {...restField} name={[name, 'refundDuration']}>
                                                                <InputCustom title='Refund Duration (hours)' type='number' />
                                                            </Form.Item> 
                                                            <div className="c-toggle mt-10">
                                                                <Form.Item {...restField} name={[name, 'allowRefund']} valuePropName="checked" className="c-toggle__cont">
                                                                    <Switch className="c-toggle__custom" /> 
                                                                </Form.Item> 
                                                                <p className="c-toggle__title">ON/OFF refund button</p>
                                                            </div> 
                                                           
                                                    </div>
                                                    ))}
                                                    <div className="c-btn c-btn--flat" onClick={() => add()}><Plus /> Add Vesting Schedule</div>
                                                </>
                                                )}
                                            </Form.List> :  <Form.List name="vestingSchedules" >
                                                {(fields, { add, remove }) => (
                                                <>
                                                    {fields.map(({ key, name, ...restField }) => (
                                                    <div className="c-repeater__row" key={key}>
                                                        <span className="c-repeater__remove" onClick={() => remove(name)}>
                                                            <img src="../images/icon-remove.svg" alt="Remove" />
                                                        </span>
                                                        <div className="c-date is-edit">
                                                            <Form.Item rules={[{ required: true, message: 'time is required!' }]} {...restField} name={[name, 'time']}>
                                                                <input className="c-dateCustom" type="datetime-local" />
                                                            </Form.Item>
                                                        </div>
                                                        <Form.Item rules={[{ required: true, message: 'percentage is required!' }]} {...restField} name={[name, 'percentage']}>
                                                            <InputCustom placeholder='Add Vesting' />
                                                        </Form.Item>
                                                    </div>
                                                    ))}
                                                    <div className="c-btn c-btn--flat" onClick={() => add()}><Plus /> Add Vesting Schedule</div>
                                                </>
                                                )}
                                            </Form.List> }  
                                        </div>
                                    </div>
                                </div>
                                <div className="c-round__timeTable">
                                    <h3 className="c-ttl">Round Timetable</h3>
                                    <div className="c-round__box"> 
                                        <div className="c-toggle">
                                            <Form.Item name="timeTable" valuePropName="checked" className="c-toggle__cont">
                                                <Switch className="c-toggle__custom" onChange={(e) => setTimeTable(e)} /> 
                                            </Form.Item> 
                                            <p className="c-toggle__title">Use campaign timetable</p>
                                        </div>
                                        {timeTable === false ? <>
                                            <div className="c-date">
                                                <p className="c-date__title">UpComing Date (GMT+7)</p>
                                                <Form.Item name="idoDate">
                                                    <DatePicker showTime format='HH:mm:ss DD-MM-YYYY' />
                                                </Form.Item>
                                            </div>
                                            <div className="c-date">
                                                <p className="c-date__title">[Start-End] Join Pool Date (GMT+7)</p>
                                                <Form.Item name="startJoinPool">
                                                    <RangePicker showTime format='HH:mm:ss DD-MM-YYYY' />
                                                </Form.Item>
                                            </div>
                                            <div className="c-date">
                                                <p className="c-date__title">Claim Date (GMT+7)</p>
                                                <Form.Item name="claimDate">
                                                    <DatePicker showTime format='HH:mm:ss DD-MM-YYYY' />
                                                </Form.Item>
                                            </div>
                                        </> : null}
                                    </div>
                                </div>
                                <div className="c-round__row">
                                    <div className="c-round__refund-especial">
                                        <h3 className="c-ttl">Refund Especial</h3>
                                        <div className="c-round__box">
                                            <div className="c-toggle">
                                                <Form.Item name="refundEspecialAllow" valuePropName="checked" className="c-toggle__cont">
                                                    <Switch className="c-toggle__custom" onChange={(e) => setAllowRefundEspecial(e)}/>
                                                </Form.Item>
                                                <p className="c-toggle__title">Allow refund especial ?</p>
                                            </div>
                                            {allowRefundEspecial && <>
                                            <div className="c-round__refund-especial__col">
                                                <div className="c-toggle">
                                                    <Form.Item name="refundEspecialClaimedAllowed" valuePropName="checked" className="c-toggle__cont">
                                                        <Switch className="c-toggle__custom" />
                                                    </Form.Item>
                                                    <p className="c-toggle__title">Allow who claimed ?</p>
                                                </div>
                                                <div className="c-date">
                                                    <p className="c-date__title">Refund duration (GMT+7)</p>
                                                    <Form.Item name="refundEspecialDuration">
                                                        <RangePicker showTime format='HH:mm:ss DD-MM-YYYY' />
                                                    </Form.Item>
                                                </div>
                                            </div>
                                            <div className="c-round__refund-especial__col">
                                                <div className="c-date">
                                                    <Form.Item name="addressRefundEspecial">
                                                        <InputCustom title='Address refund especial' placeholder='Address refund especial' />
                                                    </Form.Item>
                                                </div>
                                                <div className="c-date">
                                                    <Form.Item 
                                                        name="refundEspecialPercent" 
                                                        rules={[{ 
                                                            required: false, 
                                                            message: "Commit Max Percent wrong format",
                                                            pattern: new RegExp(/^\d*(\.\d+)?$/)
                                                        }]} 
                                                    >
                                                        <InputCustom title='Refund Especial Percent (%)' placeholder='100' />
                                                    </Form.Item>
                                                </div>
                                            </div>
                                            
                                            </>}
                                        </div>
                                        
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="p-roundEdit__btn">
                <div className="c-btn c-btn--grey" onClick={() => navigate('/campaign-info/'+roundData.campaignId)}>Cancel</div>
                <div>
                    <div className="c-btn c-btn--flat" onClick={() => setIsDelete(true)}>Delete</div>
                    <Button title='Edit' type='blue' htmlType='submit'  />
                </div>
            </div>
            {isDelete ? <Popup 
                title='Do you want delete this round?' 
                subTitle='You can’t restore this file' 
                btnTitle='Delete' 
                icon='Delete' 
                onCancel={() => setIsDelete(false)}
                onClick={() => handleDelete()}
            /> : ''}
        </div>
        </Form>
        </>
    )
}

export default RoundEdit;