
export const authActionType = {
    LOGIN_SUCCESS: 'LOGIN_SUCCESS',
    LOGIN_FAIL: 'LOGIN_FAIL',
    LOGOUT: 'LOGOUT',
    FORGOT_PASSWORD: 'FORGOT_PASSWORD',
    RESET_PASSWORD: 'RESET_PASSWORD',
    ADMIN_FORGOT_PASSWORD: 'ADMIN_FORGOT_PASSWORD',
    ADMIN_RESET_PASSWORD: 'ADMIN_RESET_PASSWORD',
};

export const tokenInformActionType = {
    GET_TOKEN_INFORM_LIST:  "GET_TOKEN_INFORM_LIST"
}

export const whitelistActionType = {
    GET_WHITELIST_STAKING_BY_ROUNDID: "GET_WHITELIST_STAKING_BY_ROUNDID",
    GET_WHITELIST_PRIORITY_BY_ROUNDID: "GET_WHITELIST_PRIORITY_BY_ROUNDID",
    GENERATE_SIGNATURE_COMMIT_ALL: "GENERATE_SIGNATURE_COMMIT_ALL",
    GENERATE_SIGNATURE_COMMIT_ONE: "GENERATE_SIGNATURE_COMMIT_ONE",
}

export const snapshotActionType = {
    GET_SNAPSHOT_PRIORITY_LIST_BY_ROUNDID: "GET_SNAPSHOT_PRIORITY_LIST_BY_ROUNDID",
    UPDATE_PRIORITY: "UPDATE_PRIORITY",
    DELETE_PRIORITY: "DELETE_PRIORITY",
    SEARCH_PRIORITY: "SEARCH_PRIORITY"
}

export const userActionType = {
    GET_USER_LIST: "GET_USER_LIST",
}