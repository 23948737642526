import React, {Suspense, lazy} from 'react';
import { ReactNotifications } from 'react-notifications-component';
import {Route, Navigate, Routes, useLocation, Outlet, useNavigate} from 'react-router-dom';
import { Tokens } from '../../../constants';
import { checkExistingRoute } from '../../../utils/sharedMethod';
import routes from "../../../routes";
import 'react-notifications-component/dist/theme.css';

const SideBar = React.lazy(() => import('../sideBar'));
const Header = React.lazy(() => import('../header'));

const Layout = () => {

    let token = localStorage.getItem(Tokens.ADMIN);
    return (
        <>
        {token ? 
            <div className="l-app">
                <SideBar />
                <Header />
                <ReactNotifications />

                <div className="l-main">
                    <Outlet />
                </div>
            </div> : <Navigate to="/login" replace />}
        </>
    );
};

export default React.memo(Layout);