import CampaignDataService from "../services/CampaignService";
import {
    GET_CAMPAIGN_LIST,
    GET_CAMPAIGN_BY_ID,
    POST_CREATE_CAMPAIGN,
    UPDATE_CAMPAIGN,
    DELETE_CAMPAIGN,
    GET_CAMPAIGN_SIMPLE,
    GET_REMAINING_BY_ID,
    GET_SAVE_DRAFT
} from "./campaignType";

const getCampaignList = (account) => async (dispatch) => {
  try {
    const res = await CampaignDataService.getCampaignList(account);
    // console.log(res && res.data);
    dispatch({
      type: GET_CAMPAIGN_LIST,
      payload: res && res.data || []
    });
  } catch (err) {
    console.log(err);
  }
}

const getCampaignById = (id) => async (dispatch) => {
  try {
    const res = await CampaignDataService.getCampaignById(id);
    dispatch({
      type: GET_CAMPAIGN_BY_ID,
      payload: res && res.data || []
    });
  } catch (err) {
    console.log(err);
  }
}

const createCampaign = (payload, callback) => async (dispatch) => {
  try {
    const res = await CampaignDataService.createCampaign(payload); 
    dispatch({
      type: POST_CREATE_CAMPAIGN,
      payload: {}
    });
    callback(res)
  } catch (err) { 
    callback(err.response.data);
  }
}

const updateCampaign = (payload, callback) => async (dispatch) => {
  try {
    const res = await CampaignDataService.updateCampaign(payload); 
    dispatch({
      type: UPDATE_CAMPAIGN,
      payload: res
    });
    callback(res)
  } catch (err) {
    callback(err.response.data);
  }
}

const deleteCampaign = (payload) => async (dispatch) => { 
  try {
    const res = await CampaignDataService.deleteCampaign(payload);  
    dispatch({
      type: DELETE_CAMPAIGN,
      payload: res
    });
  } catch (err) {
    console.log(err);
  }
}

const getCampaignSimple = (payload) => async (dispatch) => {
  try {
    const res = await CampaignDataService.getCampaignSimple(payload);
    dispatch({
      type: GET_CAMPAIGN_SIMPLE,
      payload: res && res.data || []
    });
  } catch (err) {
    console.log(err);
  }
}

const getRemainingById = (id) => async (dispatch) => {
  try {
    const res = await CampaignDataService.getRemainingById(id);
    dispatch({
      type: GET_REMAINING_BY_ID,
      payload: res && res.data || []
    });
  } catch (err) {
    console.log(err);
  }
}

const getSaveDraft= (payload, callback) => async (dispatch) => {
  try {
    const res = await CampaignDataService.getSaveDraft(); 
    dispatch({
      type: GET_SAVE_DRAFT,
      payload: res.data
    });  
  } catch (err) { 
    console.log(err) 
  }
}

const campaignAction = {
  getCampaignList,
  getCampaignById,
  createCampaign,
  updateCampaign,
  deleteCampaign,
  getCampaignSimple,
  getRemainingById,
  getSaveDraft
};

export default campaignAction;