import { Tokens } from "../constants";
import http from "./http-common";

const authenticate = (payload) => {
  return http.post("/authenticate", payload);
};

const logout = () => {
  localStorage.removeItem(Tokens.ADMIN);
};

const AuthService = {
  authenticate,
  logout
};

export default AuthService;