
import {
    GET_MARKET_LIST, 
    GET_BANNER_ID,
    GET_AIRDROP_LIST, 
    GET_MARKETGAME_BY_ID,
    GET_CATALOG_LIST
} from "../actions/gamehubType";

const initialState = [];

function gamehubReducer(gamehub = initialState, action) {
    const { type, payload } = action;  
    switch (type) {
        case GET_MARKET_LIST:
            return {...gamehub, marketList: payload}; 
        case GET_BANNER_ID:
            return {...gamehub, bannerById: payload}; 
        case GET_MARKETGAME_BY_ID:
            return {...gamehub, marketGameById: payload}; 
        case GET_AIRDROP_LIST:
            return {...gamehub, gamehubList: payload}; 
        case  GET_CATALOG_LIST :
        return {...gamehub, catalogsList: payload}; 
          
        default:
            return gamehub;
    }
};

export default gamehubReducer;