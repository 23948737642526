import React, {useState, useEffect, useRef} from 'react';
import { Title, InputCustom, Button, Loading } from '../component';
import { Select, DatePicker, Form, Input } from 'antd';
import {useNavigate, useLocation} from "react-router-dom";
import allActions from '../../actions';
import {useSelector, useDispatch} from 'react-redux';
import moment from 'moment-timezone';
import { Store } from 'react-notifications-component';
import addNotify from '../common/Notify/addNotify';
import {NetworkArray, Networks, Tokens} from '../../constants';
import axios from 'axios';  
import {Upload} from '../common/asset/icon'; 
const CampaignAdd = () => {  
    const location = useLocation(); 
    const [imgBanner, setImgBanner] = useState(''); 
    const inputImage = useRef();
    const [imgLogo, setImgLogo] = useState('');  
    const inputLogo = useRef();
    const [nextEndJoinPoolDate, setNextEndJoinPoolDate] = useState("");
    const [loadingPage, setLoadingPage] = useState(false);
    const [nextIdoDate, setNextIdoDate] = useState("")
    const [form] = Form.useForm();
    const { TextArea } = Input; 
    // moment.tz.setDefault("GMT");
    let navigate = useNavigate();
    const dispatch = useDispatch();
    const tokenInformData = useSelector(state => state.tokenInform);
    const [campaignData, setCampaignData] = useState({
        "acceptCurrency": "BUSD",
        "activated": false,
        "bannerURL": "",
        "campaignName": "", 
        "commitRangeMaxPercent": 0,
        "commitRangeMinPercent": 0, 
        "description": "",
        "announcement": "",
        "discordLink": "",
        "claimDate": null,
        "endJoinPool": null,
        "facebookLink": "",
        "handle": "",
        "idoDate": null,
        "idoRate": 0,
        "id": 0,
        "logoUrl": "",
        "mediumLink": "",
        "network": "BSC",
        "priority": 0,
        "stakingDeadline": "",
        "startJoinPool": null,
        "status": "UPCOMING",
        "telegramLink": "",
        "tokenInformId": 0,
        "totalRaise": 0,
        "twitterLink": "",
        "website": "",
        "youtubeLink": ""
    });
    const { Option } = Select;
    const { RangePicker } = DatePicker; 

    useEffect(() => {
        dispatch(allActions.tokenInformAction.getTokenInformList());
    },[]); 

    const handleIdoDatePicker = (date, dateString) => { 
        let _idoDate = new Date(dateString);  
        if(date){ 
        setCampaignData({
            ...campaignData, 
            idoDate: _idoDate.toISOString()
        }); 
        }
    };
   
    const handleJoinPoolDateRangePicker = (date, dateString) => { 
        let startJoinPoolDate = new Date(dateString[0]);
        let endJoinPoolDate = new Date(dateString[1]);  
        if(date){
            setCampaignData({
                ...campaignData, 
                startJoinPool: startJoinPoolDate.toISOString(), 
                endJoinPool: endJoinPoolDate.toISOString()
            });  
        }
        
    };

    const handleStartClaimDatePicker = (date, dateString) => {  
        let claimDateDate = new Date(dateString);
        if(date){ 
            setCampaignData({
                ...campaignData, 
                claimDate: claimDateDate.toISOString()
            });  
        }  
    }; 

    const changeModal = (value) => {
        setCampaignData({
            ...campaignData,  
            modelType: value 
        });
    }

    const onChangeImage= (e) => { 
        let file = e.target.files[0];
        const formData = new FormData();
        formData.append("file", file); 
        axios({
            method: 'post',
            url: process.env.REACT_APP_API_URL + `/upload-to-s3`,
            headers: {
                'accept': '*/*',
                "Authorization": `Bearer ${localStorage.getItem(Tokens.ADMIN)}`,
                "Content-Type": "multipart/form-data"
            },
            data: formData,
        }).then(res => {
           setImgBanner(res.data);
           setCampaignData({
            ...campaignData,  
            bannerURL: res.data
            });
        }).catch(err => {
            console.log(err);
        });
    }
 
    const onChangeLogo = (e) => {  
        let file = e.target.files[0];
        const formData = new FormData();
        formData.append("file", file); 
        axios({
            method: 'post',
            url: process.env.REACT_APP_API_URL + `/upload-to-s3`,
            headers: {
                'accept': '*/*',
                "Authorization": `Bearer ${localStorage.getItem(Tokens.ADMIN)}`,
                "Content-Type": "multipart/form-data"
            },
            data: formData,
        }).then(res => {
           setImgLogo(res.data);
           setCampaignData({
            ...campaignData,  
            bannerURL: res.data
            });
        }).catch(err => {
            console.log(err);
        });
    }

    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };

   
    const onFinish = (values) => { 
        // setLoadingPage(true);  
        if (!values.campaignName) {
            Store.addNotification(addNotify('Please enter campaign name !', 'danger'));
            setLoadingPage(false);
            return;
        }

        if (!values.handle) {
            Store.addNotification(addNotify('Please enter campaign handle !', 'danger'));
            setLoadingPage(false);
            return;
        }

        if (!values.tokenInform) {
            Store.addNotification(addNotify('Please select Token Inform !', 'danger'));
            setLoadingPage(false);
            return;
        }
        
        let _tokenInformValid = tokenInformData.find(x => x.id === values.tokenInform);
        if (!_tokenInformValid) {
            Store.addNotification(addNotify('Please select valid Token Inform !', 'danger'));
            setLoadingPage(false);
            return;
        }

        if (!NetworkArray.includes(values.network)) {
            Store.addNotification(addNotify('Please select valid network !', 'danger'));
            setLoadingPage(false);
            return;
        } 
        if(values.joinPoolDate && values.joinPoolDate >0){
            if(values?.joinPoolDate[0]._d <= values?.idoDate?._d) {
                Store.addNotification(addNotify('Please select Start Join Pool Date < Ido Date', 'danger'));
                setLoadingPage(false);
                return;
            }
            if(values?.joinPoolDate[1]._d >= values?.claimDate?._d) {
                Store.addNotification(addNotify('Please select Claim Date > End Join Pool Date', 'danger'));
                setLoadingPage(false);
                return;
            }
        } 
       
        let payload = {
            "acceptCurrency": values.acceptCurrency ?? null,
            "activated": location.state?.name ==="campaign" ? true :false,
            "announcement": values.announcement ?? null,
            "bannerURL": imgBanner ?? null,
            "campaignName": values.campaignName,
            "claimDate": values.claimDate ? values.claimDate._d.toISOString() : null,
            "description": values.description ?? null,
            "discordLink": values.discordLink ?? null,
            "endJoinPool": values.joinPoolDate && values?.joinPoolDate[1] ? values?.joinPoolDate[1]._d.toISOString() : null,
            "facebookLink": values.facebookLink ?? null,
            "handle": values.handle,
            "idoDate": values.idoDate ? values.idoDate._d.toISOString() : null,
            "idoRate": values.idoRate ?? 0,
            "logoUrl": imgLogo ?? null,
            "mediumLink": values.mediumLink ?? null,
            "modelType": parseInt(values.modelType) === 1 ? 1: 2,
            "network": values.network ?? null,
            "priority": values.priority ? parseInt(values.priority) : 0,
            "stakingDeadline": values.stakingDeadline ?? 0,
            "startJoinPool": values.joinPoolDate && values?.joinPoolDate[0] ? values?.joinPoolDate[0]._d.toISOString() : null,
            "status": values.status ? values.status : "UPCOMING",
            "telegramLink": values.telegramLink ?? null,
            "tokenInformId": values.tokenInform ? parseInt(values.tokenInform) : 0,
            "totalRaise": values.totalRaise ?? 0,
            "twitterLink": values.twitterLink ?? null,
            "website": values.websiteLink ?? null,
            "youtubeLink": values.youtubeLink ?? null,
            "fullLink": values.fullLink ?? null,
            "id": 0
        };    
    
        dispatch(allActions.campaignAction.createCampaign(payload, (response) => { 
            if(response.status === 201){ 
                if(location.state?.name ==="campaign"){
                    navigate('/'); 
                }
                else{
                    navigate('/savedraft');
                } 
                setLoadingPage(false);
            }
            else{ 
                Store.addNotification(addNotify(response.title, 'danger')); 
                setLoadingPage(false);
            }  
        }));  
    };
    
    return(
        <>
        {loadingPage ? <Loading /> : ''}  
        <Form 
            name="basic"
            form={form}
            labelCol={{ span: 8 }}
            wrapperCol={{ span: 16 }}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            autoComplete="off"
        >
        <div className="p-campaignAdd">
            <div className="p-campaignAdd__left">
                {location.state.name == "campaign"
                ? <Title title='Campaign’s Information' subTitle='Enter the required information below to add project. You can change it before the project ends.' />
                : <Title title='SaveDraft’s Information' subTitle='Enter the required information below to add project. You can change it before the project ends.' />} 
                <div className="p-campaignAdd__row">
                <div className="c-select">
                    <Form.Item   
                        name="priority" 
                        rules={[{ 
                            required: false, 
                            message: "Priority wrong format",
                            pattern: new RegExp(/^\d*(\.\d+)?$/)
                        }]} 
                        >
                        <InputCustom title='Priority' value={campaignData.priority} type="number" />
                    </Form.Item> 
                </div>
                <div className="c-select">
                    <Form.Item  
                        name="handle" 
                        rules={[{ required: true, message: 'Handle is required!' }]}
                    >
                        <InputCustom required={true} title='Handle' value={campaignData.handle} />
                    </Form.Item>
                </div>  
                </div>
                <div className="p-campaignAdd__row">
                    <div className="c-select">
                        <p className="c-select__title">Network</p>
                        <Form.Item name="network">
                            <Select className="c-selectChange" placeholder='Select network'>
                                <Option value={Networks.BSC}><img src="../images/icon-binance.svg" alt="Binance Smart Chain" />Binance Smart Chain</Option>
                                <Option value={Networks.POLY}><img src="../images/icon-polygon.svg" alt="Polygon" />Polygon</Option>
                                <Option value={Networks.ETH}><img src="../images/icon-ethereum.svg" alt="Ethereum" />Ethereum</Option>
                                {process.env.REACT_APP_MODE ==="development" && 
                                <>
                                    <Option value={Networks.ARBTESTNET}><img src="../images/icon-arb.svg" alt="Arbitrum" />ArbitrumTest</Option>
                                    <Option value={Networks.ARB}><img src="../images/icon-arb.svg" alt="Arbitrum" />Arbitrum</Option>
                                </> 
                                }
                                {process.env.REACT_APP_MODE ==="production" && <Option value={Networks.ARB}><img src="../images/icon-arb.svg" alt="Arbitrum" />Arbitrum</Option>}
                                
                            </Select>
                        </Form.Item>
                    </div>
                    <div className="c-select">
                        <p className="c-select__title">Accept Currency</p>
                        <Form.Item name="acceptCurrency">
                            <Select className="c-selectChange" placeholder='Select accept currency' >
                                <Option value="BUSD"><img src="../images/icon-busd.svg" alt="BUSD" />BUSD</Option>
                                <Option value="USDC"><img src="../images/icon-usdc.svg" alt="USDC" />USDC</Option>
                                <Option value="USDT"><img src="../images/icon-usdt.svg" alt="USDT" />USDT</Option>
                            </Select>
                        </Form.Item>
                    </div>
                </div>
                <div className="p-campaignAdd__row">
                    <div className="c-select">
                        <p className="c-select__title">Token Inform</p>
                        <Form.Item name="tokenInform"> 
                            <Select className="c-selectChange" 
                                placeholder='Select token inform' 
                                allowClear
                                showSearch
                                filterOption={(inputValue, option) =>
                                    option.children.join('').toLowerCase().includes(inputValue.toLowerCase())
                                }
                            >
                                {tokenInformData && tokenInformData.map((token) => (
                                    <Option key={token.id} value={token.id}>
                                        <img src={token.logoURL} alt={token.symbol + " - " + token.name} />
                                        {token.symbol} - {token.name}
                                    </Option>)
                                )}
                            </Select>
                        </Form.Item>
                    </div>
                    <div className="c-select">
                        <Form.Item 
                            name="stakingDeadline"
                            rules={[{ 
                                required: false, 
                                message: "Staking Deadline wrong format",
                                pattern: new RegExp(/^\d*(\.\d+)?$/)
                            }]}  
                        >
                            <InputCustom title='Staking Deadline (hours)' note='<p>Period time from the stake deadline to the start of pool join<p>' />
                        </Form.Item>
                    </div> 
                     
                </div>
                <div className="p-campaignAdd__row">
                    <div className="c-select">
                        <Form.Item  
                            name="totalRaise" 
                            rules={[{ 
                                required: false, 
                                message: "Total raise wrong format",
                                pattern: new RegExp(/^\d*(\.\d+)?$/)
                            }]} 
                        >
                            <InputCustom title='Total Raise (BUSD)' placeholder='0' />
                        </Form.Item>
                    </div>
                    <div className="c-select">
                        <Form.Item 
                            name="idoRate" 
                            >
                            <InputCustom title='IDO Rate (BUSD)' placeholder='0' />
                        </Form.Item>  
                    </div>
                </div>
                <div className="p-campaignAdd__row">
                    <div className="c-select">
                        <p className="c-select__title">Model type<span style={{color: 'red'}}>*</span></p>
                        <Form.Item 
                            name="modelType"  
                            rules={[{ required: true, message: 'Model Type is required!' }]}
                        >
                            <Select className="c-selectChange" placeholder="Select a option modal">
                                <Option value="1">Model 1</Option>
                                <Option value="2">Model 2</Option> 
                            </Select>
                        </Form.Item>
                    </div>
                </div>
               
                <div className="p-campaignAdd__timeTable">
                    <h3  className="c-ttl">Campaign’s Timetable</h3>
                    <div  className="p-campaignAdd__box">
                        <div className="c-date">
                            <p className="c-date__title">IDO Date (GMT+7)</p>
                            <Form.Item name ="idoDate"> 
                                <DatePicker  
                                    showTime
                                    format='HH:mm:ss YYYY-MM-DD'
                                    onChange={handleIdoDatePicker}  
                                />
                            </Form.Item>
                        </div>
                        <div className="c-date">
                            <p className="c-date__title">Join Pool Date (GMT+7)</p>
                            <Form.Item name ="joinPoolDate"> 
                                <RangePicker   
                                    showTime
                                    format='HH:mm:ss YYYY-MM-DD'
                                    onChange={handleJoinPoolDateRangePicker}
                                />
                            </Form.Item>
                        </div>
                        <div className="c-date">
                            <p className="c-date__title">Claim Date (GMT+7)</p>
                            <Form.Item name ="claimDate"> 
                                <DatePicker  
                                    showTime
                                    format='HH:mm:ss YYYY-MM-DD'
                                    onChange={handleStartClaimDatePicker}
                                />
                            </Form.Item>
                        </div>
                    </div>
                </div>
            </div>
            <div className="p-campaignAdd__right"> 
                <div className="c-upload">
                    <h3 className="c-upload__title">Images</h3>
                    <div className={`c-upload__box${imgBanner === '' ? ' is-add' : ''}`}>
                        {imgBanner === '' ? <div className='btn-click' onClick={()=> inputImage.current.click()}> 
                            <Upload/>
                            <p>Upload</p>
                        </div> : <div onClick={() => inputImage.current.click()} className="c-upload__edit">
                            <img src={imgBanner} alt="" />
                        </div>}
                    </div>
                    <input ref={inputImage} onChange={onChangeImage} type="file" /> 
                </div>
                <div className="p-campaignAdd__row">
                    <div className="c-select">
                        <Form.Item 
                            name="campaignName" 
                            rules={[{ required: true, message: 'Campaign name is required!' }]}
                        >
                            <InputCustom required={true} title='Campaign Name' placeholder='Enter campaign name' />
                        </Form.Item>
                    </div>
                    <div className="c-upload">
                        <h3 className="c-upload__title">Logo</h3>
                        <div className={`c-upload__box${imgLogo === '' ? ' is-addSmall' : ''}`}>
                            {imgLogo === '' ? <div className='btn-click' onClick={()=>inputLogo.current.click()}> 
                                <Upload/>
                                <p>Upload</p>
                            </div> : <div onClick={() => inputLogo.current.click()} className="c-upload__edit is-logo">
                                <img src={imgLogo} alt="" />
                            </div>}
                        </div>
                        <input ref={inputLogo} onChange={onChangeLogo} type="file" /> 
                    </div>
                </div>
                <div className="p-campaignAdd__row is-wFull">
                    <div className="c-select">
                        <div className="c-textarea">
                            <p className="c-textarea__title">Description</p>
                            <Form.Item name="description" className="c-textarea__custom">
                                <TextArea />
                            </Form.Item>
                        </div>
                    </div>
                </div>
                <div className="p-campaignAdd__row is-wFull">
                    <div className="c-select">
                        <p className="c-select__title">Status</p>
                        <Form.Item name="status">
                            <Select className="c-selectChange" defaultValue="Upcoming" >
                                <Option value="UPCOMING">Upcoming</Option>
                                <Option value="OPENING">Opening</Option>
                                <Option value="COMPLETED">Completed</Option>
                                <Option value="TBA">TBA</Option>
                            </Select>
                        </Form.Item>
                    </div>
                </div>
                <div className="p-campaignAdd__row is-wFull">
                    <div className="c-select">
                        <Form.Item name="announcement">
                            <InputCustom title='Announcement' placeholder='Enter announcement' />
                        </Form.Item>
                    </div>
                </div>
                <div className="p-campaignAdd__row is-wFull">
                    <h3 className="c-ttl is-font16">Channel Media</h3>
                    <ul className="c-media">
                        <li>
                            <p>Channel name</p>
                            <p>URL</p>
                        </li>
                        <li>
                            <h3><img src="../images/icon-telegram.svg" alt="" />Telegram</h3>
                            <div className='inputCustom'>
                                <Form.Item name="telegramLink">
                                    <InputCustom placeholder='Enter link' />
                                </Form.Item>
                            </div>
                        </li>
                        <li>
                            <h3><img src="../images/icon-twitter.svg" alt="" />Twitter</h3>
                            <div className='inputCustom'>
                                <Form.Item name="twitterLink">
                                    <InputCustom placeholder='Enter link' />
                                </Form.Item>
                            </div>
                        </li>
                        <li>
                            <h3><img src="../images/icon-medium.svg" alt="" />Medium</h3>
                            <div className='inputCustom'>
                                <Form.Item name="mediumLink">
                                    <InputCustom placeholder='Enter link' />
                                </Form.Item>
                            </div>
                        </li>
                        <li>
                            <h3><img src="../images/icon-website.svg" alt="" />Website</h3>
                            <div className='inputCustom'>
                                <Form.Item name="websiteLink">
                                    <InputCustom placeholder='Enter link' />
                                </Form.Item>
                            </div>
                        </li>
                        <li>
                            <h3><img src="../images/icon-facebook.svg" alt="" />Facebook</h3>
                            <div className='inputCustom'>
                                <Form.Item name="facebookLink">
                                    <InputCustom placeholder='Enter link' />
                                </Form.Item>
                            </div>
                        </li>
                        <li>
                            <h3><img src="../images/icon-discord.svg" alt="" />Discord</h3>
                            <div className='inputCustom'>
                                <Form.Item name="discordLink">
                                    <InputCustom placeholder='Enter link'/>
                                </Form.Item>
                            </div>
                        </li>
                        <li>
                            <h3><img src="../images/icon-youtube.svg" alt="" />Youtube</h3>
                            <div className='inputCustom'>
                                <Form.Item name="youtubeLink">
                                    <InputCustom placeholder='Enter link'/>
                                </Form.Item>
                            </div>
                        </li>
                        <li>
                            <h3><img src="../images/icon-website.svg" alt="" />Full Link</h3>
                            <div className='inputCustom'>
                                <Form.Item name="fullLink">
                                    <InputCustom placeholder='Enter link'/>
                                </Form.Item>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
            <div className="p-campaignAdd__btn">
                <div className="p-campaignAdd__left">
                    <Button title='Cancel' type='grey' onClick={() => navigate('/')} />
                </div>
                <div className="p-campaignAdd__right">
                    <Button title='Save Draft' type='flat'/>
                    <Button title='Submit' type='blue' onKeyDown={(e)=> e.keyCode == 13 ? e.preventDefault(): ''} />
                </div>
            </div>
        </div>
        </Form>
        </>
    )
}

export default CampaignAdd;