import React, { useState, useEffect } from 'react';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import { Button, InputCustom } from '../component';
import { Form } from 'antd';
import allActions from '../../actions';
import {useSelector, useDispatch} from 'react-redux';
import addNotify from '../common/Notify/addNotify';
import { Store } from 'react-notifications-component';

const InoTimelineEdit = () => {
    const [isLoad, setIsLoad] = useState(true);
    const [form] = Form.useForm();
    const dispatch = useDispatch();
    let navigate = useNavigate();
    let { handle } = useParams();
    let location = useLocation();
    let myPathname = location.pathname; 
    const sep = /\//;
    let isPathname = myPathname.split(sep); 

    const inoTimelineEdit = useSelector(state => state.ino?.inoTimelineById);

    const inoDetail = useSelector(state => state.ino?.inoInfo);
    const inoCampaign = useSelector(state => state.ino?.inoById);

    useEffect(() => {
        if(isPathname[2]) {
            dispatch(allActions.inoAction.getInoInfo(isPathname[2]));
        }        
    },[isPathname[2]]);    

    useEffect(() => {
        if(inoDetail?.id) {
            dispatch(allActions.inoAction.getInoById(inoDetail?.id));
        }
    },[inoDetail?.id]);

    useEffect(() => {
        if(handle) {
            dispatch(allActions.inoAction.getInoTimelineById(handle));
        }
    },[handle]);

    useEffect(() => {
        if(inoTimelineEdit) {
            form.setFieldsValue(inoTimelineEdit);
            setIsLoad(false);
        }        
    },[inoTimelineEdit]);

    const onFinishEdit = (values) => {
        setIsLoad(true);        
        values.id = parseInt(handle);
        values.inoLaunchpad = inoCampaign;
        values.title = values.title ? values.title : null;

        dispatch(allActions.inoAction.editTimelineIno(values, (response) => {
            if(response.status === 200){  
                setIsLoad(false);
                navigate(`/ino-campaigns/${isPathname[2]}`)
            }
            else{
                Store.addNotification(addNotify(response.title, 'danger')); 
                setIsLoad(false);
            }  
        }));
    }

    return(
        <>
        {isLoad ? <div className="c-loading">
            <div className="c-loading__box">
                <img src="../../../images/loading-3s.gif" alt="Loading" />
            </div>
        </div> : ''} 
        <Form form={form} name="basic" onFinish={onFinishEdit}>
            <div className="c-popup">
                <div className="c-popup__box">
                    <div className="c-popup__close" onClick={() => navigate(`/ino-campaigns/${isPathname[2]}`)}><img src="../../../images/icon-close.svg" alt="Close" /></div>
                    <h3 className="c-popup__title">Edit Rule</h3>
                    <div className="c-popup__content">
                        <Form.Item name="title">
                            <InputCustom placeholder='Enter title' />
                        </Form.Item>
                        <Form.Item name="description">
                            <InputCustom placeholder='Enter description' />
                        </Form.Item>
                    </div>          
                    <div className="c-popup__btn">
                        <div className="c-btn c-btn--greyBorder" onClick={() => navigate(`/ino-campaigns/${isPathname[2]}`)}>Cancel</div>
                        <Button title='Edit Rule' type='primary' htmlType='submit' />
                    </div>
                </div>
            </div>
        </Form>
        </>
    )
}

export default InoTimelineEdit;