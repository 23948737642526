import React, { useState, useRef, useEffect } from 'react';
import { useParams, useNavigate } from "react-router-dom";
import { InputCustom, Title, Button, Popup, NoData } from '../component';
import { Form, Input, Select } from 'antd';
import { Plus, UploadIcon } from '../common/asset/icon';
import allActions from '../../actions';
import {useSelector, useDispatch} from 'react-redux';
import { formatDateTime } from '../../utils/formatDatetime';
import InoTimeLine from '../inoInfo/inoTimeline';
import InoRule from '../inoInfo/inoRule';
import InoBox from '../inoInfo/inoBox';
import InoInfomation from '../inoInfo/inoInfomation';
import InoSetting from '../inoInfo/inoSetting';

const InoCampaignInfo = () => {
    const dispatch = useDispatch();
    const { handle } = useParams();
    const [form] = Form.useForm();
    let navigate = useNavigate();
    const inoDetail = useSelector(state => state.ino?.inoInfo);
    const inoCampaign = useSelector(state => state.ino?.inoById);

    useEffect(() => {
        if(handle) {
            dispatch(allActions.inoAction.getInoInfo(handle));
        }        
    },[handle]);    

    useEffect(() => {
        if(inoDetail?.id) {
            dispatch(allActions.inoAction.getInoById(inoDetail?.id));
        }
    },[inoDetail?.id]);

    const paymentContract = inoDetail?.paymentContract ? `${inoDetail?.paymentContract.substring(0, 4)}...${inoDetail?.paymentContract.substring(inoDetail?.paymentContract.length - 4)}` : '--';
    const boxContract = inoDetail?.boxContract ? `${inoDetail?.boxContract.substring(0, 4)}...${inoDetail?.boxContract.substring(inoDetail?.boxContract.length - 4)}` : '--';
    const inoContract = inoDetail?.inoContract ? `${inoDetail?.inoContract.substring(0, 4)}...${inoDetail?.inoContract.substring(inoDetail?.inoContract.length - 4)}` : '--';

    return(
        <>
        <div className="p-ino">
            <Title title={`${inoDetail?.title}’s Information`} />
            <div className="p-ino__left">                
                <InoBox data={inoDetail?.inoBoxes} inoCampaign={inoCampaign} />
                <InoTimeLine data={inoDetail?.inoTimelines} inoCampaign={inoCampaign} />
                <InoRule data={inoDetail?.inoRules} inoCampaign={inoCampaign} />
            </div>
            <div className="p-ino__right">
                <Button title='Edit INO Campaign' type='flat' iconLeft='Edit' onClick={() => navigate(`/ino-edit/${inoCampaign.id}`)} />
                <div className="c-inoInfoCampaign is-edit">
                    <div className="c-inoInfoCampaign__row">
                        <div className="c-inoInfoCampaign__info is-mt0">
                            <span>Title</span>
                            <p className="c-inoInfoCampaign__text">{inoDetail?.title}</p>
                        </div>
                        <div className="c-inoInfoCampaign__info is-mt0 is-textRight">
                            <span>Logo</span>
                            <p className="c-inoInfoCampaign__text is-textRight"><img src={inoDetail?.logoLink} alt={inoDetail?.title} /></p>
                        </div>
                    </div>
                    <div className="c-inoInfoCampaign__row">
                        <div className="c-inoInfoCampaign__info">
                            <span>Network</span>
                            <p className="c-inoInfoCampaign__text is-network"><img src="../images/icon-binance.svg" alt="" />{inoDetail?.networkChain}</p>
                        </div>
                        <div className="c-inoInfoCampaign__info is-textRight">
                            <span>Token Payment</span>
                            <p className="c-inoInfoCampaign__text is-textRight">{paymentContract}</p>
                        </div>
                    </div>
                    <div className="c-inoInfoCampaign__row">
                        <div className="c-inoInfoCampaign__info">
                            <span>Market Contract</span>
                            <p className="c-inoInfoCampaign__text">{inoContract}</p>
                        </div>
                        <div className="c-inoInfoCampaign__info is-textRight">
                            <span>Box Contract</span>
                            <p className="c-inoInfoCampaign__text is-textRight">{boxContract}</p>
                        </div>
                    </div>
                    <div className="c-inoInfoCampaign__info">
                        <span>Selling Time (UTC)</span>
                        <p className="c-inoInfoCampaign__text">{formatDateTime(inoDetail?.startTime)} - {formatDateTime(inoDetail?.sellingTime)}</p>
                    </div>
                    {inoDetail?.sellingTimePhase2 && <div className="c-inoInfoCampaign__info">
                        <span>Selling time Phase 2 (UTC)</span>
                        <p className="c-inoInfoCampaign__text">{formatDateTime(inoDetail?.sellingTimePhase2)} - {formatDateTime(inoDetail?.endTime)}</p>
                    </div>}
                </div>
                <InoInfomation data={inoDetail?.inoInformation} inoCampaign={inoCampaign} />
                <InoSetting data={inoDetail?.inoSettings} inoCampaign={inoCampaign} />
            </div>
        </div>
        </>
    )
}

export default InoCampaignInfo;