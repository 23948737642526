import http from "./http-common";

const getMarketList = () => {
  return http.get("/game-markets");
}; 

const postMarketList = (payload) => {
  return http.post("/game-markets", payload);
}; 

const getMarketGameById = (id) => {
  return http.get(`/game-markets/${id}`);
};  
 
const updateMarketGameById = (id, payload) => { 
  console.log("payload", payload);
  return http.put(`/game-markets/${id}`, payload);
};  
 
const deleteMarketGame = (id) =>{
  return http.delete(`/game-markets/${id}`);
}  

const getCatalogList = () =>{
  return http.get("/catalogs");
}

const postCatalogList = (payload) =>{
  return http.post("/catalogs", payload);
} 

const updateCatalogList = (id, payload) =>{
  return http.put(`/catalogs/${id}`, payload);
}

const deleteCatalogList = (id) =>{
  return http.delete(`/catalogs/${id}`);
}


const GamehubService = {
    getMarketList,
    postMarketList,
    deleteMarketGame, 
    getMarketGameById,
    updateMarketGameById,  
    getCatalogList,
    postCatalogList,
    updateCatalogList,
    deleteCatalogList
};
export default GamehubService;