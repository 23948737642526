import React, { useEffect, useState , useRef } from 'react';
import { Link } from 'react-router-dom';
import {Title, InputCustom, Popup} from '../component';
import { Tabs, Table, Select, Form } from 'antd';
import allActions from '../../actions';
import {useSelector, useDispatch} from 'react-redux';
import addNotify from '../common/Notify/addNotify';
import { Store } from 'react-notifications-component';
import { UploadIcon } from '../common/asset/icon';
import axios from 'axios'; 
import {Tokens} from '../../constants';

const BannerItem = ({onClickRemaining}) => {
    const { Option } = Select;
    const dispatch = useDispatch(); 
    const [isDelete, setIsDelete] = useState(false);
    const [idBanner, setIdBanner] = useState(); 
    const [isEdit, setIsEdit] = useState(false)
    const [form] = Form.useForm(); 
    const [imageData, setImageData] = useState("");
    const [imageDataMobile, setImageDataMobile] = useState(""); 
    const [linkImage, setLinkImage] = useState("");
    const [bannerData, setBannerData] = useState("")
    const inputRef = useRef(null);
    const inputRefMobi = useRef(null); 
    
    const bannerList = useSelector(state => state.airdrop?.bannerList);   
    const bannerById = useSelector(state => state.airdrop?.bannerById); 
    
    const handleDelete = (id) => { 
        dispatch(allActions.airdropAction.deleteBanner( idBanner , (res)=>{
            if(res.status === 204){
                setIsDelete(false);
                window.location.reload();
            }
            else{
                setIsDelete(false);
                Store.addNotification(addNotify('Delete Fail!', 'danger'));
            }
        }))
    }
    const columnsOverview = [
        {
            title: 'IMAGE WEBSITE',
            dataIndex: 'banner',
            ellipsis: true,
            render: (_, record) => (
                <div className="c-nameTable">
                    <div className="c-nameTable__logo"><img src={record?.banner} alt={record.title} /></div> 
                </div>
            ),
        }, {
            title: 'IMAGE MOBILE',
            dataIndex: 'bannerMobile',  
            ellipsis: true,
            render: (_, record) => (
                <div className="c-nameTable">
                    <div className="c-nameTable__logo"><img src={record?.bannerMobile} alt={record.title} /></div> 
                </div>
            ),
        }, {
            title: 'LINK',
            dataIndex: 'link', 
            ellipsis: true,
        }, {
            title: 'STATUS',
            dataIndex: 'status', 
            ellipsis: true,
        }, {
            title: 'ACTION',
            dataIndex: 'action',
            render: (_, record) => ( 
                <div className="c-actionTable">
                    <div className="c-actionTable__box">
                        <img src="../images/icon-more.svg" alt="" />
                        <ul className="c-actionTable__content">
                            <li onClick={()=> {setIsEdit(true); setIdBanner(record?.id)} }>Edit</li>
                            <li onClick={()=> {setIsDelete(true); setIdBanner(record?.id)} }>Delete</li> 
                        </ul>
                    </div>
                </div>
            ),
        }
    ];
    
    useEffect(() => {
        if(idBanner){
            dispatch(allActions.airdropAction.getBannerId(idBanner))
        } 
      }, [idBanner]);

    useEffect(() => {
        dispatch(allActions.airdropAction.getBannerList()); 
    }, []);  

    useEffect(() => {
        form.setFieldsValue(bannerById);
        form.setFieldsValue({ 
            banner: bannerById?.banner, 
            bannerMobile: bannerById?.bannerMobile, 
        });
    },[bannerById]); 

    const onChange = (pagination, filters, sorter, extra) => {
        console.log('params', pagination, filters, sorter, extra);
    }; 
    const onChangeImage = (e, type) =>{
        let file = e.target.files[0]; 
        const formData = new FormData(); 
        formData.append("file", file); 
        axios({
            method: 'post',
            url: process.env.REACT_APP_API_URL + `/upload-to-s3`,
            headers: {
                'accept': '*/*',
                "Authorization": `Bearer ${localStorage.getItem(Tokens.ADMIN)}`,
                "Content-Type": "multipart/form-data"
            },
            data: formData,
        }).then(res => { 
            if(type === "web"){
                setImageData(res.data);
            }
            else{
                setImageDataMobile(res.data)
            }  
        }).catch(err => {
            console.log(err);
        });
    }  
  
    const onFinish = (values) => {
        const payload = {
            banner: imageData,
            bannerMobile: imageDataMobile, 
            position: "TOP",
            title: values?.linkImage?  values?.linkImage :null,
            type: "AIRDROP"
        }  
        dispatch(allActions.airdropAction.updateBanner(idBanner, payload ,(res)=>{
            if(res){
                setIsEdit(false);
                window.location.reload(true);
            }
        })); 
    } 

    return(
        <div className="c-table"> 
            <Table rowKey='id' columns={columnsOverview} dataSource={bannerList} onChange={onChange} /> 
            {isDelete ? <Popup 
                title='Do you want delete this banner?' 
                subTitle='You can’t restore this file' 
                btnTitle='Delete' 
                icon='Delete' 
                onCancel={() => setIsDelete(false)}
                onClick={() => handleDelete()}
            /> : ''}
            {isEdit ? <Form form={form} name="basic" onFinish={onFinish}>
            <Popup  title='Upload Banner' btnTitle='Save' onCancel={() => setIsEdit(false)}>
                <Form.Item name="id" className="l-displayNone">
                    <InputCustom type="hidden" />
                </Form.Item>
                <Form.Item name="key" className="l-displayNone">
                    <InputCustom type="hidden" />
                </Form.Item> 
                <h3 className="add_img">Website</h3>
                <Form.Item name="banner">
                    <input ref={inputRef} type='file' onChange={(e) => onChangeImage(e, "web")} />  
                    <div onClick={()=>inputRef.current.click()} className="c-img airdrop-upload">
                        <img src={ !imageData ? bannerById?.banner : imageData } alt="" />
                    </div>  
                </Form.Item> 

                <h3 className="add_img">Mobile</h3>
                <Form.Item name="bannerMobile">
                    <input ref={inputRefMobi} type='file' onChange={(e) => onChangeImage(e, "mobi")} /> 
                        <div onClick={()=>inputRefMobi.current.click()} className="c-img airdrop-upload">
                           <img src={ !imageDataMobile ? bannerById?.bannerMobile : imageDataMobile } alt="" />
                        </div>
                </Form.Item> 
                <Form.Item name="linkImage">
                    <InputCustom title='Link Image' />
                </Form.Item> 
            </Popup>
            </Form> : null} 
        </div>
    );
}

export default BannerItem;