import React, {useState, useEffect, useRef} from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Button, InputCustom, Loading } from '../component';
import { Select, Form, Upload } from 'antd';
import allActions from '../../actions';
import {useSelector, useDispatch} from 'react-redux';
import { UploadIcon } from '../common/asset/icon';
import {Tokens} from '../../constants';
import axios from 'axios';

const CatalogEdit = () => {
    const [isLoad, setIsLoad] = useState(false);
    const dispatch = useDispatch();
    let navigate = useNavigate(); 
    const [form] = Form.useForm(); 
    const [imageData, setImageData] = useState({ url: null, name: null});
    const inputRef = useRef(); 
    let { handle } = useParams();
    const onChangeImage = (e) => { 
        let file = e.target.files[0];
        const formData = new FormData();
        formData.append("file", file); 
        axios({
            method: 'post',
            url: process.env.REACT_APP_API_URL + `/upload-to-s3`,
            headers: {
                'accept': '*/*',
                "Authorization": `Bearer ${localStorage.getItem(Tokens.ADMIN)}`,
                "Content-Type": "multipart/form-data"
            },
            data: formData,
        }).then(res => {
            setImageData({url: res.data, name: file.name}); 
        }).catch(err => {
            console.log(err);
        });   
    }

    const onFinish = (values) => {
        const payload = {
            id:handle,
            logo: imageData.url,
            name: values.name
        }
        dispatch(allActions.gamehubAction.updateCatalogList(handle, payload)); 
    };

    return(
        <>
        {isLoad ? <Loading /> : ''} 
        <Form form={form} name="basic" onFinish={onFinish}>
        <div className="c-popup">
            <div className="c-popup__box">
                <div className="c-popup__close" onClick={() => navigate('/catalogs')}><img src="../images/icon-close.svg" alt="Close" /></div>
                <h3 className="c-popup__title">Edit Catalog</h3>
                <div className="c-popup__content">
                    <Form.Item name="id" className="l-displayNone">
                        <InputCustom type="hidden" />
                    </Form.Item>
                    <Form.Item name="logo">
                        <input ref={inputRef} type='file' onChange={(e) => onChangeImage(e)} />
                        {imageData.url ? <div onClick={()=>inputRef.current.click()} className="c-img"><img src={imageData.url} alt="" />{imageData.name}</div> : 
                        <div className="c-uploadIMG"> 
                            <div onClick={()=>inputRef.current.click()} className="c-btn c-btn--flat">
                                <UploadIcon />Upload Image Catalog
                            </div>
                        </div>
                        }
                    </Form.Item>
                    <Form.Item name="name">
                        <InputCustom placeholder='Name' />
                    </Form.Item>  
                </div>          
                <div className="c-popup__btn">
                    <div className="c-btn c-btn--greyBorder" onClick={() => navigate('/catalogs')}>Cancel</div>
                    <Button title='Edit' type='primary' htmlType='submit' />
                </div>
            </div>
        </div>
        </Form>
        </>
    )
}

export default CatalogEdit;