import TokenInformService from "../services/TokenInformService";
import {
  GET_TOKEN_INFORM_LIST,
  GET_TOKEN_INFORM_BY_ID,
  UPDATE_TOKEN_INFORM,
  CREATE_TOKEN_INFORM,
  DELETE_TOKEN_INFORM
} from "./tokenInformType";

const getTokenInformList = (payload) => async (dispatch) => {
    try {
      const res = await TokenInformService.getTokenInformList(payload);
      dispatch({
        type: GET_TOKEN_INFORM_LIST,
        payload: res && res.data || []
      });
    } catch (err) {
      console.log(err);
    }
}

const getTokenInformById = (id) => async (dispatch) => {
  try {
    const res = await TokenInformService.getTokenInformById(id);
    dispatch({
      type: GET_TOKEN_INFORM_BY_ID,
      payload: res && res.data || []
    });
  } catch (err) {
    console.log(err);
  }
}

const updateTokenInform = (payload, onCallback) => async (dispatch) => {
  try {
    await TokenInformService.updateTokenInform(payload)
    .then((res) => {
      if(res && res.status === 200) {
        onCallback({callResult: true});
      }
    });
  } catch (err) {
    onCallback({callResult: true});
    console.log(err);
  }
}

const createTokenInform = (payload, onCallback) => async (dispatch) => {
  try {
    await TokenInformService.createTokenInform(payload)
    .then((res) => { 
      if(res && res.status === 201) {
        onCallback({callResult: true});
      }
    });
  } catch (err) {
    onCallback({callResult: true});
    console.log(err);
  }
}

const deleteTokenInform = (id) => async (dispatch) => {
  try {
    const res = await TokenInformService.deleteTokenInform(id);
    dispatch({
      type: DELETE_TOKEN_INFORM,
      payload: {}
    });
  } catch (err) {
    console.log(err);
  }
}

const TokenInformAction = {
  getTokenInformList,
  getTokenInformById,
  updateTokenInform,
  createTokenInform,
  deleteTokenInform
};

export default TokenInformAction;