import {
    GET_CAMPAIGN_LIST,
    GET_CAMPAIGN_BY_ID,
    GET_CAMPAIGN_SIMPLE,
    GET_REMAINING_BY_ID,
    GET_SAVE_DRAFT
} from "../actions/campaignType";

const initialState = [];

function campaignReducer(campaigns = initialState, action) {
    const { type, payload } = action;

    switch (type) {
        case GET_CAMPAIGN_LIST:
            return {...campaigns, campaignList: payload};

        case GET_CAMPAIGN_BY_ID:
            return {...campaigns, campaignOne: payload};
        
        case GET_CAMPAIGN_SIMPLE:
            return {...campaigns, campaignSimpleList: payload};
        
        case GET_REMAINING_BY_ID:
            return {...campaigns, remainingList: payload};

        case GET_SAVE_DRAFT:
            return {...campaigns, savedraftList: payload};
        default:
            return campaigns;
    }
};

export default campaignReducer;