import {
    GET_ALLOCATION_LIST,
    SEARCH_ALLOCATION,
    GEN_SIGN_BY_ROUNDID,
    ASYNC_VESTING_SCHEDULE
} from "../actions/allocationType";

const initialState = [];

function allocationReducer(allocations = initialState, action) {
    const { type, payload } = action;

    switch (type) {
        case GET_ALLOCATION_LIST:
            return payload;

        case SEARCH_ALLOCATION:
            return payload;

        case GEN_SIGN_BY_ROUNDID:
            return payload;

        case ASYNC_VESTING_SCHEDULE:
            return payload;
            
        default:
            return allocations;
    }
};

export default allocationReducer;