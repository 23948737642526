import React, {useState} from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { formatData, formatText } from '../../utils/StringHelper';
import { formatDateTimeNotMs, formatDateTime } from '../../utils/formatDatetime';
import {Title, Button} from '../component';
import { getCampaignTimeItem, getRoundType } from '../../utils/campaignUltils';

const RoundInfo = ({item, campaignData}) => {
    const [isOpen, setIsOpen] = useState(false);
    let navigate = useNavigate();

    const addressCommited = item?.addressCommited ? `${item?.addressCommited.substring(0, 4)}...${item?.addressCommited.substring(item?.addressCommited.length - 4)}` : '--';
    const addressRemaningFund = item?.addressRemaningFund ? `${item?.addressRemaningFund.substring(0, 4)}...${item?.addressRemaningFund.substring(item?.addressRemaningFund.length - 4)}` : '--';
    const addressClaimIdo = item?.addressClaimIdo ? `${item?.addressClaimIdo.substring(0, 4)}...${item?.addressClaimIdo.substring(item?.addressClaimIdo.length - 4)}` : '--';
    const addressRefundEspecial = item?.addressRefundEspecial ? `${item?.addressRefundEspecial.substring(0, 4)}...${item?.addressRefundEspecial.substring(item?.addressRefundEspecial.length - 4)}` : '--';
    const addressClaimDaily = item?.addressClaimDaily ? `${item?.addressClaimDaily.substring(0, 4)}...${item?.addressClaimDaily.substring(item?.addressClaimDaily.length - 4)}` : '--';
    const getDatetimeRound = getCampaignTimeItem(campaignData, item);
    
    return(
        <div className="c-roundInfo">
            <div className="c-roundInfo__head">
                <div className={`c-roundInfo__toggle${isOpen ? ' is-open' : ''}`} onClick={() => setIsOpen(!isOpen)}><img src="../images/icon-arrow-down.svg" alt="Arrow" /></div>
                <Title title={item?.round} subTitle={`Round ID: ${item?.id} | Phase: ${item?.phase} | Type: ${getRoundType(item?.roundType)}`} />
                <Button title='Edit' type='blue' onClick={() => navigate('/round-edit/'+item?.id)}/>
                <div className="c-roundInfo__btnRound">
                    <Button title='Whitelist' type='blue' onClick={() => navigate('/whitelist?campaign='+item?.campaignId+'&round='+item?.id)}/>
                    <Button title='Allocation' type='blue' onClick={() => navigate('/allocation?campaign='+item?.campaignId+'&round='+item?.id)} />
                </div>
            </div>
            <div className={`c-roundInfo__body${isOpen ? ' is-open' : ''}`}>
                <div className="c-roundInfo__content">
                    <div className="c-roundInfo__dflex">
                        <div className="c-roundInfo__row">
                            <h3 className="c-roundInfo__ttl">Address Commited</h3>
                            <p className="c-roundInfo__txt">{addressCommited}</p>
                        </div>
                        <div className="c-roundInfo__row">
                            <h3 className="c-roundInfo__ttl">Address Remaining Fund</h3>
                            <p className="c-roundInfo__txt">{addressRemaningFund}</p>
                        </div>
                        <div className="c-roundInfo__row">
                            <h3 className="c-roundInfo__ttl">Address Claim IDO</h3>
                            <p className="c-roundInfo__txt">{addressClaimIdo}</p>
                        </div>
                        <div className="c-roundInfo__row">
                            <h3 className="c-roundInfo__ttl">Address Claim Daily</h3>
                            <p className="c-roundInfo__txt">{addressClaimDaily}</p>
                        </div>
                        <div className="c-roundInfo__row">
                            <h3 className="c-roundInfo__ttl">Address Refund Especial</h3>
                            <p className="c-roundInfo__txt">{addressRefundEspecial}</p>
                        </div>
                        <div className="c-roundInfo__row">
                            <h3 className="c-roundInfo__ttl">Progress Bar (%)</h3>
                            <p className="c-roundInfo__txt">{formatData(item?.processBar,'')}</p>
                        </div>
                        <div className="c-roundInfo__row">
                            <h3 className="c-roundInfo__ttl">Total Raise ({campaignData?.acceptCurrency})</h3>
                            <p className="c-roundInfo__txt">{formatData(item?.totalRaise, '')}</p>
                        </div>
                        <div className="c-roundInfo__row">
                            <h3 className="c-roundInfo__ttl">IDO Rate ({campaignData?.acceptCurrency})</h3>
                            <p className="c-roundInfo__txt">{item?.idoRate}</p>
                        </div>
                        <div className="c-roundInfo__row">
                            <h3 className="c-roundInfo__ttl">Commit Min (%)</h3>
                            <p className="c-roundInfo__txt">{item?.commitRangeMinPercent}</p>
                        </div>
                        <div className="c-roundInfo__row">
                            <h3 className="c-roundInfo__ttl">Commit Max (%)</h3>
                            <p className="c-roundInfo__txt">{item?.commitRangeMaxPercent}</p>
                        </div>
                        <div className="c-roundInfo__row">
                            <h3 className="c-roundInfo__ttl">Whitelist Apply?</h3>
                            <p className="c-roundInfo__txt">{(item?.roundType === 4 || item?.phase === 2)  ? "No" : "Yes"}</p>
                        </div>
                        <div className="c-roundInfo__row">
                            <h3 className="c-roundInfo__ttl">Slots</h3>
                            <p className="c-roundInfo__txt">{item?.slots}</p>
                        </div>
                    </div>
                    <div className="c-roundInfo__dflex">
                        <div className="c-roundInfo__row is-wAnno">
                            <h3 className="c-roundInfo__ttl">Announcement</h3>
                            <p className="c-roundInfo__txt">{formatText(item?.announcement)}</p>
                        </div>
                    </div>  
                    <div className={`c-roundInfo__row ${item?.roundType !== 2 ? 'is-mt0' : ''}`}>
                        <h3 className="c-roundInfo__txt">Round Timetable</h3>
                        <div className="c-roundInfo__box">
                            {!item.startIDO ? <p className="c-roundInfo__txt">Use campaign timetable</p> : <>
                            <div className="c-roundInfo__row">
                                <h3 className="c-roundInfo__ttl">IDO Date (UTC)</h3>
                                <p className="c-roundInfo__txt">{formatDateTime(getDatetimeRound?.startIDO)}</p>
                            </div>
                            <div className="c-roundInfo__row">
                                <h3 className="c-roundInfo__ttl">Join Pool Date (UTC)</h3>
                                <p className="c-roundInfo__txt">{formatDateTime(getDatetimeRound?.startJoinPool)} - {formatDateTime(getDatetimeRound?.endJoinPool)}</p>
                            </div>
                            <div className="c-roundInfo__row">
                                <h3 className="c-roundInfo__ttl">Claim Date (UTC)</h3>
                                <p className="c-roundInfo__txt">{formatDateTime(getDatetimeRound?.startClaim)}</p>
                            </div>
                            </>}
                        </div>
                    </div>
                    <div className="c-roundInfo__row">
                        <h3 className="c-roundInfo__txt">Vesting</h3>
                        <div className="c-roundInfo__box is-vesting">
                            <div className="c-roundInfo__row">
                                <h3 className="c-roundInfo__ttl">Refund Duration (hours)</h3>
                                <p className="c-roundInfo__txt">{item?.refundDuration}</p>
                            </div>
                            <div className="c-roundInfo__row">
                                <h3 className="c-roundInfo__ttl">Claim Type</h3>
                                <p className="c-roundInfo__txt">{item?.claimType}</p>
                            </div>
                            <div className="c-roundInfo__row">
                                <h3 className="c-roundInfo__ttl">Vesting Text</h3>
                                <p className="c-roundInfo__txt">{!item?.vesting ? '--' : item?.vesting}</p>
                            </div>
                            <div className="c-roundInfo__row">
                                <h3 className="c-roundInfo__ttl">Vesting Schedule (UTC)</h3>
                                <ul className="c-roundInfo__vesting">
                                    {item?.vestingSchedules && item?.vestingSchedules.length > 0 ? item?.vestingSchedules.map((item, index) => (
                                        <li key={index}>{index + 1}. Vesting {item?.percentage} time date {formatDateTimeNotMs(item?.time)}</li>
                                    )) : <li>--</li>}
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default RoundInfo;