import http from "./http-common";

const updateClaimIdo = (payload) => {
  return http.put("/campaign/claim-ido/client", { params: payload });
};

const getAllocationList = (id) => {
  return http.get(`/allocations/${id}`);
};

const searchAllocation = (payload) => {
  return http.post('/allocations/search', payload);
};

const genSignByRoundId = (payload) => {
  return http.post('/allocations/gen-sign-claim', payload);
};

const asyncVestingSchedule = (payload) => {
  return http.post('/allocations/sync-vesting-schedule', payload);
};

const generateAllocation = (payload) => {
  return http.post('/allocations/generate', payload);
}
const AllocationService = {
  updateClaimIdo,
  getAllocationList,
  searchAllocation,
  genSignByRoundId,
  asyncVestingSchedule,
  generateAllocation
};
export default AllocationService;