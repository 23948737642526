import GamehubService from "../services/GamehubService";
import {
    GET_MARKET_LIST,
    GET_BANNER_LIST,  
    GET_MARKETGAME_BY_ID,
    GET_CATALOG_LIST
} from "./gamehubType";

const getBannerList = (account) => async (dispatch) => {
  try {
    const res = await GamehubService.getMarketList(account);  
    dispatch({
      type: GET_BANNER_LIST,
      payload: res && res.data || []
    });
  } catch (err) {
    console.log(err);
  }
}

const getMarketList = (account) => async (dispatch) => {
  try {
    const res = await GamehubService.getMarketList(account);  
    dispatch({
      type: GET_MARKET_LIST,
      payload: res && res.data || []
    });
  } catch (err) {
    console.log(err);
  }
}


const postMarketList = (payload, onCallBack) => async (dispatch) => {
  try {
    const res = await GamehubService.postMarketList(payload); 
    onCallBack(res)
  } catch (err) {
    console.log(err);
  }
}

const getMarketGameById = (id) => async (dispatch) => {
  try {
    const res = await GamehubService.getMarketGameById(id); 
    dispatch({
      type: GET_MARKETGAME_BY_ID,
      payload: res && res.data || []
    });
  } catch (err) {
    console.log(err);
  }
}

const updateMarketGameById = (id, payload, onCallBack) => async (dispatch) => {
  try {
    const res = await GamehubService.updateMarketGameById(id, payload); 
    onCallBack(res)
  } catch (err) {
    console.log(err);
  }
}

const deleteMarketGame = (id, onCallBack) => async (dispatch) => {
  try {
    const res = await GamehubService.deleteMarketGame(id);  
    onCallBack(res)
  } catch (err) {
    onCallBack(err) 
  }
} 
 
const getCatalogList = () => async (dispatch) => {
  try {
    const res = await GamehubService.getCatalogList(); 
    dispatch({
      type: GET_CATALOG_LIST,
      payload: res && res.data || []
    });
  } catch (err) {
    console.log(err);
  }
}

const postCatalogList = (payload, onCallBack) => async (dispatch) => {
  try {
    const res = await GamehubService.postCatalogList(payload); 
    onCallBack(res)
  } catch (err) {
    console.log(err);
  }
}


const updateCatalogList = (id, payload, onCallBack) => async (dispatch) => {
  try {
    const res = await GamehubService.updateCatalogList(id, payload); 
    onCallBack(res)
  } catch (err) {
    console.log(err);
  }
}

const deleteCatalogList = (id, onCallBack) => async (dispatch) => {
  try {
    const res = await GamehubService.deleteCatalogList(id); 
    onCallBack(res)
  } catch (err) {
    console.log(err);
  }
} 

const gamehubAction = {
    getBannerList,
    postMarketList,
    updateMarketGameById,
    deleteMarketGame,
    getMarketGameById,  
    getMarketList,
    getCatalogList,
    postCatalogList,
    updateCatalogList,
    deleteCatalogList
};

export default gamehubAction;