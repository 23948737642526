import React, { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {Title, Button, NoData} from '../component';
import RoundInfo from './roundInfo';
import allActions from '../../actions';
import {useSelector, useDispatch} from 'react-redux';
import { formatData, formatText } from '../../utils/StringHelper';
import { formatDateTime } from '../../utils/formatDatetime';

const CampaignInfo = () => {
    let navigate = useNavigate();
    const dispatch = useDispatch();
    let { handle } = useParams();
    const roundData = useSelector(state => state.rounds);
    const campaignData = useSelector(state => state.campaigns?.campaignOne);
    const { campaignRoundList } = roundData;

    campaignRoundList && campaignRoundList.length > 0 && campaignRoundList.sort((a, b) => {return a.id - b.id});
     
    useEffect(() => {
        if(handle !== null) {
            dispatch(allActions.campaignAction.getCampaignById(handle));
            dispatch(allActions.roundAction.getRoundsByCampaignsId(handle));
        }
        window.scrollTo({top: 0, left: 0, behavior: 'smooth' });
    },[]);

    return(
        <div className="p-campaignInfo">
            <Title title={`${campaignData?.title}’s Information`} />
            <div className="p-campaignInfo__round">
                <div className="p-campaignInfo__title">
                    <p>Round</p>
                    <Button title='Add Round' type='flat' iconLeft='BoxAdd' onClick={() => navigate('/round-add/'+handle)}/>
                </div>
                <div className="p-campaignInfo__box">
                    {campaignRoundList && campaignRoundList.length > 0 ? campaignRoundList.map((item, i) => {
                        return(<RoundInfo item={item} key={i} campaignData={campaignData} />);
                    }) : <NoData />}
                </div>
            </div>
            <div className="p-campaignInfo__info">
                <div className="p-campaignInfo__btn">
                    <Button title='Edit Campaign' type='flat' iconLeft='Edit' onClick={() => navigate('/campaign-edit/'+handle)}/>
                    {/* <Button title='Submit' type='blue' /> */}
                </div>
                <div className="c-info">
                    <div className="c-info__img"><img src={campaignData?.bannerURL} alt={campaignData?.title} /></div>
                    <div className="c-info__logo">
                        <p>{campaignData?.title}</p>
                        <span><img src={campaignData?.logoUrl} alt={campaignData?.title} /></span>
                    </div>
                    <div className="c-info__row">
                        <h3 className="c-info__ttl">Description</h3>
                        {/* <p className="c-info__txt">{campaignData?.description}</p> */}
                        <div className="c-info__txt" dangerouslySetInnerHTML={{__html: campaignData?.description}}></div>
                    </div>
                    <div className="c-info__row">
                        <h3 className="c-info__ttl">Status</h3>
                        <p className="c-info__txt">{campaignData?.status}</p>
                    </div>
                    <div className="c-info__row">
                        <h3 className="c-info__ttl">Announcement</h3>
                        <p className="c-info__txt">{formatText(campaignData?.announcement)}</p>
                    </div>
                    <div className="c-info__row">
                        <h3 className="c-info__ttl">Channel Media</h3>
                        <ul className="c-info__media">
                            <li>
                                <span><img src="../images/icon-telegram.svg" alt="" />Telegram</span>
                                <p>{formatText(campaignData?.telegramLink)}</p>
                            </li>
                            <li>
                                <span><img src="../images/icon-twitter.svg" alt="" />Twitter</span>
                                <p>{formatText(campaignData?.twitterLink)}</p>
                            </li>
                            <li>
                                <span><img src="../images/icon-medium.svg" alt="" />Medium</span>
                                <p>{formatText(campaignData?.mediumLink)}</p>
                            </li>
                            <li>
                                <span><img src="../images/icon-website.svg" alt="" />Website</span>
                                <p>{formatText(campaignData?.website)}</p>
                            </li>
                            <li>
                                <span><img src="../images/icon-facebook.svg" alt="" />Facebook</span>
                                <p>{formatText(campaignData?.facebookLink)}</p>
                            </li>
                            <li>
                                <span><img src="../images/icon-discord.svg" alt="" />Discord</span>
                                <p>{formatText(campaignData?.discordLink)}</p>
                            </li>
                            <li>
                                <span><img src="../images/icon-youtube.svg" alt="" />Youtube</span>
                                <p>{formatText(campaignData?.youtubeLink)}</p>
                            </li>
                        </ul>
                    </div>
                    <div className="c-info__dflex">
                        <div className="c-info__row">
                            <h3 className="c-info__ttl">Network</h3>
                            <p className="c-info__txt">{campaignData?.network}</p>
                        </div>
                        <div className="c-info__row">
                            <h3 className="c-info__ttl">Accept Currency</h3>
                            <p className="c-info__txt"><img src={campaignData?.acceptCurrencyLogo} alt={campaignData?.acceptCurrency} />{campaignData?.acceptCurrency}</p>
                        </div>
                    </div>
                    <div className="c-info__dflex">
                        <div className="c-info__row">
                            <h3 className="c-info__ttl">Claim network</h3>
                            <p className="c-info__txt">{campaignData?.claimChain}</p>
                        </div>
                        <div className="c-info__row">
                            <h3 className="c-info__ttl"></h3>
                            <p className="c-info__txt"></p>
                        </div>
                    </div>
                    <div className="c-info__dflex">
                        <div className="c-info__row">
                            <h3 className="c-info__ttl">Token Inform</h3>
                            <p className="c-info__txt">{formatText(campaignData?.tokenInform && campaignData?.tokenInform.symbol)}</p>
                        </div>
                        <div className="c-info__row">
                            <h3 className="c-info__ttl">Staking Deadline (hours)</h3>
                            <p className="c-info__txt">{formatText(campaignData?.stakingDeadline)}</p>
                        </div>
                    </div>
                    <div className="c-info__dflex">
                        <div className="c-info__row">
                            <h3 className="c-info__ttl">Total Raise ({campaignData?.acceptCurrency})</h3>
                            <p className="c-info__txt">{formatData(campaignData?.totalRaise, '')}</p>
                        </div>
                        <div className="c-info__row">
                            <h3 className="c-info__ttl">IDO Rate ({campaignData?.acceptCurrency})</h3>
                            <p className="c-info__txt">{campaignData?.idoRate}</p>
                        </div>
                    </div>
                    <div className="c-info__row">
                        <h3 className="c-info__ttl">Campaign’s Timetable</h3>
                        <div className="c-info__box">
                            <div className="c-info__row">
                                <h3 className="c-info__ttl">IDO Date (UTC)</h3>
                                <p className="c-info__txt">{formatDateTime(campaignData?.startIDO)}</p>
                            </div>
                            <div className="c-info__row">
                                <h3 className="c-info__ttl">Join Pool Date (UTC)</h3>
                                <p className="c-info__txt">{formatDateTime(campaignData?.startJoinPool)} - {formatDateTime(campaignData?.endJoinPool)}</p>
                            </div>
                            <div className="c-info__row">
                                <h3 className="c-info__ttl">Claim Date (UTC)</h3>
                                <p className="c-info__txt">{formatDateTime(campaignData?.startClaim)}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default CampaignInfo;