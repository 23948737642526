import {
    GET_ROUNDS_BY_CAMPAIGN_ID,
    GET_ROUND_BY_ID,
    UPDATE_ROUND,
    DELETE_ROUND,
    CREATE_ROUND
} from "../actions/roundType";

const initialState = [];

function roundReducer(rounds = initialState, action) {
    const { type, payload } = action;

    switch (type) {
        case GET_ROUNDS_BY_CAMPAIGN_ID:
            return { ...rounds, campaignRoundList: payload};

        case GET_ROUND_BY_ID:
            return { ...rounds, roundOne: payload};

        case UPDATE_ROUND:
            return payload;

        case DELETE_ROUND:
            return payload;

        case CREATE_ROUND:
            return payload;

        default:
            return rounds;
    }
};

export default roundReducer;