import {userActionType} from './actionType';
import UserDataService from './../services/UserService';

const setUser = (userObj) => {
    return {
        type: "SET_USER",
        payload: userObj
    }
}

const setChainId = (chainObj) => {
    return {
        type: "SET_CHAIN",
        payload: chainObj
    }
}

const logOut = () => {
    return {
        type: "LOG_OUT"
    }
}

const getUserList = (payload) => async (dispatch) => {
    try {
      const res = await UserDataService.getUserList(payload);
      dispatch({
        type: userActionType.GET_USER_LIST,
        payload: res && res.data || []
      });
    } catch (err) {
      console.log(err);
    }
}

const userAction = {
    setUser,
    setChainId,
    logOut,
    getUserList
};

export default userAction;