import InoDataService from "../services/InoService";
import {
    GET_PRIORITY_LIST_BY_CAMPAIGN_ID
} from "./inoType";

const getPriorityListByCampaignId = (id) => async (dispatch) => {
    try {
        const res = await InoDataService.getPriorityListByCampaignId(id);  
        dispatch({
            type: GET_PRIORITY_LIST_BY_CAMPAIGN_ID,
            payload: res && res.data || []
        });
    } catch (err) {
        console.log(err) 
    }
}

const deleteAllPriority = (id, onCallback) => async (dispatch) => {
    try {
        await InoDataService.deleteAllPriority(id)
        .then((res)=> {
            if(res && res.status === 200) {
                onCallback({callResult: true});
            }
        });  
        
    } catch (err) {
        onCallback({callResult: true});
        console.log(err) 
    }
}

const deletePriority = (id, onCallback) => async (dispatch) => {
    try {
        await InoDataService.deletePriority(id)
        .then((res)=> {
            if(res && res.status === 200) {
                onCallback({callResult: true});
            }
        });  
        
    } catch (err) {
        onCallback({callResult: true});
        console.log(err) 
    }
}

const updatePriority = (payload, onCallback) => async (dispatch) => {
    try {
        await InoDataService.updatePriority(payload.id, payload)
        .then((res)=> {
            if(res && res.status === 200) {
                onCallback({callResult: true});
            }
        });  
        
    } catch (err) {
        onCallback({callResult: true});
        console.log(err) 
    }
}

const inoWhitelistAction = {
    getPriorityListByCampaignId,
    deleteAllPriority,
    deletePriority,
    updatePriority
};
  
export default inoWhitelistAction;