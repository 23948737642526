import { snapshotActionType } from "../actions/actionType";

const initialState = [];

function snapshotReducer(snapshot = initialState, action) {
    const { type, payload } = action;

    switch (type) {
        case snapshotActionType.GET_SNAPSHOT_PRIORITY_LIST_BY_ROUNDID:
        case snapshotActionType.SEARCH_PRIORITY:
            return { ...snapshot, priorityList:  payload }

        default:
            return { ...snapshot };
    }
};

export default snapshotReducer;