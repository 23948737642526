import React, {useState} from 'react';
import {Plus, ArrowLeft, ArrowRight, BoxAdd, Upload, Edit, Download, Trash, Refresh, Unlock, Export, Claim} from './common/asset/icon';
import { LoadingOutlined } from '@ant-design/icons';
import { Spin, Form } from 'antd';

export const Button = ({htmlType, title, iconLeft, iconRight, type, disabled, onClick}) => {
    const classType = type && type !== null ? ' c-btn--'+type : '';
    const handleIcon = (value) => {
        let icon = '';
        if(value === 'Plus') {
            icon = <Plus />;
        } else if(value === 'ArrowLeft') {
            icon = <ArrowLeft />;
        } else if(value === 'ArrowRight') {
            icon = <ArrowRight />;
        } else if(value === 'BoxAdd') {
            icon = <BoxAdd />;
        } else if(value === 'Upload') {
            icon = <Upload />;
        } else if(value === 'Edit') {
            icon = <Edit />
        } else if(value === 'Download') {
            icon = <Download />;
        } else if(value === 'Trash') {
            icon = <Trash />;
        } else if(value === 'Refresh') {
            icon = <Refresh />;
        } else if(value === 'Unlock') {
            icon = <Unlock />;
        } else if(value === 'Export') {
            icon = <Export />;
        } else if(value === 'Claim') {
            icon = <Claim />;
        }
        return icon;
    } 
    return(
        <button 
            type={htmlType}
            className={`c-btn${classType}${iconLeft && iconLeft !== null ? ' is-left' : ''}${iconRight && iconRight !== null ? ' is-right' : ''}`} 
            disabled={disabled}
            onClick={onClick}
        >
            {iconLeft && iconLeft !== null ? handleIcon(iconLeft) : ''}
            {title}
            {iconRight && iconRight !== null ? handleIcon(iconRight) : ''}
        </button>
    );
}

export const Toggle = ({title, name, checked, onChange}) => {
    return(
        <div className="c-toggle">
            <label className="c-toggle__box">
                <input type="checkbox" name={name} defaultChecked={checked} onChange={onChange} />
                <span></span>
            </label>
            <p className="c-toggle__title">{title}</p>
        </div>
    )
}

export const Radio = ({name, title, items, checked}) => {
    return(
        <div className="c-radio">
            <p className="c-radio__title">{title}</p>
            {items && items.map((item, index) => (
                <div className="c-radio__box" key={index}>
                    <label className="c-radio__check">
                        <input type="radio" name={name} defaultChecked={checked === item ? true : false} />
                        <span>{item}</span>
                    </label>                    
                </div>
            ))}
        </div>
    )
}

export const Search = ({title, name, placeholder, value, onChange, onKeyDown, onKeyPress}) => {
    return(
        <div className="c-search">
            {title && title !== null ? <p className="c-search__title">{title}</p> : ''}
            <label className="c-search__box">
                <input
                    type='text'
                    name={name && name !== null ? name : 'search'}
                    placeholder={placeholder}
                    defaultValue={value}
                    onChange={onChange}
                    onKeyDown={onKeyDown} 
                    onKeyPress={onKeyPress}
                />
                <span className="c-search__icon"><img src="../images/icon-search.svg" alt="Search" /></span>
            </label>
        </div>
    )
}

export const Input = ({title, type, name, placeholder, required, value, note, onChange, readonly}) => {
    const [typeInput, setTypeInput] = useState(type);
    // const [valueInput, setValueInput] = useState(value);
    // const handleValue = (e)=>{
    //     setValueInput(e.target.value);
    // }
    const togglePassword = () =>{
        if(typeInput==="password") {
            setTypeInput("text")
            return;
        }
        setTypeInput("password")
    }
    return(
        <div className="c-input">
            {title && title !== null ? note && note !== null ? 
                <div className="c-input__title is-note">
                    <h3>{title}
                        {required && required===true ? <span>(*)</span> : ''}
                        <div className="c-input__note">
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                <path d="M21.17 8.83003V15.67C21.17 16.79 20.57 17.83 19.6 18.4L13.66 21.83C12.69 22.39 11.49 22.39 10.51 21.83L4.57001 18.4C3.60001 17.84 3 16.8 3 15.67V8.83003C3 7.71003 3.60001 6.66999 4.57001 6.09999L10.51 2.67C11.48 2.11 12.68 2.11 13.66 2.67L19.6 6.09999C20.57 6.66999 21.17 7.70003 21.17 8.83003Z" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                                <path d="M12.159 7.5C12.753 7.5 13.2705 7.5945 13.7115 7.7835C14.1525 7.9725 14.4945 8.2425 14.7375 8.5935C14.9895 8.9445 15.1155 9.363 15.1155 9.849C15.1155 10.371 14.9985 10.8075 14.7645 11.1585C14.5395 11.5005 14.2335 11.7615 13.8465 11.9415C13.4685 12.1215 13.0455 12.2385 12.5775 12.2925V13.737H11.457V11.712C12.114 11.613 12.6225 11.4915 12.9825 11.3475C13.3515 11.2035 13.608 11.0235 13.752 10.8075C13.905 10.5915 13.9815 10.326 13.9815 10.011C13.9815 9.696 13.9095 9.426 13.7655 9.201C13.6305 8.976 13.4235 8.805 13.1445 8.688C12.8655 8.571 12.51 8.5125 12.078 8.5125C11.484 8.5125 10.998 8.679 10.62 9.012C10.251 9.336 10.0305 9.7815 9.9585 10.3485L9 9.795C9.099 9.336 9.288 8.9355 9.567 8.5935C9.855 8.2425 10.215 7.9725 10.647 7.7835C11.088 7.5945 11.592 7.5 12.159 7.5ZM11.997 14.925C12.258 14.925 12.4695 15.006 12.6315 15.168C12.7935 15.33 12.8745 15.5415 12.8745 15.8025C12.8745 16.0635 12.7935 16.275 12.6315 16.437C12.4695 16.599 12.258 16.68 11.997 16.68C11.736 16.68 11.5245 16.599 11.3625 16.437C11.2005 16.275 11.1195 16.0635 11.1195 15.8025C11.1195 15.5415 11.2005 15.33 11.3625 15.168C11.5245 15.006 11.736 14.925 11.997 14.925Z"/>
                            </svg>
                            <p>{note}</p>
                        </div>
                    </h3>
                </div> : 
                <p className="c-input__title">{title}{required && required===true ? <span>(*)</span> : ''}</p> : ''}
            <div className={`c-input__box${type==='password'? ' is-password': ''}`}>
                <input 
                    type={type ? typeInput : 'text'}
                    name={name}
                    defaultValue={value} 
                    placeholder={placeholder} 
                    onChange={onChange}
                    readOnly={readonly && readonly === true ? 'readonly' : ''} 
                />
                {type==="password" ? <i className="c-input__eye" onClick={() => togglePassword()}>
                    <img src="../images/icon-eye.svg" alt="" />
                </i> : ''}
            </div>
        </div>
    );
}

export const InputCustom = ({title, type, name, placeholder, required, value, note, onChange, readonly}) => {
    const [typeInput, setTypeInput] = useState(type);
    // const [valueInput, setValueInput] = useState(value);
    // const handleValue = (e)=>{
    //     setValueInput(e.target.value);
    // }
    const togglePassword = () =>{
        if(typeInput==="password") {
            setTypeInput("text")
            return;
        }
        setTypeInput("password")
    }
    return(
        <div className="c-input">
            {title && title !== null ? note && note !== null ? 
                <div className="c-input__title is-note">
                    <h3>{title}
                        {required && required===true ? <span style={{color: 'red'}}>*</span> : ''}
                        <div className="c-input__note">
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                <path d="M21.17 8.83003V15.67C21.17 16.79 20.57 17.83 19.6 18.4L13.66 21.83C12.69 22.39 11.49 22.39 10.51 21.83L4.57001 18.4C3.60001 17.84 3 16.8 3 15.67V8.83003C3 7.71003 3.60001 6.66999 4.57001 6.09999L10.51 2.67C11.48 2.11 12.68 2.11 13.66 2.67L19.6 6.09999C20.57 6.66999 21.17 7.70003 21.17 8.83003Z" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                                <path d="M12.159 7.5C12.753 7.5 13.2705 7.5945 13.7115 7.7835C14.1525 7.9725 14.4945 8.2425 14.7375 8.5935C14.9895 8.9445 15.1155 9.363 15.1155 9.849C15.1155 10.371 14.9985 10.8075 14.7645 11.1585C14.5395 11.5005 14.2335 11.7615 13.8465 11.9415C13.4685 12.1215 13.0455 12.2385 12.5775 12.2925V13.737H11.457V11.712C12.114 11.613 12.6225 11.4915 12.9825 11.3475C13.3515 11.2035 13.608 11.0235 13.752 10.8075C13.905 10.5915 13.9815 10.326 13.9815 10.011C13.9815 9.696 13.9095 9.426 13.7655 9.201C13.6305 8.976 13.4235 8.805 13.1445 8.688C12.8655 8.571 12.51 8.5125 12.078 8.5125C11.484 8.5125 10.998 8.679 10.62 9.012C10.251 9.336 10.0305 9.7815 9.9585 10.3485L9 9.795C9.099 9.336 9.288 8.9355 9.567 8.5935C9.855 8.2425 10.215 7.9725 10.647 7.7835C11.088 7.5945 11.592 7.5 12.159 7.5ZM11.997 14.925C12.258 14.925 12.4695 15.006 12.6315 15.168C12.7935 15.33 12.8745 15.5415 12.8745 15.8025C12.8745 16.0635 12.7935 16.275 12.6315 16.437C12.4695 16.599 12.258 16.68 11.997 16.68C11.736 16.68 11.5245 16.599 11.3625 16.437C11.2005 16.275 11.1195 16.0635 11.1195 15.8025C11.1195 15.5415 11.2005 15.33 11.3625 15.168C11.5245 15.006 11.736 14.925 11.997 14.925Z"/>
                            </svg>
                            <div dangerouslySetInnerHTML={{__html: note}}></div>
                        </div>
                    </h3>
                </div> : 
                <p className="c-input__title">{title}{required && required===true ? <span style={{color: 'red'}}>*</span> : ''}</p> : ''}
            <div className={`c-input__box${type==='password'? ' is-password': ''}`}>
                <input 
                    onKeyPress={e => {
                        if (e.key === 'Enter') e.preventDefault();
                    }}
                    type={type ? typeInput : 'text'}
                    name={name}
                    defaultValue={value} 
                    placeholder={placeholder} 
                    onChange={onChange}
                    readOnly={readonly && readonly === true ? 'readonly' : ''}
                />
                {type==="password" ? <i className="c-input__eye" onClick={() => togglePassword()}>
                    <img src="../images/icon-eye.svg" alt="" />
                </i> : ''}
            </div>
        </div>
    );
}

export const Textarea = ({input, title, name, placeholder, children}) => {
    return(
        <div className="c-textarea">
            {title && title !== null ? <p className="c-textarea__title">{title}</p>: ''}
            <div className="c-textarea__box">
                <textarea name={name} placeholder={placeholder} defaultValue={children} {...input}/>
            </div>
        </div>
    )
}

export const Select = ({title, name, items, defaultValue}) => {
    const defaultSelectValue = defaultValue && defaultValue !== null ? defaultValue : items[0];
    const [selected, setSelected] = useState(defaultSelectValue);

    return(
        <div className="c-select">
            {title && title !== null ? <p className="c-select__title">{title}</p> : ''}
            <div className="c-select__box">
                <select name={name} defaultValue={selected} onChange={(e) => setSelected(e.target.value)}>
                    {items.map(item => (
                        <option key={item} value={item}>
                            {item}
                        </option>
                    ))}
                </select>
            </div>
        </div>
    );
}

export const Title = ({title, subTitle}) => {
    return(
        <div className="c-title">
            {title && title !== null ? <h3>{title}</h3> : ''}
            {subTitle && subTitle !== null ? <p>{subTitle}</p> : ''}
        </div>
    )
}

export const Label = ({title}) => {
    return(
        <div className={`c-label is-${title}`}>{title}</div>
    )
}

export const StatusLabel = ({title, status}) => {
    return(
        <div className={`c-label is-${status}`}>{title}</div>
    )
}

export const Popup = ({title, subTitle, icon, btnTitle, children, onCancel, onClick}) => {
    const hancleIMG = (value) => {
        let img = '';
        if(value === 'Delete') {
            img = '../images/icon-popup.svg';
        } else if(value === 'Add') {
            img = '../images/icon-popup-add.svg';
        } else if(value === 'Claim') {
            img = '../images/icon-popup-claim.svg';
        } else if(value === 'Edit') {
            img = '../images/icon-popup-edit.svg';
        } else if(value === 'Account') {
            img = '../images/icon-popup-account.svg';
        }
        return img;
    }
    return(
        <div className="c-popup">
            <div className="c-popup__box">
                <div className="c-popup__close" onClick={onCancel}><img src="../images/icon-close.svg" alt="Close" /></div>
                {icon && <div className="c-popup__icon">
                    <img src={hancleIMG(icon)} alt="Icon" />
                </div>}
                {icon && icon === true ? <div className="c-popup__icon"><img src="../images/icon-popup.svg" alt="Icon" /></div> : ''}
                {title && title !== null ? <h3 className="c-popup__title">{title}</h3> : ''}
                {subTitle && subTitle !== null ? <p className="c-popup__ttl">{subTitle}</p> : ''}
                {children && children !== null ? <div className="c-popup__content">{children}</div> : ''}                
                <div className="c-popup__btn">
                    <Button type='greyBorder' title='Cancel' onClick={onCancel} />                    
                    {btnTitle === 'Delete' ? <div className="c-btn c-btn--primary" onClick={onClick}>Delete</div> : 
                    <Button title={btnTitle} type='primary' onClick={onClick} />}
                </div>
            </div>
        </div>
    )
}

export const NoData = () => {
    return(
        <div className="c-noData">
            <img src="../images/nodata.svg" alt="No Data" />
        </div>
    )
}

export const Loading = ({time}) => {
    return(
        <div className="c-loading">
            <div className="c-loading__box">
                {time ? <img src={`../images/loading-${time}s.gif`} alt="Loading" /> : <img src="../images/loading-3s.gif" alt="Loading" />}
            </div>
        </div>
    )
}

export const DataLoading = ({title}) => (
    <div className="c-popup">
        <div className="c-popup__box" style={{width: "800px"}}>
            <h3 className="c-popup__title">CAUTION !!!</h3>
            <div className="c-popup__content">
                <div className="c-uploadFile__box">
                    <img src="../images/loading-3s.gif" alt="Loading" width="500px" />
                    <h3 className="c-popup__title" style={{textTransform: "uppercase"}}>{title}...</h3>
                    <p style={{color: "red"}}>Please wait and don't close this window ultil completed!</p>
                </div>
            </div>             
        </div>
    </div>
)