import React, { useEffect, useState, useRef } from 'react';
import { Title, Button, Loading } from '../component';
import { Select, Form } from "antd";
import allActions from '../../actions';
import {useSelector, useDispatch} from 'react-redux';
import { useSearchParams, useNavigate } from 'react-router-dom';
import { Store } from 'react-notifications-component';
import addNotify from '../common/Notify/addNotify';
import WinnerListTable from './winnerList';
import { formatData } from '../../utils/StringHelper';
import {dataStatus, Tokens} from '../../constants'; 
import axios from 'axios';  

const WinnerList = () => {
    const { Option } = Select;
    const dispatch = useDispatch();
    const [isLoading, setIsLoading] = useState(false);
    const [isUpload, setIsUpload] = useState(false);
    let [searchParams, setSearchParams] = useSearchParams();
    const inputFileUpload = useRef();
    let airdropId = searchParams.get("airdrop");
    const [campaignSelected, setCampaignSelected] = useState(airdropId);
    const [form] = Form.useForm();
    const airdropList = useSelector(state => state.airdrop.airdropList);
    const airdropWhiteList = useSelector(state => state.airdrop.airdropWhiteList);
    
    let totalStandardCommit = 0;
    airdropWhiteList && airdropWhiteList.map((item, index) => {
        totalStandardCommit += item?.totalAmount;
    });

    useEffect(() => {
        dispatch(allActions.airdropAction.getAirdrop());
    },[campaignSelected]);

    

    const onChangCampaign = (id) => {
        if(id) {
            dispatch(allActions.airdropAction.getAddressWhiteList(id));
            setSearchParams({'airdrop': id});
            setCampaignSelected(id);
        }
    }

    const onChangeFileUpload = (e) => { 
        setIsLoading(true);
        let file = e.target.files[0];
        const formData = new FormData();
        formData.append("file", file);
        formData.append("airdropId", airdropId);

        axios({
            method: 'post',
            url: process.env.REACT_APP_API_URL + `/airdrops/${airdropId}/upload-white-lists`,
            headers: {
                'accept': '*/*',
                "Authorization": `Bearer ${localStorage.getItem(Tokens.ADMIN)}`,
                "Content-Type": "multipart/form-data"
            },
            data: formData,
        }).then(res => {
            console.log("res", res)
            if(res.status === 200 && res.data === dataStatus.SUCCESS){
                dispatch(allActions.airdropAction.getAddressWhiteList(airdropId));
                setTimeout(() => {
                    Store.addNotification(addNotify('File upload successfully!', 'success'));
                    setIsUpload(false);
                    setIsLoading(false);
                }, 3000);
            }
            else {
                setIsLoading(false);
                setIsUpload(false);
                Store.addNotification(addNotify('File upload failed!', 'danger'));
                return;
            }
        }).catch(err => {
            setIsLoading(false);
            setIsUpload(false);
            Store.addNotification(addNotify('File upload failed! ' + err?.response?.data?.title, 'danger'));
            return;
        });
    }

    return(
        <>
        {isUpload ? 
        (<div className="c-popup">
                <div className="c-popup__box">
                    <div className="c-popup__close" onClick={() => setIsUpload(false)}><img src="../images/icon-close.svg" alt="Close" /></div>
                    <h3 className="c-popup__title">Upload priority list</h3>
                    <div className="c-popup__content">
                        <div className="c-uploadFile">
                            <Form
                                name="basic"
                                form={form}
                                labelCol={{ span: 8 }}
                                wrapperCol={{ span: 16 }} 
                                autoComplete="off"
                                onSubmit = {e => e.preventDefault()}
                            >
                                <div className="c-uploadFile__box" onClick={() => inputFileUpload.current.click()}>
                                    <img src="../images/icon-cloud-upload.svg" alt="Upload" />
                                    <p>Choose file here</p>
                                </div>

                                <input ref={inputFileUpload} onChange={onChangeFileUpload} type="file" /> 
                            </Form>
                        </div>
                    </div>             
                </div>
            </div>) : ''}
        <div className="p-whitelist">
            {isLoading && <Loading />}
            <div className="p-whitelist__head">
                <Title title='Winner list' subTitle='' />
                <div className="p-whitelist__btn">
                    <Button title='Upload' type='primary' iconLeft='Upload' onClick={() => setIsUpload(true)}/>
                        <Button title='Template' type='flat' iconLeft='Download' onClick={() => window.location.href = 'https://review-image-app.s3.ap-southeast-1.amazonaws.com/1664331328035-airdrop_wl_list.xlsx'}/>
                </div>
            </div>
            <div className="p-whitelist__boxHead">
                <div className="c-select">
                    <p className="c-select__title">Event</p>
                        <Select className="c-selectChange" defaultValue={airdropList ? parseInt(campaignSelected) : null} onChange={(value) => onChangCampaign(value)} style={{width: 260}} placeholder='Select Campaign'>
                            {airdropList && airdropList.length > 0 && airdropList.map((item, index) => (
                            <Option key={index} value={item.id}>{item.title}</Option>
                        ))}
                    </Select>
                </div> 
                <ul>
                    <li>
                        <span>Total Address<img src="../images/icon-gps.svg" alt="Total Address" /></span>
                        {airdropWhiteList && airdropWhiteList.length}
                    </li>
                    <li className="is-active">
                        <span>Total Amount<img src="../images/icon-coin.svg" alt="Total Amount" /></span>
                        {formatData(totalStandardCommit, '')}
                    </li>
                </ul>
            </div>
                <WinnerListTable airdropId={airdropId} title='Winner List' data={airdropWhiteList} />
        </div>
        </>
    )
}

export default WinnerList;