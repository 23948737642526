import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { NoData, Button, Loading, Popup, InputCustom } from '../component';
import { Form, Input } from 'antd';
import allActions from '../../actions';
import {useDispatch} from 'react-redux';
import addNotify from '../common/Notify/addNotify';
import { Store } from 'react-notifications-component';

const InoInfomation = ({data, inoCampaign}) => {
    const [isLoad, setIsLoad] = useState(false);
    const [addInfo, setAddInfo] = useState(false);
    const { TextArea } = Input;
    const dispatch = useDispatch();
    let navigate = useNavigate();

    const onFinish = (values) => {
        values.inoLaunchpad = inoCampaign;
        values.description = values.description ? values.description : null;
        values.facebook = values.facebook ? values.facebook : null;        
        values.logoLink = inoCampaign.logoLink;
        values.title = inoCampaign.title;
        values.medium = values.medium ? values.medium : null;
        values.other = values.other ? values.other : null;
        values.telegram = values.telegram ? values.telegram : null;
        values.twitter = values.twitter ? values.twitter : null;
        
        setIsLoad(true);
        dispatch(allActions.inoAction.createInfomationIno(values, (response) => {
            if(response.status === 201){  
                setIsLoad(false);
                window.location.reload();
            }
            else{
                Store.addNotification(addNotify(response.title, 'danger')); 
                setIsLoad(false);
            }  
        }));
    }

    return(
        <>
            {isLoad ? <Loading /> : ''} 
            <div className="c-inoBoxInfo">
                <div className="c-inoBoxInfo__head">
                    <h3 className="c-ttl is-font16">INO Infomation</h3>
                    <div className="c-inoBoxInfo__btn">
                        {data === null ? <Button title='Add INO Infomation' type='flat' iconLeft='BoxAdd' onClick={() => setAddInfo(true)}/>
                        : <Button title='Edit INO Infomation' type='blue' onClick={() => navigate(`/ino-campaigns/${inoCampaign.handle}/info/${data?.id}`)} />}                        
                    </div>
                </div>
                <div className="c-inoBoxInfo__box">
                    {data === null ? <NoData /> : <>
                        <div className="c-inoBoxInfo__info">
                            <span>Project’s Infor</span>
                            <p className="c-inoBoxInfo__text">{data?.description}</p>
                        </div>
                        <div className="c-inoBoxInfo__info">
                            <span>Channel Media</span>
                            <ul>
                                <li>
                                    <span><img src="../images/icon-website.svg" alt="" />Website</span>
                                    <p>{data?.other}</p>
                                </li>
                                <li>
                                    <span><img src="../images/icon-telegram.svg" alt="" />Telegram</span>
                                    <p>{data?.telegram}</p>
                                </li>
                                <li>
                                    <span><img src="../images/icon-twitter.svg" alt="" />Twitter</span>
                                    <p>{data?.twitter}</p>
                                </li>
                                <li>
                                    <span><img src="../images/icon-medium.svg" alt="" />Medium</span>
                                    <p>{data?.medium}</p>
                                </li>                          
                                <li>
                                    <span><img src="../images/icon-facebook.svg" alt="" />Facebook</span>
                                    <p>{data?.facebook}</p>
                                </li>
                            </ul>
                        </div>
                    </>}
                </div>
            </div>
            {addInfo ? <Form name="basic" onFinish={onFinish}>
                <Popup title='Add INO Infomation' btnTitle='Add INO Infomation' onCancel={() => setAddInfo(false)}>
                    <Form.Item name="description">
                        <TextArea rows={2} placeholder='Desciption' />
                    </Form.Item>
                    <Form.Item name="other">
                        <InputCustom placeholder='Link Website' />
                    </Form.Item>
                    <Form.Item name="telegram">
                        <InputCustom placeholder='Link Telegram' />
                    </Form.Item>
                    <Form.Item name="twitter">
                        <InputCustom placeholder='Link Twitter' />
                    </Form.Item>
                    <Form.Item name="facebook">
                        <InputCustom placeholder='Link Facebook' />
                    </Form.Item>
                    <Form.Item name="medium">
                        <InputCustom placeholder='Link Medium' />
                    </Form.Item>
                </Popup>
            </Form> : ''}
        </>
    )
}

export default InoInfomation;