import React, { useState, useEffect, useRef } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Button, InputCustom, Loading } from '../component';
import { Select, Form, Upload } from 'antd';
import allActions from '../../actions';
import { useSelector, useDispatch } from 'react-redux';
import { UploadIcon } from '../common/asset/icon';
import { Tokens } from '../../constants';
import axios from 'axios';

const TokenInformEdit = () => {
    const [isLoad, setIsLoad] = useState(false);
    const dispatch = useDispatch();
    let navigate = useNavigate();
    let { handle } = useParams();
    const [form] = Form.useForm();
    const { Option } = Select;
    const tokenInformData = useSelector(state => state.tokenInform);
    const [imageData, setImageData] = useState({ url: tokenInformData?.logoURL, name: tokenInformData?.name });
    const inputRef = useRef();
    useEffect(() => {
        dispatch(allActions.tokenInformAction.getTokenInformById(handle));
    }, []);
    useEffect(() => {
        form.setFieldsValue(tokenInformData);
        setImageData({ url: tokenInformData?.logoURL, name: tokenInformData?.name });
    }, [tokenInformData]);

    const onChangeImage = (e) => {
        let file = e.target.files[0];
        const formData = new FormData();
        formData.append("file", file);
        axios({
            method: 'post',
            url: process.env.REACT_APP_API_URL + `/upload-to-s3`,
            headers: {
                'accept': '*/*',
                "Authorization": `Bearer ${localStorage.getItem(Tokens.ADMIN)}`,
                "Content-Type": "multipart/form-data"
            },
            data: formData,
        }).then(res => {
            setImageData({ url: res.data, name: file.name });
        }).catch(err => {
            console.log(err);
        });
    }

    const onFinish = (values) => {
        let imgUrl = imageData.url === null ? values.logoURL : imageData.url;
        values.tokenId = String(values.tokenId);
        values.decimals = String(values.decimals);
        values.logoURL = imgUrl;
        values.totalSupply = parseInt(values.totalSupply);
        setIsLoad(true);
        dispatch(allActions.tokenInformAction.updateTokenInform(values, (res) => {
            if (res.callResult) {
                dispatch(allActions.tokenInformAction.getTokenInformList());
                setIsLoad(false);
            }
        }));
    };

    return (
        <>
            {isLoad ? <Loading /> : ''}
            <Form form={form} name="basic" onFinish={onFinish}>
                <div className="c-popup">
                    <div className="c-popup__box">
                        <div className="c-popup__close" onClick={() => navigate('/token-inform')}><img src="../images/icon-close.svg" alt="Close" /></div>
                        <div className="c-popup__icon">
                            <img src="../images/icon-popup-add.svg" alt="Icon" />
                        </div>
                        <h3 className="c-popup__title">Edit Token Inform</h3>
                        <div className="c-popup__content">
                            <Form.Item name="id" className="l-displayNone">
                                <InputCustom type="hidden" />
                            </Form.Item>
                            <Form.Item name="logoURL">
                                <input ref={inputRef} type='file' onChange={(e) => onChangeImage(e)} />
                                {imageData.url ? <div onClick={() => inputRef.current.click()} className="c-img">
                                    <img src={imageData.url} alt="" />{imageData.name}</div> :
                                    <div onClick={() => inputRef.current.click()} className="c-uploadIMG">
                                        <div className="c-btn c-btn--flat">
                                            <UploadIcon />Upload Image Token
                                        </div>
                                    </div>
                                }
                            </Form.Item>
                            <Form.Item name="tokenId">
                                <InputCustom placeholder='Token ID' />
                            </Form.Item>
                            <Form.Item name="tokenAddress">
                                <InputCustom placeholder='Token address' />
                            </Form.Item>
                            <Form.Item name="symbol">
                                <InputCustom placeholder='Symbol' />
                            </Form.Item>
                            <Form.Item name="decimals">
                                <Select className="c-selectChange" placeholder='Decimals'>
                                    <Option value='picoether'>6</Option>
                                    <Option value='1e8'>8</Option>
                                    <Option value='nanoether'>9</Option>
                                    <Option value='microether'>12</Option>
                                    <Option value='ether'>18</Option>
                                </Select>
                            </Form.Item>
                            <Form.Item name="totalSupply">
                                <InputCustom placeholder='Total supply' />
                            </Form.Item>
                        </div>
                        <div className="c-popup__btn">
                            <div className="c-btn c-btn--greyBorder" onClick={() => navigate('/token-inform')}>Cancel</div>
                            <Button title='Edit' type='primary' htmlType='submit' />
                        </div>
                    </div>
                </div>
            </Form>
        </>
    )
}

export default TokenInformEdit;