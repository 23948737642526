export const GET_BANNER_ID    = "GET_BANNER_ID";

export const GET_BANNER_LIST   = "GET_BANNER_LIST"; 

export const GET_AIRDROP_BY_HANDLE = "GET_AIRDROP_BY_HANDLE";

export const GET_AIRDROP_LIST = "GET_AIRDROP_LIST";

export const GET_MARKET_LIST = "GET_MARKET_LIST";

export const GET_MARKETGAME_BY_ID = "GET_MARKETGAME_BY_ID";

export const GET_CATALOG_LIST = "GET_CATALOG_LIST";



