import React, {useState, useEffect} from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { InputCustom, Button, Toggle, Title, Loading } from '../component';
import { Select, DatePicker, Form, Switch, Radio, Input, InputNumber } from 'antd';
import allActions from '../../actions';
import {useSelector, useDispatch} from 'react-redux';
import { Plus } from '../common/asset/icon';
import { Store } from 'react-notifications-component';
import addNotify from '../common/Notify/addNotify';
import moment from 'moment';
const RoundAdd = () => { 
    const [isLoad, setIsLoad] = useState(false);
    const [timeTable, setTimeTable] = useState(true);
    // const [refundBtn, setRefundBtn] = useState(true);
    const [allowRefund, setAllowRefund] = useState(false);
    const [allowRefundEspecial, setAllowRefundEspecial] = useState(false);
    const [isChangeRound, setIsChangeRound] = useState(); 
    const dispatch = useDispatch();
    let navigate = useNavigate();
    let { handle } = useParams();
    const { Option } = Select;
    const { TextArea } = Input;
    const { RangePicker } = DatePicker;
    const [form] = Form.useForm();
    const campaignData = useSelector(state => state.campaigns?.campaignOne);
    
    useEffect(() => {
        if(handle) {
            dispatch(allActions.campaignAction.getCampaignById(handle));
        }
    },[]);
    
    useEffect(() => {
        form.setFieldsValue({
            id: 0,
            campaignId: parseInt(handle),
            activated: true,
            phase: 1,
            slots: parseInt(0),
            allowRefund: true,
            refundDuration: 2,
            claimType: "MONTHLY",
            modelType: 2,

        });
        setAllowRefund(true);
        
    },[]);

    const onFinishFailed = (errorInfo) => { 
        if(errorInfo.errorFields.length>0)
        {
            errorInfo?.errorFields.map((e,i )=>{ 
                Store.addNotification(addNotify(e.errors[0], 'danger')); 
            }) 
        } 
    }; 
    const onFinish = (values) => { 
        if(!Boolean(values.allowRefund) && Boolean(values.refundEspecialAllow)) {
            Store.addNotification(addNotify('Please enable allowRefund!', 'danger'));
            setIsLoad(false);
            return;
        }
       
        values.activated = true; 
        values.priority = parseInt(values.priority);
        values.phase = parseInt(values.phase);
        values.processBar = parseInt(values.processBar);
        values.roundType = parseInt(values.roundType);
        values.totalRaise = parseInt(values.totalRaise);
        if (timeTable === true) {
            values.idoDate = null
            values.startJoinPool = null
            values.endJoinPool = null
            values.claimDate = null
        }
        else {
            let startJoinPool = values.startJoinPool && values.startJoinPool[0];
            let endJoinPool = values.startJoinPool && values.startJoinPool[1];
            values.startJoinPool = startJoinPool;
            values.endJoinPool = endJoinPool;
            values.startJoinPool = values.startJoinPool ? values.startJoinPool._d.toISOString() : null;
            values.endJoinPool = values.endJoinPool ? values.endJoinPool._d.toISOString() : null;
            values.claimDate = values.claimDate ? values.claimDate._d.toISOString() : null;
            values.idoDate = values.idoDate ? values.idoDate._d.toISOString() : null;
        }

        values.addressClaimIdo = values.addressClaimIdo ? values.addressClaimIdo : null;
        values.addressCommited = values.addressCommited ? values.addressCommited : null;
        values.addressRemaningFund = values.addressRemaningFund ? values.addressRemaningFund : null;
        values.addressClaimDaily = values.addressClaimDaily ? values.addressClaimDaily : null;
        values.allowRefund = Boolean(values.allowRefund);
        values.refundDuration = Boolean(values.allowRefund) ? (values.refundDuration ? parseInt(values.refundDuration) : 2) : 0;
        values.announcement = values.announcement ? values.announcement : null;
        
        values.claimType = values.claimType ? values.claimType : null;
        values.commitRangeMaxPercent = values.commitRangeMaxPercent ? values.commitRangeMaxPercent : 100;
        values.commitRangeMinPercent = values.commitRangeMinPercent ? values.commitRangeMinPercent : 100;
        
        values.phase = values.phase ? values.phase : null;
        values.priority = values.priority ? values.priority : null;
        values.processBar = values.processBar ? values.processBar : null;
        values.refundDuration = values.refundDuration ? values.refundDuration : null;
        values.roundName = values.roundName ? values.roundName : null;
        values.roundType = values.roundType ? values.roundType : null;
        
        values.totalRaise = values.totalRaise ? values.totalRaise : null;
        values.vesting = values.vesting ? values.vesting : null;
        
        values.timeTable = values.timeTable ? values.timeTable : false;

        if(campaignData?.modelType === 1){  
            for (let index = 0; index < values.vestingInfos.length; index++) {
               values.vestingInfos[index].allowRefund = values.vestingInfos[index].allowRefund ? values.vestingInfos[index].allowRefund: false;
               values.vestingInfos[index].id = 0;   
               values.vestingInfos[index].refundDuration = values.vestingInfos[index].refundDuration ? Number(values.vestingInfos[index].refundDuration) :0; 
                 
               values.vestingInfos[index].vestingPercent =Number(values.vestingInfos[index].vestingPercent);
                // values.vestingInfos[index].refundDuration =Number(values.vestingInfos[index].refundDuration);
            } 
            values.vestingInfos = values.vestingInfos;
        }
        else{
            values.vestingSchedules = JSON.stringify(values.vestingSchedules);
            values.vestingSchedules = values.vestingSchedules ? values.vestingSchedules : null;
        }
        

        setIsLoad(true);
        // Round = 1,3,4 OR phase = 2
        if(((values.phase === 1 && values.roundType !== 2) || values.phase === 2)) {
            values.slots = parseInt(values.slots);
        } else {
            values.slots = 0;
        }    

        if(values.startJoinPool < values.idoDate) {
            Store.addNotification(addNotify('Please select start Join Pool Date >= Ido Date', 'danger'));
            setIsLoad(false);
            return;
        }
        if(values.endJoinPool > values.claimDate) {
            Store.addNotification(addNotify('Please select Claim Date >= End Join Pool Date', 'danger'));
            setIsLoad(false);
            return;
        }

        console.log(":values", values)

        dispatch(allActions.roundAction.createRound(values, (response) => {
            if(response.status === 201){  
                navigate('/campaign-info/'+handle); 
                setIsLoad(false);
            }
            else{
                Store.addNotification(addNotify(response.title, 'danger')); 
                setIsLoad(false);
            }  
        })); 
    
    }
    
    const handleChangeRound = (value) => {
        setIsChangeRound(value);
    }

    return(
        <>
        {isLoad ? <Loading /> : ''}  
        <Form onFinishFailed={onFinishFailed} form={form} name="basic" initialValues={{ remember: true }} onFinish={onFinish}>
        <Form.Item name="activated" className="l-displayNone">
            <InputCustom type="hidden" />
        </Form.Item>
        <Form.Item name="id" className="l-displayNone">
            <InputCustom type="hidden" />
        </Form.Item>
        <Form.Item name="campaignId" className="l-displayNone">
            <InputCustom type="hidden" />
        </Form.Item>
        <div className="p-roundAdd">
            <div className="p-roundAdd__head">
                <Title title='Round' subTitle='List of rounds added to the campaign' />
            </div>
            <div className="p-roundAdd__body">
                <h3 className="c-ttl"><span>Campaign:</span>{campaignData?.title}</h3>
                <div className="c-round">
                    <div className="c-round__head">
                        <div className="c-round__row is-col3">
                            <Form.Item name="roundName" rules={[{ required: true, message: 'Round Name is required!' }]}>
                                <InputCustom title='Round Name' />
                            </Form.Item>
                            <div className="c-select">
                                <p className="c-select__title">Round Type</p>
                                <Form.Item name="roundType" rules={[{ required: true, message: 'Round Type is required!' }]}>
                                    <Select className="c-selectChange" onChange={handleChangeRound} placeholder="Please Select Round Type">
                                        <Option value={1}>Community</Option>
                                        <Option value={2}>Guaranteed</Option>
                                        <Option value={3}>Private / Most Value KDG Hold</Option>
                                        <Option value={4}>Most Value KDG Builder</Option>
                                    </Select>
                                </Form.Item> 
                            </div>
                            <div className="c-select">
                                <p className="c-select__title">Phase</p>
                                <Form.Item name="phase" rules={[{ required: true, message: 'Phase is required!' }]}>
                                    <Select className="c-selectChange" placeholder="Please Select Phase">
                                        <Option value={1}>1</Option>
                                        <Option value={2}>2</Option>
                                    </Select>
                                </Form.Item>
                            </div>
                        </div>
                    </div>
                    <div className="c-round__body is-open">
                        <div className="c-round__content">
                            <div className="c-round__row">
                                <Form.Item name="addressCommited">
                                    <InputCustom title='Address Commited' placeholder='Address Commited' />
                                </Form.Item>
                                <Form.Item name="addressRemaningFund">
                                    <InputCustom title='Address Remaning Fund' placeholder='Address Remaning Fund' />
                                </Form.Item>
                                {campaignData?.modelType === 2 && <Form.Item name="addressClaimIdo">
                                    <InputCustom title='Address Claim IDO' placeholder='Address Claim IDO' />
                                </Form.Item>}
                                
                                <Form.Item name="addressClaimDaily">
                                    <InputCustom title='Address Claim Daily' placeholder='Address Claim Daily' />
                                </Form.Item>
                                <Form.Item name="processBar">
                                    <InputCustom title='Progress Bar' placeholder='Progress Bar' />
                                </Form.Item>
                                <Form.Item name="totalRaise">
                                    <InputCustom title={`Total Raise (${campaignData?.acceptCurrency})`} placeholder={`Total Raise (${campaignData?.acceptCurrency})`} />
                                </Form.Item>
                                <Form.Item name="idoRate" >
                                    <InputCustom title={`IDO Rate (${campaignData?.acceptCurrency})`} placeholder={`IDO Rate (${campaignData?.acceptCurrency})`} />
                                </Form.Item>
                                <Form.Item 
                                    name="commitRangeMinPercent"
                                    rules={[{ 
                                        required: false, 
                                        message: "Commit Min Percent wrong format",
                                        pattern: new RegExp(/^\d*(\.\d+)?$/)
                                    }]}  
                                >
                                    <InputCustom title='Commit Min Percent (%)'  placeholder='100' />
                                </Form.Item>
                                <Form.Item 
                                    name="commitRangeMaxPercent" 
                                    rules={[{ 
                                        required: false, 
                                        message: "Commit Max Percent wrong format",
                                        pattern: new RegExp(/^\d*(\.\d+)?$/)
                                    }]} 
                                >
                                    <InputCustom title='Commit Max Percent (%)' placeholder='100' />
                                </Form.Item>
                                <Form.Item name="slots">
                                    <InputCustom title='Slots' note="<p>Setup Slots for: <br/>1. Phase=1, Type=Community<br/>2. Phase=1, Type=Private/MVKH<br/>3. Phase=1, Type=MVKB<br/>4. Phase=2, Type=(Any) </p>"/>
                                </Form.Item>
                                <Form.Item name="priority">
                                    <InputCustom title='Priority' type='number' placeholder='Priority' />
                                </Form.Item>
                                <Form.Item name="announcement">
                                    <InputCustom title='Annoucement' placeholder='Enter annoucement' />
                                </Form.Item>
                            </div>
                            <div className="c-round__row">
                                <div className="c-round__vesting">
                                    <h3 className="c-ttl">Vesting</h3>
                                    <div className="c-round__box"> 
                                            <div className="c-toggle">
                                                <Form.Item name="allowRefund" valuePropName="checked" className="c-toggle__cont">
                                                    <Switch className="c-toggle__custom" onChange={(e) => setAllowRefund(e)}/>
                                                </Form.Item>
                                                {campaignData?.modelType === 1 
                                                ?<p className="c-toggle__title">Allow refund all at TGE ?</p>
                                                :<p className="c-toggle__title">Allow refund?</p>}  
                                            </div>
                                            {allowRefund && <Form.Item name="refundDuration">
                                                <InputCustom placeholder="Default value: 2h" title='Refund Duration (hours)' type='number' />
                                            </Form.Item>} 
                                            <div className="c-radio">
                                                <p className="c-radio__title">Claim Type</p>
                                                <Form.Item name="claimType" className="c-radio__custom">
                                                    <Radio.Group>
                                                        <Radio value="MONTHLY">Monthly</Radio>
                                                        <Radio value="DAILY">Daily</Radio>
                                                    </Radio.Group>
                                                </Form.Item>
                                            </div>  
                                        <div className="c-textarea">
                                            <p className="c-textarea__title">Vesting Text</p>
                                            <Form.Item name="vesting" className="c-textarea__custom">
                                                <TextArea />
                                            </Form.Item>
                                        </div>
                                        <div className="c-repeater">
                                            <h3 className="c-repeater__title">Vesting Schedule (UTC)</h3> 
                                            {campaignData?.modelType === 1 ?  <Form.List name="vestingInfos" >
                                                {(fields, { add, remove }) => (
                                                <>
                                                    {fields.map(({ key, name, ...restField }) => (
                                                    <div className='bg_vestingteam'>
                                                        <div className="c-repeater__row" key={key}>
                                                            <span className="c-repeater__remove" onClick={() => remove(name)}>
                                                                <img src="../images/icon-remove.svg" alt="Remove" />
                                                            </span>
                                                            <div className="c-date is-edit">
                                                                <Form.Item rules={[{ required: true, message: 'time is required!' }]} {...restField} name={[name, 'vestingTime']}>
                                                                    <input className="c-dateCustom" type="datetime-local" />
                                                                </Form.Item>
                                                            </div>
                                                            <Form.Item rules={[{ required: true, message: 'percentage is required!' }]} {...restField} name={[name, 'vestingPercent']}>
                                                                <InputCustom placeholder='Add Vesting' />
                                                            </Form.Item>   
                                                        </div>
                                                            <Form.Item {...restField} name={[name, 'vestingSc']}>
                                                                <InputCustom  title='SC Claim' placeholder='SC claim' />
                                                            </Form.Item>
                                                            
                                                            <Form.Item {...restField} name={[name, 'refundDuration']}>
                                                                <InputCustom title='Refund Duration (hours)' type='number' />
                                                            </Form.Item> 
                                                            <div className="c-toggle mt-10">
                                                                <Form.Item {...restField} name={[name, 'allowRefund']} valuePropName="checked" className="c-toggle__cont">
                                                                    <Switch className="c-toggle__custom" /> 
                                                                </Form.Item> 
                                                                <p className="c-toggle__title">ON/OFF refund button</p>
                                                            </div> 
                                                            
                                                    </div>
                                                    ))}
                                                    <div className="c-btn c-btn--flat" onClick={() => add()}><Plus /> Add Vesting Schedule</div>
                                                </>
                                                )}
                                            </Form.List> :  <Form.List name="vestingSchedules" >
                                                {(fields, { add, remove }) => (
                                                <>
                                                    {fields.map(({ key, name, ...restField }) => (
                                                    <div className="c-repeater__row" key={key}>
                                                        <span className="c-repeater__remove" onClick={() => remove(name)}>
                                                            <img src="../images/icon-remove.svg" alt="Remove" />
                                                        </span>
                                                        <div className="c-date is-edit">
                                                            <Form.Item rules={[{ required: true, message: 'time is required!' }]} {...restField} name={[name, 'time']}>
                                                                <input className="c-dateCustom" type="datetime-local" />
                                                            </Form.Item>
                                                        </div>
                                                        <Form.Item rules={[{ required: true, message: 'percentage is required!' }]} {...restField} name={[name, 'percentage']}>
                                                            <InputCustom placeholder='Add Vesting' />
                                                        </Form.Item>
                                                    </div>
                                                    ))}
                                                    <div className="c-btn c-btn--flat" onClick={() => add()}><Plus /> Add Vesting Schedule</div>
                                                </>
                                                )}
                                            </Form.List> }  
                                        </div>
                                    </div>
                                </div>
                                <div className="c-round__timeTable">
                                    <h3 className="c-ttl">Round Timetable</h3>
                                    <div className="c-round__box">
                                        <div className="c-toggle">
                                            <Form.Item name="timeTable" valuePropName="checked" className="c-toggle__cont">
                                                <Switch  defaultChecked className="c-toggle__custom" onChange={(e) => setTimeTable(e)} /> 
                                            </Form.Item> 
                                            <p className="c-toggle__title">Use campaign timetable</p>
                                        </div>
                                        {timeTable ? null : <>
                                            <div className="c-date">
                                                <p className="c-date__title">UpComing Date (GMT+7)</p>
                                                <Form.Item name="idoDate">
                                                    <DatePicker showTime format='HH:mm:ss DD-MM-YYYY' />
                                                </Form.Item>
                                            </div>
                                            <div className="c-date">
                                                <p className="c-date__title">[Start-End] Join Pool Date (GMT+7)</p>
                                                <Form.Item name="startJoinPool">
                                                    <RangePicker showTime format='HH:mm:ss DD-MM-YYYY' />
                                                </Form.Item>
                                            </div>
                                            <div className="c-date">
                                                <p className="c-date__title">Claim Date (GMT+7)</p>
                                                <Form.Item name="claimDate">
                                                    <DatePicker showTime format='HH:mm:ss DD-MM-YYYY' />
                                                </Form.Item>
                                            </div>
                                        </>}
                                    </div>
                                </div>
                            </div>
                            <div className="c-round__row">
                                <div className="c-round__refund-especial">
                                    <h3 className="c-ttl">Refund Especial</h3>
                                    <div className="c-round__box">
                                        <div className="c-toggle">
                                            <Form.Item name="refundEspecialAllow" valuePropName="checked" className="c-toggle__cont">
                                                <Switch className="c-toggle__custom" onChange={(e) => setAllowRefundEspecial(e)}/>
                                            </Form.Item>
                                            <p className="c-toggle__title">Allow refund especial ?</p>
                                        </div>
                                        {allowRefundEspecial && <>
                                        <div className="c-round__refund-especial__col">
                                            <div className="c-toggle">
                                                <Form.Item name="refundEspecialClaimedAllowed" valuePropName="checked" className="c-toggle__cont">
                                                    <Switch className="c-toggle__custom" />
                                                </Form.Item>
                                                <p className="c-toggle__title">Allow who claimed ?</p>
                                            </div>
                                            <div className="c-date">
                                                <p className="c-date__title">Refund duration (UTC)</p>
                                                <Form.Item name="refundEspecialDuration">
                                                    <RangePicker showTime format='HH:mm:ss DD-MM-YYYY' />
                                                </Form.Item>
                                            </div>
                                        </div>
                                        <div className="c-round__refund-especial__col">
                                            <div className="c-date">
                                                <Form.Item 
                                                    name="refundEspecialPercent" 
                                                    rules={[{ 
                                                        required: false, 
                                                        message: "Commit Max Percent wrong format",
                                                        pattern: new RegExp(/^\d*(\.\d+)?$/)
                                                    }]} 
                                                >
                                                    <InputCustom title='Refund Especial Percent (%)' placeholder='100' />
                                                </Form.Item>
                                            </div>
                                        </div>
                                        
                                        </>}
                                    </div>
                                    
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="p-roundAdd__btn">
                <Button title='Cancel' type='grey' />
                <Button title='Submit' type='blue'/>
            </div>
        </div>
        </Form>
        </>
    )
}

export default RoundAdd;