import React from 'react';

export const BoxAdd = () => {
    return(
        <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M23.207 18C23.207 18.75 22.997 19.46 22.627 20.06C22.417 20.42 22.147 20.74 21.837 21C21.137 21.63 20.217 22 19.207 22C17.987 22 16.897 21.45 16.177 20.59C16.157 20.56 16.127 20.54 16.107 20.51C15.987 20.37 15.877 20.22 15.787 20.06C15.417 19.46 15.207 18.75 15.207 18C15.207 16.74 15.787 15.61 16.707 14.88C17.397 14.33 18.267 14 19.207 14C20.207 14 21.107 14.36 21.807 14.97C21.927 15.06 22.037 15.17 22.137 15.28C22.797 16 23.207 16.95 23.207 18Z" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M20.6968 17.98H17.7168" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M19.207 16.52V19.51" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M3.37695 7.43994L12.207 12.5499L20.977 7.46991" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M12.207 21.61V12.54" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M21.8177 9.17V14.83C21.8177 14.88 21.8177 14.92 21.8077 14.97C21.1077 14.36 20.2077 14 19.2077 14C18.2677 14 17.3977 14.33 16.7077 14.88C15.7877 15.61 15.2077 16.74 15.2077 18C15.2077 18.75 15.4177 19.46 15.7877 20.06C15.8777 20.22 15.9877 20.37 16.1077 20.51L14.2777 21.52C13.1377 22.16 11.2776 22.16 10.1376 21.52L4.79765 18.56C3.58765 17.89 2.59766 16.21 2.59766 14.83V9.17C2.59766 7.79 3.58765 6.11002 4.79765 5.44002L10.1376 2.48C11.2776 1.84 13.1377 1.84 14.2777 2.48L19.6177 5.44002C20.8277 6.11002 21.8177 7.79 21.8177 9.17Z" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
    )
}

export const Plus = () => {
    return(
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
            <path d="M6 12H18" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M12 18V6" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
    )
}

export const ArrowLeft = () => {
    return(
        <svg xmlns="http://www.w3.org/2000/svg" width="25" height="24" viewBox="0 0 25 24" fill="none">
            <path d="M15.4998 19.9201L8.97984 13.4001C8.20984 12.6301 8.20984 11.3701 8.97984 10.6001L15.4998 4.08008" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
    )
}

export const ArrowRight = () => {
    return(
        <svg xmlns="http://www.w3.org/2000/svg" width="25" height="24" viewBox="0 0 25 24" fill="none">
            <path d="M9.20719 4.07992L15.7272 10.5999C16.4972 11.3699 16.4972 12.6299 15.7272 13.3999L9.20719 19.9199" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
    )
}

export const Upload = () => {
    return(
        <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M15.1465 6.56006L12.5865 4.00006L10.0265 6.56006" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M12.5859 14.2402L12.5859 4.07023" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M20.707 12.1802C20.707 16.6002 17.707 20.1802 12.707 20.1802C7.70703 20.1802 4.70703 16.6002 4.70703 12.1802" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
    )
}

export const UploadIcon = () => {
    return(
        <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M15.1465 6.56006L12.5865 4.00006L10.0265 6.56006" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M12.5859 14.2402L12.5859 4.07023" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M20.707 12.1802C20.707 16.6002 17.707 20.1802 12.707 20.1802C7.70703 20.1802 4.70703 16.6002 4.70703 12.1802" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
    )
}

export const Edit = () => {
    return(
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M11 2H9C4 2 2 4 2 9V15C2 20 4 22 9 22H15C20 22 22 20 22 15V13" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M16.0399 3.01976L8.15988 10.8998C7.85988 11.1998 7.55988 11.7898 7.49988 12.2198L7.06988 15.2298C6.90988 16.3198 7.67988 17.0798 8.76988 16.9298L11.7799 16.4998C12.1999 16.4398 12.7899 16.1398 13.0999 15.8398L20.9799 7.95976C22.3399 6.59976 22.9799 5.01976 20.9799 3.01976C18.9799 1.01976 17.3999 1.65976 16.0399 3.01976Z" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M14.9102 4.1499C15.5802 6.5399 17.4502 8.4099 19.8502 9.0899" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
    )
}

export const Download = () => {
    return(
        <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M9.52734 11.6802L12.0873 14.2402L14.6473 11.6802" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M12.0879 4V14.17" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M20.207 12.1802C20.207 16.6002 17.207 20.1802 12.207 20.1802C7.20703 20.1802 4.20703 16.6002 4.20703 12.1802" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
    )
}

export const Trash = () => {
    return(
        <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M21.377 5.97998C18.047 5.64998 14.697 5.47998 11.357 5.47998C9.37695 5.47998 7.39695 5.57998 5.41695 5.77998L3.37695 5.97998" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M8.87695 4.97L9.09695 3.66C9.25695 2.71 9.37695 2 11.067 2H13.687C15.377 2 15.507 2.75 15.657 3.67L15.877 4.97" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M19.2273 9.14014L18.5773 19.2101C18.4673 20.7801 18.3773 22.0001 15.5873 22.0001H9.16734C6.37734 22.0001 6.28734 20.7801 6.17734 19.2101L5.52734 9.14014" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M10.707 16.5H14.037" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M9.87695 12.5H14.877" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
    )
}

export const Refresh = () => {
    return(
        <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M22.207 12.5C22.207 18.02 17.727 22.5 12.207 22.5C6.68703 22.5 3.31703 16.94 3.31703 16.94M3.31703 16.94H7.83703M3.31703 16.94V21.94M2.20703 12.5C2.20703 6.98 6.64703 2.5 12.207 2.5C18.877 2.5 22.207 8.06 22.207 8.06M22.207 8.06V3.06M22.207 8.06H17.767" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
    )
}

export const Unlock = () => {
    return(
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
            <path d="M10.0195 17.52C10.4795 18.11 11.1995 18.5 11.9995 18.5C13.3795 18.5 14.4995 17.38 14.4995 16C14.4995 15.43 14.3095 14.9 13.9895 14.48" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M2.82 20.8C2.21 20.04 2 18.83 2 17V15C2 11 3 10 7 10H17C17.36 10 17.69 10.01 18 10.03C21.17 10.21 22 11.36 22 15V17C22 21 21 22 17 22H7C6.64 22 6.31 21.99 6 21.97" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M6 10V8C6 4.69 7 2 12 2C16.15 2 17.54 3.38 17.9 5.56" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M22 2L2 22" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
    )
}

export const Export = () => {
    return(
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
            <path d="M12 2V9L14 7" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M12 9L10 7" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M1.98047 13H6.39047C6.77047 13 7.11047 13.21 7.28047 13.55L8.45047 15.89C8.79047 16.57 9.48047 17 10.2405 17H13.7705C14.5305 17 15.2205 16.57 15.5605 15.89L16.7305 13.55C16.9005 13.21 17.2505 13 17.6205 13H21.9805" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M7 4.12988C3.46 4.64988 2 6.72988 2 10.9999V14.9999C2 19.9999 4 21.9999 9 21.9999H15C20 21.9999 22 19.9999 22 14.9999V10.9999C22 6.72988 20.54 4.64988 17 4.12988" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
    )
}

export const Claim = () => {
    return(
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
            <path d="M18.04 13.55C17.62 13.96 17.38 14.55 17.44 15.18C17.53 16.26 18.52 17.05 19.6 17.05H21.5V18.24C21.5 20.31 19.81 22 17.74 22H7.63C7.94 21.74 8.21 21.42 8.42 21.06C8.79 20.46 9 19.75 9 19C9 16.79 7.21 15 5 15C4.06 15 3.19 15.33 2.5 15.88V11.51C2.5 9.44001 4.19 7.75 6.26 7.75H17.74C19.81 7.75 21.5 9.44001 21.5 11.51V12.95H19.48C18.92 12.95 18.41 13.17 18.04 13.55Z" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M2.5 12.4103V7.84035C2.5 6.65035 3.23 5.5903 4.34 5.1703L12.28 2.1703C13.52 1.7003 14.85 2.62033 14.85 3.95033V7.75032" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M22.5588 13.9702V16.0302C22.5588 16.5802 22.1188 17.0302 21.5588 17.0502H19.5988C18.5188 17.0502 17.5288 16.2602 17.4388 15.1802C17.3788 14.5502 17.6188 13.9602 18.0388 13.5502C18.4088 13.1702 18.9188 12.9502 19.4788 12.9502H21.5588C22.1188 12.9702 22.5588 13.4202 22.5588 13.9702Z" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M7 12H14" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M9 19C9 19.75 8.79 20.46 8.42 21.06C8.21 21.42 7.94 21.74 7.63 22C6.93 22.63 6.01 23 5 23C3.54 23 2.27 22.22 1.58 21.06C1.21 20.46 1 19.75 1 19C1 17.74 1.58 16.61 2.5 15.88C3.19 15.33 4.06 15 5 15C7.21 15 9 16.79 9 19Z" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M6.49171 18.9795H3.51172" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M5 17.5195V20.5095" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
    )
}