
import {
    GET_BANNER_LIST, 
    GET_BANNER_ID,
    GET_AIRDROP_LIST,
    GET_AIRDROP_BY_HANDLE,
    GET_WHITELIST_BY_ID
} from "../actions/airdropType";

const initialState = [];

function airdropReducer(airdrop = initialState, action) {
    const { type, payload } = action;  
    switch (type) {
        case GET_BANNER_LIST:
            return {...airdrop, bannerList: payload}; 
        case GET_BANNER_ID:
            return {...airdrop, bannerById: payload}; 
        case GET_AIRDROP_LIST:
            return {...airdrop, airdropList: payload}; 
        case GET_AIRDROP_BY_HANDLE:
            return {...airdrop, airdropInfo: payload};
        case GET_WHITELIST_BY_ID:
            return { ...airdrop, airdropWhiteList: payload }; 
        default:
            return airdrop;
    }
};

export default airdropReducer;