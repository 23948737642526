import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Title, Button, NoData, Popup, Loading } from '../component';
import { Select, Table } from 'antd';
import allActions from '../../actions';
import {useSelector, useDispatch} from 'react-redux';


const Ino = () => {
    const [dataIno, setDataIno] = useState({});
    const [deleteItem, setDeleteItem] = useState(null);
    const [isLoad, setIsLoad] = useState(false);
    const { Option } = Select;
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const inoList = useSelector(state => state.ino?.inoListCampaign);
    const inoAll = useSelector(state => state.ino?.inoListAll);

    useEffect(() => {
        dispatch(allActions.inoAction.getInoLuanchpad());
        dispatch(allActions.inoAction.getInoAll());
    },[]);

    useEffect(() => {
        setDataIno(inoList);
    },[inoList]);

    const columns = [
        {
            title: 'Campaign Name',
            dataIndex: 'title',
            render: (_, record) => (
                <Link to={`/ino-campaigns/${record.handle}`}>
                <div className="c-nameTable">
                    <div className="c-nameTable__logo"><img src={record.logoLink} alt={record.title} /></div>
                    <p className="c-nameTable__title">{record.title}</p>
                </div>
                </Link>
            ),
            ellipsis: true,
        }, {
            title: 'Token ADDRESS',
            dataIndex: 'paymentContract',
            ellipsis: true,
            render: (text) => <>{text ? text.length > 8 ? `${text.substring(0, 4)}...${text.substring(text.length - 4)}` : text : '--'}</>,
        }, {
            title: 'PHASES',
            dataIndex: 'phase',
            ellipsis: true,
            render: (text) => <>{text ? <>Phases {text}</> : '--'}</>,
        }, {
            title: 'action',
            dataIndex: 'action',
            render: (_, record) => (
                <div className="c-actionTable">
                    <div className="c-actionTable__box">
                        <img src="../images/icon-more.svg" alt="" />
                        <ul className="c-actionTable__content">
                            <li><Link to={`/ino-edit/${record.handle}`}>Edit</Link></li>
                            <li><Link to={`/ino-campaigns/${record.handle}`}>Information</Link></li>
                            <li><span>Whitelist</span></li>
                            <li onClick={() => setDeleteItem(record.id)}><span>Delete</span></li>
                        </ul>
                    </div>
                </div>
            )
        }
    ];

    const handleChange = (value) => {
        if(value) {
            dispatch(allActions.inoAction.searchCampaignIno(value));
        }
    };

    return(
        <>
        {isLoad ? <Loading /> : ''} 
        <div className="p-inoInfo">
            <div className="p-inoInfo__head">
                <Title title='INO Campaign' subTitle='List of campaign that admin added' />
                <Button title='Add INO Campaign' type='primary' iconLeft='BoxAdd' onClick={() => navigate("/ino-add")} />
            </div>
            <div className="c-table">
                <div className="c-table__head">
                    <Select className="c-selectChange" style={{width: 210}} placeholder='Select a campaign' onChange={handleChange}>
                        {inoAll && inoAll.length > 0 && inoAll.map((item,key) => {
                            return(
                                <Option key={key} value={item.title}>{item.title}</Option>
                            )
                        })}                        
                    </Select>
                    <Button title='Export' type='blueBorder' iconRight='Export'/>
                </div>
                {dataIno && dataIno.length > 0 ? <Table rowKey='id' columns={columns} dataSource={dataIno} /> : <NoData />}
                
            </div>            
        </div>
        {deleteItem && <Popup 
            title='Do you want delete this campaign?' 
            subTitle='You can’t restore this file' 
            btnTitle='Delete' icon='Delete' 
            onCancel={() => setDeleteItem(null)}
            onClick={() => {
                dispatch(allActions.inoAction.deleteCampaignIno(deleteItem));
                setIsLoad(true);
                setTimeout(() => {
                    window.location.reload(true);
                }, 3000);}
            }
        />}
        </>
    )
}

export default Ino;