import React, { useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { InputCustom, Button, Popup, NoData, Loading } from '../component';
import { Form, Input } from 'antd';
import { UploadIcon } from '../common/asset/icon';
import allActions from '../../actions';
import {useDispatch} from 'react-redux';
import axios from 'axios';
import { Tokens } from '../../constants';
import addNotify from '../common/Notify/addNotify';
import { Store } from 'react-notifications-component';

const InoBox = ({data, inoCampaign}) => {
    const [imageData, setImageData] = useState({ url: null, name: null});
    const [addBoxes, setAddBoxes] = useState(false);
    const [isLoad, setIsLoad] = useState(false);
    const [deleteItem, setDeleteItem] = useState(null);
    const { TextArea } = Input;
    const inputRef = useRef();
    const dispatch = useDispatch();
    let navigate = useNavigate();

    const onChangeImage = (e) => {  
        let file = e.target.files[0];
        const formData = new FormData();
        formData.append("file", file); 
        axios({
            method: 'post',
            url: process.env.REACT_APP_API_URL + `/upload-to-s3`,
            headers: {
                'accept': '*/*',
                "Authorization": `Bearer ${localStorage.getItem(Tokens.ADMIN)}`,
                "Content-Type": "multipart/form-data"
            },
            data: formData,
        }).then(res => {
            setImageData({url: res.data, name: file.name});
        }).catch(err => {
            console.log(err);
        });   
    }

    const onFinish = (values) => {
        let imgUrl = imageData.url === null ? values.imageLink : imageData.url;

        values.inoLaunchpad = inoCampaign;
        values.imageLink = imgUrl;
        values.name = values.name ? values.name : null;
        values.total = values.total ? parseInt(values.total) : null;
        values.currency = values.currency ? values.currency : null;
        values.price = values.price ? parseInt(values.price) : null;
        values.desciption = values.desciption ? values.desciption : null;
        values.soldOut = values.soldOut ? parseInt(values.soldOut) : null;
        
        setIsLoad(true);
        dispatch(allActions.inoAction.createBoxesIno(values, (response) => {
            if(response.status === 201){  
                setIsLoad(false);
                window.location.reload();
            }
            else{
                Store.addNotification(addNotify(response.title, 'danger')); 
                setIsLoad(false);
            }  
        }));

        console.log('values',values);
    }

    const boxContract = inoCampaign?.boxContract ? `${inoCampaign?.boxContract.substring(0, 4)}...${inoCampaign?.boxContract.substring(inoCampaign?.boxContract.length - 4)}` : '--';
    return(
        <>
        {isLoad ? <Loading /> : ''} 
        <div className="c-inoBox">
            <div className="c-inoBox__head">
                <h3 className="c-ttl is-font16">Box</h3>
                <div className="c-inoBox__btn">
                    <Button title='Whitelist' type='blue' />
                    <Button title='Add Box' type='flat' iconLeft='BoxAdd' onClick={() => setAddBoxes(true)} />
                </div>
            </div>
            <div className="c-inoBox__box">
                {data && data.length > 0 ? data.map((item, key) => (
                    <div className="c-inoBox__item" key={key} data={item.id}>
                        <div className="c-inoBox__img">
                            <img src={item?.imageLink} alt={item?.name} />
                            <div className="c-inoBox__btn">
                                <Button title='Delete' type='flat' onClick={() => setDeleteItem(item.id)} />
                                <Button title='Edit' type='blue' onClick={() => navigate(`/ino-campaigns/${inoCampaign.handle}/boxes/${item.id}`)} />                                    
                            </div>
                        </div>
                        <p className="c-inoBox__info">
                            <span>Box’s Name</span>
                            {item?.name}
                        </p>
                        <p className="c-inoBox__info">
                            <span>Box Contract</span>
                            {boxContract}
                        </p>
                        <p className="c-inoBox__info">
                            <span>Total Sale</span>
                            {item?.total}
                        </p>
                        <p className="c-inoBox__info">
                            <span>Price / Box</span>
                            {item?.price} {item?.currency}
                        </p>
                        <div className="c-inoBox__des">
                            <span>Description</span>
                            <p>{item?.description ? item?.description : '--'}</p>
                        </div>
                    </div>
                )) : <NoData />}
            </div>
        </div>

        {addBoxes ? <Form name="basic" onFinish={onFinish}>
            <Popup title='Add Box' btnTitle='Add Box' onCancel={() => setAddBoxes(false)}>

                <input ref={inputRef} type='file' onChange={(e) => onChangeImage(e)} />
                {imageData.url ? <div onClick={()=>inputRef.current.click()} className="c-img"><img src={imageData.url} alt="" />{imageData.name}</div> : 
                <div className="c-uploadIMG"> 
                    <div onClick={()=>inputRef.current.click()} className="c-btn c-btn--flat">
                        <UploadIcon />Upload Image
                    </div>
                </div>
                }

                <Form.Item name="name">
                    <InputCustom placeholder='Box name' />
                </Form.Item>
                <Form.Item name="total">
                    <InputCustom placeholder='Total sale' />
                </Form.Item>
                <Form.Item name="currency">
                    <InputCustom placeholder='Currency' />
                </Form.Item>
                <Form.Item name="price">
                    <InputCustom placeholder='Price' />
                </Form.Item>
                <Form.Item name="description">
                    <TextArea rows={2} placeholder='Desciption' />
                </Form.Item>
                <Form.Item name="soldOut">
                    <InputCustom placeholder='Sold Out' />
                </Form.Item>
            </Popup>
        </Form> : ''}

        {deleteItem && <Popup 
            title='Do you want delete this boxes?' 
            subTitle='You can’t restore this file' 
            btnTitle='Delete' icon='Delete' 
            onCancel={() => setDeleteItem(null)}
            onClick={() => {
                dispatch(allActions.inoAction.deleteBoxesIno(deleteItem));
                setIsLoad(true);
                setTimeout(() => {
                    window.location.reload(true);
                }, 3000);}
            }
        />}
        </>
    )
}

export default InoBox;