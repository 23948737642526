import React, { useState, useRef, useEffect } from 'react';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import { Button, InputCustom, NoData, Popup, Loading } from '../component';
import { Input, Form } from 'antd';
import allActions from '../../actions';
import {useSelector, useDispatch} from 'react-redux';
import addNotify from '../common/Notify/addNotify';
import { Store } from 'react-notifications-component';
import { UploadIcon } from '../common/asset/icon';
import axios from 'axios';
import { Tokens } from '../../constants';

const InoBoxEdit = () => {
    const [imageData, setImageData] = useState({ url: null, name: null});
    const [isLoad, setIsLoad] = useState(false);
    const [form] = Form.useForm();
    const { TextArea } = Input;
    const inputRef = useRef();
    const dispatch = useDispatch();
    let navigate = useNavigate();
    let { handle } = useParams();
    let location = useLocation();
    let myPathname = location.pathname; 
    const sep = /\//;
    let isPathname = myPathname.split(sep); 

    const inoBoxesEdit = useSelector(state => state.ino?.inoBoxesById);

    const inoDetail = useSelector(state => state.ino?.inoInfo);
    const inoCampaign = useSelector(state => state.ino?.inoById);

    useEffect(() => {
        if(isPathname[2]) {
            dispatch(allActions.inoAction.getInoInfo(isPathname[2]));
        }        
    },[isPathname[2]]);    

    useEffect(() => {
        if(inoDetail?.id) {
            dispatch(allActions.inoAction.getInoById(inoDetail?.id));
        }
    },[inoDetail?.id]);

    useEffect(() => {
        if(handle) {
            dispatch(allActions.inoAction.getInoBoxesById(handle));
        }
    },[handle]);

    useEffect(() => {
        if(inoBoxesEdit) {
            form.setFieldsValue(inoBoxesEdit);
            setIsLoad(false);
        }        
    },[inoBoxesEdit]);

    const onChangeImage = (e) => {  
        let file = e.target.files[0];
        const formData = new FormData();
        formData.append("file", file); 
        axios({
            method: 'post',
            url: process.env.REACT_APP_API_URL + `/upload-to-s3`,
            headers: {
                'accept': '*/*',
                "Authorization": `Bearer ${localStorage.getItem(Tokens.ADMIN)}`,
                "Content-Type": "multipart/form-data"
            },
            data: formData,
        }).then(res => {
            setImageData({url: res.data, name: file.name});
        }).catch(err => {
            console.log(err);
        });   
    }

    const onFinishEdit = (values) => {
        setIsLoad(true);
        let imgUrl = imageData.url === null ? values.imageLink : imageData.url;

        values.id = parseInt(handle);
        values.inoLaunchpad = inoCampaign;
        values.imageLink = imgUrl;
        values.name = values.name ? values.name : null;
        values.total = values.total ? parseInt(values.total) : null;
        values.currency = values.currency ? values.currency : null;
        values.price = values.price ? parseInt(values.price) : null;
        values.description = values.description ? values.description : null;
        values.soldOut = values.soldOut ? parseInt(values.soldOut) : null;

        dispatch(allActions.inoAction.editBoxesIno(values, (response) => {
            if(response.status === 200){  
                setIsLoad(false);
                navigate(`/ino-campaigns/${isPathname[2]}`)
            }
            else{
                Store.addNotification(addNotify(response.title, 'danger')); 
                setIsLoad(false);
            }  
        }));
    }

    return(
        <>
        {isLoad ? <div className="c-loading">
            <div className="c-loading__box">
                <img src="../../../images/loading-3s.gif" alt="Loading" />
            </div>
        </div> : ''} 
        <Form form={form} name="basic" onFinish={onFinishEdit}>
            <div className="c-popup">
                <div className="c-popup__box">
                    <div className="c-popup__close" onClick={() => navigate(`/ino-campaigns/${isPathname[2]}`)}><img src="../../../images/icon-close.svg" alt="Close" /></div>
                    <h3 className="c-popup__title">Edit Boxes</h3>
                    <div className="c-popup__content">
                        <Form.Item name="imageLink">
                        <input ref={inputRef} type='file' onChange={(e) => onChangeImage(e)} />
                        {imageData.url ? <div onClick={()=>inputRef.current.click()} className="c-img"><img src={imageData.url} alt="" />{imageData.name}</div> : 
                        <div className="c-uploadIMG"> 
                            <div onClick={()=>inputRef.current.click()} className="c-btn c-btn--flat">
                                <UploadIcon />Upload Image
                            </div>
                        </div>
                        }
                        </Form.Item>
                        <Form.Item name="name">
                            <InputCustom placeholder='Box name' />
                        </Form.Item>
                        <Form.Item name="total">
                            <InputCustom placeholder='Total sale' />
                        </Form.Item>
                        <Form.Item name="currency">
                            <InputCustom placeholder='Currency' />
                        </Form.Item>
                        <Form.Item name="price">
                            <InputCustom placeholder='Price' />
                        </Form.Item>
                        <Form.Item name="description">
                            <TextArea rows={2} placeholder='Desciption' />
                        </Form.Item>
                        <Form.Item name="soldOut">
                            <InputCustom placeholder='Sold Out' />
                        </Form.Item>
                    </div>          
                    <div className="c-popup__btn">
                        <div className="c-btn c-btn--greyBorder" onClick={() => navigate(`/ino-campaigns/${isPathname[2]}`)}>Cancel</div>
                        <Button title='Edit Rule' type='primary' htmlType='submit' />
                    </div>
                </div>
            </div>
        </Form>
        </>
    )
}

export default InoBoxEdit;