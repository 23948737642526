import {
    GET_TOKEN_INFORM_LIST,
    GET_TOKEN_INFORM_BY_ID,
    UPDATE_TOKEN_INFORM,
    CREATE_TOKEN_INFORM,
    DELETE_TOKEN_INFORM
} from "../actions/tokenInformType";

const initialState = [];

function tokenInformReducer(tokenInform = initialState, action) {
    const { type, payload } = action;

    switch (type) {
        case GET_TOKEN_INFORM_LIST:
            return payload;

        case GET_TOKEN_INFORM_BY_ID:
            return payload;

        case UPDATE_TOKEN_INFORM:
            return payload;

        case CREATE_TOKEN_INFORM:
                return payload;

        case DELETE_TOKEN_INFORM:
            return payload;

        default:
            return tokenInform;
    }
};

export default tokenInformReducer;