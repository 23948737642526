import React, { useState, useEffect } from 'react';
import { Excel } from 'antd-table-saveas-excel';
import { Title, Search, Button, Popup, NoData, Loading } from '../component';
import { Tabs, Table } from 'antd';
import allActions from '../../actions';
import {useSelector, useDispatch} from 'react-redux';
import { formatDateTime } from '../../utils/formatDatetime';

const columnsErrorExport = [
    {
        title: 'No.',
        dataIndex: 'key',
        ellipsis: true,
    }, {
        title: 'time',
        dataIndex: 'time',
        ellipsis: true,
        sorter: {
            compare: (a, b) => new Date(a.time) - new Date(b.time),
        },
        render: (text) => <>{formatDateTime(text)}</>
    }, {
        title: 'address',
        dataIndex: 'address',
        ellipsis: true,
    }, {
        title: 'Hashid',
        dataIndex: 'hash',
        ellipsis: true,
        render: (text) => <a href={text} target="_blank" rel="noreferrer noopener">{text}</a>,
    }, {
        title: 'MESSAGE',
        dataIndex: 'message',
        ellipsis: true,
    }
];

const columnsLockExport = [
    {
        title: 'No.',
        dataIndex: 'key',
        ellipsis: true,
    }, {
        title: 'time',
        dataIndex: 'time',
        ellipsis: true,
        render: (text) => <>{formatDateTime(text)}</>
    }, {
        title: 'address',
        dataIndex: 'address',
        ellipsis: true,
    }, {
        title: 'TYPE',
        dataIndex: 'status',
        ellipsis: true,
        render: (text) => <>{text ? 'Lock' : 'Unlock'}</>
    }
];

const SystemAdmin = () => {
    const [isUnlock, setIsUnlock] = useState(null);
    const [isLoader, setIsLoader] = useState(false);
    const [searchError, setSearchError] = useState('');
    const [searchLock, setSearchLock] = useState('');
    const [errorListDataTable, setErrorListDataTable] = useState([]);
    const [lockListDataTable, setLockListDataTable] = useState([]);
    const { TabPane } = Tabs;
    const dispatch = useDispatch();

    const errorListData = useSelector(state => state.systemAdmin.errorList);
    const lockListData = useSelector(state => state.systemAdmin.lockList);

    const columnsError = [
        {
            title: 'No.',
            dataIndex: 'key',
            ellipsis: true,
        }, {
            title: 'time',
            dataIndex: 'time',
            ellipsis: true,
            sorter: {
                compare: (a, b) => new Date(a.time) - new Date(b.time),
            },
            render: (text) => <>{formatDateTime(text)}</>
        }, {
            title: 'address',
            dataIndex: 'address',
            ellipsis: true,
            render: (text) => <>{text ? text.length > 8 ? `${text.substring(0, 4)}...${text.substring(text.length - 4)}` : text : '--'}</>,
        }, {
            title: 'Hashid',
            dataIndex: 'hash',
            ellipsis: true,
            render: (text) => <a href={text} target="_blank" rel="noreferrer noopener">{text}</a>,
        }, {
            title: 'MESSAGE',
            dataIndex: 'message',
            ellipsis: true,
        }
    ];

    const columnsLock = [
        {
            title: 'No.',
            dataIndex: 'key',
            className: 'is-padd16',
            ellipsis: true,
        }, {
            title: 'time',
            dataIndex: 'time',
            className: 'is-padd16',
            ellipsis: true,
            render: (text) => <>{formatDateTime(text)}</>
        }, {
            title: 'address',
            dataIndex: 'address',
            className: 'is-padd16',
            ellipsis: true,
            render: (text) => <>{text ? text.length > 8 ? `${text.substring(0, 4)}...${text.substring(text.length - 4)}` : text : '--'}</>,
        }, {
            title: 'TYPE',
            dataIndex: 'status',
            ellipsis: true,
            className: 'is-padd16',
            render: (_, record) => (
                <Button title='Unlock' type='flat' iconLeft='Unlock' onClick={() => setIsUnlock(record.id)}/>
            ),
        }
    ];

    useEffect(() => {
        dispatch(allActions.systemAdminAction.getSystemErrorList());
        dispatch(allActions.systemAdminAction.getSystemLockList());
    },[]);

    useEffect(() => {        
        setErrorListDataTable(errorListData);
        setLockListDataTable(lockListData);
    },[errorListData, lockListData]);

    const onUnlock = () => {
        setIsLoader(true);
        if(isUnlock) {
            dispatch(allActions.systemAdminAction.systemChangeStatus(isUnlock, (res) => {
                if(res.changeStatusResult === true) {
                    window.location.reload(true);
                }
                
            }));
        }
    }

    const onSearchError = () => {
        if (searchError !== '') {
            const data = {
                "address": searchError.toLowerCase()
            };
            dispatch(allActions.systemAdminAction.searchErrorList(data));
            setErrorListDataTable(errorListData);
        } else {
            dispatch(allActions.systemAdminAction.getSystemErrorList());
            setErrorListDataTable(errorListData);
        }
    }

    const onSearchLock = () => {
        if (searchLock !== '') {
            const data = {
                "address": searchLock.toLowerCase()
            };
            dispatch(allActions.systemAdminAction.searchLockList(data));
            setLockListDataTable(lockListData);
        } else {
            dispatch(allActions.systemAdminAction.getSystemLockList());
            setLockListDataTable(lockListData);
        }
    }

    const handleErrorListExport = () => {
        const excel = new Excel();
        excel
        .addSheet("Error List")
        .addColumns(columnsErrorExport)
        .addDataSource(errorListData, {
            str2Percent: true
        })
        .saveAs("errorList.xlsx");
    };

    const handleLockListExport = () => {
        const excel = new Excel();
        excel
        .addSheet("Lock List")
        .addColumns(columnsLockExport)
        .addDataSource(lockListData, {
            str2Percent: true
        })
        .saveAs("lockList.xlsx");
    };

    return(
        <>
        {isLoader ? <Loading /> : ''}
        <div className="p-systemAdmin">
            <Title title='System Admin' subTitle='Manage the list of locked and failed wallets' />
            <div className="p-systemAdmin__tabs">
                <Tabs className="c-tabs" defaultActiveKey="1">
                    <TabPane tab="Error List" key="1">
                        <div className="c-table c-table--systemAdmin">
                            <div className="c-table__head">
                                <Search
                                    placeholder='Search address'
                                    onChange={(e) => setSearchError(e.target.value)}
                                    onKeyDown={(e) => {
                                        if (e.code === "Enter") {
                                            onSearchError();                                    
                                        }
                                    }}
                                />
                                <Button title='Export' type='blueBorder' iconRight='Export' onClick={handleErrorListExport}/>
                            </div>
                            {errorListDataTable && errorListDataTable.length > 0 ? <Table rowKey='id' columns={columnsError} dataSource={errorListDataTable} /> : <NoData /> }
                        </div>
                    </TabPane>
                    <TabPane tab="Lock List" key="2">
                        <div className="c-table c-table--systemAdmin">
                            <div className="c-table__head">
                                <Search
                                    placeholder='Search address'
                                    onChange={(e) => setSearchLock(e.target.value)}
                                    onKeyDown={(e) => {
                                        if (e.code === "Enter") {
                                            onSearchLock();                                    
                                        }
                                    }}
                                />
                                <Button title='Export' type='blueBorder' iconRight='Export' onClick={handleLockListExport}/>
                            </div>
                            {lockListDataTable && lockListDataTable.length > 0 ? <Table rowKey='id' columns={columnsLock} dataSource={lockListDataTable} /> : <NoData /> }
                        </div>
                    </TabPane>
                </Tabs>
            </div>
        </div>
        {isUnlock ? <Popup
            title='Do you want unlock address?'
            subTitle='You can’t restore this file'
            icon="Delete"
            btnTitle="Unlock"
            onCancel={() => setIsUnlock(null)}
            onClick={() => onUnlock()}
        /> : ''}
        </>
    )
}

export default SystemAdmin;