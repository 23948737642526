import http from "./http-common";

const getSystemErrorList = (payload) => {
  return http.get("/error-lists", { params: payload });
};

const getSystemLockList = (payload) => {
  return http.get("/lock-lists", { params: payload });
};

const systemChangeStatus = (id) => {
  return http.post(`/lock-lists/${id}`);
};

const searchErrorList = (payload) => {
  return http.post('/error-lists/search', payload);
};

const searchLockList = (payload) => {
  return http.post('/lock-lists/search', payload);
};

const SystemAdminService = {
    getSystemErrorList,
    getSystemLockList,
    systemChangeStatus,
    searchErrorList,
    searchLockList
};
export default SystemAdminService;