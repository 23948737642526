import http from "./http-common";

const getWhitelistByRoundId = (roundId, type) => {
  return http.get(`round/${roundId}/white-list?type=${type}`);
};

const generateSignatureCommitAll = (payload) => {
  return http.post(`white-lists/round/gen-sign-join`, payload);
};

const generateSignatureCommitOne = (payload) => {
  return http.post(`/white-lists/gen-sign`, payload);
};

const generateWhitelist = (payload) => {
  return http.post(`/white-lists/generate`, payload);
};

const recalculate = (payload) => {
  return http.post(`/white-lists/recalculate`, payload);
};

const searchWhitelist = (payload) => {  
  return http.get(`/whitelist/${payload.roundId}/${payload.address}`);
}; 

const generateSignatureEspecialRefund = (payload) => {  
  return http.post(`/whitelist/gen-sign-refund-especial`, payload);
}; 


const WhitelistService = {
  getWhitelistByRoundId,
  generateSignatureCommitAll,
  generateSignatureCommitOne,
  generateWhitelist,
  recalculate,
  searchWhitelist,
  generateSignatureEspecialRefund
};
export default WhitelistService;