import React from 'react';
import { Title, Button } from '../component';
import { Tabs, Select, Pagination } from 'antd';
import TokenSchedule from './tokenSchedule';

const ClaimTokenSchedule = () => {
    const { TabPane } = Tabs;
    const { Option } = Select;
    return(
        <div className="p-claimSchedule">
            <Title title="Claim Token Schedule" subTitle="Statistic vesting time to have total amount need, then sent token and enter hashID" />
            <div className="p-claimSchedule__tabs">
                <Tabs className="c-tabs" defaultActiveKey="1">
                    <TabPane tab="On Going Campaign" key="1">
                        <div className="p-claimSchedule__boxBtn">
                            <Select className="c-selectChange" style={{width: 260}} defaultValue='all'>
                                <Option value='all'>All Campaign</Option>
                                <Option value="Fado">Fado</Option>
                                <Option value="Midoland">Midoland</Option>
                            </Select>
                            <Button title='Export' type='blueBorder' iconRight='Export'/>
                        </div>
                        <ul className="p-claimSchedule__head">
                            <li>Round</li>
                            <li>claim time</li>
                            <li>Total amount<br/>needed</li>
                            <li>sent token</li>
                            <li>hashid</li>
                            <li>action</li>
                        </ul>
                        <TokenSchedule />
                        <TokenSchedule />
                        <div className="c-pagination">
                            <Pagination defaultCurrent={1} total={100} />
                        </div>
                    </TabPane>
                    <TabPane tab="End Campaign" key="2">
                        <div className="p-claimSchedule__boxBtn">
                            <Select className="c-selectChange" style={{width: 260}} defaultValue='all'>
                                <Option value='all'>All Campaign</Option>
                                <Option value="Fado">Fado</Option>
                                <Option value="Midoland">Midoland</Option>
                            </Select>
                            <Button title='Export' type='blueBorder' iconRight='Export'/>
                        </div>
                        <ul className="p-claimSchedule__head">
                            <li>Round</li>
                            <li>claim time</li>
                            <li>Total amount<br/>needed</li>
                            <li>sent token</li>
                            <li>hashid</li>
                            <li>action</li>
                        </ul>
                        <TokenSchedule />
                        <TokenSchedule />
                        <div className="c-pagination">
                            <Pagination defaultCurrent={1} total={100} />
                        </div>
                    </TabPane>
                </Tabs>
            </div>
        </div>
    )
}

export default ClaimTokenSchedule;