import InoDataService from "../services/InoService";
import {
    GET_ALL_INO,
    GET_INO_CAMPAIGN,
    GET_INO_BY_ID,
    GET_INO_INFO,
    CREATE_CAMPAIGN_INO,
    EDIT_CAMPAIGN_INO,
    DELETE_CAMPAIGN_INO,
    GET_BOXES_INO,
    GET_BOXES_INO_BY_ID,
    CREATE_BOXES_INO,
    EDIT_BOXES_INO,
    DELETE_BOXES_INO,
    CREATE_RULE_INO,
    GET_RULE_INO_BY_ID,
    EDIT_RULE_INO,
    DELETE_RULE_INO,
    CREATE_TIMELINE_INO,
    GET_TIMELINE_INO_BY_ID,
    EDIT_TIMELINE_INO,
    DELETE_TIMELINE_INO,
    CREATE_INFOMATION_INO,
    EDIT_INFOMATION_INO,
    GET_INFOMATION_INO_BY_ID,
    CREATE_SETTING_INO,
    GET_SETTING_INO_BY_ID,
    EDIT_SETTING_INO,
    DELETE_SETTING_INO,
    SEARCH_CAMPAIGN_INO,
    SEARCH_BOX_INO
} from "./inoType";

const getInoAll = (payload) => async (dispatch) => {
    try {
        const res = await InoDataService.getInoAll(payload);  
        dispatch({
            type: GET_ALL_INO,
            payload: res && res.data || []
        });
    } catch (err) {
        console.log(err) 
    }
}

const getInoLuanchpad = (payload) => async (dispatch) => {
    try {
        const res = await InoDataService.getInoLuanchpad(payload);  
        dispatch({
            type: GET_INO_CAMPAIGN,
            payload: res && res.data || []
        });
    } catch (err) {
        console.log(err) 
    }
}

const getInoById = (id) => async (dispatch) => {
    try {
        const res = await InoDataService.getInoById(id);  
        dispatch({
            type: GET_INO_BY_ID,
            payload: res && res.data || []
        });
    } catch (err) {
        console.log(err) 
    }
}

const getInoInfo = (handle) => async (dispatch) => {
    try {
        const res = await InoDataService.getInoInfo(handle);  
        dispatch({
            type: GET_INO_INFO,
            payload: res && res.data || []
        });
    } catch (err) {
        console.log(err) 
    }
}

const createCampaignIno = (payload, callback) => async (dispatch) => {
    try {
        const res = await InoDataService.createCampaignIno(payload);   
        dispatch({
            type: CREATE_CAMPAIGN_INO,
            payload: {}
        }); 
        callback(res);  
    } catch (err) { 
        callback(err.response.data);
    }
}

const editCampaignIno = (payload, callback) => async (dispatch) => { 
    try {  
      const res = await InoDataService.editCampaignIno(payload); 
        dispatch({
          type: EDIT_CAMPAIGN_INO,
          payload: {}
        });   
        callback(res)
    } catch (err) { 
      callback(err.response.data);
    }
}

const deleteCampaignIno = (id) => async (dispatch) => {
    try {
      const res = await InoDataService.deleteCampaignIno(id); 
      dispatch({
        type: DELETE_CAMPAIGN_INO,
        payload: {}
      });
      
    } catch (err) {
      console.log(err);
    }
}

const getInoBoxes = (payload) => async (dispatch) => {
    try {
        const res = await InoDataService.getInoBoxes(payload);  
        dispatch({
            type: GET_BOXES_INO,
            payload: res && res.data || []
        });
    } catch (err) {
        console.log(err) 
    }
}

const getInoBoxesById = (id) => async (dispatch) => {
    try {
        const res = await InoDataService.getInoBoxesById(id);  
        dispatch({
            type: GET_BOXES_INO_BY_ID,
            payload: res && res.data || []
        });
    } catch (err) {
        console.log(err) 
    }
}

const createBoxesIno = (payload, callback) => async (dispatch) => {
    try {
        const res = await InoDataService.createBoxesIno(payload);   
        dispatch({
            type: CREATE_BOXES_INO,
            payload: {}
        }); 
        callback(res);  
    } catch (err) { 
        callback(err.response.data);
    }
}

const editBoxesIno = (payload, callback) => async (dispatch) => { 
    try {  
      const res = await InoDataService.editBoxesIno(payload); 
        dispatch({
          type: EDIT_BOXES_INO,
          payload: {}
        });   
        callback(res)
    } catch (err) { 
      callback(err.response.data);
    }
}

const deleteBoxesIno = (id) => async (dispatch) => {
    try {
      const res = await InoDataService.deleteBoxesIno(id); 
      dispatch({
        type: DELETE_BOXES_INO,
        payload: {}
      });
      
    } catch (err) {
      console.log(err);
    }
}

const createRuleIno = (payload, callback) => async (dispatch) => {
    try {
        const res = await InoDataService.createRuleIno(payload);   
        dispatch({
            type: CREATE_RULE_INO,
            payload: {}
        }); 
        callback(res);  
    } catch (err) { 
        callback(err.response.data);
    }
}

const getInoRuleById = (id) => async (dispatch) => {
    try {
        const res = await InoDataService.getInoRuleById(id);  
        dispatch({
            type: GET_RULE_INO_BY_ID,
            payload: res && res.data || []
        });
    } catch (err) {
        console.log(err) 
    }
}

const editRuleIno = (payload, callback) => async (dispatch) => { 
    try {  
      const res = await InoDataService.editRuleIno(payload); 
        dispatch({
          type: EDIT_RULE_INO,
          payload: {}
        });   
        callback(res)
    } catch (err) { 
      callback(err.response.data);
    }
}

const deleteRuleIno = (id) => async (dispatch) => {
    try {
      const res = await InoDataService.deleteRuleIno(id); 
      dispatch({
        type: DELETE_RULE_INO,
        payload: {}
      });
      
    } catch (err) {
      console.log(err);
    }
}

const createTimelineIno = (payload, callback) => async (dispatch) => {
    try {
        const res = await InoDataService.createTimelineIno(payload);   
        dispatch({
            type: CREATE_TIMELINE_INO,
            payload: {}
        }); 
        callback(res);  
    } catch (err) { 
        callback(err.response.data);
    }
}

const getInoTimelineById = (id) => async (dispatch) => {
    try {
        const res = await InoDataService.getInoTimelineById(id);  
        dispatch({
            type: GET_TIMELINE_INO_BY_ID,
            payload: res && res.data || []
        });
    } catch (err) {
        console.log(err) 
    }
}

const editTimelineIno = (payload, callback) => async (dispatch) => { 
    try {  
      const res = await InoDataService.editTimelineIno(payload); 
        dispatch({
          type: EDIT_TIMELINE_INO,
          payload: {}
        });   
        callback(res)
    } catch (err) { 
      callback(err.response.data);
    }
}

const deleteTimelineIno = (id) => async (dispatch) => {
    try {
      const res = await InoDataService.deleteTimelineIno(id); 
      dispatch({
        type: DELETE_TIMELINE_INO,
        payload: {}
      });
      
    } catch (err) {
      console.log(err);
    }
}

const createInfomationIno = (payload, callback) => async (dispatch) => {
    try {
        const res = await InoDataService.createInfomationIno(payload);   
        dispatch({
            type: CREATE_INFOMATION_INO,
            payload: {}
        }); 
        callback(res);  
    } catch (err) { 
        callback(err.response.data);
    }
}

const editInfomationIno = (payload, callback) => async (dispatch) => { 
    try {  
      const res = await InoDataService.editInfomationIno(payload); 
        dispatch({
          type: EDIT_INFOMATION_INO,
          payload: {}
        });   
        callback(res)
    } catch (err) { 
      callback(err.response.data);
    }
}

const getInoInfomationById = (id) => async (dispatch) => {
    try {
        const res = await InoDataService.getInoInfomationById(id);  
        dispatch({
            type: GET_INFOMATION_INO_BY_ID,
            payload: res && res.data || []
        });
    } catch (err) {
        console.log(err) 
    }
}

const createSettingIno = (payload, callback) => async (dispatch) => {
    try {
        const res = await InoDataService.createSettingIno(payload);   
        dispatch({
            type: CREATE_SETTING_INO,
            payload: {}
        }); 
        callback(res);  
    } catch (err) { 
        callback(err.response.data);
    }
}

const getInoSettingById = (id) => async (dispatch) => {
    try {
        const res = await InoDataService.getInoSettingById(id);  
        dispatch({
            type: GET_SETTING_INO_BY_ID,
            payload: res && res.data || []
        });
    } catch (err) {
        console.log(err) 
    }
}

const editSettingIno = (payload, callback) => async (dispatch) => { 
    try {  
      const res = await InoDataService.editSettingIno(payload); 
        dispatch({
          type: EDIT_SETTING_INO,
          payload: {}
        });   
        callback(res)
    } catch (err) { 
      callback(err.response.data);
    }
}

const deleteSettingIno = (id) => async (dispatch) => {
    try {
      const res = await InoDataService.deleteSettingIno(id); 
      dispatch({
        type: DELETE_SETTING_INO,
        payload: {}
      });
      
    } catch (err) {
      console.log(err);
    }
}

const searchCampaignIno = (title) => async (dispatch) => {
    try {
        const res = await InoDataService.searchCampaignIno(title);  
        dispatch({
            type: SEARCH_CAMPAIGN_INO,
            payload: res && res.data || []
        });
    } catch (err) {
        console.log(err) 
    }
}

const searchBoxIno = (title) => async (dispatch) => {
    try {
        const res = await InoDataService.searchBoxIno(title);  
        dispatch({
            type: SEARCH_BOX_INO,
            payload: res && res.data || []
        });
    } catch (err) {
        console.log(err) 
    }
}

const inoAction = {
    getInoAll,
    getInoLuanchpad,
    getInoById,
    getInoInfo,
    createCampaignIno,
    editCampaignIno,
    deleteCampaignIno,
    getInoBoxes,
    getInoBoxesById,
    createBoxesIno,
    editBoxesIno,
    deleteBoxesIno,
    createRuleIno,
    getInoRuleById,
    editRuleIno,
    deleteRuleIno,
    createTimelineIno,
    getInoTimelineById,
    editTimelineIno,
    deleteTimelineIno,
    createInfomationIno,
    editInfomationIno,
    getInoInfomationById,
    createSettingIno,
    getInoSettingById,
    editSettingIno,
    deleteSettingIno,
    searchCampaignIno,
    searchBoxIno
};
  
export default inoAction;