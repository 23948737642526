export const formatDateTime = (dateTime) => {

  if(!dateTime) return "--";
  let _dateTime = new Date(dateTime);
  var options = { 
    // weekday: 'short', 
    year: 'numeric', 
    month: 'short', 
    day: 'numeric',
    hour: '2-digit', 
    minute: '2-digit',
    timeZoneName: 'short',
    timeZone: 'Etc/GMT',
    hour12: true
  };

  return _dateTime.toLocaleTimeString('en-GB', options);
}

export const formatDateTimeNotMs = (dateTime) => {

  if (!dateTime) return "--";
  let _dateTime = new Date(dateTime);
  var options = {
    // weekday: 'short', 
    year: 'numeric',
    month: 'short',
    day: 'numeric',
    hour: '2-digit',
    minute: '2-digit', 
    hour12: true
  };

  return _dateTime.toLocaleTimeString('en-GB', options);
}

export const formatDateTimetoISOString = (dateTime) => {

  console.log(dateTime);
  if(!dateTime) return "--";
  let _dateTime = new Date(dateTime);

  var options = { 
    // weekday: 'short', 
    year: 'numeric', 
    month: 'short', 
    day: 'numeric',
    hour: '2-digit', 
    minute: '2-digit',
    timeZoneName: 'short',
    timeZone: 'Etc/GMT',
    hour12: true
  };
  _dateTime.toLocaleTimeString('en-GB', options);
  console.log(_dateTime);
  console.log(_dateTime.toISOString());
  return _dateTime.toISOString();
}

export const formatShortDateTime = (dateTime) => {
  if(!dateTime) return "--";
  let _dateTime = new Date(dateTime);
  var options = { 
    // weekday: 'short', 
    year: 'numeric', 
    month: 'short', 
    day: 'numeric',
    hour: '2-digit', 
    minute: '2-digit',
    timeZone: 'Etc/GMT',
    // timeZoneName: 'short',
    hour12: false
  };

  return _dateTime.toLocaleTimeString('en-GB', options);
}

export const formatShortDateEnUs = (dateTime) => {
  if(!dateTime) return "--";
  let _dateTime = new Date(dateTime);
  var options = { 
    year: 'numeric', 
    month: 'short', 
    day: 'numeric',
    timeZone: 'Etc/GMT'
  };

  return _dateTime.toLocaleDateString('en-US', options);
}

export const formatShortDateAndTime = (dateTime) => {
  if(!dateTime) return "--";
  
  let _dateTime = new Date(dateTime);
  var options = { 
    year: 'numeric', 
    month: 'short', 
    day: 'numeric',
    timeZone: 'Etc/GMT',
  };
  var optionTime = { hour: '2-digit', minute: '2-digit', timeZoneName: 'short', timeZone: 'Etc/GMT' };
  return _dateTime.toLocaleDateString('en-US', options) + " " + _dateTime.toLocaleTimeString([], optionTime);
}
