import React, {useState, useEffect} from 'react';
import { Link } from 'react-router-dom';
import { Title, Search, Button, Popup, Input, NoData } from '../component';
import { Tabs, Table, Select, Checkbox } from 'antd';
import allActions from '../../actions';
import {useSelector, useDispatch} from 'react-redux';

const Setting = () => {
    const [isKey, setIsKey] = useState('1');
    const [isChangePass, setIsChangePass] = useState(false);
    const [isAccount, setIsAccount] = useState(false);
    const [userDataTable, setUserDataTable] = useState([]);
    const { TabPane } = Tabs;
    const { Option } = Select;
    const dispatch = useDispatch();

    const usserData = useSelector(state => state.user?.userList);

    const columnsAccount = [
        {
            title: 'No.',
            dataIndex: 'id',
            ellipsis: true,
        }, {
            title: 'Email',
            dataIndex: 'email',
            ellipsis: true,
        }, {
            title: 'Role',
            dataIndex: 'authorities',
            ellipsis: true,
            sorter: {
                compare: (a, b) => a.authorities.length - b.authorities.length,
            },
            render: (_, { authorities }) => (
                <>
                {authorities.map((auth) => (
                    <span key={auth} style={{display: 'block'}}>
                        {auth}
                    </span>
                ))}
                </>
              ),
        }, {
            title: 'action',
            dataIndex: 'action',
            render: (_, record) => (
                <div className="c-actionTable">
                    <div className="c-actionTable__box">
                        <img src="../images/icon-more.svg" alt="" />
                        <ul className="c-actionTable__content">
                            <li><span onClick={() => setIsChangePass(true)}>Change password</span></li>
                            <li><Link to={`${record.id}`}>Edit permission</Link></li>
                        </ul>
                    </div>
                </div>
            ),
        }
    ];

    useEffect(() => {
        dispatch(allActions.userActions.getUserList());
    },[]);

    useEffect(() => {
        setUserDataTable(usserData)
    },[usserData]);

    
    

    return(
        <>
        <div className="p-setting">
            <div className="p-setting__head">
                <Title title="Setting" subTitle="Setting your account" />
                {isKey === '1' ? <Button title='Add Another Account' type='primary' iconLeft='BoxAdd' onClick={() => setIsAccount(true)}/> : ''}
            </div>
            <div className="p-setting__tabs">
                <Tabs className="c-tabs" defaultActiveKey="1" onChange={(key) => setIsKey(key)}>
                    <TabPane tab="Account" key="1">
                        <div className="p-setting__title">
                            <h3>Accounts</h3>
                            <p>Manage your existing accounts and change their roles permissions</p>
                        </div>
                        <div className="c-table">
                            <div className="c-table__head">
                                <Select className="c-selectChange" defaultValue="all" style={{width: 260}}>
                                    <Option value="all">All Role</Option>
                                    <Option value="ceo">CEO</Option>
                                    <Option value="asit_ceo">Asit CEO</Option>
                                    <Option value="marketing">Marketing</Option>
                                    <Option value="sub_marketing">Sub Marketing</Option>
                                    <Option value="system_admin">System Admin</Option>
                                    <Option value="supper_admin">Supper Admin</Option>
                                </Select>
                                <Search placeholder="Search email" />
                            </div>
                            {/* <Table rowKey='id' columns={columnsAccount} dataSource={usserData} /> */}
                            {userDataTable && userDataTable.length > 0 ? <Table rowKey='id' columns={columnsAccount} dataSource={userDataTable} /> : <NoData /> }
                        </div>
                    </TabPane>
                    <TabPane tab="Notification" key="2">
                        <div className="p-setting__box">
                            <div className="p-setting__title">
                                <h3>Notification</h3>
                                <p>Receive the lastest news, <br/>updates ...</p>
                            </div>
                            <div className="p-setting__notifi">
                                <Checkbox defaultChecked={true}>Announce the countdown time to create Whitelist</Checkbox>
                                <Checkbox defaultChecked={true}>Announce the countdown time to Join Pool</Checkbox>
                                <Checkbox defaultChecked={true}>Announce the countdown time to End Join Pool</Checkbox>
                                <Checkbox defaultChecked={true}>Announce the countdown time to Claim</Checkbox>
                                <Checkbox defaultChecked={true}>Announce the countdown time to create Whitelist</Checkbox>
                                <Checkbox defaultChecked={true}>Announce the countdown time to End Refund</Checkbox>
                            </div>
                        </div>
                    </TabPane>
                    <TabPane tab="System" key="3">
                        <div className="p-setting__box">
                            <div className="p-setting__title">
                                <h3>Timetable</h3>
                                <p>Receive the lastest news, <br/>updates ...</p>
                            </div>
                            <div className="p-setting__timetable">
                                <div className="p-setting__inputHours">
                                    <p>Staking Deadline (the period from the end of the stake to the start of the pool join)</p>
                                    <Input value='20' />
                                    <span>hours</span>
                                </div>
                                <div className="p-setting__inputHours">
                                    <p>Allocation start time (the time counted from the end of join pool)</p>
                                    <Input value='20' />
                                    <span>hours</span>
                                </div>
                            </div>
                        </div>
                        <div className="p-setting__box">
                            <div className="p-setting__title">
                                <h3>Data Source</h3>
                                <p>Receive the lastest news, <br/>updates ...</p>
                            </div>
                            <div className="p-setting__table">
                                <table>
                                    <thead>
                                        <tr>
                                            <th></th>
                                            <th>Snapshot</th>
                                            <th>Out Whitelist List</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>Community</td>
                                            <td><Checkbox defaultChecked={true} /></td>
                                            <td><Checkbox defaultChecked={true} /></td>
                                        </tr>
                                        <tr>
                                            <td>Guaranteed</td>
                                            <td><Checkbox defaultChecked={true} /></td>
                                            <td><Checkbox defaultChecked={true} /></td>
                                        </tr>
                                        <tr>
                                            <td>Private</td>
                                            <td><Checkbox defaultChecked={true} /></td>
                                            <td><Checkbox defaultChecked={true} /></td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div className="p-setting__box">
                            <div className="p-setting__title">
                                <h3>Commit Range</h3>
                                <p>Setting commit range percent</p>
                            </div>
                            <div className="p-setting__input">
                                <Input type='number' title='Min Percent (%)' value='100' />
                                <Input type='number' title='Max Percent (%)' value='100' />
                            </div>
                        </div>
                    </TabPane>
                </Tabs>
            </div>
        </div>
        {isChangePass ? <Popup title='Change Password' subTitle='Enter new password' icon='Edit' btnTitle='Change' onCancel={() => setIsChangePass(false)}>
            <Input placeholder='Enter new password' />
            <Input placeholder='Confirm new password' />
        </Popup> : ''}
        {isAccount ? <Popup title='Add Another Account' icon='Account' btnTitle='Add Account' onCancel={() => setIsAccount(false)}>
            <Input placeholder='Enter email' />
            <Input placeholder='Enter new password' />
            <Input placeholder='Confirm new password' />
            <Select className="c-selectChange" placeholder="Role">
                <Option value="ceo">CEO</Option>
                <Option value="asit_ceo">Asit CEO</Option>
                <Option value="marketing">Marketing</Option>
                <Option value="sub_marketing">Sub Marketing</Option>
                <Option value="system_admin">System Admin</Option>
                <Option value="supper_admin">Supper Admin</Option>
            </Select>
        </Popup> : ''}
        </>
    )
}

export default Setting;