import React, {useState, useEffect, useRef} from 'react';
import { Title, InputCustom, Button, Loading, Popup } from '../component';
import { Select, DatePicker, Form, Input } from 'antd';
import {useNavigate, useParams} from "react-router-dom";
import allActions from '../../actions';
import {useSelector, useDispatch} from 'react-redux';
import moment from 'moment-timezone';
import { Store } from 'react-notifications-component';
import addNotify from '../common/Notify/addNotify';
import { NetworkArray, Networks, Tokens, dataStatus } from '../../constants';
import axios from 'axios';  


const BountyEdit = () => {  
    const { handle } = useParams();
    const [imgBanner, setImgBanner] = useState(''); 
    const inputImage = useRef(); 
    const [loadingPage, setLoadingPage] = useState(false); 
    const [isDelete, setIsDelete] = useState(false);
    const [form] = Form.useForm();  
    // moment.tz.setDefault("GMT");
    let navigate = useNavigate();
    const dispatch = useDispatch();
    const tokenInformData = useSelector(state => state.tokenInform); 
    const airdropInfo = useSelector(state => state.airdrop?.airdropInfo); 
    const [airdropData, setAirdropData] = useState({});
    const { Option } = Select;
    const { RangePicker } = DatePicker;  
    
    useEffect(() => { 
        form.setFieldsValue({
            id: parseInt(handle),
            network: airdropInfo?.network,
            tokenInform: airdropInfo?.tokenInform?.id,
            acceptCurrency: airdropInfo?.acceptCurrency, 
            joinPoolDate: [airdropInfo?.startJoinPool ? moment(airdropInfo?.startJoinPool) : null, airdropInfo?.endJoinPool ? moment(airdropInfo?.endJoinPool) : null],
            startClaim: airdropInfo?.startClaim ? moment(airdropInfo?.startClaim) : null,
            handle:airdropInfo?.handle,
            title:airdropInfo?.title, 
            priority:airdropInfo?.priority, 
            totalRaise:airdropInfo?.totalRaise,
            idoRate:airdropInfo?.idoRate,
            status:airdropInfo?.status, 
            bannerURL:airdropInfo?.bannerURL,
            addressClaimAirdrop: airdropInfo?.addressClaimAirdrop,
            joinNowUrl: airdropInfo?.joinNowUrl,
            detailUrl: airdropInfo?.detailUrl
        }); 
     
    },[airdropInfo]);  
    
    useEffect(() => {
        if(handle !== null) {
            dispatch(allActions.airdropAction.getAirdropByHandle(handle));
        }
        dispatch(allActions.tokenInformAction.getTokenInformList());
    },[]);   

    useEffect(() => {
        dispatch(allActions.tokenInformAction.getTokenInformList());
    },[]); 
 
   
    const handleDurationTimeRangePicker = (date, dateString) => {  
        let startJoinPoolDate = new Date(dateString[0]);
        let endJoinPoolDate = new Date(dateString[1]); 
        if(date){
            setAirdropData({
                ...airdropData, 
                startJoinPool: startJoinPoolDate.toISOString(), 
                endJoinPool: endJoinPoolDate.toISOString()
            });  
        } 
    };

    const handleStartClaimDatePicker = (date, dateString) => {  
        let claimDateDate = new Date(dateString);
        if(date){ 
            setAirdropData({
                ...airdropData, 
                startClaim: claimDateDate.toISOString()
            });  
        }  
    }; 

    const onChangeImage= (e) => { 
        let file = e.target.files[0];
        const formData = new FormData();
        formData.append("file", file); 
        axios({
            method: 'post',
            url: process.env.REACT_APP_API_URL + `/upload-to-s3`,
            headers: {
                'accept': '*/*',
                "Authorization": `Bearer ${localStorage.getItem(Tokens.ADMIN)}`,
                "Content-Type": "multipart/form-data"
            },
            data: formData,
        }).then(res => {
           setImgBanner(res.data);
           setAirdropData({
            ...airdropData,  
            bannerURL: res.data
            });
        }).catch(err => {
            console.log(err);
        });
    }  
    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    }; 

    const handleDelete = () => {   
        setLoadingPage(true); 
        dispatch(allActions.airdropAction.deleteAirdrop(handle, (res)=>{ 
            setIsDelete(false);
            if (res.data === dataStatus.SUCCESS){
                navigate('/airdrop-bounty'); 
            }
            else{
                Store.addNotification("Delete Failed",addNotify('warning'));
            }
        })); 
        setLoadingPage(false); 
    } 

    const onFinish = (values) => {  
        if (!values.handle) {
            Store.addNotification(addNotify('Please enter campaign handle !', 'danger'));
            setLoadingPage(false);
            return;
        }

        if (!values.tokenInform) {
            Store.addNotification(addNotify('Please select Token Inform !', 'danger'));
            setLoadingPage(false);
            return;
        }
        
        let _tokenInformValid = tokenInformData.find(x => x.id === values.tokenInform); 
       
        if (!_tokenInformValid) {
            Store.addNotification(addNotify('Please select valid Token Inform !', 'danger'));
            setLoadingPage(false);
            return;
        }

        if (!NetworkArray.includes(values.network)) {
            Store.addNotification(addNotify('Please select valid network !', 'danger'));
            setLoadingPage(false);
            return;
        } 
       
        if(values?.joinPoolDate){  
            if(values?.joinPoolDate[1]._d > values?.startClaim?._d) {
                Store.addNotification(addNotify('Please select Claim Date >= End Join Pool Date', 'danger'));
                setLoadingPage(false);
                return;
            }
        }   
   
        let payload = {
            "activated": true,
            "id": handle,
            "addressClaimAirdrop": values?.addressClaimAirdrop ? values?.addressClaimAirdrop : null,
            "bannerURL": imgBanner ? imgBanner : airdropInfo?.bannerURL,
            "detailUrl": values.detailUrl ? values.detailUrl :null,
            "endJoinPool":  airdropData?.endJoinPool ? airdropData?.endJoinPool : values?.joinPoolDate ? values?.joinPoolDate[1]?._d.toISOString() : null,
            "handle": values?.handle? values?.handle : null, 
            "joinNowUrl": values?.joinNowUrl ? values?.joinNowUrl: null,
            "network": values?.network ? values?.network : null, 
            "priority": values?.priority ? values?.priority : null, 
            "startAirdrop": airdropData?.startJoinPool ? airdropData?.startJoinPool : values?.joinPoolDate ?  values?.joinPoolDate[0]?._d.toISOString():null,
            "startClaim": airdropData?.startClaim ? airdropData?.startClaim: values?.startClaim?._d.toISOString(),
            "startJoinPool":  airdropData?.startJoinPool ? airdropData?.startJoinPool: values?.joinPoolDate ? values?.joinPoolDate[0]?._d.toISOString():null,
            "title": values?.title ? values?.title : null,
            "tokenInform": values.tokenInform ? {id : parseInt(values.tokenInform)} : 0,
            "totalRaise": values?.totalRaise ? values?.totalRaise : null
        };  
         
        dispatch(allActions.airdropAction.editAirdrop(handle, payload, (response) => {  
            if(response.status === 200){  
                setLoadingPage(false);
                navigate('/airdrop-bounty'); 
            }
            else{ 
                Store.addNotification(addNotify(response.title, 'danger')); 
                setLoadingPage(false);
            }  
        }));  
    };
    
    return(
        <>
        {loadingPage ? <Loading /> : ''}  
        <Form 
            name="basic"
            form={form}
            labelCol={{ span: 8 }}
            wrapperCol={{ span: 16 }}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            autoComplete="off"
        >
        <div className="p-campaignAdd">
            <div className="p-campaignAdd__left">
                <Title title='Event’s Information' subTitle='Enter the required information below to add event. You can change it before the event ends.' />
                <div className="p-campaignAdd__row">
                <div className="c-select">
                    <Form.Item   
                        name="priority" 
                        rules={[{ 
                            required: false, 
                            message: "Priority wrong format",
                            pattern: new RegExp(/^\d*(\.\d+)?$/)
                        }]} 
                        >
                        <InputCustom title='Priority' value={airdropData.priority} type="number" />
                    </Form.Item> 
                </div>
                <div className="c-select">
                    <Form.Item  
                        name="handle" 
                        rules={[{ required: true, message: 'Handle is required!' }]}
                    >
                        <InputCustom required={true} title='Handle' value={airdropData.handle} />
                    </Form.Item>
                </div>  
                </div>
                <div className="p-campaignAdd__row">
                    <div className="c-select">
                        <p className="c-select__title">Network</p>
                        <Form.Item name="network">
                            <Select className="c-selectChange" placeholder='Select network'>
                                <Option value={Networks.BSC}><img src="../images/icon-binance.svg" alt="Binance Smart Chain" />Binance Smart Chain</Option>
                                <Option value={Networks.POLY}><img src="../images/icon-polygon.svg" alt="Polygon" />Polygon</Option>
                                <Option value={Networks.ETH}><img src="../images/icon-ethereum.svg" alt="Ethereum" />Ethereum</Option>
                                {process.env.REACT_APP_MODE ==="development" && 
                                <>
                                    <Option value={Networks.ARBTESTNET}><img src="../images/icon-arb.svg" alt="Arbitrum" />ArbitrumTest</Option>
                                    <Option value={Networks.ARB}><img src="../images/icon-arb.svg" alt="Arbitrum" />Arbitrum</Option>
                                </> 
                                }
                                {process.env.REACT_APP_MODE ==="production" && <Option value={Networks.ARB}><img src="../images/icon-arb.svg" alt="Arbitrum" />Arbitrum</Option>}
                            </Select>
                        </Form.Item>
                    </div> 
                    <div className="c-select">
                        <p className="c-select__title">Token Inform</p>
                        <Form.Item name="tokenInform"> 
                            <Select className="c-selectChange" 
                                placeholder='Select token inform' 
                                allowClear
                                showSearch
                                filterOption={(inputValue, option) =>
                                    option.children.join('').toLowerCase().includes(inputValue.toLowerCase())
                                }
                            >
                                {tokenInformData && tokenInformData.map((token) => (
                                    <Option key={token.id} value={token.id}>
                                        <img src={token.logoURL} alt={token.symbol + " - " + token.name} />
                                        {token.symbol} - {token.name}
                                    </Option>)
                                )}
                            </Select>
                        </Form.Item>
                    </div>
                </div> 
                <div className="p-campaignAdd__row"> 
                    <div className="c-select">
                        <Form.Item name="totalRaise">
                            <InputCustom title='Reward' />
                        </Form.Item>
                    </div> 
                </div>
               
                <div className="p-campaignAdd__timeTable">
                    <h3  className="c-ttl">Campaign’s Timetable</h3>
                    <div  className="p-campaignAdd__box">
                        <div className="c-date">
                            <p className="c-date__title">Duration Time (VN)</p>
                            <Form.Item name ="joinPoolDate"> 
                                <RangePicker   
                                    showTime
                                    format='HH:mm:ss YYYY-MM-DD'
                                    onChange={handleDurationTimeRangePicker}
                                />
                            </Form.Item>
                        </div> 
                        <div className="c-date">
                            <p className="c-date__title">Claim Date (VN)</p>
                            <Form.Item name ="startClaim"> 
                                <DatePicker  
                                    showTime
                                    format='HH:mm:ss YYYY-MM-DD'
                                    onChange={handleStartClaimDatePicker}
                                />
                            </Form.Item>
                        </div>
                    </div>
                </div>
            </div>
            <div className="p-campaignAdd__right"> 
                <div className="c-upload">
                    <h3 className="c-upload__title">Images</h3>
                    <div className={`c-upload__box${imgBanner === '' ? ' is-add' : ''}`}> 
                        {imgBanner ? 
                            <div onClick={() => inputImage.current.click()} className="c-upload__edit">
                                <img src={imgBanner} alt="" />
                            </div>:
                            <div onClick={() => inputImage.current.click()} className="c-upload__edit">
                                <img src={airdropInfo?.bannerURL} alt="" />
                            </div> }  
                    </div>
                    <input ref={inputImage} onChange={onChangeImage} type="file" /> 
                </div>
                <div className="p-campaignAdd__row is-wFull">
                    <div className="c-select">
                        <Form.Item 
                            name="title"  
                            rules={[{ required: true, message: 'Event Name is required!' }]}
                        >
                            <InputCustom required={true} title='Event Name' placeholder='Enter event name' />
                        </Form.Item>
                    </div> 
                </div>
                <div className="p-campaignAdd__row is-wFull">
                    <div className="c-select">
                        <Form.Item 
                            name="detailUrl"  
                        >
                            <InputCustom title='Detail Link' placeholder='Enter detail link' />
                        </Form.Item>
                    </div>
                </div> 
                <div className="p-campaignAdd__row is-wFull">
                    <div className="c-select">
                        <Form.Item 
                            name="joinNowUrl"  
                        >
                            <InputCustom title='Join Now Link' placeholder='Enter join now link' />
                        </Form.Item>
                    </div>
                </div> 
                <div className="p-campaignAdd__row is-wFull">
                    <div className="c-select">
                        <Form.Item 
                        name="addressClaimAirdrop"  
                        >
                            <InputCustom title='Address Claim' placeholder='Enter event name' />
                        </Form.Item>
                    </div> 
                </div>
            </div>
            <div className="p-campaignAdd__btn">
                <div className="p-campaignAdd__left">
                    <Button title='Cancel' type='grey' onClick={() => navigate('/')} />
                </div>
               
                <div className="p-campaignAdd__right"> 
                    <div className="c-btn c-btn--flat" type='flat' onClick={() => setIsDelete(true)}>Delete</div> 
                    <Button title='Submit' type='blue' onKeyDown={(e)=> e.keyCode == 13 ? e.preventDefault(): ''} />
                </div>
            </div>
        </div>
        {isDelete ? <Popup onClick={() => handleDelete()} title='Do you want delete this campaign?' subTitle='You can’t restore this file' btnTitle='Delete' icon='Delete' onCancel={() => setIsDelete(false)} /> : ''}
        </Form>
        </>
    )
}

export default BountyEdit;