import {Routes, Tokens} from '../constants';
import EventBus from '../components/common/EventBus';

const axiosInterceptor = {
    setupInterceptors: (axios, isToken = false, isFormData = false) => {
        axios.interceptors.request.use(config => {
                if (isToken) {
                    return config;
                }
                let token = localStorage.getItem(Tokens.ADMIN);
                
                if (token) {
                    config.headers['Authorization'] = `Bearer ${token}`;
                }
                
                if (isFormData) {
                    config.headers['Content-Type'] = 'multipart/form-data';
                }
                return config;
            },
            (error) => {
                return Promise.reject(error);
            }
        );
        axios.interceptors.response.use(
            response => successHandler(response),
            error => errorHandler(error)
        );
        const errorHandler = (error) => {
            const config = error?.config;
            if((error?.response?.status === 401 || error?.response?.status === 403) && !config?.sent) {
                EventBus.dispatch("logout");
            }

            if (error.response.data.message) {
                
            }
            return Promise.reject({ ...error })
        };
        const successHandler = (response) => {
            return response;
        };
    }
};

export default axiosInterceptor;