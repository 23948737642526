import http from "./http-common";

const getTokenInformList = (payload) => {
  return http.get("/token-informs", { params: payload });
};

const getTokenInformById = (id) => {
  return http.get(`/token-informs/${id}`);
};

const updateTokenInform = (payload) => {
  return http.put(`/token-informs/${payload.id}`, payload);
};

const createTokenInform = (payload) => {
  return http.post(`/token-informs/`, payload);
};

const deleteTokenInform = (id) => {
  return http.delete(`/token-informs/${id}`);
};

const TokenInformService = {
  getTokenInformList,
  getTokenInformById,
  updateTokenInform,
  createTokenInform,
  deleteTokenInform
};
export default TokenInformService;