import { Networks } from "../constants";

export const getCampaignTimeItem = (campaign, round) => {
    let timeItem = null;
    if (round?.startIDO) {
        timeItem = {
            startIDO: round?.startIDO ?? null,
            startClaim: round?.startClaim ?? null,
            startJoinPool: round?.startJoinPool ?? null,
            endJoinPool: round?.endJoinPool ?? null,
            endIDO: round?.endIDO ?? null,
            status: campaign?.status
        };
    }
    else {
        timeItem = {
            startIDO: campaign?.startIDO ?? null,
            startClaim: campaign?.startClaim ?? null,
            startJoinPool: campaign?.startJoinPool ?? null,
            endJoinPool: campaign?.endJoinPool ?? null,
            endIDO: campaign?.endIDO ?? null,
            status: campaign?.status
        };
    }
    return timeItem;
}

export const getNetwork = (_network) => {

    let network = "";
    if (_network === "Binance Smart Chain") {
        network = "bsc";
    } else if (_network === "Polygon") {
        network = "polygon";
    } else if (_network === "Etherum") {
        network = "eth";
    }
    return network;
}

export const networkTxnUrl = (network, hash) => {

    let url_prefix = "";
    if (network === Networks.BSC) {
        url_prefix = "https://bscscan.com/tx/";
    } else if (network === Networks.POLY) {
        url_prefix = "https://polygonscan.com/tx/";
    } else if (network === Networks.ETH) {
        url_prefix = "https://etherscan.io/tx/";
    }
    return url_prefix + hash;
}

export const getRoundType = (roundType) => {

    let roundTypeText = "";
    if (roundType === 1) {
        roundTypeText = "Community";
    } else if (roundType === 2) {
        roundTypeText = "Guaranteed";
    } else if (roundType === 3) {
        roundTypeText = "Private/MVKH";
    } else if (roundType === 4) {
        roundTypeText = "MVKB";
    }
    return roundTypeText;
}

export const getTokenDecimals = (decimalsString) => {

    let decimals = 0;
    if (decimalsString === 'picoether') {
        decimals = 6;
    } if (decimalsString === '1e8') {
        decimals = 8;
    } else if (decimalsString === 'nanoether') {
        decimals = 9;
    } else if (decimalsString === 'microether') {
        decimals = 12;
    } else if (decimalsString === 'ether') {
        decimals = 18;
    }
    return decimals;
}