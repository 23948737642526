import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import {Title, Button, StatusLabel} from '../component';
import { Tabs, Table, Select } from 'antd';
import allActions from '../../actions';
import {useSelector, useDispatch} from 'react-redux';
import { formatDateTime } from '../../utils/formatDatetime';
import { formatData } from '../../utils/StringHelper';
import { Excel } from 'antd-table-saveas-excel';

const BountyItem = () => {
    const { Option } = Select;
    const dispatch = useDispatch(); 
    
    const columnsOverview = [
        {
            title: 'EVENT NAME',
            dataIndex: 'title', 
            ellipsis: true,
        }, {
            title: 'TOKEN',
            dataIndex: 'tokenInform',
            render: (_, record) => (
                <p>{record?.tokenInform?.symbol}</p>
            ), 
            ellipsis: true,
        }, {
            title: 'REWARD',
            dataIndex: 'totalRaise',  
            ellipsis: true, 
        }, {
            title: 'DURATION',
            dataIndex: 'startAirdrop', 
            ellipsis: true,
            render: (_, record) => (
                <>
                    <p>{formatDateTime(record?.startJoinPool)}</p>
                    <p>{formatDateTime(record?.endJoinPool)}</p>
                </> 
            ), 
        },  {
            title: 'Status',
            dataIndex: 'status',
            sorter: (a, b) => a.status.length - b.status.length,
            render: (_, record) => (
                <StatusLabel title={record.status} status={record?.status?.toLowerCase()} />
            ),
            ellipsis: true,
        }, {
            title: 'action',
            dataIndex: 'action',
            render: (_, record) => (
                <div className="c-actionTable">
                    <div className="c-actionTable__box">
                        <img src="../images/icon-more.svg" alt="" />
                        <ul className="c-actionTable__content">
                            <li><Link to={'/bounty-edit/'+record.id} state={{ name: "bounty" }}>Edit</Link></li>
                            <li><Link to={'/bounty-info/'+record.id} state={{ name: "bounty" }}>Information</Link></li> 
                            <li><Link to={'/airdrop-winner?airdrop='+record.id}>Winner list</Link></li> 
                        </ul>
                    </div>
                </div>
            ),
        }
    ];

    useEffect(() => { 
        dispatch(allActions.airdropAction.getAirdrop()); 
    }, []); 
    
    const airdropList = useSelector(state => state.airdrop.airdropList);  
    
    airdropList && airdropList.length > 0 && airdropList.sort((a, b) => {return b.id - a.id});


    const onChange = (pagination, filters, sorter, extra) => {
        console.log('params', pagination, filters, sorter, extra);
    };  
    return(
        <div className="c-table"> 
            <Table rowKey='id' columns={columnsOverview} dataSource={airdropList} onChange={onChange} />
        </div>
    );
}

export default BountyItem;