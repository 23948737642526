import { formatNumberCurrency } from "./formatNumberCurrency";

export const getShortString = (inputString, maxLength = 200) => {
    //trim the string to the maximum length
    var trimmedString = inputString.substr(0, maxLength);

    // //re-trim if we are in the middle of a word
    trimmedString = trimmedString.substr(0, Math.min(trimmedString.length, trimmedString.lastIndexOf(" ")));
    return trimmedString + "...";
}

export const extractContent = (s, space)  => {
    var span= document.createElement('span');
    span.innerHTML= s;
    if(space) {
      var children= span.querySelectorAll('*');
      for(var i = 0 ; i < children.length ; i++) {
        if(children[i].textContent)
          children[i].textContent+= ' ';
        else
          children[i].innerText+= ' ';
      }
    }
    return [span.textContent || span.innerText].toString().replace(/ +/g,' ');
};

function isNumber(n) { return !isNaN(parseFloat(n)) && !isNaN(n - 0) }

export const formatData = (_value, symbolValue) => {

  let text = "";

  if(_value) {
    if(isNumber(_value)) {
      text = formatNumberCurrency(_value) + " " + symbolValue;
    }
    else {
      text = _value + " " + symbolValue;
    }
  }
  else {
    text = "--";
  }
  return text;
}

export const formatText = (_value) => {

  let text = "";

  if(_value) {
    text = _value;
  }
  else {
    text = "--";
  }
  return text;
}

export const titleCase = (_value) => {
  let text = '';

  if(_value) {
    text = _value.toLowerCase().replace(/\b\w/g, s => s.toUpperCase());
  }

  return text;
}