import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import {Title, Button, StatusLabel} from '../component';
import { Tabs, Table, Select } from 'antd';
import allActions from '../../actions';
import {useSelector, useDispatch} from 'react-redux';
import { formatDateTime } from '../../utils/formatDatetime';
import { formatData } from '../../utils/StringHelper';
import { Excel } from 'antd-table-saveas-excel'; 
 


const columnsOverview = [
    {
        title: 'Campaign Name',
        dataIndex: 'title',
        render: (_, record) => (
            <div className="c-nameTable">
                <div className="c-nameTable__logo"><img src={record.logoUrl} alt={record.title} /></div>
                <p className="c-nameTable__title">{record.title}</p>
            </div>
        ),
        ellipsis: true,
    }, {
        title: 'Token',
        dataIndex: 'tokenInformSymbol',
        ellipsis: true,
    }, {
        title: 'Total Raise (BUSD)',
        dataIndex: 'totalRaise',
        sorter: {
            compare: (a, b) => a.raise - b.raise,
        },
        ellipsis: true,
    }, {
        title: 'IDO Rate (BUSD)',
        dataIndex: 'idoRate',
        sorter: {
            compare: (a, b) => a.rate - b.rate,
        },
        ellipsis: true,
    }, {
        title: 'IDO Date (UTC)',
        dataIndex: 'startIDO',
        defaultSortOrder: 'descend',
        sorter: {
            compare: (a, b) => new Date(a.date) - new Date(b.date),
        },
        ellipsis: true,
    }, {
        title: 'Status',
        dataIndex: 'status',
        sorter: (a, b) => a.status.length - b.status.length,
        render: (_, record) => (
            <StatusLabel title={record.status} status={record.status.toLowerCase()} />
        ),
        ellipsis: true,
    }, {
        title: 'action',
        dataIndex: 'action',
        render: (_, record) => (
            <div className="c-actionTable">
                <div className="c-actionTable__box">
                    <img src="../images/icon-more.svg" alt="" />
                    <ul className="c-actionTable__content">
                        <li><Link to={'/campaign-edit/'+record.id} state={{ name: "savedraft" }}>Edit</Link></li>
                        <li><Link to={'/campaign-info/'+record.id} state={{ name: "savedraft" }}>Information</Link></li> 
                    </ul>
                </div>
            </div>
        ),
    }
];

const SaveDraftListTable = () => {
    const { Option } = Select;
    const dispatch = useDispatch();
    const [campaignSelected, setCampaignSelected] = useState();
    
    // useEffect(() => {
    //     dispatch(allActions.campaignAction.getCampaignList(campaignSelected));
    //     dispatch(allActions.campaignAction.getCampaignSimple());
    // }, []);

    useEffect(() => {  
        dispatch(allActions.campaignAction.getSaveDraft());
    }, [campaignSelected]);
    
    const saveDraftData = useSelector(state => state.campaigns.savedraftList);  
     
    saveDraftData && saveDraftData.length > 0 && saveDraftData.map(campaign => {
        campaign.startIDO = formatDateTime(campaign.startIDO);
        campaign.totalRaise = formatData(campaign.totalRaise, '');
    });

    const onChange = (pagination, filters, sorter, extra) => {
        console.log('params', pagination, filters, sorter, extra);
    };
    const handleChange = (value) => {
        console.log(`selected ${value}`);
    };

    const handleExport = () => {
        const excel = new Excel();

        const columnsExport = [
            {
                title: 'Campaign Name',
                dataIndex: 'title',
            }, {
                title: 'Token',
                dataIndex: 'tokenInformSymbol',
            }, {
                title: 'Total Raise (BUSD)',
                dataIndex: 'totalRaise',
            }, {
                title: 'IDO Rate (BUSD)',
                dataIndex: 'idoRate',
            }, {
                title: 'IDO Date (UTC)',
                dataIndex: 'startIDO',
            }, {
                title: 'Status',
                dataIndex: 'status',
            }
        ];

        excel
        .addSheet("SaveDraft")
        .addColumns(columnsExport)
        .addDataSource(saveDraftData, {
            str2Percent: true
        })
        .saveAs("SaveDraft.xlsx");
    };

    
    const changeCampaign = (value) => {
        let payload = {
            "ids": value,
            "sort": 'startIDO,desc'
        };
        dispatch(allActions.campaignAction.getCampaignList(payload));
    }

    const changeStatus = (value) => {
        let payload = {
            "ids": value,
            "sort": 'startIDO,desc'
        };
        dispatch(allActions.campaignAction.getCampaignList(payload));
    }

    return( 
            <div className="c-table">
                <div className="c-table__head">
                    <Select className="c-selectChange" defaultValue={campaignSelected ? parseInt(campaignSelected) : null} onChange={(value) => changeCampaign(value)} style={{width: 260}} placeholder='Select a campaign'>
                        {saveDraftData && saveDraftData.length > 0 && saveDraftData.map((item, index) => (
                            <Option key={index} value={item.id}>{item.title}</Option>
                        ))}
                    </Select> 
                    <Button title='Export' type='blueBorder' iconRight='Export' onClick={() => handleExport()}/>
                </div>
                <Table columns={columnsOverview} dataSource={saveDraftData} onChange={onChange} />
            </div> 
    );
}

export default SaveDraftListTable;