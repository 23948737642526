import {combineReducers} from 'redux';
import currentUser from './currentUser';
import campaigns from './campaigns';
import rounds from './rounds';
import tokenInform from './tokenInform';
import whitelist from './whitelist';
import allocations from './allocations';
import snapshot from './snapshot';
import systemAdmin from './systemAdmin';
import airdrop from './airdrop'; 
import user from './user';
import ino from './ino';
import inoWhitelist from './inoWhitelist';
import gamehub from './gamehub';

const rootReducer = combineReducers({
    currentUser,
    campaigns,
    rounds,
    tokenInform,
    whitelist,
    allocations,
    snapshot,
    systemAdmin,
    airdrop,
    user,
    ino,
    inoWhitelist,
    gamehub
})

export default rootReducer;