import React, { useEffect, useState, useCallback } from 'react';
import { Title, Button, Loading, InputCustom, DataLoading } from '../component';
import { Select } from 'antd';
import WhitelistTable from './whitelistTable';
import allActions from '../../actions';
import { useSelector, useDispatch } from 'react-redux';
import { useSearchParams, useNavigate } from 'react-router-dom';
import { RoundType, Statuses, whitelistType } from '../../constants';
import { Store } from 'react-notifications-component';
import addNotify from '../common/Notify/addNotify';
import { formatData } from '../../utils/StringHelper';
import Countdown from "react-countdown";
import { getCampaignTimeItem } from '../../utils/campaignUltils';

const Whitelist = () => {
    const { Option } = Select;
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false);
    const [isLoadingWL, setIsLoadingWL] = useState(false);
    const [isLoadingSR, setIsLoadingSR] = useState(false);
    const [isLoadingData, setIsLoadingData] = useState(false);
    const [isUpload, setIsUpload] = useState(false);
    let [searchParams, setSearchParams] = useSearchParams();
    let campaignId = searchParams.get("campaign");
    let roundId = searchParams.get("round");
    const [campaignSelected, setCampaignSelected] = useState(campaignId);
    const [roundSelected, setRoundSelected] = useState(roundId);
    const allCampaignData = useSelector(state => state.campaigns);
    const roundData = useSelector(state => state.rounds);
    const whitelistData = useSelector(state => state.whitelist);

    const { campaignSimpleList, campaignOne } = allCampaignData;
    const { campaignRoundList, roundOne } = roundData;
    const { stakingList, priorityList } = whitelistData;

    let totalCommitPriorityList = 0, totalCommitStakingList = 0;
    stakingList?.dataList && stakingList?.dataList.map((item, index) => {
        totalCommitStakingList += item.commited;
    });
    priorityList?.dataList && priorityList?.dataList.map((item, index) => {
        totalCommitPriorityList += item.commited;
    });

    const totalAddress = stakingList?.dataList && stakingList?.dataList.length + priorityList?.dataList && priorityList?.dataList.length;
    const timeItem = campaignOne && roundOne ? getCampaignTimeItem(campaignOne, roundOne) : null;
    const nowDate = new Date();
    const startJoinPoolDate = timeItem && timeItem.startJoinPool ? new Date(timeItem.startJoinPool) : null;

    useEffect(() => {
        dispatch(allActions.campaignAction.getCampaignSimple());
        onChangCampaign(campaignSelected);
        if (campaignSelected) {
            dispatch(allActions.campaignAction.getCampaignById(campaignSelected));
        }
    }, [campaignSelected]);

    useEffect(() => {
        if (roundSelected) {
            setIsLoading(true);
            onChangRound(roundSelected);
            dispatch(allActions.roundAction.getRoundById(roundId));
            dispatch(allActions.whitelistAction.getWhitelistByRoundId(roundId, whitelistType.PRIORITY, (res) => {
                if (res && res.callResult === true) {
                    setIsLoading(false);
                }
            }));
            dispatch(allActions.whitelistAction.getWhitelistByRoundId(roundId, whitelistType.STAKING, (res) => {
                if (res && res.callResult === true) {
                    setIsLoading(false);
                }
            }));
        }
    }, [roundSelected]);

    const onChangCampaign = (id) => {
        if (id) {
            dispatch(allActions.roundAction.getRoundsByCampaignsId(id));
            setSearchParams({ 'campaign': id });
            setCampaignSelected(id);
            setRoundSelected(0);
        }
    }

    const onChangRound = (id) => {
        if (id) {
            setSearchParams({ 'campaign': campaignId, 'round': id });
            setRoundSelected(id);
        }
    }

    const handleGenerateWhitelist = () => {

        if (!roundId) {
            Store.addNotification(addNotify('Please choose a round!', 'danger'));
            return;
        }

        let payload = {
            "roundId": parseInt(roundId)
        };
        setIsLoadingWL(true);
        dispatch(allActions.whitelistAction.generateWhitelist(payload, (response) => {
            if (response.result === true) {
                dispatch(allActions.whitelistAction.getWhitelistByRoundId(roundId, whitelistType.PRIORITY, (res) => { }));
                dispatch(allActions.whitelistAction.getWhitelistByRoundId(roundId, whitelistType.STAKING, (res) => { }));
                Store.addNotification(addNotify('Whitelist generated successfully!', 'success'));
            }
            else {
                Store.addNotification(addNotify('Whitelist generated fail! ' + response?.error?.title, 'danger'));
            }
            setIsLoadingWL(false);
        }));
    }

    const handleUpdateData = () => {

        if (!roundId) {
            Store.addNotification(addNotify('Please choose a round!', 'danger'));
            return;
        }

        let payload = {
            "roundId": parseInt(roundId)
        };
        setIsLoadingData(true);
        dispatch(allActions.whitelistAction.recalculate(payload, (response) => {
            if (response.result === true) {
                dispatch(allActions.whitelistAction.getWhitelistByRoundId(roundId, whitelistType.PRIORITY));
                dispatch(allActions.whitelistAction.getWhitelistByRoundId(roundId, whitelistType.STAKING));
                Store.addNotification(addNotify('Actual commit fund recalculated successfully!', 'success'));
            }
            else {
                Store.addNotification(addNotify('Actual commit fund recalculated fail! ' + response?.error?.title, 'danger'));
            }
            setIsLoadingData(false);
        }));
    }

    const handleGenerateSignatureEspecialRefund = () => {
        if (!roundId) {
            Store.addNotification(addNotify('Please choose a round!', 'danger'));
            return;
        }

        let payload = {
            "roundId": parseInt(roundId)
        };
        setIsLoadingSR(true);
        dispatch(allActions.whitelistAction.generateSignatureEspecialRefund(payload, (response) => {
            if (response.result === true) {
                dispatch(allActions.whitelistAction.getWhitelistByRoundId(roundId, whitelistType.PRIORITY, (res) => { }));
                dispatch(allActions.whitelistAction.getWhitelistByRoundId(roundId, whitelistType.STAKING, (res) => { }));
                Store.addNotification(addNotify('Signature especial refund generated successfully!', 'success'));
            }
            else {
                Store.addNotification(addNotify('Signature especial refund generated fail! ' + response?.error?.title, 'danger'));
            }
            setIsLoadingSR(false);
        }));
    }
    const handlePriorityList = () => {

        if (!campaignId || campaignId == 'null') {
            Store.addNotification(addNotify('Please select Campaign! ', 'danger'));
            return;
        }

        if (!roundId || roundId == 'null') {
            Store.addNotification(addNotify('Please select Round! ', 'danger'));
            return;
        }

        navigate(`/priority-list?campaign=${campaignId}&round=${roundId}`);
    }

    const getProgressTime = useCallback((startTime) => {
        if (!startTime) {
            return Statuses.TBA;
        }
        const now = new Date();
        const utcDate = Date.UTC(
            now.getFullYear(),
            now.getMonth(),
            now.getDate(),
            now.getHours(),
            now.getMinutes(),
            now.getSeconds(),
            0
        );
        const startDate = new Date(startTime);
        const startTS = Date.UTC(
            startDate.getFullYear(),
            startDate.getMonth(),
            startDate.getDate(),
            startDate.getHours(),
            startDate.getMinutes(),
            startDate.getSeconds(),
            0
        );
        let delta = Math.abs(startTS.valueOf() - utcDate.valueOf()) / 1000;
        const days = Math.floor(delta / 86400);
        delta -= days * 86400;
        const hours = Math.floor(delta / 3600);
        delta -= hours * 3600;
        const minutes = Math.floor(delta / 60) % 60;
        delta -= minutes * 60;
        const seconds = Math.floor(delta % 60);
        if (days > 0) {
            return (
                Date.now() +
                days * 24 * 60 * 60 * 1000 +
                hours * 60 * 60 * 1000 +
                minutes * 60 * 1000 +
                seconds * 1000
            );
        } else {
            return (
                Date.now() +
                hours * 60 * 60 * 1000 +
                minutes * 60 * 1000 +
                seconds * 1000
            );
        }
    }, []);

    const renderer = ({ days, hours, minutes, seconds, completed }) => {
        if (completed) {
            window.location.reload(false);
        }
        return (
            <>
                <span>{days}d</span>
                <span>{hours}h</span>
                <span>{minutes}m</span>
                <span>{seconds}s</span>
            </>
        );
    };

    return (
        <>
            {isUpload ? <div className="c-popup">
                <div className="c-popup__box">
                    <div className="c-popup__close" onClick={() => setIsUpload(false)}><img src="../images/icon-close.svg" alt="Close" /></div>
                    <h3 className="c-popup__title">Upload priority list</h3>
                    <div className="c-popup__content">
                        <div className="c-uploadFile">
                            <InputCustom type='file' />
                            <div className="c-uploadFile__box">
                                <img src="../images/icon-cloud-upload.svg" alt="Upload" />
                                <p>Drag & drop file here</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div> : ''}
            {isLoading && <Loading />}
            {isLoadingWL && <DataLoading title="WHITELIST'S GENERATING" />}
            {isLoadingSR && <DataLoading title="WHITELIST'S GENERATING" />}
            {isLoadingData && <DataLoading title="ACTUAL COMMIT FUND RECALCULATING" />}
            <div className="p-whitelist">
                <div className="p-whitelist__head">
                    <Title title='Whitelist' subTitle='The list of wallet addresses participating in the whitelist, you can also add out the whitelist and edit the information' />
                    <div className="p-whitelist__btn">
                        <Button title='Priority list' type='primary' iconLeft='Upload' onClick={() => handlePriorityList()} />
                        <Button title='Update data' type='blueBorder' iconLeft='Refresh' onClick={() => handleUpdateData()}/>
                    <Button title='Generate whitelist' type='blueBorder' iconLeft='Refresh' onClick={() => handleGenerateWhitelist()}/>
                    <Button title='Generate sig es-refund' type='blueBorder' iconLeft='Refresh' onClick={() => handleGenerateSignatureEspecialRefund()}/>
                    </div>
                </div>
                <div className="p-whitelist__time">
                    WHITELIST DEADLINE:
                    {timeItem && timeItem.startJoinPool && <Countdown date={getProgressTime(timeItem.startJoinPool)} renderer={renderer} />}
                </div>
                <div className="p-whitelist__boxHead">
                    <div className="c-select">
                        <p className="c-select__title">Campaign</p>
                        <Select className="c-selectChange" defaultValue={campaignSelected ? parseInt(campaignSelected) : null} onChange={(value) => onChangCampaign(value)} style={{ width: 260 }} placeholder='Select Campaign'>
                            {campaignSimpleList && campaignSimpleList.length > 0 && campaignSimpleList.map((item, index) => (
                                <Option key={index} value={item.id}>{item.title}</Option>
                            ))}
                        </Select>
                    </div>
                    <div className="c-select">
                        <p className="c-select__title">Round</p>
                        <Select className="c-selectChange" defaultValue={roundSelected ? parseInt(roundSelected) : null} onChange={(value) => onChangRound(value)} style={{ width: 260 }} placeholder='Select Round'>
                            {campaignRoundList && campaignRoundList.map((item, index) => (
                                <Option key={index} value={item.id}>{item.round}</Option>
                            ))}
                        </Select>
                    </div>
                    <ul>
                        <li>
                            <span>Total Address<img src="../images/icon-gps.svg" alt="Total Address" /></span>
                            {totalAddress}
                        </li>
                        <li className="is-active">
                            <span>Total BUSD<img src="../images/icon-coin.svg" alt="Total BUSD" /></span>
                            {formatData(totalCommitPriorityList + totalCommitStakingList, '')}
                        </li>
                        <li>
                            <span>Total Token<img src="../images/icon-cube.svg" alt="Total Token" /></span>
                            0
                        </li>
                    </ul>
                </div>
                <WhitelistTable roundId={roundId} title='Priority List' type={1} data={priorityList} />
                <WhitelistTable roundId={roundId} title='Staking List' type={2} data={stakingList} />
            </div>
        </>
    )
}

export default Whitelist;