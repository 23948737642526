import { authActionType } from "../actions/actionType";
import { userActionType } from "../actions/actionType";


const currentUser = (state = {}, action) => {
    switch(action.type){

        case authActionType.LOGIN_SUCCESS:
            return {
                ...state,
                isLoggedIn: true,
                token: action.payload.token,
            };
        case authActionType.LOGIN_FAIL:
            return {
                ...state,
                isLoggedIn: false,
                token: null,
            };
        case authActionType.LOGOUT:
            return {
                ...state,
                isLoggedIn: false,
                token: null,
            };

        case userActionType.GET_USER_LIST:
            return action.payload;

        default:
            return state
    }
}

export default currentUser;