import React, { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {Title, Button, NoData} from '../component';
// import RoundInfo from './roundInfo';
import allActions from '../../actions';
import {useSelector, useDispatch} from 'react-redux';
import { formatData, formatText } from '../../utils/StringHelper';
import { formatDateTime } from '../../utils/formatDatetime';

const BountyInfo = () => {
    let navigate = useNavigate();
    const dispatch = useDispatch();
    let { handle } = useParams(); 
 
    useEffect(() => {
        if(handle !== null) {
            dispatch(allActions.airdropAction.getAirdropByHandle(handle)); 
        }
        window.scrollTo({top: 0, left: 0, behavior: 'smooth' });
    },[]);

    const airdropInfo = useSelector(state => state.airdrop.airdropInfo);  
 
    return(
        <div className="p-campaignInfo">
            <Title title={`${airdropInfo?.title}’s Information`} />
            <div className="p-campaignInfo__round">
                <div className="p-campaignInfo__title">
                    <p>Event’s Timetable</p>  
                </div>
                <div className="c-info__row"> 
                    <div className="c-info__box">
                        <div className="c-info__row">
                            <h3 className="c-info__ttl">Duration Time (UTC)</h3>
                            <p className="c-info__txt">{formatDateTime(airdropInfo?.startJoinPool)} - {formatDateTime(airdropInfo?.endJoinPool)}</p>
                        </div>  
                        <div className="c-info__row">
                            <h3 className="c-info__ttl">Display On Web (Since claiming date start)</h3>
                            <p className="c-info__txt">{formatDateTime(airdropInfo?.startClaim)}</p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="p-campaignInfo__info">
                <div className="p-campaignInfo__btn">
                    <Button title='Edit Event' type='flat' iconLeft='Edit' onClick={() => navigate('/bounty-edit/'+handle)}/>
                     
                </div>
                <div className="c-info">
                    <div className="c-info__img"><img src={airdropInfo?.bannerURL} alt={airdropInfo?.title} /></div>
                    <div className="c-info__logo">
                        <p>{airdropInfo?.title}</p> 
                    </div> 
                    <div className="c-info__dflex">
                        <div className="c-info__row">
                            <h3 className="c-info__ttl">Network</h3>
                            <p className="c-info__txt"><img src={airdropInfo?.networkLogo} alt={airdropInfo?.network} />{airdropInfo?.network}</p>
                        </div>
                        <div className="c-info__row">
                            <h3 className="c-info__ttl">Accept Currency</h3>
                            <p className="c-info__txt"><img src={airdropInfo?.tokenInform?.logoURL} alt={airdropInfo?.tokenInform?.symbol} />{airdropInfo?.tokenInform?.symbol}</p>
                        </div>
                    </div>
                    <div className="c-info__dflex">
                        <div className="c-info__row">
                            <h3 className="c-info__ttl">Token Inform</h3>
                            <p className="c-info__txt">{formatText(airdropInfo?.tokenInform && airdropInfo?.tokenInform.symbol)}</p>
                        </div> 
                        <div className="c-info__row">
                            <h3 className="c-info__ttl">Reward</h3>
                            <p className="c-info__txt">{formatData(airdropInfo?.totalRaise, '')}</p>
                        </div>
                    </div> 
                </div>
            </div>
        </div>
    )
}

export default BountyInfo;