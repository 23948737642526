import React, {useState, useRef} from 'react'; 
import {Title, Button, Label, Search, Popup, InputCustom, Loading } from '../component'; 
import BountyItem  from './bountyItem'; 
import { Link, useNavigate, useSearchParams } from 'react-router-dom';

const Bounty = () => {  
    const navigate = useNavigate();
    return(
        <> 
        <div className="p-campaign">
            <div className="p-campaign__head">
                <Title title='Bounty / Airdrop' subTitle='Banners that have been uploaded' />
                <Button title='Upload Event' type='primary' iconLeft='BoxAdd' onClick={() => navigate("/bounty-add")}/>
            </div> 
            <div className="p-campaign__tabs"> 
                <BountyItem /> 
            </div>
        </div> 
        </> 
    );
}

export default Bounty;