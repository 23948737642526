import React, { useEffect, useState, useRef } from 'react';
import { Title, Button, Loading } from '../../component';
import { Select, Form } from "antd";
import allActions from '../../../actions';
import {useSelector, useDispatch} from 'react-redux';
import { useSearchParams, useNavigate } from 'react-router-dom';
import { Store } from 'react-notifications-component';
import addNotify from '../../common/Notify/addNotify';
import PriorityListTable from './priorityListTable';
import { formatData } from '../../../utils/StringHelper';
import {dataStatus, Tokens} from '../../../constants'; 
import axios from 'axios';  

const PriorityList = () => {
    const { Option } = Select;
    const dispatch = useDispatch();
    const [isLoading, setIsLoading] = useState(false);
    const [isUpload, setIsUpload] = useState(false);
    let [searchParams, setSearchParams] = useSearchParams();
    const inputFileUpload = useRef();
    let campaignId = searchParams.get("campaign");
    const [campaignSelected, setCampaignSelected] = useState(campaignId);
    const snapshotData = useSelector(state => state.snapshot);
    const [form] = Form.useForm();
    const { priorityList } = snapshotData;
    const navigate = useNavigate();

    let maxBuy = 0;
    const inoList = useSelector(state => state.ino?.inoListCampaign);
    const inoPriorityList = useSelector(state => state.inoWhitelist?.inoPriorityList);
    
    inoPriorityList && inoPriorityList.map((item, index) => {
        maxBuy += item.maxBuy;
    });

    useEffect(() => {
        dispatch(allActions.inoAction.getInoLuanchpad());
        if(campaignSelected) {
            dispatch(allActions.inoWhitelistAction.getPriorityListByCampaignId(campaignSelected));
            onChangCampaign(campaignSelected);
        }
        
    },[campaignSelected]);

    const onChangCampaign = (id) => {
        if(id) {
            setSearchParams({'campaign': id});
            setCampaignSelected(id);
        }
    }

    const onChangeFileUpload = (e) => { 
        setIsLoading(true);
        let file = e.target.files[0];
        const formData = new FormData();
        formData.append("file", file);
        formData.append("inoLaunchpadId", campaignId);

        axios({
            method: 'post',
            url: process.env.REACT_APP_API_URL + `/ino-launchpads/${campaignId}/upload-priority-list`,
            headers: {
                'accept': '*/*',
                "Authorization": `Bearer ${localStorage.getItem(Tokens.ADMIN)}`,
                "Content-Type": "multipart/form-data"
            },
            data: formData,
        }).then(res => {
            if(res.status === 200 && res.data === dataStatus.SUCCESS){
                dispatch(allActions.inoWhitelistAction.getPriorityListByCampaignId(campaignId));
                setTimeout(() => {
                    Store.addNotification(addNotify('File upload successfully!', 'success'));
                    setIsUpload(false);
                    setIsLoading(false);
                }, 3000);
            }
            else {
                setIsLoading(false);
                setIsUpload(false);
                Store.addNotification(addNotify('File upload failed!', 'danger'));
                return;
            }
        }).catch(err => {
            setIsLoading(false);
            setIsUpload(false);
            Store.addNotification(addNotify('File upload failed! ' + err?.response?.data?.title, 'danger'));
            return;
        });
    }

    return(
        <>
        {isUpload ? 
        (<div className="c-popup">
                <div className="c-popup__box">
                    <div className="c-popup__close" onClick={() => setIsUpload(false)}><img src="../images/icon-close.svg" alt="Close" /></div>
                    <h3 className="c-popup__title">Upload priority list</h3>
                    <div className="c-popup__content">
                        <div className="c-uploadFile">
                            <Form
                                name="basic"
                                form={form}
                                labelCol={{ span: 8 }}
                                wrapperCol={{ span: 16 }}
                                autoComplete="off"
                                onSubmit = {e => e.preventDefault()}
                            >
                                <div className="c-uploadFile__box" onClick={() => inputFileUpload.current.click()}>
                                    <img src="../images/icon-cloud-upload.svg" alt="Upload" />
                                    <p>Choose file here</p>
                                </div>

                                <input ref={inputFileUpload} onChange={onChangeFileUpload} type="file" /> 
                            </Form>
                        </div>
                    </div>             
                </div>
            </div>) : ''}
        <div className="p-whitelist">
            {isLoading && <Loading />}
            <div className="p-whitelist__head">
                <Title title='Priority list' subTitle='' />
                <div className="p-whitelist__btn">
                    <Button title='Upload' type='primary' iconLeft='Upload' onClick={() => setIsUpload(true)}/>
                    <Button title='Template' type='flat' iconLeft='Download' onClick={() => window.location.href = 'https://review-image-app.s3.ap-southeast-1.amazonaws.com/1664113445567-ino_priority_list.xlsx'}/>
                    {/* <Button title='Whitelist' type='blueBorder' iconLeft='Refresh' onClick={() => navigate(`/ino-whitelist?campaign=${campaignId}`)} /> */}
                </div>
            </div>
            <div className="p-whitelist__boxHead">
                <div className="c-select">
                    <p className="c-select__title">Campaign</p>
                    <Select className="c-selectChange" defaultValue={campaignSelected ? parseInt(campaignSelected) : null} onChange={(value) => onChangCampaign(value)} style={{width: 260}} placeholder='Select Campaign'>
                        {inoList && inoList.length > 0 && inoList.map((item, index) => (
                            <Option key={index} value={item.id}>{item.title}</Option>
                        ))}
                    </Select>
                </div>
                <ul>
                    <li>
                        <span>Total Address<img src="../images/icon-gps.svg" alt="Total Address" /></span>
                        {inoPriorityList && inoPriorityList.length}
                    </li>
                    <li className="is-active">
                        <span>Total Box<img src="../images/icon-coin.svg" alt="Total Box" /></span>
                        {formatData(maxBuy, '')}
                    </li>
                </ul>
            </div>
            <PriorityListTable campaignId={campaignId} title='INO Priority List' data={inoPriorityList} />
        </div>
        </>
    )
}

export default PriorityList;